import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import citations from 'services/citations';
import sources from 'services/sources';

class SourcesCard extends Component {
  
  state = {
    citation: this.props.citation,
    sourceTypeOptions: this.props.sourceTypeOptions,
    searchString: this.props.searchString
   // key: this.props.key
  }

  render() {
    const { classes, className, id, type, source, citation, sourceTypeOptions} = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <div >
        <Paper onClick={event => this.addSource(id, type)} className={rootClassName}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="h4"
            >
              {source}
            </Typography>
          </div>
        </Paper>
      </div>
    );
  }

  async addSource(key, type){
    window.alert("Setting Source Value");
    if(type=="book"){
      this.state.searchString = '';
      this.state.citation.relationships.book = await sources.getBook(key);
      this.state.citation.relationships.periodical = null;
      this.state.citation.relationships.utterance = null;
      this.state.citation.relationships.website = null;
      this.state.citation.attributes.bookId = key;
      this.state.citation.attributes.periodicalId = null;
      this.state.citation.attributes.utteranceId = null;
      this.state.citation.attributes.websiteId = null;
      this.state.citation.attributes.sourceType = 'books';
      this.props.setSourceType('books');
    } else if(type=="periodical") {
      this.state.searchString = '';
      this.state.citation.relationships.periodical = await sources.getPeriodical(key);
      this.state.citation.relationships.book = null;
      this.state.citation.relationships.utterance = null;
      this.state.citation.relationships.website = null;
      this.state.citation.attributes.periodicalId = key;
      this.state.citation.attributes.bookId = null;
      this.state.citation.attributes.utteranceId = null;
      this.state.citation.attributes.websiteId = null;
      this.state.citation.attributes.sourceType = 'periodicals';;
      this.props.setSourceType('periodicals');
    } else if(type=="utterance") {
      this.state.searchString = '';
      this.state.citation.relationships.utterance = await sources.getUtterance(key);
      this.state.citation.relationships.book = null;
      this.state.citation.relationships.periodical = null;
      this.state.citation.relationships.website = null;
      this.state.citation.attributes.utteranceId = key;
      this.state.citation.attributes.periodicalId = null;
      this.state.citation.attributes.bookId = null;
      this.state.citation.attributes.websiteId = null;
      this.state.citation.attributes.sourceType = 'utterances';
      this.props.setSourceType('utterances');
    } else if(type=="website"){
      this.state.searchString = '';
      this.state.citation.relationships.website = await sources.getWebsite(key);
      this.state.citation.relationships.book = null;
      this.state.citation.relationships.periodical = null;
      this.state.citation.relationships.utterance = null;
      this.state.citation.attributes.websiteId = key;
      this.state.citation.attributes.periodicalId = null;
      this.state.citation.attributes.utteranceId = null;
      this.state.citation.attributes.bookId = null;
      this.state.citation.attributes.sourceType = 'websites';
      this.props.setSourceType('websites');
    }
    this.props.previewClicked();
    this.props.renderSourcePreview();
    this.props.onSearchChange('');
    window.alert("Source has been added to Quotation");

  }

}


SourcesCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  citation: PropTypes.object.isRequired,
};

export default withStyles(styles)(SourcesCard);

import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

let styles = theme => ({
  field: {
    margin: theme.spacing(3)
  },
  textField: {
    width: '420px',
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
});

class Website extends Component {

  constructor(props) {
    super(props);
    this.state = {
      website: {
        attributes: {
        }
      },
      citation: props.citation,
      error: null,
      typeOptions: [
        { value: '', label: ''},
        { value: 'Online (website)', label: 'Online (website)' },
        { value: 'Online (social media)', label: 'Online (social media)' },
      ]
    };
  }

  onChange(path, value) {
    let website = this.state.website;
    _.set(website, path, this.fromViewValue(value));
    this.setState({ website });
    this.state.citation.relationships.website = website;
  }

  onChangeType(path, value) {
    const { citation } = this.state;
    let website = this.state.website;
    _.set(website, path, this.fromViewValue(value));
    this.setState({ website });
    this.state.citation.relationships.website = website;
    this.props.updateTypeSelection(value);
  }

  fromViewValue(value) {
    return value === '' ? null : value;
  }

  componentWillMount(){
    if(this.state.website){
      this.state.website.attributes = {};
    }
  }

  componentWillUnmount(){
    this.state.website = {
      attributes: {
      }
    }
    this.state.citation.relationships.website = undefined;
  }

  render() {
    const { classes, website } = this.props;
    return (
      <form autoComplete="off" noValidate>
        <div className={classes.field}>
        <TextField
            className={classes.textField}
            label="Type"
            margin="dense"
            required
            select
            SelectProps={{ native: true }}
            value={website.attributes.type || ''}
            onChange={(event) => this.onChangeType('attributes.type', event.target.value)}
            variant="outlined">
            {this.state.typeOptions.map(option => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
           <TextField
            className={classes.textField}
            label="Site Name"
            margin="dense"
            required
            value={website.attributes.siteName || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.siteName', event.target.value)}
          />
           <TextField
            className={classes.textField}
            label="DSL Bibliographical Reference ID"
            margin="dense"
            value={website.attributes.dslBibliographyRef || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.dslBibliographyRef', event.target.value)}
          />
        </div>
      </form>
    );
  }
}

Website.propTypes = {
  citation: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  website: PropTypes.object
};

Website.defaultProps = {
  website: {
    attributes: {},
    relationships: {}
  }
};


export default withStyles(styles)(Website);

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import deletion from 'services/deletion';
// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles, Button } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

class PeriodicalCard extends Component {
  render() {
    const { classes, className, periodical, citations} = this.props;

    const rootClassName = classNames(classes.root, className);

   // let citationsCount = periodical.relationships.citations.count;
   let numCitations1 = citations.find(c => c.periodicalId === periodical.id);
   let numCitations = numCitations1 ? numCitations1.count : 0
    return (
        <Paper className={rootClassName} onClick={event => this.chooseEntry(true)}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="h4"
            >
              {this.title()}
            </Typography>
            <Typography
              className={classes.description}
              variant="body1"
            >
              {this.search()}
              {numCitations + " Quotations"}
            </Typography>
          </div>
        </Paper>

    );
  }
  
  chooseEntry(keep){
    this.props.chooseQuotation(keep, this.props.periodical);
  }

  delete(){
    const { classes, className, periodical, citations } = this.props;

    if(window.confirm("Are you sure you want to permanantly delete this entry?")){
        let test = deletion.deletePeriodical(periodical);
        test.then(( value ) => 
        { 
          window.alert("Entry Deleted");
          window.location.reload();
        }
        ).catch ((error) => 
        {
          window.alert("Please delete all quotations for this periodical first");
        });
    }
  }
  
  quotes(){
    let visibleHeadwords = Object.values(this.props.citations.attributes).filter(citation => citation.periodicalId==this.props.periodical.id);
    return (visibleHeadwords.length + " Quotations");
  }

  filter(text){
    const {searchString} = this.props;
    var title="";
    let match = true;
    if(searchString.length==1){
      title = "";
      for (var i = 0; i<text.length; i++){
        if(searchString.toLowerCase().includes(text.charAt(i).toLowerCase())){
          title = title + "<mark>"+text.charAt(i)+"</mark>";
        } else {
          title = title + text.charAt(i);
        }
      }
    } else if(searchString.length>1){
      title = "";
      for (var i = 0; i<text.length; i++){
        if(searchString.charAt(0).toLowerCase()==(text.charAt(i).toLowerCase())){
          for(var j = 1; j<searchString.length; j++){
            if(i+j<text.length && searchString.charAt(j).toLowerCase()==(text.charAt(i+j).toLowerCase())){
              match = true;
            } else {
              match = false;
              title = title + text.substring(i, i+j+1);
              i = i+j;
              break;
            }
          }
          if(match){
            title = title + "<mark>"+text.substring(i, i+j)+"</mark>" + text.charAt(i+j);
            i = i+j;
          }
        } else {
          title = title + text.charAt(i);
        }
      }
    } else {
      title = text;
    }
      return(title);
  }

  title(){
    if(!this.props.typeCheck){
      if(this.props.periodical.title!=undefined){
         let title = this.filter(this.props.periodical.title);
        return(
          <div>
          <div dangerouslySetInnerHTML={{__html:title}}></div>
          </div>
        );
      } 
    } else {
        return(
          this.props.periodical.title
        );
    }
  }

  search(){
    if(this.props.typeCheck){
      if(this.props.periodical.type!=null){
        let type = "Type: " + this.filter(this.props.periodical.type);
        return(
          <div>
          <div dangerouslySetInnerHTML={{__html:type}}></div>
          </div>
        );
      }
    }
  }
}

PeriodicalCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  periodical: PropTypes.object.isRequired
};

export default withStyles(styles)(PeriodicalCard);

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import deletion from 'services/deletion';
import service from 'services/citations';
// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography, Button } from '@material-ui/core';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

class QuotationCard extends Component {
  render() {
    const { classes, className, citation, citations } = this.props;

    const rootClassName = classNames(classes.root, className);

   // let citationsCount = citation.relationships.citations.count;

    return (

      <Paper className={rootClassName}>
        <Link to={`/citations/${citation.id}`}>
          <div className={classes.details}>
            <Typography
              className={classes.description}
              variant="body1"
            >
              
             {this.search()}
             
            </Typography>
          </div>

        </Link>
        <div>
        <Button style={{marginTop:'13px'}} onClick={event => this.delete()} color="primary" size="small" variant="outlined">Delete</Button>
        <Button style={{marginLeft:'20px', marginTop:'13px'}} onClick={event => this.duplicate()} color="primary" size="small" variant="outlined">Duplicate</Button>
        </div>
      </Paper>
    );
  }

  async duplicate(){
    const { classes, className, citation, citations } = this.props;
    if(window.confirm("Are you sure you want to duplicate this entry?")){
      let citationToDup = await service.getCitation(citation.id);
      citationToDup.id = undefined;
      await service.createCitation(citationToDup);
      window.alert("Entry Duplicated");
      window.location.reload();
    }
  }

  delete(){
    const { classes, className, citation, citations } = this.props;

    if(window.confirm("Are you sure you want to permanantly delete this entry?")){
      deletion.deleteCitation(citation);
      window.alert("Entry Deleted");
      window.location.reload();
    }
  }

  filter(text){
    const {searchString} = this.props;
    var title="";
    let match = true;
    if(searchString.length==1){
      title = "";
      for (var i = 0; i<text.length; i++){
        if(searchString.toLowerCase().includes(text.charAt(i).toLowerCase())){
          title = title + "<mark>"+text.charAt(i)+"</mark>";
        } else {
          title = title + text.charAt(i);
        }
      }
    } else if(searchString.length>1){
      title = "";
      for (var i = 0; i<text.length; i++){
        if(searchString.charAt(0).toLowerCase()==(text.charAt(i).toLowerCase())){
          for(var j = 1; j<searchString.length; j++){
            if(i+j<text.length && searchString.charAt(j).toLowerCase()==(text.charAt(i+j).toLowerCase())){
              match = true;
            } else {
              match = false;
              title = title + text.substring(i, i+j+1);
              i = i+j;
              break;
            }
          }
          if(match){
            title = title + "<mark>"+text.substring(i, i+j)+"</mark>" + text.charAt(i+j);
            i = i+j;
          }
        } else {
          title = title + text.charAt(i);
        }
      }
    } else {
      title = text;
    }
      return(title);
  }

  search(){
    const {searchString, authorCheck, editorCheck, publishedCheck, 
      recordingCheck, articleCheck, regionCheck, notesCheck, legacyNotesCheck, createdByCheck, lastModifiedByCheck, sources, merge} = this.props;
    if(authorCheck){
      var author="Author(s): ";
      let text = Object.values(sources.attributes).filter(t => t.author != null && this.props.citation.bookId==t.id);
      if(this.props.citation.author!=null && this.props.citation.author!=""){
        let title = author + "<b>" + this.filter(this.props.citation.author) + "</b>";
        return(
          <div>
            <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
          <div dangerouslySetInnerHTML={{__html:title}}></div>
        </div>
        );
      } else if(text[0].author!=null && text[0].author!=""){
        let title = author + "<b>" + this.filter(text[0].author) + "</b>";
        return(
          <div>
            <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
          <div dangerouslySetInnerHTML={{__html:title}}></div>
        </div>
        );
      }
      return(
        <div>
          <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
        <div dangerouslySetInnerHTML={{__html:author}}></div>
        </div>
      );
    } else if(editorCheck){
      var editor="Editor(s): ";
      let text = Object.values(sources.attributes).filter(t => t.editor != null && this.props.citation.bookId==t.id);
      if(this.props.citation.editor!=null && this.props.citation.editor!=""){
        let title = editor + "<b>" + this.filter(this.props.citation.editor) + "</b>";
        return(
          <div>
            <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
          <div dangerouslySetInnerHTML={{__html:title}}></div>
        </div>
        );
      } else if(text[0].editor!=null && text[0].editor!=""){
        let title = editor + "<b>" + this.filter(text[0].editor) + "</b>";
        return(
          <div>
            <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
          <div dangerouslySetInnerHTML={{__html:title}}></div>
        </div>
        );
      }
      return(
        <div>
          <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
        <div dangerouslySetInnerHTML={{__html:editor}}></div>
        </div>
      );
    } else if(publishedCheck){
      var publishedDate="Date Published: ";
      if(this.props.citation.publishedDate!=null && this.props.citation.publishedDate!=""){
        let title = "Date Published: " + "<b>" + this.filter(this.props.citation.publishedDate) + "</b>";
        return(
          <div>
          <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
          <div dangerouslySetInnerHTML={{__html:title}}></div>
        </div>
        );
      }
      return(
        <div>
          <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
        <div dangerouslySetInnerHTML={{__html:publishedDate}}></div>
        </div>
      );
    } else if(legacyNotesCheck){
      var publishedDate="Legacy Notes: ";
      if(this.props.citation.legacyNotes!=null && this.props.citation.legacyNotes!=""){
        let title = "Legacy Notes: " + "<b>" + this.filter(this.props.citation.legacyNotes) + "</b>";
        return(
          <div>
          <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
          <div dangerouslySetInnerHTML={{__html:title}}></div>
        </div>
        );
      }
      return(
        <div>
          <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
        <div dangerouslySetInnerHTML={{__html:publishedDate}}></div>
        </div>
      );
    } else if(notesCheck){
      var publishedDate="Notes: ";
      if(this.props.citation.notes!=null && this.props.citation.notes!=""){
        let title = "Notes: " + "<b>" + this.filter(this.props.citation.notes) + "</b>";
        return(
          <div>
          <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
          <div dangerouslySetInnerHTML={{__html:title}}></div>
        </div>
        );
      }
      return(
        <div>
          <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
        <div dangerouslySetInnerHTML={{__html:publishedDate}}></div>
        </div>
      );
    } else if(recordingCheck){
      if(this.props.citation.recordingDate!=null && this.props.citation.recordingDate!=""){
        let title = "Date Recorded (Utterance): " + "<b>" + this.filter(this.props.citation.recordingDate) + "</b>";
        return(
          <div>
          <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
          <div dangerouslySetInnerHTML={{__html:title}}></div>
        </div>
        );
      }
    } else if(articleCheck){
      if(this.props.citation.articleTitle!=null && this.props.citation.articleTitle!=""){
        let title = "Article Title: " + "<b>" + this.filter(this.props.citation.articleTitle) + "</b>";
        return(
          <div>
          <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
          <div dangerouslySetInnerHTML={{__html:title}}></div>
        </div>
        );
      }
    } else if(regionCheck){
      if(this.props.citation.region!=null && this.props.citation.region!=""){
        let title = "Region: " + "<b>" + this.filter(this.props.citation.region) + "</b>";
        return(
          <div>
          <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
          <div dangerouslySetInnerHTML={{__html:title}}></div>
        </div>
        );
      }
    } else if(createdByCheck){
      if(this.props.citation.createdBy!=null && this.props.citation.createdBy!=""){
        let title = "Quotation Created By: " + "<b>" + this.filter(this.props.citation.createdBy) + "</b>";
        return(
          <div>
          <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
          <div dangerouslySetInnerHTML={{__html:title}}></div>
        </div>
        );
      }
    } else if(lastModifiedByCheck){
      if(this.props.citation.lastModifiedBy!=null && this.props.citation.lastModifiedBy!=""){
        let title = "Quotation Last Modified By: " + "<b>" + this.filter(this.props.citation.lastModifiedBy) + "</b>";
        return(
          <div>
          <div dangerouslySetInnerHTML={{__html:this.props.citation.quotation}}></div>
          <div dangerouslySetInnerHTML={{__html:title}}></div>
        </div>
        );
      }
    } else {
      if(this.props.citation.quotation!=undefined){
        let title = this.filter(this.props.citation.quotation);
        return(
          <div>
            <div dangerouslySetInnerHTML={{__html:title}}></div>
          </div>
        );
      } 
    }
  }
}

QuotationCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  citation: PropTypes.object.isRequired
};

export default withStyles(styles)(QuotationCard);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, FormGroup, withStyles } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { DisplayMode, SearchInput } from 'components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  Button,
  IconButton,
  CircularProgress,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon
} from '@material-ui/icons';
import { Dashboard as DashboardLayout } from 'layouts';
import service from 'services/headwords';
import citation from 'services/citations';
import BookCard from './components/BookCard';
import PeriodicalCard from './components/PeriodicalCard';
import UtteranceCard from './components/UtteranceCard';
import WebsiteCard from './components/WebsiteCard';
import QuotationCard from './components/QuotationCard';
import KeywordCard from './components/KeywordCard';
import { state as appState } from 'App';
import { TextField } from '@material-ui/core';
import styles from './styles';
import headwords from 'services/headwords';
import ac from 'services/allCitations';

class Search extends Component {
  signal = true;

  state = {
    mergeSelected: false,
    isLoading: false,
    exactSearch: false,
    limit: 6,
    sources: [],
    searchString: '',
    selectedLetter: null,
    error: null,
    citations: { type: "arrays", attributes: {}},
    headwords: {},
    user: appState.get('user'),
    sourceOptions: [
     // { value: 'All', label: 'All'},
      { value: 'Texts', label: 'Texts' },
      { value: 'Periodicals', label: 'Periodicals' },
      { value: 'Utterances', label: 'Utterances' },
      { value: 'Websites', label: 'Websites' },
      { value: 'Quotations', label: 'Quotations'},
      { value: 'Keywords', label: 'Keywords'}
    ],
    sourceType: 'Texts',
    minChars: 4,
    minCharOptions: [
      { value: 0, label: '0'},
      { value: 1, label: '1'},
      { value: 2, label: '2'},
      { value: 3, label: '3' },
      { value: 4, label: '4'},
      { value: 5, label: '5'},
      { value: 6, label: '6'},
      { value: 7, label: '7'},
      { value: 8, label: '8'},
      { value: 9, label: '9' },
      { value: 10, label: '10'},
    ],
    POSCheck: false,
    authorCheck: false, 
    editorCheck: false, 
    publishedCheck:false, 
    recordingCheck: false, 
    articleCheck:false, 
    regionCheck:false, 
    yearCheck:false, 
    typeCheck:false,
    notInDSLCheck:false,
    sentOnCheck:false,
    legacyNotesCheck:false,
    notesCheck:false,
    otherCheck:false,
    kwInDslCheck:false,
    kwNotInDslCheck:false,
    createdByCheck:false,
    lastModifiedByCheck:false,
    visibleBooks:[],
    visiblePeriodicals:[],
    visibleUtterances: [],
    visibleWebsites: [],
    visibleKeywords: [],
    visibleQuotations: [],
    currPosition: 0,
    currDisplayed: [],
    progress: 0,
    progressLabel: "",
    citationCount: null,
    citationsArray: []
  };
  


  async getData(limit) {
    try {      
      this.setState({ isLoading: true });

      this.setState({
        searchString: localStorage.getItem('CTSearchString') ? localStorage.getItem('CTSearchString') : '',
        sourceType: localStorage.getItem('CTSourceType') ? localStorage.getItem('CTSourceType') : 'Texts',
        minChars: localStorage.getItem('CTMinChars') ? parseInt(localStorage.getItem('CTMinChars')) : 4,
        POSCheck: localStorage.getItem('CTPOSCheck') ? (localStorage.getItem('CTPOSCheck')=='true') : false,
        authorCheck: localStorage.getItem('CTAuthorCheck') ? (localStorage.getItem('CTAuthorCheck')=='true') : false,
        editorCheck: localStorage.getItem('CTEditorCheck') ? (localStorage.getItem('CTEditorCheck')=='true') : false,
        publishedCheck: localStorage.getItem('CTPublishedCheck') ? (localStorage.getItem('CTPublishedCheck')=='true') : false,
        recordingCheck: localStorage.getItem('CTRecordingCheck') ? (localStorage.getItem('CTRecordingCheck')=='true') : false,
        articleCheck: localStorage.getItem('CTArticleCheck') ? (localStorage.getItem('CTArticleCheck')=='true') : false,
        regionCheck: localStorage.getItem('CTRegionCheck') ? (localStorage.getItem('CTRegionCheck')=='true') : false,
        yearCheck: localStorage.getItem('CTYearCheck') ? (localStorage.getItem('CTYearCheck')=='true') : false, 
        typeCheck: localStorage.getItem('CTTypeCheck') ? (localStorage.getItem('CTTypeCheck')=='true') : false,
        notInDSLCheck: localStorage.getItem('CTNotInDSLCheck') ? (localStorage.getItem('CTNotInDSLCheck')=='true') : false,
        sentOnCheck: localStorage.getItem('CTSentOnCheck') ? (localStorage.getItem('CTSentOnCheck')=='true') : false,
        legacyNotesCheck: localStorage.getItem('CTLegacyNotesCheck') ? (localStorage.getItem('CTLegacyNotesCheck')=='true') : false,
        notesCheck: localStorage.getItem('CTNotesCheck') ? (localStorage.getItem('CTNotesCheck')=='true') : false,
        otherCheck: localStorage.getItem('CTOtherCheck') ? (localStorage.getItem('CTOtherCheck')=='true') : false,
        kwInDslCheck: localStorage.getItem('CTKwInDslCheckCheck') ? (localStorage.getItem('CTKwInDslCheckCheck')=='true') : false,
        kwNotInDslCheck: localStorage.getItem('CTKwNotInDslCheck') ? (localStorage.getItem('CTKwNotInDslCheck')=='true') : false,
        createdByCheck: localStorage.getItem('CTCreatedByCheck') ? (localStorage.getItem('CTCreatedByCheck')=='true') : false,
        lastModifiedByCheck: localStorage.getItem('CTLastModifiedByCheck') ? (localStorage.getItem('CTLastModifiedByCheck')=='true') : false
      });

      let prevSourceType = localStorage.getItem('CTSourceType');

      if(prevSourceType!=null && prevSourceType!='Texts'){
        this.onChange(prevSourceType);
      } else {
        this.setState({ progress: 0, progressLabel: "getting texts" });
        const allSources = await citation.getBooks();
        this.setState({progress: 70, progressLabel: "getting texts' citations"})
        this.state.citationCount = await ac.getBookCitationsCount();

        /*this.setState({ progress: 0, progressLabel: "fetching headwords" });
        this.state.headwords = await headwords.getHeadwords(limit);
        this.setState({ progress: 30, progressLabel: "fetching sources" });
        const allSources = await citation.getCitations(limit);
        this.setState({ progress: 50, progressLabel: "fetching citations" });
        this.state.citations = await ac.getAllCitations();
        this.setState({ progress: 100 }); */

        if (this.signal) {
          this.setState({
            isLoading: false,
            sources: allSources,
            limit
          });
        }
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  selectLetter(selectedLetter) {
    this.setState({ selectedLetter });
  }

  
  onSearchChange(searchString) {
    localStorage.setItem('CTSearchString', searchString);
    this.setState({ searchString });
   // console.log(searchString);
  }

  componentWillMount() {
    this.signal = true;

    const { limit } = this.state;

    this.getData(limit);
    this._unwatch = appState.watch('user', user => {
      this.setState({ user });
    });
  }

  componentWillUnmount() {
    this._unwatch();
    this.signal = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if((localStorage.getItem('clearingSearches')=='true')){  
      localStorage.removeItem('clearingSearches');
    } else {
      localStorage.setItem('CTPOSCheck', this.state.POSCheck.toString()); 
      localStorage.setItem('CTAuthorCheck', this.state.authorCheck.toString());
      localStorage.setItem('CTEditorCheck', this.state.editorCheck.toString());
      localStorage.setItem('CTPublishedCheck', this.state.publishedCheck.toString());
      localStorage.setItem('CTRecordingCheck', this.state.recordingCheck.toString());
      localStorage.setItem('CTArticleCheck', this.state.articleCheck.toString());
      localStorage.setItem('CTRegionCheck', this.state.regionCheck.toString());
      localStorage.setItem('CTYearCheck', this.state.yearCheck.toString());
      localStorage.setItem('CTTypeCheck', this.state.typeCheck.toString());
      localStorage.setItem('CTNotInDSLCheck', this.state.notInDSLCheck.toString());
      localStorage.setItem('CTSentOnCheck', this.state.sentOnCheck.toString());
      localStorage.setItem('CTLegacyNotesCheck', this.state.legacyNotesCheck.toString());
      localStorage.setItem('CTNotesCheck', this.state.notesCheck.toString());
      localStorage.setItem('CTOtherCheck', this.state.otherCheck.toString());
      localStorage.setItem('CTKwInDslCheckCheck', this.state.kwInDslCheck,toString());
      localStorage.setItem('CTKwNotInDslCheck', this.state.kwNotInDslCheck.toString());
      localStorage.setItem('CTCreatedByCheck', this.state.createdByCheck.toString());
      localStorage.setItem('CTLastModifiedByCheck', this.state.lastModifiedByCheck.toString());
    }
  }

  canEdit() {
    return this.state.user && this.state.user.attributes.roles.includes('editor');
  }

  renderBooks() {
    const { classes, selectedUsers  } = this.props;
    const { isLoading, sources, selectedLetter, searchString, authorCheck, editorCheck, yearCheck,
       typeCheck, notesCheck, legacyNotesCheck, otherCheck, currPosition } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }
    if(authorCheck){
      this.state.visibleBooks = Object.values(sources.attributes).filter(book => book.author!=null && (book.author.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visibleBooks.sort((a, b) =>  a.title.localeCompare(b.title));
    } else if(typeCheck){
      this.state.visibleBooks = Object.values(sources.attributes).filter(book => book.type!=null && (book.type.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visibleBooks.sort((a, b) =>  a.title.localeCompare(b.title));
    } else if(editorCheck){
      this.state.visibleBooks = Object.values(sources.attributes).filter(book => book.editor!=null && (book.editor.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visibleBooks.sort((a, b) =>  a.title.localeCompare(b.title));
    } else if(yearCheck){
      this.state.visibleBooks = Object.values(sources.attributes).filter(book => book.yearPublished!=null && (book.yearPublished.toString().includes(searchString)));
      this.state.visibleBooks.sort((a, b) =>  a.title.localeCompare(b.title));
    } else if(notesCheck){
      this.state.visibleBooks = Object.values(sources.attributes).filter(book => book.notes!=null && book.notes!="" && (book.notes.toString().includes(searchString)));
      this.state.visibleBooks.sort((a, b) =>  a.title.localeCompare(b.title));
    } else if(legacyNotesCheck){
      this.state.visibleBooks = Object.values(sources.attributes).filter(book => book.legacyNotes!=null && book.legacyNotes!="" &&(book.legacyNotes.toString().includes(searchString)));
      this.state.visibleBooks.sort((a, b) =>  a.title.localeCompare(b.title));
    } else if(otherCheck){
      this.state.visibleBooks = Object.values(sources.attributes).filter(book => book.other!=null && book.other!="" && (book.title.toString().includes(searchString)));
      this.state.visibleBooks.sort((a, b) =>  a.title.localeCompare(b.title));
    } else {
      this.state.visibleBooks = Object.values(sources.attributes).filter(book => book.title!=null && (book.title.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visibleBooks.sort((a, b) =>  a.title.localeCompare(b.title));
    }  
    

    if (this.state.visibleBooks.length === 0) {
      return (
        <div>
        <Typography variant="h6">No books to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleBooks.length > 249) {
      if(this.state.visibleBooks.length > currPosition+250){
        this.state.currDisplayed = this.state.visibleBooks.slice(currPosition, currPosition + 250);
      } else {
        this.state.currDisplayed = this.state.visibleBooks.slice(currPosition);
      }
      return (
        this.state.currDisplayed.map(book => (
          <BookCard key={book.id} book={book} citations={this.state.citationCount} searchString={searchString} 
          authorCheck={authorCheck} editorCheck={editorCheck} yearCheck={yearCheck} 
          notesCheck={notesCheck} legacyNotesCheck={legacyNotesCheck} otherCheck={otherCheck} typeCheck = {typeCheck} merge={false} />
        ))
        );  
    } else {
      return (
        this.state.visibleBooks.map(book => (
          <BookCard key={book.id} book={book} citations={this.state.citationCount} searchString={searchString} 
          authorCheck={authorCheck} editorCheck={editorCheck} yearCheck={yearCheck}
          notesCheck={notesCheck} legacyNotesCheck={legacyNotesCheck} otherCheck={otherCheck} typeCheck = {typeCheck} merge={false} />
        ))
      ); 
    } 
  }

  renderPeriodicals(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, sources, selectedLetter, searchString, typeCheck, notesCheck, legacyNotesCheck, currPosition } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if(typeCheck){
      this.state.visiblePeriodicals = Object.values(sources.attributes).filter(periodical => periodical.type != null && (periodical.type.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visiblePeriodicals.sort((a, b) =>  a.title.localeCompare(b.title));   
    } else if(notesCheck){
      this.state.visiblePeriodicals = Object.values(sources.attributes).filter(periodical => periodical.notes != null && periodical.notes != "" && (periodical.notes.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visiblePeriodicals.sort((a, b) =>  a.title.localeCompare(b.title));   
    } else if(legacyNotesCheck){
      this.state.visiblePeriodicals = Object.values(sources.attributes).filter(periodical =>  periodical.legacyNotes != null && periodical.legacyNotes != "" && (periodical.legacyNotes.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visiblePeriodicals.sort((a, b) =>  a.title.localeCompare(b.title));   
    } else {
      this.state.visiblePeriodicals = Object.values(sources.attributes).filter(periodical => periodical.title != null && (periodical.title.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visiblePeriodicals.sort((a, b) =>  a.title.localeCompare(b.title));   
    }
    if (this.state.visiblePeriodicals.length === 0) {
      return (
        <div>
        <Typography variant="h6">No periodicals to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visiblePeriodicals.length > 249) {
      if(this.state.visiblePeriodicals.length > currPosition+250){
        this.state.currDisplayed = this.state.visiblePeriodicals.slice(currPosition, currPosition + 250);
      } else {
        this.state.currDisplayed = this.state.visiblePeriodicals.slice(currPosition);
      }
      return (
        this.state.currDisplayed.map(periodical => (
          <PeriodicalCard key={periodical.id} periodical={periodical} citations={this.state.citationCount} 
          typeCheck={typeCheck} notesCheck={notesCheck} legacyNotesCheck={legacyNotesCheck} searchString={searchString} merge={false} />
        ))
        );  
    } else {
      return (
        this.state.visiblePeriodicals.map(periodical => (
          <PeriodicalCard key={periodical.id} periodical={periodical} citations={this.state.citationCount} 
          typeCheck={typeCheck} notesCheck={notesCheck} legacyNotesCheck={legacyNotesCheck} searchString={searchString} merge={false} />
        ))
      ); 
    } 
  }

  renderUtterances(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, sources, selectedLetter, searchString, currPosition} = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    this.state.visibleUtterances = Object.values(sources.attributes).filter(utterance => utterance.siteName != null && (utterance.siteName.toLowerCase().includes(searchString.toLowerCase())));
    this.state.visibleUtterances.sort((a, b) =>  a.siteName.localeCompare(b.siteName));    

    if (this.state.visibleUtterances.length === 0) {
      return (
        <div>
        <Typography variant="h6">No utterances to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleUtterances.length > 249) {
      if(this.state.visibleUtterances.length > currPosition+250){
        this.state.currDisplayed = this.state.visibleUtterances.slice(currPosition, currPosition + 250);
      } else {
        this.state.currDisplayed = this.state.visibleUtterances.slice(currPosition);
      }
      return (
        this.state.currDisplayed.map(utterance => (
          <UtteranceCard key={utterance.id} utterance={utterance} citations={this.state.citationCount} searchString={searchString} merge={false} />
        ))
        );  
    } else {
      return (
        this.state.visibleUtterances.map(utterance => (
          <UtteranceCard key={utterance.id} utterance={utterance} citations={this.state.citationCount} searchString={searchString} merge={false} />
        ))
      );  
    }
  }

  renderWebsites(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, sources, legacyNotesCheck, selectedLetter, searchString, currPosition} = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }
    if(!legacyNotesCheck){
      this.state.visibleWebsites = Object.values(sources.attributes).filter(website => website.siteName != null && (website.siteName.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visibleWebsites.sort((a, b) =>  a.siteName.localeCompare(b.siteName));   
    } else {
      this.state.visibleWebsites = Object.values(sources.attributes).filter(website => website.legacyNotes != null && website.legacyNotes != "" && (website.legacyNotes.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visibleWebsites.sort((a, b) =>  a.siteName.localeCompare(b.siteName)); 
    }

    if (this.state.visibleWebsites.length === 0) {
      return (
        <div>
        <Typography variant="h6">No websites to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleWebsites.length > 249) {
      if(this.state.visibleWebsites.length > currPosition+250){
        this.state.currDisplayed = this.state.visibleWebsites.slice(currPosition, currPosition + 250);
      } else {
        this.state.currDisplayed = this.state.visibleWebsites.slice(currPosition);
      }
      return (
        this.state.currDisplayed.map(website => (
          <WebsiteCard key={website.id} website={website} citations={this.state.citationCount} searchString={searchString} legacyNotesCheck={legacyNotesCheck} merge={false} />
        ))
        );  
    } else {
      return (
        this.state.visibleWebsites.map(website => (
          <WebsiteCard key={website.id} website={website} citations={this.state.citationCount} searchString={searchString} legacyNotesCheck={legacyNotesCheck} merge={false} />
        ))
      ); 
    } 
  }

  renderQuotations(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, citations, sources, searchString, authorCheck, 
      editorCheck, publishedCheck, recordingCheck, articleCheck, regionCheck,
       notInDSLCheck, sentOnCheck, notesCheck, legacyNotesCheck, createdByCheck, lastModifiedByCheck, currPosition } = this.state;

    if(authorCheck){
      this.state.visibleQuotations = [];
      this.state.visibleQuotations =  Object.values(citations.attributes).filter(citation => citation.author!=null &&  citation.author!="" && (citation.author.toLowerCase().includes(searchString.toLowerCase())));
      let other = Object.values(sources.attributes).filter(t => t.author != null && t.author != "" && (t.type == "Text (other)" && t.author.toLowerCase().includes(searchString.toLowerCase())));
      let onlyQuotesWithBooks = Object.values(citations.attributes).filter(citation => citation.bookId != null && citation.bookId != "");
      console.log(other.length)
      console.log(onlyQuotesWithBooks.length)
      other.map(text => {
        let temp = onlyQuotesWithBooks.filter(citation => citation.bookId == text.id);
        if(temp[0] !=null){
            this.state.visibleQuotations.push(temp[0]);
        }
      });
      let cleanedQuotations = this.state.visibleQuotations.map(q => {
        q.quotation = q.quotation.replace(/(<([^>]+)>)/ig,"").replace(/\&nbsp;/g, ' ');
        return q
      });
      cleanedQuotations.sort((a, b) =>  a.quotation.localeCompare(b.quotation));
      let uniq = [...new Set(cleanedQuotations)];
      this.state.visibleQuotations = Array.from(uniq);
    } else if(editorCheck){
      this.state.visibleQuotations = [];
      let other = Object.values(sources.attributes).filter(t => t.editor != null && (t.type == "Text (anthology/collection)" && t.editor.toLowerCase().includes(searchString.toLowerCase())));
      let onlyQuotesWithBooks = Object.values(citations.attributes).filter(citation => citation.bookId != null && citation.bookId != "");
      other.map(text => {
        let temp = onlyQuotesWithBooks.filter(citation => citation.bookId == text.id);
        if(temp[0] !=null){
            this.state.visibleQuotations.push(temp[0]);
        }
      });
      let cleanedQuotations = this.state.visibleQuotations.map(q => {
        q.quotation = q.quotation.replace(/(<([^>]+)>)/ig,"").replace(/\&nbsp;/g, ' ');
        return q
      });
      cleanedQuotations.sort((a, b) =>  a.quotation.localeCompare(b.quotation));  
      let uniq = [...new Set(cleanedQuotations)];
      this.state.visibleQuotations = Array.from(uniq); 
    } else if(publishedCheck){
      this.state.visibleQuotations = Object.values(citations.attributes).filter(citation => citation.publishedDate!=null && citation.publishedDate!="" && (citation.publishedDate.toString().includes(searchString)));
      this.state.visibleQuotations.sort((a, b) =>  a.quotation.localeCompare(b.quotation));     
    } else if(recordingCheck){
      this.state.visibleQuotations = Object.values(citations.attributes).filter(citation => citation.recordingDate!=null && citation.recordingDate!="" && (citation.recordingDate.toString().includes(searchString)));
      this.state.visibleQuotations.sort((a, b) =>  a.quotation.localeCompare(b.quotation)); 
    } else if(articleCheck){
      this.state.visibleQuotations = Object.values(citations.attributes).filter(citation => citation.articleTitle!=null && citation.articleTitle!="" && (citation.articleTitle.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visibleQuotations.sort((a, b) =>  a.quotation.localeCompare(b.quotation)); 
    } else if(regionCheck) {
      this.state.visibleQuotations = Object.values(citations.attributes).filter(citation => citation.region!=null && citation.region!="" && (citation.region.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visibleQuotations.sort((a, b) =>  a.quotation.localeCompare(b.quotation)); 
    } else if(notesCheck) {
      this.state.visibleQuotations = Object.values(citations.attributes).filter(citation => citation.notes!=null && citation.notes!="" && (citation.notes.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visibleQuotations.sort((a, b) =>  a.quotation.localeCompare(b.quotation)); 
    }else if(legacyNotesCheck) {
      this.state.visibleQuotations = Object.values(citations.attributes).filter(citation => citation.legacyNotes!=null && citation.legacyNotes!="" && (citation.legacyNotes.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visibleQuotations.sort((a, b) =>  a.quotation.localeCompare(b.quotation)); 
    } else if(notInDSLCheck) {
      this.state.visibleQuotations = Object.values(citations.attributes).filter(citation => citation.decision=="Rejected" && (citation.quotation.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visibleQuotations.sort((a, b) =>  a.quotation.localeCompare(b.quotation)); 
    } else if(sentOnCheck) {
      this.state.visibleQuotations = Object.values(citations.attributes).filter(citation => citation.decision=="Sent On" && (citation.quotation.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visibleQuotations.sort((a, b) =>  a.quotation.localeCompare(b.quotation)); 
    } else if(createdByCheck) {
      this.state.visibleQuotations = Object.values(citations.attributes).filter(citation => citation.createdBy!=null && (citation.createdBy.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visibleQuotations.sort((a, b) =>  a.quotation.localeCompare(b.quotation)); 
    } else if(lastModifiedByCheck) {
      this.state.visibleQuotations = Object.values(citations.attributes).filter(citation => citation.lastModifiedBy!=null && (citation.lastModifiedBy.toLowerCase().includes(searchString.toLowerCase())));
      this.state.visibleQuotations.sort((a, b) =>  a.quotation.localeCompare(b.quotation)); 
    } else {
      this.state.visibleQuotations = Object.values(citations.attributes).filter(citation => citation.quotation!=null && (citation.quotation.toLowerCase().includes(searchString.toLowerCase())));
      let cleanedQuotations = this.state.visibleQuotations.map(q => {
        q.quotation = q.quotation.replace(/(<([^>]+)>)/ig,"").replace(/\&nbsp;/g, ' ');
        return q
      });
      cleanedQuotations.sort((a, b) =>  a.quotation.localeCompare(b.quotation));   
      this.state.visibleQuotations = cleanedQuotations;
    }
    if (this.state.visibleQuotations.length === 0) {
      return (
        <div>
        <Typography variant="h6">No quotations to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleQuotations.length > 249) {
      if(this.state.visibleQuotations.length > currPosition+250){
        this.state.currDisplayed = this.state.visibleQuotations.slice(currPosition, currPosition + 250);
      } else {
        this.state.currDisplayed = this.state.visibleQuotations.slice(currPosition);
      }
      return (
        this.state.currDisplayed.map(citation => (
          <QuotationCard key={citation.id} citation={citation} citations={this.state.citations} 
          searchString={searchString} authorCheck={authorCheck} editorCheck={editorCheck} publishedCheck={publishedCheck}
          recordingCheck={recordingCheck} articleCheck={articleCheck} regionCheck={regionCheck} notesCheck={notesCheck} 
          legacyNotesCheck={legacyNotesCheck} createdByCheck={createdByCheck} lastModifiedByCheck={lastModifiedByCheck} sources={sources} merge={false} />
        ))
        );  
    } else {
      return (
        this.state.visibleQuotations.map(citation => (
          <QuotationCard key={citation.id} citation={citation} citations={this.state.citations} 
          searchString={searchString} authorCheck={authorCheck} editorCheck={editorCheck} publishedCheck={publishedCheck}
          recordingCheck={recordingCheck} articleCheck={articleCheck} regionCheck={regionCheck} notesCheck={notesCheck} 
          legacyNotesCheck={legacyNotesCheck} createdByCheck={createdByCheck} lastModifiedByCheck={lastModifiedByCheck} sources={sources} merge={false} />
        ))
      );  
    }
  }
 
  filterOnField(field, headword) {
    const { exactSearch, searchString } = this.state;
    if(exactSearch){
      return headword?.attributes?.[field]?.toLowerCase() === searchString.toLowerCase();
    } 
    return headword?.attributes?.[field]?.toLowerCase()?.includes(searchString.toLowerCase());
  };

  renderKeywords(){
    const { headwords, searchString, POSCheck, legacyNotesCheck, kwInDslCheck, kwNotInDslCheck, 
      createdByCheck, lastModifiedByCheck, currPosition } = this.state;

    if(POSCheck){
      this.state.visibleKeywords = Object.values(headwords).filter(headword => this.filterOnField('partOfSpeech', headword));
    } else if(legacyNotesCheck){
      this.state.visibleKeywords = Object.values(headwords).filter(headword => this.filterOnField('legacyNotes', headword));
    } else if(kwInDslCheck){
      this.state.visibleKeywords = Object.values(headwords).filter(headword => (headword.attributes.kwInDsl == "Yes") && this.filterOnField('headword', headword));   
    } else if(kwNotInDslCheck){
      this.state.visibleKeywords = Object.values(headwords).filter(headword => (headword.attributes.kwInDsl == "No") && this.filterOnField('headword', headword));
    } else if(createdByCheck){
      this.state.visibleKeywords = Object.values(headwords).filter(headword => this.filterOnField('createdBy', headword));
    } else if(lastModifiedByCheck){
      this.state.visibleKeywords = Object.values(headwords).filter(headword => this.filterOnField('lastModifiedBy', headword));
    } else{
      this.state.visibleKeywords = Object.values(headwords).filter(headword => this.filterOnField('headword', headword));
    }

    this.state.visibleKeywords.sort((a, b) =>  a.attributes.headword.localeCompare(b.attributes.headword));   

    if (this.state.visibleKeywords.length === 0) {
      return (
        <div>
        <Typography variant="h6">No Keywords to display</Typography>
        <br></br>
        </div>
      );
    };

    if(this.state.visibleKeywords.length > 249) {
      if(this.state.visibleKeywords.length > currPosition+250){
        this.state.currDisplayed = this.state.visibleKeywords.slice(currPosition, currPosition + 250);
      } else {
        this.state.currDisplayed = this.state.visibleKeywords.slice(currPosition);
      }
      return (
        this.state.currDisplayed.map(keyword => (
          <KeywordCard key={keyword.id} keyword={keyword} citations={this.state.citationCount} 
          POSCheck={POSCheck} legacyNotesCheck={legacyNotesCheck} kwInDslCheck={kwInDslCheck} 
          kwNotInDslCheck={kwNotInDslCheck} createdByCheck={createdByCheck} lastModifiedByCheck={lastModifiedByCheck} 
          searchString={searchString} merge={false}/>
        ))
        );  
    } else {
      return (
        this.state.visibleKeywords.map(keyword => (
          <KeywordCard key={keyword.id} keyword={keyword} citations={this.state.citationCount} 
          POSCheck={POSCheck} legacyNotesCheck={legacyNotesCheck} kwInDslCheck={kwInDslCheck}
          kwNotInDslCheck={kwNotInDslCheck} createdByCheck={createdByCheck} lastModifiedByCheck={lastModifiedByCheck} 
          searchString={searchString} merge={false}/>
        ))
      );  
    }
  }

  async onChange(value){
    localStorage.setItem('CTSourceType', value);
    this.state.sourceType = value;
    if(value=='Texts'){
      try {      
        this.setState({ isLoading: true });
        this.setState({ progress: 0, progressLabel: "getting texts" });
        const allSources = await citation.getBooks();
        this.setState({progress: 70, progressLabel: "getting texts' citations"})
        this.state.citationCount = await ac.getBookCitationsCount();
  
        if (this.signal) {
          this.setState({
            isLoading: false,
            sources: allSources,
          });
        }
      } catch (error) {
        if (this.signal) {
          this.setState({
            isLoading: false,
            error
          });
        }
      }
    } else if(value=='Periodicals'){
      try {      
        this.setState({ isLoading: true });
        this.setState({ progress: 0, progressLabel: "getting periodicals" });
        const allSources = await citation.getPeriodicals();
        this.setState({progress: 70, progressLabel: "getting periodicals' citations"})
        this.state.citationCount = await ac.getPeriodicalCitationsCount();
  
        if (this.signal) {
          this.setState({
            isLoading: false,
            sources: allSources,
          });
        }
      } catch (error) {
        if (this.signal) {
          this.setState({
            isLoading: false,
            error
          });
        }
      }
    } else if(value=='Utterances'){
      try {      
        this.setState({ isLoading: true });
        this.setState({ progress: 0, progressLabel: "getting utterancess" });
        const allSources = await citation.getUtterances();
        this.setState({progress: 70, progressLabel: "getting utterances' citations"})
        this.state.citationCount = await ac.getUtteranceCitationsCount();
  
        if (this.signal) {
          this.setState({
            isLoading: false,
            sources: allSources,
          });
        }
      } catch (error) {
        if (this.signal) {
          this.setState({
            isLoading: false,
            error
          });
        }
      }

    } else if(value=='Websites'){
      try {      
        this.setState({ isLoading: true });
        this.setState({ progress: 0, progressLabel: "getting websites" });
        const allSources = await citation.getWebsites();
        this.setState({progress: 70, progressLabel: "getting websites' citations"})
        this.state.citationCount = await ac.getWebsiteCitationsCount();
  
        if (this.signal) {
          this.setState({
            isLoading: false,
            sources: allSources,
          });
        }
      } catch (error) {
        if (this.signal) {
          this.setState({
            isLoading: false,
            error
          });
        }
      }
    } else if(value=='Quotations'){
      try { 
        if(this.state.citations.attributes[0]!=null){
          this.setState({ isLoading: true });
          this.setState({ progress: 0, progressLabel: "getting authors..." });
          const allSources = await citation.getBooks();
          this.setState({ isLoading: true, sources: allSources });
        } else {
          this.setState({ isLoading: true });
          this.setState({ progress: 0, progressLabel: "getting quotation count..." });
         // let count = await ac.countCitations();
          let count = await ac.getMaxCitationId();
        //  console.log("Max ID: " + count2);
          let numIters = Math.ceil(count/5000);
          let max = 5000 * numIters;
          this.setState({ isLoading: true });
          this.setState({ progress: 0, progressLabel: "getting authors..." });
          const allSources = await citation.getBooks();
          this.setState({ progress: 0, progressLabel: "starting to get quotations..." });
          for(var i=0; i<numIters; i++){
            let citations = null
            let progressPoint = i * 5000;
            let progressPointPercentage = Math.round(i/numIters)
            this.setState({ progress: progressPointPercentage, progressLabel: "getting quotes...(" + progressPoint + " out of " + count + ")" });
            if(i==numIters-1){
              citations = await ac.getCitationsInRange(i*5000, ((i+1)*5000)+1);
              let low = i*5000
              let high = ((i+1)*5000)+1
              console.log("Range: " + low + " - " + high)
              this.state.citationsArray.push(citations.attributes);
            } else {
              citations = await ac.getCitationsInRange(i*5000, (i+1)*5000);
              let low = i*5000
              let high = (i+1)*5000
              console.log("Range: " + low + " - " + high)
              this.state.citationsArray.push(citations.attributes);
            }
          }
          this.setState({ progressLabel: "rebuilding full quotation list" });
          let total = 0;
          for(var i=0; i<this.state.citationsArray.length; i++){
            let j = 0;
            while(this.state.citationsArray[i][j]!=null){
                this.state.citations.attributes[total+j] = this.state.citationsArray[i][j];
                j = j + 1;
            }
            total = total + j;
          }
          console.log("Counting Citations...")
          let k=0;
          while(this.state.citations.attributes[k]!=null){
            k = k+1;
          }
          console.log("Number of Citations in State: " + k);
          if (this.signal) {
            this.setState({
              isLoading: false,
              sources: allSources
            });
          }
        }
      } catch (error) {
        if (this.signal) {
          this.setState({
            isLoading: false,
            error
          });
        }
      }
    } else if(value=='Keywords'){
      try {      
        this.setState({ isLoading: true });
        this.setState({ progress: 0, progressLabel: "getting keywords" });
        this.state.headwords = await headwords.getHeadwords();
        this.setState({ isLoading: true, progressLabel: "getting quotations counts" });
        this.state.citationCount = await ac.getCitationsCount();
  
        if (this.signal) {
          this.setState({
            isLoading: false,
          });
        }
      } catch (error) {
        if (this.signal) {
          this.setState({
            isLoading: false,
            error
          });
        }
      }
    }
    this.forceUpdate();
  }
  onCharChange(value){
    localStorage.setItem('CTMinChars', value);
    this.state.minChars = value;
    this.forceUpdate();
  }

  renderAllSources(){
    const { classes } = this.props;
    const { minChars, isLoading, progress, progressLabel, sourceType } = this.state;
    if (isLoading && sourceType=='Quotations') {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress size={110} value={progress} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
          </Box>
            <Typography variant="h5" component="div" color="textSecondary">{
              progressLabel
            }</Typography>
        </div>
      );
    } else if(isLoading){
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress size={110} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
          </Box>
            <Typography variant="h5" component="div" color="textSecondary">{
              progressLabel
            }</Typography>
        </div>
      );
    }

    if(this.state.sourceType=='Texts' && this.state.searchString.length>=minChars){
      return(
        <div>
        <Typography
             className={classes.title}
             variant="h4"
           >
             Texts
           </Typography>
           <br></br>
           {this.renderBooks()}
        </div>
      );
    } else if(this.state.sourceType=='Periodicals' && this.state.searchString.length>=minChars){
      return(
        <div>
        <Typography
        className={classes.title}
        variant="h4"
      >
        Periodicals
      </Typography>
      <br></br>
      {this.renderPeriodicals()}
      </div>
      );
    } else if(this.state.sourceType=='Utterances' && this.state.searchString.length>=minChars){
      return(
        <div>
         <Typography
          className={classes.title}
          variant="h4"
        >
          Utterances
        </Typography>
        <br></br>
        {this.renderUtterances()}
      </div>);
    } else if(this.state.sourceType=='Websites' && this.state.searchString.length>=minChars){
      return(
          <div>
          <Typography
          className={classes.title}
          variant="h4"
        >
          Websites
        </Typography>
        <br></br>
        {this.renderWebsites()}
        </div>
      );
    }
    else if(this.state.sourceType=='Quotations' && this.state.searchString.length>=minChars){
      return(
          <div>
          <Typography
          className={classes.title}
          variant="h4"
        >
          Quotations
        </Typography>
        <br></br>
        {this.renderQuotations()}
        </div>
      );
    } else if(this.state.sourceType=='Keywords' && this.state.searchString.length>=minChars){
      return(
          <div>
          <Typography
          className={classes.title}
          variant="h4"
        >
        Keywords
        </Typography>
        <br></br>
        {this.renderKeywords()}
        </div>
      );
    } else if(this.state.sourceType=='All' && this.state.searchString.length>=minChars){
    return(
      <div>
         <Typography
              className={classes.title}
              variant="h4"
            >
              Texts
            </Typography>
            <br></br>
            {this.renderBooks()}
            <Typography
              className={classes.title}
              variant="h4"
            >
              Periodicals
            </Typography>
            <br></br>
            {this.renderPeriodicals()}
            <Typography
              className={classes.title}
              variant="h4"
            >
              Utterances
            </Typography>
            <br></br>
            {this.renderUtterances()}
            <Typography
              className={classes.title}
              variant="h4"
            >
              Websites
            </Typography>
            <br></br>
            {this.renderWebsites()}
            <Typography
                className={classes.title}
                variant="h4"
              >
                Quotations
              </Typography>
              <br></br>
              {this.renderQuotations()}
              <Typography
                className={classes.title}
                variant="h4"
              >
                Keywords
              </Typography>
              <br></br>
              {this.renderKeywords()}
            
      </div>
    );
    } else if(this.state.searchString.length>=minChars) {
      return(
        <div>
           <Typography
                className={classes.title}
                variant="h4"
              >
                Texts
              </Typography>
              <br></br>
              {this.renderBooks()}
              <Typography
                className={classes.title}
                variant="h4"
              >
                Periodicals
              </Typography>
              <br></br>
              {this.renderPeriodicals()}
              <Typography
                className={classes.title}
                variant="h4"
              >
                Utterances
              </Typography>
              <br></br>
              {this.renderUtterances()}
              <Typography
                className={classes.title}
                variant="h4"
              >
                Websites
              </Typography>
              <br></br>
              {this.renderWebsites()}
              <Typography
                className={classes.title}
                variant="h4"
              >
                Quotations
              </Typography>
              <br></br>
              {this.renderWebsites()}
              <Typography
                className={classes.title}
                variant="h4"
              >
                Keywords
              </Typography>
              <br></br>
              {this.renderWebsites()}
        </div>
      );
      }
  }

  updateSearchState(type){
    const { POSCheck, authorCheck, editorCheck, publishedCheck, 
      recordingCheck, articleCheck, regionCheck, yearCheck, typeCheck, notesCheck, 
      legacyNotesCheck, otherCheck, notInDSLCheck, sentOnCheck, kwInDslCheck, kwNotInDslCheck, createdByCheck, lastModifiedByCheck } = this.state;
    if(type == "POS"){
      this.setState({POSCheck: !POSCheck, authorCheck: false, editorCheck: false, publishedCheck: false, 
        recordingCheck: false, articleCheck:false, regionCheck:false, yearCheck:false, typeCheck:false, 
        notesCheck:false, legacyNotesCheck:false, otherCheck:false, notInDSLCheck:false, sentOnCheck:false, kwInDslCheck:false, kwNotInDslCheck:false,
        createdByCheck:false, lastModifiedByCheck:false  });
    } else if(type == "Author") {
      this.setState({POSCheck: false, authorCheck: !authorCheck, editorCheck: false, publishedCheck: false, 
        recordingCheck: false, articleCheck:false, regionCheck:false, yearCheck:false, typeCheck:false, 
        notesCheck:false, legacyNotesCheck:false, otherCheck:false, notInDSLCheck:false, sentOnCheck:false, kwInDslCheck:false, kwNotInDslCheck:false,
        createdByCheck:false, lastModifiedByCheck:false  });
    } else if(type == "Editor"){
      this.setState({POSCheck: false, authorCheck: false, editorCheck: !editorCheck, publishedCheck:false, 
        recordingCheck: false, articleCheck:false, regionCheck:false, yearCheck:false, typeCheck:false,  
        notesCheck:false, legacyNotesCheck:false, otherCheck:false, notInDSLCheck:false, sentOnCheck:false, kwInDslCheck:false, kwNotInDslCheck:false,
        createdByCheck:false, lastModifiedByCheck:false  });
    } else if(type == "Published"){
      this.setState({POSCheck: false, authorCheck: false, editorCheck: false, publishedCheck:!publishedCheck, 
        recordingCheck: false, articleCheck:false, regionCheck:false, yearCheck:false, typeCheck:false,  
        notesCheck:false, legacyNotesCheck:false, otherCheck:false, notInDSLCheck:false, sentOnCheck:false, kwInDslCheck:false, kwNotInDslCheck:false,
        createdByCheck:false, lastModifiedByCheck:false  });
    } else if(type == "Recording"){
      this.setState({POSCheck: false, authorCheck: false, editorCheck: false, publishedCheck:false, 
        recordingCheck: !recordingCheck, articleCheck:false, regionCheck:false, yearCheck:false, typeCheck:false, 
        notesCheck:false, legacyNotesCheck:false, otherCheck:false, notInDSLCheck:false, sentOnCheck:false, kwInDslCheck:false, kwNotInDslCheck:false,
        createdByCheck:false, lastModifiedByCheck:false  });
    } else if(type == "Article"){
      this.setState({POSCheck: false, authorCheck: false, editorCheck: false, publishedCheck:false, 
        recordingCheck: false, articleCheck:!articleCheck, regionCheck:false, yearCheck:false, typeCheck:false, 
        notesCheck:false, legacyNotesCheck:false, otherCheck:false, notInDSLCheck:false, sentOnCheck:false, kwInDslCheck:false, kwNotInDslCheck:false,
        createdByCheck:false, lastModifiedByCheck:false  });
    } else if(type == "Region") {
      this.setState({POSCheck: false, authorCheck: false, editorCheck: false, publishedCheck:false, 
        recordingCheck: false, articleCheck:false, regionCheck:!regionCheck, yearCheck:false, typeCheck:false, 
        notesCheck:false, legacyNotesCheck:false, otherCheck:false, notInDSLCheck:false, sentOnCheck:false, kwInDslCheck:false, kwNotInDslCheck:false,
        createdByCheck:false, lastModifiedByCheck:false  });
    } else if(type == "Year"){
      this.setState({POSCheck: false, authorCheck: false, editorCheck: false, publishedCheck:false, 
        recordingCheck: false, articleCheck:false, regionCheck:false, yearCheck:!yearCheck, typeCheck:false, 
        notesCheck:false, legacyNotesCheck:false, otherCheck:false, notInDSLCheck:false, sentOnCheck:false, kwInDslCheck:false, kwNotInDslCheck:false,
        createdByCheck:false, lastModifiedByCheck:false  });
    } else if(type == "Type"){
      this.setState({POSCheck: false, authorCheck: false, editorCheck: false, publishedCheck:false, 
        recordingCheck: false, articleCheck:false, regionCheck:false, yearCheck:false, typeCheck:!typeCheck, 
        notesCheck:false, legacyNotesCheck:false, otherCheck:false, notInDSLCheck:false, sentOnCheck:false, kwInDslCheck:false, kwNotInDslCheck:false,
        createdByCheck:false, lastModifiedByCheck:false  });
    } else if(type == "notes"){
      this.setState({POSCheck: false, authorCheck: false, editorCheck: false, publishedCheck:false, 
        recordingCheck: false, articleCheck:false, regionCheck:false, yearCheck:false, typeCheck:false, 
        notesCheck:!notesCheck, legacyNotesCheck:false, otherCheck:false, notInDSLCheck:false, sentOnCheck:false, kwInDslCheck:false, kwNotInDslCheck:false,
        createdByCheck:false, lastModifiedByCheck:false  });
    }else if(type == "legacyNotes"){
      this.setState({POSCheck: false, authorCheck: false, editorCheck: false, publishedCheck:false, 
        recordingCheck: false, articleCheck:false, regionCheck:false, yearCheck:false, typeCheck:false, 
        notesCheck:false, legacyNotesCheck:!legacyNotesCheck, otherCheck:false, notInDSLCheck:false, sentOnCheck:false, kwInDslCheck:false, kwNotInDslCheck:false,
        createdByCheck:false, lastModifiedByCheck:false  });
    }else if(type == "other"){
      this.setState({POSCheck: false, authorCheck: false, editorCheck: false, publishedCheck:false, 
        recordingCheck: false, articleCheck:false, regionCheck:false, yearCheck:false, typeCheck:false, 
        notesCheck:false, legacyNotesCheck:false, otherCheck:!otherCheck, notInDSLCheck:false, sentOnCheck:false, kwInDslCheck:false, kwNotInDslCheck:false,
        createdByCheck:false, lastModifiedByCheck:false  });
    }else if(type == "notInDSL"){
      this.setState({POSCheck: false, authorCheck: false, editorCheck: false, publishedCheck:false, 
        recordingCheck: false, articleCheck:false, regionCheck:false, yearCheck:false, typeCheck:false, 
        notesCheck:false, legacyNotesCheck:false, otherCheck:false, notInDSLCheck:!notInDSLCheck, sentOnCheck:false, kwInDslCheck:false, kwNotInDslCheck:false,
        createdByCheck:false, lastModifiedByCheck:false  });
    } else if(type == "sentOn"){
      this.setState({POSCheck: false, authorCheck: false, editorCheck: false, publishedCheck:false, 
        recordingCheck: false, articleCheck:false, regionCheck:false, yearCheck:false, typeCheck:false, 
        notesCheck:false, legacyNotesCheck:false, otherCheck:false, notInDSLCheck:false, sentOnCheck:!sentOnCheck, kwInDslCheck:false, kwNotInDslCheck:false,
        createdByCheck:false, lastModifiedByCheck:false  });
    } else if(type == "kwInDsl"){
      this.setState({POSCheck: false, authorCheck: false, editorCheck: false, publishedCheck:false, 
        recordingCheck: false, articleCheck:false, regionCheck:false, yearCheck:false, typeCheck:false, 
        notesCheck:false, legacyNotesCheck:false, otherCheck:false, notInDSLCheck:false, sentOnCheck:false, kwInDslCheck:!kwInDslCheck, kwNotInDslCheck:false,
        createdByCheck:false, lastModifiedByCheck:false });
    } else if(type == "kwNotInDsl"){
      this.setState({POSCheck: false, authorCheck: false, editorCheck: false, publishedCheck:false, 
        recordingCheck: false, articleCheck:false, regionCheck:false, yearCheck:false, typeCheck:false, 
        notesCheck:false, legacyNotesCheck:false, otherCheck:false, notInDSLCheck:false, sentOnCheck:false, kwInDslCheck:false, kwNotInDslCheck:!kwNotInDslCheck,
        createdByCheck:false, lastModifiedByCheck:false});
    } else if(type == "createdByCheck"){
      this.setState({POSCheck: false, authorCheck: false, editorCheck: false, publishedCheck:false, 
        recordingCheck: false, articleCheck:false, regionCheck:false, yearCheck:false, typeCheck:false, 
        notesCheck:false, legacyNotesCheck:false, otherCheck:false, notInDSLCheck:false, sentOnCheck:false, kwInDslCheck:false, kwNotInDslCheck:false,
        createdByCheck:!createdByCheck, lastModifiedByCheck: false});
    } else if(type == "lastModifiedByCheck"){
      this.setState({POSCheck: false, authorCheck: false, editorCheck: false, publishedCheck:false, 
        recordingCheck: false, articleCheck:false, regionCheck:false, yearCheck:false, typeCheck:false, 
        notesCheck:false, legacyNotesCheck:false, otherCheck:false, notInDSLCheck:false, sentOnCheck:false, kwInDslCheck:false, kwNotInDslCheck:false,
        createdByCheck:false, lastModifiedByCheck:!lastModifiedByCheck});
    }
  }

  /*updateLocalStorage(){
    this.forceUpdate();
    //console.log(this.state.authorCheck);
    localStorage.setItem('CTPOSCheck', this.state.POSCheck.toString()); 
    localStorage.setItem('CTAuthorCheck', this.state.authorCheck.toString());
    localStorage.setItem('CTEditorCheck', this.state.editorCheck.toString());
    localStorage.setItem('CTPublishedCheck', this.state.publishedCheck.toString());
    localStorage.setItem('CTRecordingCheck', this.state.recordingCheck.toString());
    localStorage.setItem('CTArticleCheck', this.state.articleCheck.toString());
    localStorage.setItem('CTRegionCheck', this.state.regionCheck.toString());
    localStorage.setItem('CTYearCheck', this.state.yearCheck.toString());
    localStorage.setItem('CTTypeCheck', this.state.typeCheck.toString());
    localStorage.setItem('CTNotInDSLCheck', this.state.notInDSLCheck.toString());
    localStorage.setItem('CTLegacyNotesCheck', this.state.legacyNotesCheck.toString());
    localStorage.setItem('CTNotesCheck', this.state.notesCheck.toString());
    localStorage.setItem('CTOtherCheck', this.state.otherCheck.toString());
    localStorage.setItem('CTKwInDslCheckCheck', this.state.kwInDslCheck,toString());
    localStorage.setItem('CTKwNotInDslCheck', this.state.kwNotInDslCheck.toString());
  }*/

  renderCheckboxes(){
    const { POSCheck, authorCheck, editorCheck, publishedCheck, 
      recordingCheck, articleCheck, regionCheck, yearCheck, typeCheck, 
      notesCheck, legacyNotesCheck, otherCheck, notInDSLCheck, sentOnCheck, kwInDslCheck, kwNotInDslCheck, createdByCheck, lastModifiedByCheck} = this.state;
      if(this.state.sourceType == "Texts"){      
        return(
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={authorCheck}
                onChange={(event) => this.updateSearchState("Author")}
                name="authorCheck"
                color="primary"
              />
            }
            label="Author"
          />
          <FormControlLabel
              control={
                <Checkbox
                  checked={editorCheck}
                  onChange={(event) => this.updateSearchState("Editor")}
                  name="editorCheck"
                  color="primary"
                />
              }
              label="Editor"
          />        
          <FormControlLabel
            control={
              <Checkbox
                checked={yearCheck}
                onChange={(event) => this.updateSearchState("Year")}
                name="yearCheck"
                color="primary"
              />
            }
            label="Year"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={typeCheck}
                onChange={(event) => this.updateSearchState("Type")}
                name="typeCheck"
                color="primary"
              />
            }
            label="Type"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={notesCheck}
                onChange={(event) => this.updateSearchState("notes")}
                name="notesCheck"
                color="primary"
              />
            }
            label="Notes"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={legacyNotesCheck}
                onChange={(event) => this.updateSearchState("legacyNotes")}
                name="legacyNotesCheck"
                color="primary"
              />
            }
            label="Legacy Notes"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={otherCheck}
                onChange={(event) => this.updateSearchState("other")}
                name="otherCheck"
                color="primary"
              />
            }
            label="Other Type"
          />
        </FormGroup>

      );
      } else if(this.state.sourceType == "Periodicals"){
        return(
        <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={typeCheck}
              onChange={(event) => this.updateSearchState("Type")}
              name="typeCheck"
              color="primary"
            />
          }
          label="Type"
        />
         <FormControlLabel
            control={
              <Checkbox
                checked={notesCheck}
                onChange={(event) => this.updateSearchState("notes")}
                name="notesCheck"
                color="primary"
              />
            }
            label="Notes"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={legacyNotesCheck}
                onChange={(event) => this.updateSearchState("legacyNotes")}
                name="legacyNotesCheck"
                color="primary"
              />
            }
            label="Legacy Notes"
          />
      </FormGroup>
        );
      } else if(this.state.sourceType == "Quotations"){
        return(
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={authorCheck}
                onChange={(event) => this.updateSearchState("Author")}
                name="authorCheck"
                color="primary"
              />
            }
            label="Author"
          />
          <FormControlLabel
              control={
                <Checkbox
                  checked={editorCheck}
                  onChange={(event) => this.updateSearchState("Editor")}
                  name="editorCheck"
                  color="primary"
                />
              }
              label="Editor"
          />        
          <FormControlLabel
            control={
              <Checkbox
                checked={publishedCheck}
                onChange={(event) => this.updateSearchState("Published")}
                name="Published"
                color="primary"
              />
            }
            label="Published Date"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={recordingCheck}
                onChange={(event) => this.updateSearchState("Recording")}
                name="recordingCheck"
                color="primary"
              />
            }
            label="Recording Date"
          />
         <FormControlLabel
            control={
              <Checkbox
                checked={articleCheck}
                onChange={(event) => this.updateSearchState("Article")}
                name="articleCheck"
                color="primary"
              />
            }
            label="Article Title"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={regionCheck}
                onChange={(event) => this.updateSearchState("Region")}
                name="regionCheck"
                color="primary"
              />
            }
            label="Region"
          />
          {
          <FormControlLabel
            control={
              <Checkbox
                checked={notInDSLCheck}
                onChange={(event) => this.updateSearchState("notInDSL")}
                name="notInDSLCheck"
                color="primary"
              />
            }
            label="Rejected"
          /> }
          {
          <FormControlLabel
            control={
              <Checkbox
                checked={sentOnCheck}
                onChange={(event) => this.updateSearchState("sentOn")}
                name="sentOnCheck"
                color="primary"
              />
            }
            label="Sent On"
          /> }
          <FormControlLabel
            control={
              <Checkbox
                checked={notesCheck}
                onChange={(event) => this.updateSearchState("notes")}
                name="notesCheck"
                color="primary"
              />
            }
            label="Notes"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={legacyNotesCheck}
                onChange={(event) => this.updateSearchState("legacyNotes")}
                name="legacyNotesCheck"
                color="primary"
              />
            }
            label="Legacy Notes"
          />  
          <FormControlLabel
            control={
              <Checkbox
                checked={createdByCheck}
                onChange={(event) => this.updateSearchState("createdByCheck")}
                name="createdByCheck"
                color="primary"
              />
            }
            label="Created By"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={lastModifiedByCheck}
                onChange={(event) => this.updateSearchState("lastModifiedByCheck")}
                name="lastModifiedByCheck"
                color="primary"
              />
            }
            label="Last Modified By"
          />   
        </FormGroup>
        );
      } else if(this.state.sourceType == "Keywords"){
        return(
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={POSCheck}
                onChange={(event) => this.updateSearchState("POS")}
                name="POSCheck"
                color="primary"
              />
            }
            label="Part Of Speech"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={legacyNotesCheck}
                onChange={(event) => this.updateSearchState("legacyNotes")}
                name="legacyNotesCheck"
                color="primary"
              />
            }
            label="Legacy Notes"
          />
           <FormControlLabel
            control={
              <Checkbox
                checked={kwInDslCheck}
                onChange={(event) => this.updateSearchState("kwInDsl")}
                name="kwInDslCheck"
                color="primary"
              />
            }
            label="Keywords In DSL"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={kwNotInDslCheck}
                onChange={(event) => this.updateSearchState("kwNotInDsl")}
                name="kwNotInDslCheck"
                color="primary"
              />
            }
            label="Keywords Not In DSL"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={createdByCheck}
                onChange={(event) => this.updateSearchState("createdByCheck")}
                name="createdByCheck"
                color="primary"
              />
            }
            label="Created By"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={lastModifiedByCheck}
                onChange={(event) => this.updateSearchState("lastModifiedByCheck")}
                name="lastModifiedByCheck"
                color="primary"
              />
            }
            label="Last Modified By"
          />
        </FormGroup>
        );
      }else if(this.state.sourceType == "Websites"){
        return(
        <FormGroup row>
          <FormControlLabel
            control={
              <Checkbox
                checked={legacyNotesCheck}
                onChange={(event) => this.updateSearchState("legacyNotes")}
                name="legacyNotesCheck"
                color="primary"
              />
            }
            label="Legacy Notes"
          />
        </FormGroup>
        );
      }
  }

  download(sourceType){
    const {visibleBooks, visiblePeriodicals, visibleUtterances, visibleWebsites, 
      visibleKeywords, visibleQuotations} = this.state;
    if(window.confirm("Do you want to download all data on this page?")){
      if(sourceType=="Quotations"){
        var csvString = [];
        var csvRow = [];
        csvRow.push("Keyword ID", "Quotation", "Book ID", "Periodical ID", "Website ID", "Utterance ID",
        "Author", "Sub-Part Title", "Page Number", "Article Title", "Publication Date", "URL", "Accessed Date",
        "Region", "Notes", "Degree", "Function", "Paradigm", "Determiner", "Type", "Mood", "Plural",
        "Verbal", "Phrase", "DSL Bibliography Reference ID", "Recording Creator", "Recording Date", "Speaker", "Suggested Citation");
        csvString.push(csvRow);
        visibleQuotations.map(q=>{
            var csvRow = [];

            if(q.headwordId != null) {
            csvRow.push(q.headwordId.toString());
            } else {
              csvRow.push(null);
            }

            if(q.quotation!=null){
            csvRow.push(q.quotation.toString());
            } else {
              csvRow.push(null);
            }

            csvRow.push(q.bookId, q.periodicalId, q.websiteId, q.utteranceId);

            if(q.author != null){
            csvRow.push(q.author.toString());
            } else{
              csvRow.push(null);
            }

            if(q.subPartTitle != null){
            csvRow.push(q.subPartTitle.toString());
            } else{
              csvRow.push(null);
            } 

            csvRow.push( q.pageNo);

            if(q.articleTitle != null){
              csvRow.push(q.articleTitle.toString());
            } else{
              csvRow.push(null);
            }

            csvRow.push(q.publishedDate);

            if(q.url != null){
              csvRow.push(q.url.toString());
            } else {
              csvRow.push(null);
            }

            csvRow.push(q.accessedDate);

            if(q.region != null){
            csvRow.push(q.region.toString());
            } else{
              csvRow.push(null);
            }

            if(q.notes != null){
            csvRow.push(q.notes.toString());
            } else{
              csvRow.push(null);
            }

            csvRow.push(q.grammarDegree, q.grammarFunction, q.grammarParadigm, q.grammarDeterminer,
              q.grammarType, q.grammarMood, q.grammarPlural, q.grammarVerbal, q.grammarPhrase, q.dslBibRef);

            if(q.recordingCreator != null){
            csvRow.push( q.recordingCreator.toString());
            } else{
              csvRow.push(null);
            }

            csvRow.push(q.recordingDate);

            if(q.speaker != null) {
            csvRow.push(q.speaker.toString());
            } else{
              csvRow.push(null);
            }

            if(q.suggestedCitation != null){
            csvRow.push( q.suggestedCitation.toString());
            } else{
              csvRow.push(null);
            }

            csvString.push(csvRow);
        });
        csvString.map(d=>{
          for(var i = 0; i<d.length; i++){
            if(d[i]!=null){
              d[i] = d[i].replace(/"/g, '\'');
            }
          }
        });
        
      var outputString = csvString.map(function(d){
          return JSON.stringify(d);
      });

      let stringyTest = outputString.join('\n');
      let actualOut = stringyTest.replace(/(^\[)|(\]$)/mg, '');
      this.output(sourceType, actualOut);
      
      } else if (sourceType=="Keywords"){
        var csvString = [];
        var csvRow = [];
        csvRow.push("Keyword ID", "Keyword", "Part Of Speech");
        csvString.push(csvRow);
        visibleKeywords.map(k=>{
            var csvRow = [];
            if(k.id != null) {
            csvRow.push(k.id.toString());
            } else {
              csvRow.push(null);
            }

            if(k.attributes.headword!=null){
            csvRow.push(k.attributes.headword.toString());
            } else {
              csvRow.push(null);
            }

            if(k.attributes.partOfSpeech != null){
            csvRow.push(k.attributes.partOfSpeech.toString());
            } else{
              csvRow.push(null);
            }

            csvString.push(csvRow);
        });

        csvString.map(d=>{
          for(var i = 0; i<d.length; i++){
            if(d[i]!=null){
              d[i] = d[i].replace(/"/g, '\'');
            }
          }
        });
        
      var outputString = csvString.map(function(d){
          return JSON.stringify(d);
      });

      let stringyTest = outputString.join('\n');
      let actualOut = stringyTest.replace(/(^\[)|(\]$)/mg, '');
      this.output(sourceType, actualOut);

      } else if(sourceType=="Texts"){
        var csvString = [];
        var csvRow = [];
        csvRow.push("Book ID", "Title", "Type", "ISBN", "Author", "Editor",
        "Edition", "Publisher", "Year Published", "Place Published", 
        "Notes", "DSL BIBliography Reference");
        csvString.push(csvRow);
        visibleBooks.map(b=>{
            var csvRow = [];
            if(b.id != null) {
            csvRow.push(b.id.toString());
            } else {
              csvRow.push(null);
            }

            if(b.title!=null){
              csvRow.push(b.title.toString());
            } else {
              csvRow.push(null);
            }

            if(b.type!=null){
            csvRow.push(b.type.toString());
            } else {
              csvRow.push(null);
            }

            if(b.isbn!=null){
              csvRow.push(b.isbn.toString());
            } else {
              csvRow.push(null);
            }

            if(b.author!=null){
              csvRow.push(b.author.toString());
            } else {
              csvRow.push(null);
            }

            if(b.editor!=null){
              csvRow.push(b.editor.toString());
            } else {
              csvRow.push(null);
            }

            if(b.edition!=null){
              csvRow.push(b.edition.toString());
            } else {
              csvRow.push(null);
            }

            if(b.publisher!=null){
              csvRow.push(b.publisher.toString());
            } else {
              csvRow.push(null);
            }

            if(b.yearPublished!=null){
              csvRow.push(b.yearPublished.toString());
            } else {
              csvRow.push(null);
            }

            if(b.placePublished!=null){
              csvRow.push(b.placePublished.toString());
            } else {
              csvRow.push(null);
            }

            if(b.notes!=null){
              csvRow.push(b.notes.toString());
            } else {
              csvRow.push(null);
            }

            if(b.dslBibliographyRef!=null){
              csvRow.push(b.dslBibliographyRef.toString());
            } else {
              csvRow.push(null);
            }

            csvString.push(csvRow);
        });

        csvString.map(d=>{
          for(var i = 0; i<d.length; i++){
            if(d[i]!=null){
              d[i] = d[i].replace(/"/g, '\'');
            }
          }
        });
        
      var outputString = csvString.map(function(d){
          return JSON.stringify(d);
      });

      let stringyTest = outputString.join('\n');
      let actualOut = stringyTest.replace(/(^\[)|(\]$)/mg, '');
      this.output(sourceType, actualOut);

      } else if(sourceType=="Periodicals"){
        var csvString = [];
        var csvRow = [];
        csvRow.push("Periodical ID", "Title", "Type",
        "Notes", "DSL BIBliography Reference");
        csvString.push(csvRow);
        visibleBooks.map(b=>{
            var csvRow = [];
            if(b.id != null) {
            csvRow.push(b.id.toString());
            } else {
              csvRow.push(null);
            }

            if(b.title!=null){
              csvRow.push(b.title.toString());
            } else {
              csvRow.push(null);
            }

            if(b.type!=null){
            csvRow.push(b.type.toString());
            } else {
              csvRow.push(null);
            }

            if(b.notes!=null){
              csvRow.push(b.notes.toString());
            } else {
              csvRow.push(null);
            }

            if(b.dslBibliographyRef!=null){
              csvRow.push(b.dslBibliographyRef.toString());
            } else {
              csvRow.push(null);
            }

            csvString.push(csvRow);
        });

        csvString.map(d=>{
          for(var i = 0; i<d.length; i++){
            if(d[i]!=null){
              d[i] = d[i].replace(/"/g, '\'');
            }
          }
        });
        
      var outputString = csvString.map(function(d){
          return JSON.stringify(d);
      });

      let stringyTest = outputString.join('\n');
      let actualOut = stringyTest.replace(/(^\[)|(\]$)/mg, '');
      this.output(sourceType, actualOut);

      } else if(sourceType=="Utterances"){
        var csvString = [];
        var csvRow = [];
        csvRow.push("Utterance ID", "Site Name", "Type");
        csvString.push(csvRow);
        visibleUtterances.map(u=>{
            var csvRow = [];
            if(u.id != null) {
            csvRow.push(u.id.toString());
            } else {
              csvRow.push(null);
            }

            if(u.siteName!=null){
            csvRow.push(u.siteName.toString());
            } else {
              csvRow.push(null);
            }

            if(u.type != null){
            csvRow.push(u.type.toString());
            } else{
              csvRow.push(null);
            }

            csvString.push(csvRow);
        });

        csvString.map(d=>{
          for(var i = 0; i<d.length; i++){
            if(d[i]!=null){
              d[i] = d[i].replace(/"/g, '\'');
            }
          }
        });
        
      var outputString = csvString.map(function(d){
          return JSON.stringify(d);
      });

      let stringyTest = outputString.join('\n');
      let actualOut = stringyTest.replace(/(^\[)|(\]$)/mg, '');
      this.output(sourceType, actualOut);
      } else if(sourceType=="Websites") {
        var csvString = [];
        var csvRow = [];
        csvRow.push("Website ID", "Site Name", "Type");
        csvString.push(csvRow);
        visibleWebsites.map(w=>{
          var csvRow = [];
          if(w.id != null) {
          csvRow.push(w.id.toString());
          } else {
            csvRow.push(null);
          }

          if(w.siteName!=null){
          csvRow.push(w.siteName.toString());
        } else {
          csvRow.push(null);
        }

          if(w.type != null){
          csvRow.push(w.type.toString());
          } else{
          csvRow.push(null);
          }

          csvString.push(csvRow);
        });

        csvString.map(d=>{
          for(var i = 0; i<d.length; i++){
            if(d[i]!=null){
              d[i] = d[i].replace(/"/g, '\'');
            }
          }
        });
        
      var outputString = csvString.map(function(d){
          return JSON.stringify(d);
      });

      let stringyTest = outputString.join('\n');
      let actualOut = stringyTest.replace(/(^\[)|(\]$)/mg, '');
      this.output(sourceType, actualOut);
      } else if(sourceType=="All"){
        this.download("Quotations");
        this.download("Keywords");
        this.download("Books");
        this.download("Periodicals");
        this.download("Utterances");
        this.download("Websites");
      }
    }
  } 

  output(sourceType, csvString){
   let name = sourceType+".csv";
   var blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
   if (navigator.msSaveBlob) { // IE 10+
       navigator.msSaveBlob(blob, name);
   } else {
       var link = document.createElement("a");
       if (link.download !== undefined) { // feature detection
           // Browsers that support HTML5 download attribute
           var url = URL.createObjectURL(blob);
           link.setAttribute("href", url);
           link.setAttribute("download", name);
           link.style.visibility = 'hidden';
           document.body.appendChild(link);
           link.click();
           document.body.removeChild(link);
       }
    }
  }

  showMore(){
    const { visibleBooks, visiblePeriodicals, visibleUtterances, visibleWebsites,
       visibleKeywords, visibleQuotations, currPosition, sourceType } = this.state;
    if(sourceType == "All"){
      this.setState({currPosition: currPosition + 250});
      window.scrollTo(0,0);
    } else if(sourceType == "Texts"){
      if(visibleBooks.length >= currPosition + 250){
        this.setState({currPosition: currPosition + 250});
        window.scrollTo(0,0);
      }
    } else if(sourceType == "Periodicals"){
      if(visiblePeriodicals.length >= currPosition + 250){
        this.setState({currPosition: currPosition + 250});
        window.scrollTo(0,0);
      }
    } else if(sourceType == "Utterances"){
      if(visibleUtterances.length >= currPosition + 250){
        this.setState({currPosition: currPosition + 250});
        window.scrollTo(0,0);
      }
    } else if(sourceType == "Websites"){
      if(visibleWebsites.length >= currPosition + 250){
        this.setState({currPosition: currPosition + 250});
        window.scrollTo(0,0);
      }
    } else if(sourceType == "Quotations"){
      if(visibleQuotations.length >= currPosition + 250){
        this.setState({currPosition: currPosition + 250});
        window.scrollTo(0,0);
      }
    } else if(sourceType == "Keywords"){
      if(visibleKeywords.length >= currPosition + 250){
        this.setState({currPosition: currPosition + 250});
        window.scrollTo(0,0);
      }
    }
  }

  showLess(){
    const { currPosition } = this.state;
    if(currPosition - 250>=0){
      this.setState({currPosition: currPosition - 250});
      window.scrollTo(0,0)
    }
  }

  toggleExactSearch(){
    const updatedExactSearch = !this.state.exactSearch;
    this.setState({ exactSearch: updatedExactSearch });
  }

  render() {
    const { classes } = this.props;
    const { selectedLetter, isLoading, searchString, minChars, exactSearch, sourceType } = this.state;

    return (
      <DashboardLayout title="Content Manager">
        <div className={classes.root}>
        <div><br></br></div>
        <Grid container spacing={2}>
        {(!isLoading && 
        <Grid item lg={1} md={1.5} xl={1} md={3} sm={6} xs={12}>
            <Button style={{marginTop:'13px'}} onClick={event => this.download(this.state.sourceType)} fullWidth='true' color="primary" size="small" variant="outlined">Download </Button>
          </Grid>
        )}
          <Grid style={{marginTop:'13px'}} item lg={1} md={3} xl={1} sm={6} xs={12}>
            <Link to={'/merge-tool'}>
            <Button fullWidth='true' color="primary" size="small" variant="outlined"> Merge </Button>
            </Link>  
          </Grid>
        </Grid>
        {(!isLoading &&   
        <Grid container spacing={2}>
          <Grid item lg={5} md={3} xl={4} sm={5} xs={12} >
            <SearchInput style={{marginTop:'7px'}} className={classes.searchInput} value={this.state.searchString} placeholder="Search" onChange={event => this.onSearchChange(event.target.value)}/>
            </Grid>
            <Grid item lg={2} md={3} xl={1} sm={3} xs={12}>
           
                  <TextField
                    className={classes.textField}
                    label="Source Type"
                    margin="dense"
                    select
                    fullWidth='true'
                    SelectProps={{ native: true }}
                    value={this.state.sourceType}
                    onChange={(event) => this.onChange(event.target.value)}
                    variant="outlined">
                    {this.state.sourceOptions.map(option => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}  
                  </TextField>
            </Grid>
            <Grid item lg={2} md={2} xl={1} sm={2} xs={12}>
                <TextField
                  className={classes.textField}
                  label="Min Char Search"
                  margin="dense"
                  select
                  fullWidth='true'
                  SelectProps={{ native: true }}
                  value={this.state.minChars}
                  onChange={(event) => this.onCharChange(event.target.value)}
                  variant="outlined">
                  {this.state.minCharOptions.map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}  
                </TextField>
            </Grid>
            <Grid item lg={1} md={3} xl={1} sm={1} xs={12}>
            <Button style={{marginTop:'13px'}} onClick={event => this.onSearchChange((this.state.searchString + 'ȝ'))} fullWidth='true' color="primary" size="small" variant="outlined">Add ȝ </Button>
            </Grid>
            {((sourceType == "Keywords")) && (
            <div style={{marginLeft:"16px", marginTop:"14px", minWidth: "150px"}}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={exactSearch}
                    onChange={(event) => this.toggleExactSearch()}
                    name="exactSearch"
                    color="primary"
                  />
                }
                label="Exact Search"
              />     
            </div> 
            )}
            <Grid style={{marginLeft:'20px'}} item lg={5} md={3} xl={3} xs={11}>
            {this.renderCheckboxes()}
            </Grid>
            </Grid>
          )}
          <div className={classes.content}>
          {this.renderAllSources()}
          {((searchString.length>=minChars) && 
          <div style={{width: '30%'}} className={classes.row}>
          <Button onClick={event => this.showLess()} color="primary" size="small" variant="outlined">Display Previous 250 </Button>
          <div style={{width: '5%'}}></div>
          <Button onClick={event => this.showMore()} color="primary" size="small" variant="outlined">Display Next 250 </Button>
          </div>
          )}
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

Search.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles)
)(Search);

import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

let styles = theme => ({
  field: {
    margin: theme.spacing(3)
  },
  textField: {
    width: '420px',
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
});

class Utterance extends Component {

  constructor(props) {
    super(props);
    this.state = {
      utterance: props.utterance,
      citation: props.citation,
      error: null
    };
  }

  onChange(path, value) {
    let utterance = this.state.utterance;
    _.set(utterance, path, this.fromViewValue(value));
    this.setState({ utterance });
    this.state.citation.relationships.utterance = utterance;

  }

  fromViewValue(value) {
    return value === '' ? null : value;
  }

  render() {
    const { classes, utterance } = this.props;
    return (
      <form autoComplete="off" noValidate>
        <div className={classes.field}>
        <TextField
            className={classes.textField}
            label="Site Name"
            margin="dense"
            required
            value={utterance.attributes.siteName || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.siteName', event.target.value)}
          />
        </div>
      </form>
    );
  }
}

Utterance.propTypes = {
  citation: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  utterance: PropTypes.object
};

Utterance.defaultProps = {
  utterance: {
    attributes: {},
    relationships: {}
  }
};

export default withStyles(styles)(Utterance);

import React, {Component} from 'react';
import _ from 'lodash';
import Editor from 'components/Editor';
import { state as appState } from 'App';
import 'react-quill/dist/quill.snow.css';

// Externals
import PropTypes from 'prop-types';

// Material helpers
import {Typography, withStyles, CircularProgress, Box} from '@material-ui/core';

// Shared layouts
import {Dashboard as DashboardLayout} from 'layouts';

// Shared services
import {getContent, getVContent, getAContent, updateContent} from 'services/content';

import service from 'services/allCitations';

// Component styles
import styles from './styles';

class Home extends Component {

  state = {
    isLoading: false,
    content: null,
    isEditMode: false,//appState.get('isEditMode'),
    error: null,
    citationsCount: 0,
    keywordsCount: 0,
    sourcesCount: 0,
    progressLabel: "Loading Content...",
    user: appState.get('user'), 
  };

  constructor(props) {
    super(props);
    this.saveContent = _.debounce(this._saveContent.bind(this), 1000);
  }

  async getContent() {
    try {
      let content = null;
      let citations = null;
      let keywords = null;
      let books = null;
      let periodicals = null;
      let utterances = null;
      let websites = null;
      if(this.state.user.attributes.roles.includes('editor')){
        this.setState({isLoading: true});
        content = await getContent('home');
        this.setState({progressLabel: "Counting Quotations and Keywords..."});
        citations = await service.countCitations();
        keywords = await service.countKeywords();
        this.setState({progressLabel: "Counting Sources..."})
        books = await service.countBooks();
        periodicals = await service.countPeriodicals();
        utterances = await service.countUtterances()
        websites = await service.countWebsites();
      } else if(this.state.user.attributes.roles.includes('reviewer')){
        this.setState({isLoading: true});
        content = await getVContent('home');
        this.setState({progressLabel: "Counting Quotations and Keywords..."});
        citations = await service.countVCitations();
        keywords = await service.countVKeywords();
        this.setState({progressLabel: "Counting Sources..."})
        books = await service.countVBooks();
        periodicals = await service.countVPeriodicals();
        utterances = await service.countVUtterances()
        websites = await service.countVWebsites();
      } else if(this.state.user.attributes.roles.includes('admin')){
        this.setState({isLoading: true});
        content = await getAContent('home');
        this.setState({progressLabel: "Counting Quotations and Keywords..."});
        citations = await service.countACitations();
        keywords = await service.countAKeywords();
        this.setState({progressLabel: "Counting Sources..."})
        books = await service.countABooks();
        periodicals = await service.countAPeriodicals();
        utterances = await service.countAUtterances()
        websites = await service.countAWebsites();
      }
      this.state.sourcesCount = books + periodicals + utterances + websites

      this.state.keywordsCount = keywords
      //console.log(citations)
      this.state.citationsCount = citations
      //this.state.citationsCount = Object.values(citations.attributes).length
      //console.log(this.state.user)
      if (!this.unmounting) {
        this.setState({
          isLoading: false,
          content
        });
      }
    } catch (error) {
      if (!this.unmounting) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  async _saveContent() {
    try {
      await updateContent('home', this.state.content);
    } catch {
      if (!this.unmounting) {
        this.getContent();
      }
    }
  }

  componentWillMount() {
    /*this._unwatch = appState.watch('isEditMode', isEditMode => {
      this.setState({ isEditMode });
    });*/
    this._unwatch = appState.watch('user', user => {
      this.setState({ user });
    });
    if(this.state.user==null){
      this.props.history.push('/sign-in');
    }
    this.setState({ isEditMode:false });
    this.unmounting = false;
    this.getContent();
  }

  componentWillUnmount() {
    this._unwatch();
    this.unmounting = true;
  }

  onChange(html) {
    this.setState({ content: html });
    this.saveContent();
  }

  renderEditor() {
    const {classes} = this.props;
    const {progressLabel} = this.state;
    if (this.state.isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress size={110} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
          </Box>
            <Typography variant="h5" component="div" color="textSecondary">{
              progressLabel
            }</Typography>
        </div>
      );
    }
    return (
      <div>
      <Editor value={this.state.content}
              onChange={this.onChange.bind(this)}
              readOnly={!this.state.isEditMode}
      />
      {this.text()}
      </div>
    );
  }

  text(){;
    return(
          <div>
          <h3 style={{paddingLeft:"15px"}}>
            Total Number of Quotations: {this.state.citationsCount}
          </h3>
          <br></br>
          <h3 style={{paddingLeft:"15px"}}>
          Total Number of Keywords: {this.state.keywordsCount}
        </h3>
        <br></br>
          <h3 style={{paddingLeft:"15px"}}>
          Total Number of Sources: {this.state.sourcesCount}
        </h3>
        </div>
    );
  }
  render() {
    const {classes} = this.props;

    return (
      <DashboardLayout title="Home">
        <div className={classes.root}>
          <div className={classes.content}>
            {this.renderEditor()}
          </div>
        </div>

      </DashboardLayout>
    );
  }
}

Home.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Home);

import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

let styles = theme => ({
  field: {
    margin: theme.spacing(3)
  },
  textField: {
    width: '420px',
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
});

class Periodical extends Component {

  constructor(props) {
    super(props);
    this.state = {
      periodical: props.periodical,
      citation: props.citation,
      error: null
    };
  }

  onChange(path, value) {
    let periodical = this.state.periodical;
    _.set(periodical, path, this.fromViewValue(value));
    this.setState({ periodical });
    this.state.citation.relationships.periodical = periodical;
  }

  fromViewValue(value) {
    return value || null;
  }

  render() {
    const { classes, periodical } = this.props;
    return (
      <form autoComplete="off" noValidate>
        <div className={classes.field}>
          <TextField
            className={classes.textField}
            label="Title"
            margin="dense"
            required
            value={periodical.attributes.title || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.title', event.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Notes"
            margin="dense"
            required
            value={periodical.attributes.notes || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.notes', event.target.value)}
          />
        </div>
      </form>
    );
  }
}

Periodical.propTypes = {
  citation: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  periodical: PropTypes.object
};

Periodical.defaultProps = {
  periodical: {
    attributes: {},
    relationships: {}
  }
};

export default withStyles(styles)(Periodical);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles, FormGroup } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { DisplayMode, SearchInput } from 'components';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
  Button,
  IconButton,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  Grid,
  TextField,
  LinearProgress,
  Box
} from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon
} from '@material-ui/icons';
import { Dashboard as DashboardLayout } from 'layouts';
import service from 'services/headwords';
import citationService from 'services/citations';
import sourceService from 'services/sources';
import deletion from 'services/deletion';
import AlphabetToolbar from '../../../components/AlphabetToolbar';
import HeadwordCard from './components/HeadwordCard';
import { state as appState } from 'App';
import ac from 'services/allCitations';
import styles from './styles';
import headwords from 'services/headwords';
import citation from 'services/citations';
import BookCard from './components/BookCard';
import PeriodicalCard from './components/PeriodicalCard';
import UtteranceCard from './components/UtteranceCard';
import WebsiteCard from './components/WebsiteCard';
import QuotationCard from './components/QuotationCard';
import KeywordCard from './components/KeywordCard';
import citations from 'services/citations';

class Merge extends Component {
  signal = true;

  state = {
    isLoading: false,
    exactSearchK: false,
    exactSearchD: false,
    limit: 6,
    headwords: {},
    books: {}, 
    periodicals: {},
    utterances: {},
    websites: {},
    citations: { type: "arrays", attributes: {}},
    searchStringK: '',
    searchStringD: '',
    selectedLetter: null,
    error: null,
    sourceOptions: [
      { value: 'Texts', label: 'Texts' },
      { value: 'Periodicals', label: 'Periodicals' },
      { value: 'Utterances', label: 'Utterances' },
      { value: 'Websites', label: 'Websites' },
      { value: 'Quotations', label: 'Quotations'},
      { value: 'Keywords', label: 'Keywords'}
    ],
    sourceType: 'Texts',
    visibleBooks:[],
    visibleDeletedBooks:[],
    visiblePeriodicals:[],
    visibleDeletedPeriodicals:[],
    visibleUtterances: [],
    visibleDeletedUtterances: [],
    visibleWebsites: [],
    visibleDeletedWebsites: [],
    visibleKeywords: [],
    visibleDeletedKeywords: [],
    visibleQuotations: [],
    visibleDeletedQuotations: [],
    keptChosen: false,
    deleteChosen: false,
    itemToDelete: {},
    itemToKeep: {},
    progressMessage: "",
    message:"Entry Editor - Select the fields from the Quotation that you want to keep.",

    //keywords
    posChosenCheck:true,

    //quotations
    headwordChosenCheck: true, 
    sourceChosenCheck: true, 
    authorChosenCheck: true, 
    sptChosenCheck: true, 
    pageNoChosenCheck: true,
    atChosenCheck: true, 
    datePublishedChosenCheck: true, 
    urlChosenCheck: true, 
    adChosenCheck: true, 
    websiteChosenCheck: true, 
    quoteChosenCheck: true,
    regionChosenCheck: true, 
    notesChosenCheck: true, 
    gDegChosenCheck: true, 
    gfChosenCheck: true, 
    gParChosenCheck: true, 
    gDetChosenCheck: true, 
    gtChosenCheck: true, 
    gmChosenCheck: true, 
    gPluChosenCheck: true, 
    gvChosenCheck: true, 
    gPhrChosenCheck: true, 
    rcChosenCheck: true, 
    rdChosenCheck: true, 
    speakerChosenCheck: true, 
    scChosenCheck: true,

    //texts and periodicals
    typeChosenCheck: true,
    isbnChosenCheck: true,
    editorChosenCheck: true,
    titleChosenCheck: true,
    editionChosenCheck: true,
    publisherChosenCheck: true,
    yearPublishedChosenCheck: true,
    placePublishedChosenCheck: true,
    dslBibRefChosenCheck: true,

    //websites and utterances
    siteNameChosenCheck: true,
    legacyNotesChosenCheck: true,
    //keywords
    posDeletionCheck:false,

    //quotations
    headwordDeletionCheck: false, 
    sourceDeletionCheck: false, 
    authorDeletionCheck: false, 
    sptDeletionCheck: false, 
    pageNoDeletionCheck: false,
    atDeletionCheck: false, 
    datePublishedDeletionCheck: false, 
    urlDeletionCheck: false, 
    adDeletionCheck: false, 
    websiteDeletionCheck: false, 
    quoteDeletionCheck: false,
    regionDeletionCheck: false, 
    notesDeletionCheck: false, 
    gDegDeletionCheck: false, 
    gfDeletionCheck: false, 
    gParDeletionCheck: false, 
    gDetDeletionCheck: false, 
    gtDeletionCheck: false, 
    gmDeletionCheck: false, 
    gPluDeletionCheck: false, 
    gvDeletionCheck: false, 
    gPhrDeletionCheck: false, 
    rcDeletionCheck: false, 
    rdDeletionCheck: false, 
    speakerDeletionCheck: false, 
    scDeletionCheck: false,

    //texts and periodicals
    typeDeletionCheck: false,
    isbnDeletionCheck: false,
    editorDeletionCheck: false,
    titleDeletionCheck: false,
    editionDeletionCheck: false,
    publisherDeletionCheck: false,
    yearPublishedDeletionCheck: false,
    placePublishedDeletionCheck: false,
    dslBibRefDeletionCheck: false,

    //websites and utterances
    siteNameDeletionCheck: false,
    legacyNotesDeletionCheck: false,
    minChars: 3,
    minCharOptions: [
     // { value: 0, label: '0'},
      { value: 1, label: '1'},
      { value: 2, label: '2'},
      { value: 3, label: '3' },
      { value: 4, label: '4'},
      { value: 5, label: '5'}
    ],
    user: appState.get('user'),
    currDisplayed: [],
    currDisplayedDeleted: [],
    progress: 0,
    progressLabel: "",
    citationCount: null,
    citationsArray: []
  };

  async getData(limit) {
    try {
      this.setState({ isLoading: true });
      this.setState({
        searchStringK: localStorage.getItem('MergeSearchStringK') ? localStorage.getItem('MergeSearchStringK') : '',
        searchStringD: localStorage.getItem('MergeSearchStringD') ? localStorage.getItem('MergeSearchStringD') : '',
        sourceType: localStorage.getItem('MergeST') ? localStorage.getItem('MergeST') : 'Texts',
        minChars: localStorage.getItem('MergeMinChars') ? parseInt(localStorage.getItem('MergeMinChars')) : 4
      });

      let prevSourceType = localStorage.getItem('MergeST');

      if(prevSourceType!=null && prevSourceType!='Texts'){
        this.onChange(prevSourceType);
      } else {
        this.setState({ progress: 0, progressLabel: "getting texts" });
        const allSources = await citation.getBooks();
        this.setState({progress: 70, progressLabel: "getting texts' citations"})
        this.state.citationCount = await ac.getBookCitationsCount();
        this.setState({ progress: 0, progressLabel: "getting keywords" });
        this.state.headwords = await headwords.getHeadwords();
        
        //const allSources = await citation.getCitations(limit);
        //this.state.headwords = await headwords.getHeadwords(limit);
        //this.state.citations = await ac.getAllCitations();
        if (this.signal) {
          this.setState({
            isLoading: false,
            sources: allSources,
            limit
          });
        }
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }


  renderBooks() {
    const { classes, selectedUsers  } = this.props;
    const { isLoading, sources, selectedLetter, searchStringK, authorCheck, editorCheck, yearCheck, typeCheck } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }
    this.state.visibleBooks = Object.values(sources.attributes).filter(book => book.title!=null && (book.title.toLowerCase().includes(searchStringK.toLowerCase())));
    this.state.visibleBooks.sort((a, b) =>  a.title.localeCompare(b.title));
    

    if (this.state.visibleBooks.length === 0) {
      return (
        <div>
        <Typography variant="h6">No books to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleBooks.length>=100){
      this.state.currDisplayed = this.state.visibleBooks.splice(0,100)
    } else {
      this.state.currDisplayed = this.state.visibleBooks.splice(0)
    }
    return (
      this.state.currDisplayed.map(book => (
        <BookCard key={book.id} book={book} citations={this.state.citationCount} searchString={searchStringK} 
        authorCheck={authorCheck} editorCheck={editorCheck} yearCheck={yearCheck} typeCheck = {typeCheck} chooseQuotation={this.chooseKeptItem.bind(this)}/>
      ))
    );  
  }

  renderDeletedBooks() {
    const { classes, selectedUsers  } = this.props;
    const { isLoading, sources, selectedLetter, searchStringD, authorCheck, editorCheck, yearCheck, typeCheck } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    this.state.visibleDeletedBooks = Object.values(sources.attributes).filter(book => book.title!=null && (book.title.toLowerCase().includes(searchStringD.toLowerCase())));
    this.state.visibleDeletedBooks.sort((a, b) =>  a.title.localeCompare(b.title));
    

    if (this.state.visibleDeletedBooks.length === 0) {
      return (
        <div>
        <Typography variant="h6">No books to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleDeletedBooks.length>=100){
      this.state.currDisplayedDeleted = this.state.visibleDeletedBooks.splice(0,100)
    } else {
      this.state.currDisplayedDeleted = this.state.visibleDeletedBooks.splice(0)
    }
    return (
      this.state.currDisplayedDeleted.map(book => (
        <BookCard key={book.id} book={book} citations={this.state.citationCount} searchString={searchStringD} 
        authorCheck={authorCheck} editorCheck={editorCheck} yearCheck={yearCheck} typeCheck = {typeCheck} chooseQuotation={this.chooseDeletedItem.bind(this)}/>
      ))
    );  
  }

  renderPeriodicals(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, sources, selectedLetter, searchStringK, typeCheck } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    this.state.visiblePeriodicals = Object.values(sources.attributes).filter(periodical => periodical.title != null && (periodical.title.toLowerCase().includes(searchStringK.toLowerCase())));
    this.state.visiblePeriodicals.sort((a, b) =>  a.title.localeCompare(b.title));   

    if (this.state.visiblePeriodicals.length === 0) {
      return (
        <div>
        <Typography variant="h6">No periodicals to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visiblePeriodicals.length>=100){
      this.state.currDisplayed = this.state.visiblePeriodicals.splice(0,100)
    } else {
      this.state.currDisplayed = this.state.visiblePeriodicals.splice(0)
    }
    return (
      this.state.currDisplayed.map(periodical => (
        <PeriodicalCard key={periodical.id} periodical={periodical} citations={this.state.citationCount}
         typeCheck={typeCheck} searchString={searchStringK} chooseQuotation={this.chooseKeptItem.bind(this)}/>
      ))
    );  
  }

  renderDeletedPeriodicals(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, sources, selectedLetter, searchStringD, typeCheck } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    this.state.visibleDeletedPeriodicals = Object.values(sources.attributes).filter(periodical => periodical.title != null && (periodical.title.toLowerCase().includes(searchStringD.toLowerCase())));
    this.state.visibleDeletedPeriodicals.sort((a, b) =>  a.title.localeCompare(b.title));   

    if (this.state.visibleDeletedPeriodicals.length === 0) {
      return (
        <div>
        <Typography variant="h6">No periodicals to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleDeletedPeriodicals.length>=100){
      this.state.currDisplayedDeleted = this.state.visibleDeletedPeriodicals.splice(0,100)
    } else {
      this.state.currDisplayedDeleted = this.state.visibleDeletedPeriodicals.splice(0)
    }
    return (
      this.state.currDisplayedDeleted.map(periodical => (
        <PeriodicalCard key={periodical.id} periodical={periodical} citations={this.state.citationCount} 
        typeCheck={typeCheck} searchString={searchStringD} chooseQuotation={this.chooseDeletedItem.bind(this)}/>
      ))
    );  
  }

  renderUtterances(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, sources, selectedLetter, searchStringK} = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    this.state.visibleUtterances = Object.values(sources.attributes).filter(utterance => utterance.siteName != null && (utterance.siteName.toLowerCase().includes(searchStringK.toLowerCase())));
    this.state.visibleUtterances.sort((a, b) =>  a.siteName.localeCompare(b.siteName));    

    if (this.state.visibleUtterances.length === 0) {
      return (
        <div>
        <Typography variant="h6">No utterances to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleUtterances.length>=100){
      this.state.currDisplayed = this.state.visibleUtterances.splice(0,100)
    } else {
      this.state.currDisplayed = this.state.visibleUtterances.splice(0)
    }
    return (
      this.state.currDisplayed.map(utterance => (
        <UtteranceCard key={utterance.id} utterance={utterance} citations={this.state.citationCount} 
        searchString={searchStringK} chooseQuotation={this.chooseKeptItem.bind(this)}/>
      ))
    );  
  }

  renderDeletedUtterances(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, sources, selectedLetter, searchStringD} = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    this.state.visibleDeletedUtterances = Object.values(sources.attributes).filter(utterance => utterance.siteName != null && (utterance.siteName.toLowerCase().includes(searchStringD.toLowerCase())));
    this.state.visibleDeletedUtterances.sort((a, b) =>  a.siteName.localeCompare(b.siteName));    

    if (this.state.visibleDeletedUtterances.length === 0) {
      return (
        <div>
        <Typography variant="h6">No utterances to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleDeletedUtterances.length>=100){
      this.state.currDisplayedDeleted = this.state.visibleDeletedUtterances.splice(0,100)
    } else {
      this.state.currDisplayedDeleted = this.state.visibleDeletedUtterances.splice(0)
    }
    return (
      this.state.currDisplayedDeleted.map(utterance => (
        <UtteranceCard key={utterance.id} utterance={utterance} citations={this.state.citationCount} 
        searchString={searchStringD} chooseQuotation={this.chooseDeletedItem.bind(this)}/>
      ))
    );  
  }

  renderWebsites(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, sources, selectedLetter, searchStringK} = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    this.state.visibleWebsites = Object.values(sources.attributes).filter(website => website.siteName != null && (website.siteName.toLowerCase().includes(searchStringK.toLowerCase())));
    this.state.visibleWebsites.sort((a, b) =>  a.siteName.localeCompare(b.siteName));   

    if (this.state.visibleWebsites.length === 0) {
      return (
        <div>
        <Typography variant="h6">No websites to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleWebsites.length>=100){
      this.state.currDisplayed = this.state.visibleWebsites.splice(0,100)
    } else {
      this.state.currDisplayed = this.state.visibleWebsites.splice(0)
    }
    return (
      this.state.currDisplayed.map(website => (
        <WebsiteCard key={website.id} website={website} citations={this.state.citationCount} 
        searchString={searchStringK} chooseQuotation={this.chooseKeptItem.bind(this)}/>
      ))
    );  
  }

  renderDeletedWebsites(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, sources, selectedLetter, searchStringD} = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    this.state.visibleDeletedWebsites = Object.values(sources.attributes).filter(website => website.siteName != null && (website.siteName.toLowerCase().includes(searchStringD.toLowerCase())));
    this.state.visibleDeletedWebsites.sort((a, b) =>  a.siteName.localeCompare(b.siteName));   

    if (this.state.visibleDeletedWebsites.length === 0) {
      return (
        <div>
        <Typography variant="h6">No websites to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleDeletedWebsites.length>=100){
      this.state.currDisplayedDeleted = this.state.visibleDeletedWebsites.splice(0,100)
    } else {
      this.state.currDisplayedDeleted = this.state.visibleDeletedWebsites.splice(0)
    }
    return (
      this.state.currDisplayedDeleted.map(website => (
        <WebsiteCard key={website.id} website={website} citations={this.state.citationCount} 
        searchString={searchStringD} chooseQuotation={this.chooseDeletedItem.bind(this)}/>
      ))
    );  
  }

  renderQuotations(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, citations, searchStringK, searchStringD } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    this.state.visibleQuotations = Object.values(citations.attributes).filter(citation => citation.quotation!=null && (citation.quotation.toLowerCase().includes(searchStringK.toLowerCase())));
    let cleanedQuotations = this.state.visibleQuotations.map(q => {
      q.quotation = q.quotation.replace(/(<([^>]+)>)/ig,"").replace(/\&nbsp;/g, ' ');
      return q
    });
    cleanedQuotations.sort((a, b) =>  a.quotation.localeCompare(b.quotation)); 
    this.state.visibleQuotations = cleanedQuotations;

    if (this.state.visibleQuotations.length === 0) {
      return (
        <div>
        <Typography variant="h6">No quotations to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleQuotations.length>=100){
      this.state.currDisplayed = this.state.visibleQuotations.splice(0,100)
    } else {
      this.state.currDisplayed = this.state.visibleQuotations.splice(0)
    }
    return (
      this.state.currDisplayed.map(citation => (
        <QuotationCard key={citation.id} citation={citation} citations={this.state.citations} 
        searchString={searchStringK} authorCheck={false} editorCheck={false} publishedCheck={false}
        recordingCheck={false} articleCheck={false} regionCheck={false} merge={true} keep={true} chooseQuotation={this.chooseKeptItem.bind(this)}/>
      ))
    );  
  }

  renderDeletedQuotations(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, citations, searchStringK, searchStringD } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    this.state.visibleDeletedQuotations = Object.values(citations.attributes).filter(citation => citation.quotation!=null && (citation.quotation.toLowerCase().includes(searchStringD.toLowerCase())));
    let cleanedQuotations = this.state.visibleDeletedQuotations.map(q => {
      q.quotation = q.quotation.replace(/(<([^>]+)>)/ig,"").replace(/\&nbsp;/g, ' ');
      return q
    });
    cleanedQuotations.sort((a, b) =>  a.quotation.localeCompare(b.quotation)); 
    this.state.visibleDeletedQuotations = cleanedQuotations;

    if (this.state.visibleDeletedQuotations.length === 0) {
      return (
        <div>
        <Typography variant="h6">No quotations to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleDeletedQuotations.length>=100){
      this.state.currDisplayedDeleted = this.state.visibleDeletedQuotations.splice(0,100)
    } else {
      this.state.currDisplayedDeleted = this.state.visibleDeletedQuotations.splice(0)
    }
    return (
      this.state.currDisplayedDeleted.map(citation => (
        <QuotationCard key={citation.id} citation={citation} citations={this.state.citations} 
        searchString={searchStringD} authorCheck={false} editorCheck={false} publishedCheck={false}
        recordingCheck={false} articleCheck={false} regionCheck={false} merge={true} keep={true} chooseQuotation={this.chooseDeletedItem.bind(this)}/>
      ))
    );  
  }

  renderKeywords(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, headwords, selectedLetter, searchStringK, POSCheck, exactSearchK } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    if(exactSearchK){
      this.state.visibleKeywords = Object.values(headwords).filter(headword => headword?.attributes?.headword.toLowerCase() === searchStringK.toLowerCase());
    } else {
      this.state.visibleKeywords = headwords.filter(headword => headword?.attributes?.headword.toLowerCase().includes(searchStringK.toLowerCase()));
    }
    
    this.state.visibleKeywords.sort((a, b) =>  a.attributes.headword.localeCompare(b.attributes.headword));   

    if (this.state.visibleKeywords.length === 0) {
      return (
        <div>
        <Typography variant="h6">No keywords to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleKeywords.length>=100){
      this.state.currDisplayed = this.state.visibleKeywords.splice(0,100)
    } else {
      this.state.currDisplayed = this.state.visibleKeywords.splice(0)
    }
    return (
      this.state.currDisplayed.map(keyword => (
        <KeywordCard key={keyword.id} keyword={keyword} citations={this.state.citationCount} POSCheck={POSCheck} 
        searchString={searchStringK} chooseQuotation={this.chooseKeptItem.bind(this)}/>
      ))
    );  
  }

  renderDeletedKeywords(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, headwords, selectedLetter, searchStringD, POSCheck, exactSearchD } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }
    
    if(exactSearchD){
      this.state.visibleDeletedKeywords = Object.values(headwords).filter(headword => headword?.attributes?.headword.toLowerCase() === searchStringD.toLowerCase());
    } else {
      this.state.visibleDeletedKeywords = headwords.filter(headword => headword?.attributes?.headword.toLowerCase().includes(searchStringD.toLowerCase()));
    }
    
    this.state.visibleDeletedKeywords.sort((a, b) =>  a.attributes.headword.localeCompare(b.attributes.headword));   

    if (this.state.visibleDeletedKeywords.length === 0) {
      return (
        <div>
        <Typography variant="h6">No keywords to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleDeletedKeywords.length>=100){
      this.state.currDisplayedDeleted = this.state.visibleDeletedKeywords.splice(0,100)
    } else {
      this.state.currDisplayedDeleted = this.state.visibleDeletedKeywords.splice(0)
    }
    return (
      this.state.currDisplayedDeleted.map(keyword => (
        <KeywordCard key={keyword.id} keyword={keyword} citations={this.state.citationCount} POSCheck={POSCheck} 
        searchString={searchStringD} chooseQuotation={this.chooseDeletedItem.bind(this)}/>
      ))
    );  
  }

  chooseKeptItem(keep, citation){
    this.setState({ visibleQuotations: [], visibleBooks:[], visiblePeriodicals:[], visibleUtterances:[], visibleWebsites:[],
      visibleKeywords:[], searchStringK: "", keptChosen: true, itemToKeep: citation});
      console.log(citation);
 }

  chooseDeletedItem(keep, citation){
    this.setState({ visibleDeletedQuotations: [], visibleDeletedBooks:[], visibleDeletedPeriodicals:[], visibleDeletedUtterances:[], visibleDeletedWebsites:[],
      visibleDeletedKeywords:[], searchStringD: "", deleteChosen: true, itemToDelete: citation});
  }

  selectLetter(selectedLetter) {
    this.setState({ selectedLetter });
  }

  
  onSearchChangeD(searchStringD) {
    localStorage.setItem('MergeSearchStringD', searchStringD);
    this.setState({ searchStringD });
   // console.log(searchString);
  }

  onSearchChangeK(searchStringK) {
    localStorage.setItem('MergeSearchStringK', searchStringK);
    this.setState({ searchStringK });
   // console.log(searchString);
  }

  updateChosenField(type){
    const {books, periodicals, utterances, websites, sourceType, itemToKeep,
      headwordChosenCheck, sourceChosenCheck, authorChosenCheck, sptChosenCheck, pageNoChosenCheck,
      atChosenCheck, datePublishedChosenCheck, urlChosenCheck, adChosenCheck, websiteChosenCheck, quoteChosenCheck,
      regionChosenCheck, notesChosenCheck, gDegChosenCheck, gfChosenCheck, gParChosenCheck, gDetChosenCheck, gtChosenCheck, 
      gmChosenCheck, gPluChosenCheck, gvChosenCheck, gPhrChosenCheck, rcChosenCheck, rdChosenCheck, speakerChosenCheck, scChosenCheck,
      posChosenCheck, typeChosenCheck, isbnChosenCheck, editorChosenCheck, titleChosenCheck, editionChosenCheck, publisherChosenCheck,
      yearPublishedChosenCheck, placePublishedChosenCheck, dslBibRefChosenCheck, siteNameChosenCheck, legacyNotesChosenCheck,

      headwordDeletionCheck, sourceDeletionCheck, authorDeletionCheck, sptDeletionCheck, pageNoDeletionCheck,
      atDeletionCheck, datePublishedDeletionCheck, urlDeletionCheck, adDeletionCheck, websiteDeletionCheck, quoteDeletionCheck,
      regionDeletionCheck, notesDeletionCheck, gDegDeletionCheck, gfDeletionCheck, gParDeletionCheck, gDetDeletionCheck, gtDeletionCheck, 
      gmDeletionCheck, gPluDeletionCheck, gvDeletionCheck, gPhrDeletionCheck, rcDeletionCheck, rdDeletionCheck, speakerDeletionCheck, scDeletionCheck,
      posDeletionCheck, typeDeletionCheck, isbnDeletionCheck, editorDeletionCheck, titleDeletionCheck, editionDeletionCheck, publisherDeletionCheck,
      yearPublishedDeletionCheck, placePublishedDeletionCheck, dslBibRefDeletionCheck, siteNameDeletionCheck, legacyNotesDeletionCheck} = this.state;


    if(type=="headwordChosenCheck" || type=="headwordDeletionCheck"){
      this.setState({headwordChosenCheck: !headwordChosenCheck, headwordDeletionCheck: !headwordDeletionCheck});
    } else if(type=="sourceChosenCheck" || type=="sourceDeletionCheck") {
      this.setState({ sourceChosenCheck: !sourceChosenCheck, sourceDeletionCheck: !sourceDeletionCheck});
    } else if(type=="authorChosenCheck" || type=="authorDeletionCheck"){
      this.setState({authorChosenCheck: !authorChosenCheck, authorDeletionCheck: !authorDeletionCheck});
    } else if(type=="sptChosenCheck" || type=="sptDeletionCheck"){
      this.setState({sptChosenCheck: !sptChosenCheck, sptDeletionCheck: !sptDeletionCheck});
    } else if(type=="pageNoChosenCheck" || type=="pageNoDeletionCheck"){
      this.setState({pageNoChosenCheck: !pageNoChosenCheck, pageNoDeletionCheck: !pageNoDeletionCheck});
    } else if(type=="atChosenCheck" || type=="atDeletionCheck"){
      this.setState({atChosenCheck: !atChosenCheck, atDeletionCheck: !atDeletionCheck});
    } else if(type=="datePublishedChosenCheck" || type=="datePublishedDeletionCheck"){
      this.setState({datePublishedChosenCheck: !datePublishedChosenCheck, datePublishedDeletionCheck: !datePublishedDeletionCheck});
    } else if(type=="urlChosenCheck" || type=="urlDeletionCheck"){
      this.setState({urlChosenCheck:!urlChosenCheck, urlDeletionCheck:!urlDeletionCheck});
    } else if(type=="adChosenCheck" || type=="adDeletionCheck"){
      this.setState({adChosenCheck:!adChosenCheck, adDeletionCheck:!adDeletionCheck});
    } else if(type=="websiteChosenCheck" || type=="websiteDeletionCheck"){
      this.setState({websiteChosenCheck:!websiteChosenCheck, websiteDeletionCheck:!websiteDeletionCheck});
    } else if(type=="quoteChosenCheck" || type=="quoteDeletionCheck") {
      this.setState({quoteChosenCheck:!quoteChosenCheck, quoteDeletionCheck:!quoteDeletionCheck});
    } else if(type=="regionChosenCheck" || type=="regionDeletionCheck"){
      this.setState({regionChosenCheck:!regionChosenCheck, regionDeletionCheck:!regionDeletionCheck});
    } else if(type=="notesChosenCheck" || type=="notesDeletionCheck"){
      this.setState({notesChosenCheck:!notesChosenCheck, notesDeletionCheck:!notesDeletionCheck});
    } else if(type=="gDegChosenCheck" || type=="gDegDeletionCheck"){
      this.setState({gDegChosenCheck:!gDegChosenCheck, gDegDeletionCheck:!gDegDeletionCheck});
    } else if(type=="gfChosenCheck" || type=="gfDeletionCheck"){
      this.setState({gfChosenCheck: !gfChosenCheck, gfDeletionCheck: !gfDeletionCheck});
    } else if(type=="gParChosenCheck" || type=="gParDeletionCheck"){
      this.setState({gParChosenCheck:!gParChosenCheck, gParDeletionCheck:!gParDeletionCheck});
    } else if(type=="gDetChosenCheck" || type=="gDetDeletionCheck"){
      this.setState({gDetChosenCheck:!gDetChosenCheck, gDetDeletionCheck:!gDetDeletionCheck});
    } else if(type=="gtChosenCheck" || type=="gtDeletionCheck"){
      this.setState({gtChosenCheck:!gtChosenCheck, gtDeletionCheck:!gtDeletionCheck});
    } else if(type=="gmChosenCheck" || type=="gmDeletionCheck"){
      this.setState({gmChosenCheck:!gmChosenCheck, gmDeletionCheck:!gmDeletionCheck});
    } else if(type=="gPluChosenCheck" || type=="gPluDeletionCheck"){
      this.setState({gPluChosenCheck:!gPluChosenCheck, gPluDeletionCheck:!gPluDeletionCheck});
    } else if(type=="gvChosenCheck" || type=="gvDeletionCheck"){
      this.setState({gvChosenCheck:!gvChosenCheck, gvDeletionCheck:!gvDeletionCheck});
    } else if(type=="gPhrChosenCheck" || type=="gPhrDeletionCheck"){
      this.setState({gPhrChosenCheck:!gPhrChosenCheck, gPhrDeletionCheck:!gPhrDeletionCheck});
    } else if(type=="rcChosenCheck" || type=="rcDeletionCheck"){
      this.setState({rcChosenCheck:!rcChosenCheck, rcDeletionCheck:!rcDeletionCheck});
    } else if(type=="rdChosenCheck" || type=="rdDeletionCheck"){
      this.setState({rdChosenCheck:!rdChosenCheck, rdDeletionCheck:!rdDeletionCheck});
    } else if(type=="speakerChosenCheck" || type=="speakerDeletionCheck"){
      this.setState({speakerChosenCheck:!speakerChosenCheck, speakerDeletionCheck:!speakerDeletionCheck});
    } else if(type=="scChosenCheck" || type=="scDeletionCheck"){
      this.setState({scChosenCheck:!scChosenCheck, scDeletionCheck:!scDeletionCheck});
    } else if(type=="posChosenCheck" || type=="posDeletionCheck"){
      this.setState({posChosenCheck:!posChosenCheck, posDeletionCheck:!posDeletionCheck});
    } else if(type=="typeChosenCheck" || type=="typeDeletionCheck"){
      this.setState({typeChosenCheck:!typeChosenCheck, typeDeletionCheck:!typeDeletionCheck});
    } else if(type=="isbnChosenCheck" || type=="isbnDeletionCheck"){
      this.setState({isbnChosenCheck:!isbnChosenCheck, isbnDeletionCheck:!isbnDeletionCheck});
    } else if(type=="editorChosenCheck" || type=="editorDeletionCheck"){
      this.setState({editorChosenCheck:!editorChosenCheck, editorDeletionCheck:!editorDeletionCheck});
    }  else if(type=="titleChosenCheck" || type=="titleDeletionCheck"){
      this.setState({titleChosenCheck:!titleChosenCheck, titleDeletionCheck:!titleDeletionCheck});
    } else if(type=="editionChosenCheck" || type=="editionDeletionCheck"){
      this.setState({editionChosenCheck:!editionChosenCheck, editionDeletionCheck:!editionDeletionCheck});
    } else if(type=="publisherChosenCheck" || type=="publisherDeletionCheck"){
      this.setState({publisherChosenCheck:!publisherChosenCheck, publisherDeletionCheck:!publisherDeletionCheck});
    } else if(type=="yearPublishedChosenCheck" || type=="yearPublishedDeletionCheck"){
      this.setState({yearPublishedChosenCheck:!yearPublishedChosenCheck, yearPublishedDeletionCheck:!yearPublishedDeletionCheck});
    } else if(type=="placePublishedChosenCheck" || type=="placePublishedDeletionCheck"){
      this.setState({placePublishedChosenCheck:!placePublishedChosenCheck, placePublishedDeletionCheck:!placePublishedDeletionCheck});
    } else if(type=="dslBibRefChosenCheck" || type=="dslBibRefDeletionCheck"){
      this.setState({dslBibRefChosenCheck:!dslBibRefChosenCheck, dslBibRefDeletionCheck:!dslBibRefDeletionCheck});
    } else if(type=="siteNameChosenCheck" || type=="siteNameDeletionCheck"){
      this.setState({siteNameChosenCheck:!siteNameChosenCheck, siteNameDeletionCheck:!siteNameDeletionCheck});
    } else if(type=="legacyNotesChosenCheck" || type=="legacyNotesDeletionCheck"){
      this.setState({legacyNotesChosenCheck:!legacyNotesChosenCheck, legacyNotesDeletionCheck:!legacyNotesDeletionCheck});
    }
  } 


  componentWillMount() {
    this.signal = true;

    const { limit } = this.state;

    this.getData(limit);

    this._unwatch = appState.watch('user', user => {
      this.setState({ user });
    });
  }

  componentWillUnmount() {
    this._unwatch();
    this.signal = false;
  }

  canEdit() {
    return this.state.user && this.state.user.attributes.roles.includes('editor');
  }

  newHeadword() {
    this.props.history.push('headwords/new');
  }

  renderHeadwords() {
    const { classes, selectedUsers  } = this.props;
    const { isLoading, headwords, selectedLetter, searchString } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }
    
    this.state.headwords.sort((a, b) =>  a.attributes.headword.localeCompare(b.attributes.headword));
    let visibleHeadwords = headwords.filter(headword => headword.attributes.headword.toLowerCase().includes(searchString.toLowerCase()));
      
    
    if (visibleHeadwords.length === 0) {
      return (
        <Typography variant="h6">No keywords to display</Typography>
      );
    }

    return (
      visibleHeadwords.map(headword => (
        <HeadwordCard key={headword.id} headword={headword} />
      ))
    );  
  }

  async onChange(value){
    localStorage.setItem('MergeST', value);
    this.state.sourceType = value;
    if(value=='Texts'){
      try {      
        this.setState({ isLoading: true });
        this.setState({ progress: 0, progressLabel: "getting texts" });
        const allSources = await citation.getBooks();
        this.setState({progress: 70, progressLabel: "getting texts' citations"})
        this.state.citationCount = await ac.getBookCitationsCount();
        this.setState({ progress: 0, progressLabel: "getting keywords" });
        this.state.headwords = await headwords.getHeadwords();
  
        if (this.signal) {
          this.setState({
            isLoading: false,
            sources: allSources,
          });
        }
      } catch (error) {
        if (this.signal) {
          this.setState({
            isLoading: false,
            error
          });
        }
      }
    } else if(value=='Periodicals'){
      try {      
        this.setState({ isLoading: true });
        this.setState({ progress: 0, progressLabel: "getting periodicals" });
        const allSources = await citation.getPeriodicals();
        this.setState({progress: 70, progressLabel: "getting periodicals' citations"})
        this.state.citationCount = await ac.getPeriodicalCitationsCount();
        this.setState({ progress: 0, progressLabel: "getting keywords" });
        this.state.headwords = await headwords.getHeadwords();
  
        if (this.signal) {
          this.setState({
            isLoading: false,
            sources: allSources,
          });
        }
      } catch (error) {
        if (this.signal) {
          this.setState({
            isLoading: false,
            error
          });
        }
      }
    } else if(value=='Utterances'){
      try {      
        this.setState({ isLoading: true });
        this.setState({ progress: 0, progressLabel: "getting utterancess" });
        const allSources = await citation.getUtterances();
        this.setState({progress: 70, progressLabel: "getting utterances' citations"})
        this.state.citationCount = await ac.getUtteranceCitationsCount();
        this.setState({ progress: 0, progressLabel: "getting keywords" });
        this.state.headwords = await headwords.getHeadwords();
  
        if (this.signal) {
          this.setState({
            isLoading: false,
            sources: allSources,
          });
        }
      } catch (error) {
        if (this.signal) {
          this.setState({
            isLoading: false,
            error
          });
        }
      }

    } else if(value=='Websites'){
      try {      
        this.setState({ isLoading: true });
        this.setState({ progress: 0, progressLabel: "getting websites" });
        const allSources = await citation.getWebsites();
        this.setState({progress: 70, progressLabel: "getting websites' citations"})
        this.state.citationCount = await ac.getWebsiteCitationsCount();
        this.setState({ progress: 0, progressLabel: "getting keywords" });
        this.state.headwords = await headwords.getHeadwords();
  
        if (this.signal) {
          this.setState({
            isLoading: false,
            sources: allSources,
          });
        }
      } catch (error) {
        if (this.signal) {
          this.setState({
            isLoading: false,
            error
          });
        }
      }
    } else if(value=='Quotations'){
      try { 
        //this.state.citations = await ac.getAllCitations();
        if(this.state.citations.attributes[0]!=null){
          this.setState({ isLoading: true });
          this.setState({ progress: 0, progressLabel: "getting books..." });
          const books = await citation.getBooks();
          this.setState({ progress: 0, progressLabel: "getting periodicals..." });
          const periodicals = await citation.getPeriodicals();
          this.setState({ progress: 0, progressLabel: "getting utterances..." });
          const utterances = await citation.getUtterances();
          this.setState({ progress: 0, progressLabel: "getting websites..." });
          const websites = await citation.getWebsites();
          this.setState({ isLoading: false, books:books, periodicals:periodicals, utterances:utterances, websites:websites });
        } else {
          this.setState({ isLoading: true });
          this.setState({ progress: 0, progressLabel: "getting quotation count..." });
          //let count = await ac.countCitations();
          let count = await ac.getMaxCitationId();
          let numIters = Math.ceil(count/5000);
          let max = 5000 * numIters;
          this.setState({ isLoading: true });
          this.setState({ progress: 0, progressLabel: "getting books..." });
          const books = await citation.getBooks();
          this.setState({ progress: 0, progressLabel: "getting periodicals..." });
          const periodicals = await citation.getPeriodicals();
          this.setState({ progress: 0, progressLabel: "getting utterances..." });
          const utterances = await citation.getUtterances();
          this.setState({ progress: 0, progressLabel: "getting websites..." });
          const websites = await citation.getWebsites();
          this.setState({ progress: 0, progressLabel: "getting keywords" });
          this.state.headwords = await headwords.getHeadwords();
          this.setState({ progress: 0, progressLabel: "starting to get quotations..." });
          for(var i=0; i<numIters; i++){
            let citations = null
            let progressPoint = i * 5000;
            let progressPointPercentage = Math.round(i/numIters)
            this.setState({ progress: progressPointPercentage, progressLabel: "getting quotes...(" + progressPoint + " out of " + count + ")" });
            if(i==numIters-1){
              citations = await ac.getCitationsInRange(i*5000, ((i+1)*5000)+1);
              this.state.citationsArray.push(citations.attributes);
            } else {
              citations = await ac.getCitationsInRange(i*5000, (i+1)*5000);
              this.state.citationsArray.push(citations.attributes);
            }
          }
          this.setState({ progressLabel: "rebuilding full quotation list" });
          let total = 0;
          for(var i=0; i<this.state.citationsArray.length; i++){
            let j = 0;
            while(this.state.citationsArray[i][j]!=null){
                this.state.citations.attributes[total+j] = this.state.citationsArray[i][j];
                j = j + 1;
            }
            total = total + j;
          }
          if (this.signal) {
            this.setState({
              isLoading: false,
              books:books, 
              periodicals:periodicals, 
              utterances:utterances, 
              websites:websites
            });
          }
        }
      } catch (error) {
        if (this.signal) {
          this.setState({
            isLoading: false,
            error
          });
        }
      }
    } else if(value=='Keywords'){
      try {      
        this.setState({ isLoading: true });
        this.setState({ progress: 0, progressLabel: "getting keywords" });
        this.state.headwords = await headwords.getHeadwords();
        this.setState({ isLoading: true, progressLabel: "getting quotations counts" });
        this.state.citationCount = await ac.getCitationsCount();
  
        if (this.signal) {
          this.setState({
            isLoading: false,
          });
        }
      } catch (error) {
        if (this.signal) {
          this.setState({
            isLoading: false,
            error
          });
        }
      }
    }
    this.forceUpdate();
  }
  
  onCharChange(value){
    localStorage.setItem('MergeMinChars', value);
    this.state.minChars = value;
    this.forceUpdate();
  }

  async merge(){
    const {progressMessage, books, periodicals, utterances, websites, sourceType, itemToKeep, itemToDelete,
      headwordChosenCheck, sourceChosenCheck, authorChosenCheck, sptChosenCheck, pageNoChosenCheck,
      atChosenCheck, datePublishedChosenCheck, urlChosenCheck, adChosenCheck, websiteChosenCheck, quoteChosenCheck,
      regionChosenCheck, notesChosenCheck, gDegChosenCheck, gfChosenCheck, gParChosenCheck, gDetChosenCheck, gtChosenCheck, 
      gmChosenCheck, gPluChosenCheck, gvChosenCheck, gPhrChosenCheck, rcChosenCheck, rdChosenCheck, speakerChosenCheck, scChosenCheck, 
      posChosenCheck, typeChosenCheck, isbnChosenCheck, editorChosenCheck, titleChosenCheck, editionChosenCheck, publisherChosenCheck,
      yearPublishedChosenCheck, placePublishedChosenCheck, dslBibRefChosenCheck, siteNameChosenCheck, legacyNotesChosenCheck} = this.state;

      let firstmessage=" quotation(s) to \"" + itemToKeep.title + "\"...(about 2s per quote)";
      let secondmessage="All the quotations have been succesfull moved to \"" + itemToKeep.title +
      ".\"\n\nNow merging any fields being kept from \"" + itemToDelete.title + "\" to \"" + itemToKeep.title + "\".";
      let thirdmessage = "Please verifiy that all the correct fields have been moved." + "\n\n";
      if(this.state.sourceType=='Utterances' || this.state.sourceType=='Websites'){
        firstmessage=" quotation(s) to \"" + itemToKeep.siteName + "\"...(about 2s per quote)";
        secondmessage="All the quotations have been succesfull moved to \"" + itemToKeep.siteName +
        ".\"\n\nNow merging any fields being kept from \"" + itemToDelete.siteName + "\" to \"" + itemToKeep.siteName + "\".";
      } else if(this.state.sourceType=='Keywords'){
        firstmessage=" quotation(s) to \"" + itemToKeep.attributes.headword + "\"...(about 2s per quote)";
        secondmessage="All the quotations have been succesfull moved to \"" + itemToKeep.attributes.headword +
        ".\"\n\nNow merging any fields being kept from \"" + itemToDelete.attributes.headword + "\" to \"" + itemToKeep.attributes.headword + "\".";
      }
    if(itemToKeep.id==itemToDelete.id){
      window.alert("You can't merge the same entries, please select a different entry");
    } else {
      if(this.state.sourceType=='Texts'){
        if(window.confirm("Are you sure you want to merge these books?")){
          var idToChangeList = [];
          let citationsToMove = await ac.getBooksCitations(itemToDelete.id);
          let i = 0;
          while(citationsToMove.attributes[i]!=null){
            idToChangeList.push(citationsToMove.attributes[i].id);
            i = i + 1;
          }
          let bookRelation = await sourceService.getBook(itemToKeep.id);
          window.alert("Moving " + idToChangeList.length + firstmessage);
          for(let i=0;i<idToChangeList.length;i++){
            let j = i+1;
            this.state.progressMessage="Moving Quotations " + j + " of " + idToChangeList.length;
            this.forceUpdate();
            let citationToEdit = await citationService.getCitation(idToChangeList[i]);
            citationToEdit.attributes.bookId = itemToKeep.id;
            citationToEdit.relationships = {
              book: bookRelation,
              headword: citationToEdit.relationships.headword
            }
            citationToEdit.attributes.periodicalId = null;
            citationToEdit.attributes.utteranceId = null;
            citationToEdit.attributes.websiteId = null;
            citationToEdit.attributes.sourceType = "books";

            let citation = await citationService.updateCitation(citationToEdit);
          }
          window.alert(secondmessage);
          this.state.progressMessage="";
          this.forceUpdate();
          if(!titleChosenCheck){
            itemToKeep.title = itemToDelete.title;
          }
          if(!typeChosenCheck){
            itemToKeep.type = itemToDelete.type;
          }
          if(!isbnChosenCheck){
            itemToKeep.isbn = itemToDelete.isbn;
          }
          if(!authorChosenCheck){
            itemToKeep.author = itemToDelete.author;
          }
          if(!editorChosenCheck){
            itemToKeep.editor = itemToDelete.editor;
          }
          if(!editionChosenCheck){
            itemToKeep.edition = itemToDelete.edition;
          }
          if(!publisherChosenCheck){
            itemToKeep.publisher = itemToDelete.publisher;
          }
          if(!yearPublishedChosenCheck){
            itemToKeep.yearPublished = itemToDelete.yearPublished;
          } 
          if(!placePublishedChosenCheck){
            itemToKeep.placePublished = itemToDelete.placePublished;
          }
          if(!notesChosenCheck){
            itemToKeep.notes = itemToDelete.notes;
          }
          if(!dslBibRefChosenCheck){
            itemToKeep.dslBibliographyRef = itemToDelete.dslBibliographyRef;
          } 
          if(!legacyNotesChosenCheck){
            itemToKeep.legacyNotes = itemToDelete.legacyNotes;
          } 
          let book = {
            id: itemToKeep.id,
            attributes: itemToKeep
          }
          let confirmation = 
            "Title: " + itemToKeep.title + "\n" + 
            "Type: " + itemToKeep.type + "\n" +
            "ISBN: " + itemToKeep.isbn + "\n" +  
            "Author: " + itemToKeep.author + "\n" + 
            "Editor: " + itemToKeep.editor + "\n" + 
            "Edition: " + itemToKeep.edition + "\n" + 
            "Publisher: " + itemToKeep.publisher + "\n" + 
            "Year Published: " + itemToKeep.yearPublished + "\n" +
            "Place Published: " + itemToKeep.placePublished + "\n" +
            "Notes: " + itemToKeep.notes + "\n" +
            "DSL Bibliogrpahy Reference: " + itemToKeep.dslBibliographyRef + "\n" + 
            "Legacy Notes: " + itemToKeep.legacyNotes + "\n";
          if(window.confirm(thirdmessage + confirmation)){
          
          await sourceService.updateBook(book); 
          await deletion.deleteBook(itemToDelete);
          window.alert("Merge Completed.");
          window.location.reload();
          }
        } 
      } else if(this.state.sourceType=='Periodicals'){
        if(window.confirm("Are you sure you want to merge these periodicals?")){
          var idToChangeList = [];
          let citationsToMove = await ac.getPeriodicalsCitations(itemToDelete.id);
          let i = 0;
          while(citationsToMove.attributes[i]!=null){
            idToChangeList.push(citationsToMove.attributes[i].id);
            i = i + 1;
          }
          /*var idToChangeList = [];
          for(let i=0;i<Object.values(this.state.citations.attributes).length;i++){
            if(this.state.citations.attributes[i].periodicalId==itemToDelete.id){
              idToChangeList.push(this.state.citations.attributes[i].id);
            }
          }*/
          let periodicalRelation = await sourceService.getPeriodical(itemToKeep.id);
          window.alert("Moving " + idToChangeList.length + firstmessage);
          for(let i=0;i<idToChangeList.length;i++){
            let j = i+1;
            this.state.progressMessage="Moving Quotations " + j + " of " + idToChangeList.length;
            this.forceUpdate();
            let citationToEdit = await citationService.getCitation(idToChangeList[i]);
            citationToEdit.attributes.periodicalId = itemToKeep.id;
            citationToEdit.relationships = {
              periodical: periodicalRelation,
              headword: citationToEdit.relationships.headword
            }
            citationToEdit.attributes.bookId = null;
            citationToEdit.attributes.utteranceId = null;
            citationToEdit.attributes.websiteId = null;
            citationToEdit.attributes.sourceType = "periodicals";
            let citation = await citationService.updateCitation(citationToEdit);
          }
          window.alert(secondmessage);
          this.state.progressMessage="";
          this.forceUpdate();
          if(!titleChosenCheck){
            itemToKeep.title = itemToDelete.title;
          }
          if(!typeChosenCheck){
            itemToKeep.type = itemToDelete.type;
          }
          if(!notesChosenCheck){
            itemToKeep.notes = itemToDelete.notes;
          }
          if(!dslBibRefChosenCheck){
            itemToKeep.dslBibliographyRef = itemToDelete.dslBibliographyRef;
          } 
          if(!legacyNotesChosenCheck){
            itemToKeep.legacyNotes = itemToDelete.legacyNotes;
          } 
          let periodical = {
            id: itemToKeep.id,
            attributes: itemToKeep
          }
          let confirmation = 
            "Title: " + itemToKeep.title + "\n" + 
            "Type: " + itemToKeep.type + "\n" +
            "Notes: " + itemToKeep.notes + "\n" +
            "DSL Bibliogrpahy Reference: " + itemToKeep.dslBibliographyRef + "\n" +
            "Legacy Notes: " + itemToKeep.legacyNotes + "\n";
          if(window.confirm(thirdmessage + confirmation)){
          
          await sourceService.updatePeriodical(periodical); 
          await deletion.deletePeriodical(itemToDelete); 
          window.alert("Merge Completed.");
          window.location.reload();
          }
        }
      } else if(this.state.sourceType=='Utterances'){
        if(window.confirm("Are you sure you want to merge these utterances?")){
          var idToChangeList = [];
          let citationsToMove = await ac.getUtterancesCitations(itemToDelete.id);
          let i = 0;
          while(citationsToMove.attributes[i]!=null){
            idToChangeList.push(citationsToMove.attributes[i].id);
            i = i + 1;
          }
          /*var idToChangeList = [];
          for(let i=0;i<Object.values(this.state.citations.attributes).length;i++){
            if(this.state.citations.attributes[i].utteranceId==itemToDelete.id){
              idToChangeList.push(this.state.citations.attributes[i].id);
            }
          }*/
          let utteranceRelation = await sourceService.getUtterance(itemToKeep.id);
          window.alert("Moving " + idToChangeList.length + firstmessage);
          for(let i=0;i<idToChangeList.length;i++){
            let j = i+1;
            this.state.progressMessage="Moving Quotations " + j + " of " + idToChangeList.length;
            this.forceUpdate();
            let citationToEdit = await citationService.getCitation(idToChangeList[i]);
            citationToEdit.attributes.utteranceId = itemToKeep.id;
            citationToEdit.relationships = {
              utterance: utteranceRelation,
              headword: citationToEdit.relationships.headword
            }
            citationToEdit.attributes.bookId = null;
            citationToEdit.attributes.periodicalId = null;
            citationToEdit.attributes.websiteId = null;
            citationToEdit.attributes.sourceType = "utterances";
            let citation = await citationService.updateCitation(citationToEdit);
          }
          window.alert(secondmessage);
          this.state.progressMessage="";
          this.forceUpdate();
          if(!siteNameChosenCheck){
            itemToKeep.siteName = itemToDelete.siteName;
          }
          if(!typeChosenCheck){
            itemToKeep.type = itemToDelete.type;
          }
          if(!dslBibRefChosenCheck){
            itemToKeep.dslBibliographyRef = itemToDelete.dslBibliographyRef;
          } 
          let utterance = {
            id: itemToKeep.id,
            attributes: itemToKeep
          }
          let confirmation = 
            "Site Name: " + itemToKeep.siteName + "\n" + 
            "Type: " + itemToKeep.type + "\n" +
            "DSL Bibliogrpahy Reference: " + itemToKeep.dslBibliographyRef + "\n";
          if(window.confirm(thirdmessage + confirmation)){
          
          await sourceService.updateUtterance(utterance); 
          await deletion.deleteUtterance(itemToDelete); 
          window.alert("Merge Completed.");
          window.location.reload();
          }
        }
      } else if(this.state.sourceType=='Websites'){
        if(window.confirm("Are you sure you want to merge these websites?")){
          var idToChangeList = [];
          let citationsToMove = await ac.getWebsitesCitations(itemToDelete.id);
          let i = 0;
          while(citationsToMove.attributes[i]!=null){
            idToChangeList.push(citationsToMove.attributes[i].id);
            i = i + 1;
          }
          /*var idToChangeList = [];
          for(let i=0;i<Object.values(this.state.citations.attributes).length;i++){
            if(this.state.citations.attributes[i].websiteId==itemToDelete.id){
              idToChangeList.push(this.state.citations.attributes[i].id);
            }
          }*/
          let websiteRelation = await sourceService.getWebsite(itemToKeep.id);
          window.alert("Moving " + idToChangeList.length + firstmessage);
          for(let i=0;i<idToChangeList.length;i++){
            let j = i+1;
            this.state.progressMessage="Moving Quotations " + j + " of " + idToChangeList.length;
            this.forceUpdate();
            let citationToEdit = await citationService.getCitation(idToChangeList[i]);
            citationToEdit.attributes.websiteId = itemToKeep.id;
            citationToEdit.relationships = {
              website: websiteRelation,
              headword: citationToEdit.relationships.headword
            }
            citationToEdit.attributes.bookId = null;
            citationToEdit.attributes.periodicalId = null;
            citationToEdit.attributes.utteranceId = null;
            citationToEdit.attributes.sourceType = "websites";
            let citation = await citationService.updateCitation(citationToEdit);
          }
          window.alert(secondmessage);
          this.state.progressMessage="";
          this.forceUpdate();
          if(!siteNameChosenCheck){
            itemToKeep.siteName = itemToDelete.siteName;
          }
          if(!typeChosenCheck){
            itemToKeep.type = itemToDelete.type;
          }
          if(!dslBibRefChosenCheck){
            itemToKeep.dslBibliographyRef = itemToDelete.dslBibliographyRef;
          } 
          if(!legacyNotesChosenCheck){
            itemToKeep.legacyNotes = itemToDelete.legacyNotes;
          } 
          let website = {
            id: itemToKeep.id,
            attributes: itemToKeep
          }
          let confirmation = 
            "Site Name: " + itemToKeep.siteName + "\n" + 
            "Type: " + itemToKeep.type + "\n" +
            "DSL Bibliogrpahy Reference: " + itemToKeep.dslBibliographyRef + "\n" +
            "Legacy Notes: " + itemToKeep.legacyNotes + "\n";
          if(window.confirm(thirdmessage + confirmation)){
          
          await sourceService.updateWebsite(website); 
          await deletion.deleteWebsite(itemToDelete); 
          window.alert("Merge Completed.");
          window.location.reload();
          }
        }
      } else if(this.state.sourceType=='Quotations'){
        if(window.confirm("Are you sure you want to merge these quotations?")){
          let citationToKeep = await citationService.getCitation(itemToKeep.id);
          let citationToDelete = await citationService.getCitation(itemToDelete.id);
          
          if(!headwordChosenCheck){
            citationToKeep.attributes.headwordId = citationToDelete.attributes.headwordId;
            citationToKeep.relationships.headword = citationToDelete.relationships.headword;
          } 
          if(!sourceChosenCheck){
            if(itemToDelete.bookId!=null){
              citationToKeep.attributes.bookId = citationToDelete.attributes.bookId;
              citationToKeep.relationships = {
                book: citationToDelete.relationships.book,
                headword: citationToKeep.relationships.headword
              }
              citationToKeep.attributes.periodicalId = null;
              citationToKeep.attributes.utteranceId = null;
              citationToKeep.attributes.websiteId = null;
              citationToKeep.attributes.sourceType = "books";
            } else if(itemToDelete.periodicalId!=null){
              citationToKeep.attributes.periodicalId = citationToDelete.attributes.periodicalId;
              citationToKeep.relationships = {
                periodical: citationToDelete.relationships.periodical,
                headword: citationToKeep.relationships.headword
              }
              citationToKeep.attributes.bookId = null;
              citationToKeep.attributes.utteranceId = null;
              citationToKeep.attributes.websiteId = null;
              citationToKeep.attributes.sourceType = "periodicals";
            } else if(itemToDelete.utteranceId!=null){
              citationToKeep.attributes.utteranceId = citationToDelete.attributes.utteranceId;
              citationToKeep.relationships = {
                utterance: citationToDelete.relationships.utterance,
                headword: citationToKeep.relationships.headword
              }
              citationToKeep.attributes.bookId = null;
              citationToKeep.attributes.periodicalId = null;
              citationToKeep.attributes.websiteId = null;
              citationToKeep.attributes.sourceType = "utterances";
            } else if(itemToDelete.websiteName!=null) {
              citationToKeep.attributes.websiteId = citationToDelete.attributes.websiteId;
              citationToKeep.relationships = {
                website: citationToDelete.relationships.website,
                headword: citationToKeep.relationships.headword
              }
              citationToKeep.attributes.bookId = null;
              citationToKeep.attributes.periodicalId = null;
              citationToKeep.attributes.utteranceId = null;
              citationToKeep.attributes.sourceType = "websites";
            }
          }
          if(!authorChosenCheck){
            citationToKeep.attributes.author = citationToDelete.attributes.author;
          }
          if(!sptChosenCheck){
            citationToKeep.attributes.subPartTitle = citationToDelete.attributes.subPartTitile;
          }
          if(!pageNoChosenCheck){
            citationToKeep.attributes.pageNo = citationToDelete.attributes.pageNo;
          }
          if(!atChosenCheck){
            citationToKeep.attributes.articleTitle = citationToDelete.attributes.articleTitle;
          }
          if(!datePublishedChosenCheck){
            citationToKeep.attributes.publishedDate = citationToDelete.attributes.publishedDate;
          }
          if(!urlChosenCheck){
            citationToKeep.attributes.url = citationToDelete.attributes.url;
          }
          if(!adChosenCheck){
            citationToKeep.attributes.accessedDate = citationToDelete.attributes.accessedDate;
          }
          if(!websiteChosenCheck){
            citationToKeep.attributes.websiteName = citationToDelete.attributes.websiteName;
          } 
          if(!quoteChosenCheck){
            citationToKeep.attributes.quotation = citationToDelete.attributes.quotation;
          }
          if(!regionChosenCheck){
            citationToKeep.attributes.region = citationToDelete.attributes.region;
          }
          if(!notesChosenCheck){
            citationToKeep.attributes.notes = citationToDelete.attributes.notes;
          }
          if(!gDegChosenCheck){
            citationToKeep.attributes.grammarDegree = citationToDelete.attributes.grammarDegree;
          } 
          if(!gfChosenCheck){
            citationToKeep.attributes.grammarFunction = citationToDelete.attributes.grammarFunction;
          }
          if(!gParChosenCheck){
            citationToKeep.attributes.grammarParadigm = citationToDelete.attributes.grammarParadigm;
          } 
          if(!gDetChosenCheck){
            citationToKeep.attributes.grammarDeterminer = citationToDelete.attributes.grammarDeterminer;
          }
          if(!gtChosenCheck){
            citationToKeep.attributes.grammarType = citationToDelete.attributes.grammarType;
          }
          if(!gmChosenCheck){
            citationToKeep.attributes.grammarMood = citationToDelete.attributes.grammarMood;
          }
          if(!gPluChosenCheck){
            citationToKeep.attributes.grammarPlural = citationToDelete.attributes.grammarPlural;
          }
          if(!gvChosenCheck){
            citationToKeep.attributes.grammarVerbal = citationToDelete.attributes.grammarVerbal;
          }
          if(!gPhrChosenCheck){
            citationToKeep.attributes.grammarPhrase = citationToDelete.attributes.grammarPhrase;
          }
          if(!rcChosenCheck){
            citationToKeep.attributes.recordingCreator = citationToDelete.attributes.recordingCreator;
          }
          if(!rdChosenCheck){
            citationToKeep.attributes.recordingDate = citationToDelete.attributes.recordingDate;
          }
          if(!speakerChosenCheck){
            citationToKeep.attributes.speaker = citationToDelete.attributes.speaker;
          }
          if(!scChosenCheck){
            citationToKeep.attributes.suggestedCitation = citationToDelete.attributes.suggestedCitation;
          }
          if(!legacyNotesChosenCheck){
            citationToKeep.attributes.legacyNotes = citationToDelete.attributes.legacyNotes;
          } 

          //#region
          var headwordNameList = (this.state.headwords.filter( h => h.id== citationToKeep.attributes.headwordId));
          var headwordName = "";
          if(headwordNameList.length!=0){
            headwordName = headwordNameList[0].attributes.headword;
          } else {
            headwordName = null;
          }
          //#endregion
          //#region
          var sourceNameList = [];
          var sourceName = "";
          if(citationToKeep.attributes.bookId!=null){
            sourceNameList = Object.values(this.state.books.attributes).filter(b=>b.id==citationToKeep.attributes.bookId);
            /*let sourceNameList = await sourceService.getBook(citationToKeep.attributes.bookId);
            if(sourceNameList!=null){
              sourceName = sourceNameList.attributes.title;
            } else {
              sourceName= null;
            }*/
            if(sourceNameList.length!=0){
              sourceName = sourceNameList[0].title;
            } else {
              sourceName= null;
            }
          } else if(citationToKeep.attributes.periodicalId!=null){
            /*let sourceNameList = await sourceService.getPeriodical(citationToKeep.attributes.periodicalId);
            if(sourceNameList!=null){
              sourceName = sourceNameList.attributes.title;
            } else {
              sourceName= null;
            }*/
            sourceNameList = Object.values(this.state.periodicals.attributes).filter(b=>b.id==citationToKeep.attributes.periodicalId);
            if(sourceNameList.length!=0){
              sourceName = sourceNameList[0].title;
              } else {
                sourceName= null;
              }
          } else if(citationToKeep.attributes.utteranceId!=null){
            /*let sourceNameList = await sourceService.getUtterance(citationToKeep.attributes.utteranceId);
            if(sourceNameList!=null){
              sourceName = sourceNameList.attributes.siteName;
            } else {
              sourceName= null;
            }*/
            sourceNameList = Object.values(this.state.utterances.attributes).filter(b=>b.id==citationToKeep.attributes.utteranceId);
            if(sourceNameList.length!=0){
              sourceName = sourceNameList[0].siteName;
              } else {
                sourceName= null;
              }
          } else if(citationToKeep.attributes.websiteId!=null){
            /*let sourceNameList = await sourceService.getWebsite(citationToKeep.attributes.websiteId);
            if(sourceNameList!=null){
              sourceName = sourceNameList.attributes.siteName;
            } else {
              sourceName= null;
            }
            */
            sourceNameList = Object.values(this.state.websites.attributes).filter(b=>b.id==citationToKeep.attributes.websiteId);
            if(sourceNameList.length!=0){
              sourceName = sourceNameList[0].siteName;
              } else {
                sourceName= null;
              } 
          }
          //#endregion
          let cleanQuote = citationToKeep.attributes.quotation.replace(/(<([^>]+)>)/ig,"").replace(/\&nbsp;/g, ' ')
          let confirmation1 = 
          "Headword: " + headwordName + "\n" +
          "Source: " + sourceName + "\n" +
          "Author: " + citationToKeep.attributes.author + "\n" + 
          "Sub-Part Title: " + citationToKeep.attributes.subPartTitle + "\n" + 
          "Volume/Chapter/Page Number: " + citationToKeep.attributes.pageNo + "\n" +
          "Article Title: " + citationToKeep.attributes.articleTitle + "\n" +  
          "Date: " + citationToKeep.attributes.publishedDate + "\n" +
          "URL: " + citationToKeep.attributes.url + "\n" + 
          "Accessed Date: " + citationToKeep.attributes.accessedDate + "\n" + 
          "Website: " + citationToKeep.attributes.websiteName + "\n" + 
          "Quotation: " + cleanQuote + "\n";
          
          let confirmation2 = 
          "Region: " + citationToKeep.attributes.region + "\n" +
          "Notes: " + citationToKeep.attributes.notes + "\n" +
          "Grammar - Degree: " + citationToKeep.attributes.grammarDegree + "\n" +
          "Grammar - Function: " + citationToKeep.attributes.grammarFunction + "\n" +
          "Grammar - Paradigm: " + citationToKeep.attributes.grammarParadigm + "\n" +
          "Grammar - Determiner: " + citationToKeep.attributes.grammarDeterminer + "\n" +
          "Grammar - Type: " + citationToKeep.attributes.grammarType + "\n" +
          "Grammar - Mood: " + citationToKeep.attributes.grammarMood + "\n" +
          "Grammar - Plural: " + citationToKeep.attributes.grammarPlural + "\n" +
          "Grammar - Verbal: " + citationToKeep.attributes.grammarVerbal + "\n" +
          "Grammar - Phrase: " + citationToKeep.attributes.grammarPhrase + "\n" +
          "Recording Creator: " + citationToKeep.attributes.recordingCreator + "\n" +
          "Recording Date: " + citationToKeep.attributes.recordingDate + "\n" +
          "Speaker: " + citationToKeep.attributes.speaker + "\n" +
          "Suggested Citation: " + citationToKeep.attributes.suggestedCitation + "\n" +
          "Legacy Notes: " + citationToKeep.legacyNotes + "\n";

          if(window.confirm(thirdmessage + "PART 1/2: " + "\n" + confirmation1)){
            if(window.confirm("PART 2/2: " + "\n" + confirmation2)){
              let citation = await citationService.updateCitation(citationToKeep);
              await deletion.deleteCitation(itemToDelete);
              window.alert("Merge Completed.");
              window.location.reload();
            }
          }
      }
      } else if(this.state.sourceType=='Keywords'){    
        if(window.confirm("Are you sure you want to merge these keywords?")){
          var idToChangeList = [];
          const citationsToMove = await ac.getHeadwordsCitations(itemToDelete.id);
          const movedQuotations = await Promise.all(Object.values(citationsToMove.attributes).map(async (citation) => {
            return JSON.stringify(await citationService.getCitation(citation.id));
          }));
          let i = 0;
          while(citationsToMove.attributes[i]!=null){
            idToChangeList.push(citationsToMove.attributes[i].id);
            i = i + 1;
          }
          let headwordRelation = this.state.headwords.filter(h=>h.id==itemToKeep.id);
          window.alert("Moving " + idToChangeList.length + firstmessage);
          for(let i=0;i<idToChangeList.length;i++){
            let j = i+1;
            this.state.progressMessage="Moving Quotations " + j + " of " + idToChangeList.length;
            this.forceUpdate();
            let citationToEdit = await citationService.getCitation(idToChangeList[i]);
            citationToEdit.attributes.headwordId = itemToKeep.id;
            citationToEdit.relationships.headword  = headwordRelation[0];
            await citationService.updateCitation(citationToEdit);
          }
          window.alert(secondmessage);
          this.state.progressMessage="";
          this.forceUpdate();
          if(!siteNameChosenCheck){
            itemToKeep.attributes.headword = itemToDelete.attributes.headword;
          }
          if(!posChosenCheck){
            itemToKeep.attributes.partOfSpeech = itemToDelete.attributes.partOfSpeech;
          }
          let keyword = {
            id: itemToKeep.id,
            attributes: itemToKeep.attributes
          }
          let confirmation = 
            "Headword: " + itemToKeep.attributes.headword + "\n" + 
            "Part of Speech: " + itemToKeep.attributes.partOfSpeech + "\n";
          if(window.confirm(thirdmessage + confirmation)){
          
          await headwords.updateHeadword(keyword); 
          await deletion.deleteKeyword(itemToDelete); 
          window.alert("Merge Completed.");
          localStorage.removeItem('DeletedHeadword');
          localStorage.removeItem('MovedQuotations');
          localStorage.setItem('DeletedHeadword', (JSON.stringify({ attributes: { headword: itemToDelete.attributes.headword, partOfSpeech: itemToDelete.attributes.partOfSpeech }})));
          localStorage.setItem('MovedQuotations', JSON.stringify(movedQuotations));
          window.location.reload();
          }
        }
      }
    }
  }

  renderSourcePreview(){
    const {classes, updating} = this.props;
    const {sourceType, itemToKeep} = this.state;
    if(sourceType=="Texts"){
     this.state.message="Entry Editor - Select the fields from the text (ID: " + itemToKeep.id + ") that you want to keep.";
    } else if(sourceType=="Periodicals"){
      this.state.message="Entry Editor - Select the fields from the periodical (ID: " + itemToKeep.id + ") that you want to keep.";
    } else if(sourceType=="Utterances"){
      this.state.message="Entry Editor - Select the fields from the utterance (ID: " + itemToKeep.id + ") that you want to keep.";
    } else if(sourceType=="Websites"){
      this.state.message="Entry Editor - Select the fields from the website (ID: " + itemToKeep.id + ") that you want to keep.";
    } else if(sourceType=="Quotations"){
      this.state.message="Entry Editor - Select the fields from the quotation (ID: " + itemToKeep.id + ") that you want to keep.";
    } else if(sourceType=="Keywords"){
      this.state.message="Entry Editor - Select the fields from the keyword (ID: " + itemToKeep.id + ") that you want to keep.";
    }
    return(
    <Portlet>
    <PortletHeader>
      <PortletLabel title={this.state.message} />
    </PortletHeader>
    <PortletContent>
      {this.renderIndividualSource()}
    </PortletContent>
    </Portlet>);
  }

  renderIndividualSource(){
    const {classes} = this.props;
    const { books, periodicals, utterances, websites, sourceType, itemToKeep,
      headwordChosenCheck, sourceChosenCheck, authorChosenCheck, sptChosenCheck, pageNoChosenCheck,
      atChosenCheck, datePublishedChosenCheck, urlChosenCheck, adChosenCheck, websiteChosenCheck, quoteChosenCheck,
      regionChosenCheck, notesChosenCheck, gDegChosenCheck, gfChosenCheck, gParChosenCheck, gDetChosenCheck, gtChosenCheck, 
      gmChosenCheck, gPluChosenCheck, gvChosenCheck, gPhrChosenCheck, rcChosenCheck, rdChosenCheck, speakerChosenCheck, scChosenCheck,
      posChosenCheck, typeChosenCheck, isbnChosenCheck, editorChosenCheck, titleChosenCheck, editionChosenCheck, publisherChosenCheck,
      yearPublishedChosenCheck, placePublishedChosenCheck, dslBibRefChosenCheck, siteNameChosenCheck, legacyNotesChosenCheck} = this.state;
    var headwordNameList = (this.state.headwords.filter( h => h.id==itemToKeep.headwordId));
    var headwordName = "";


    var sourceNameList = [];
    var sourceName = "";
    
    var book = {}; 
    var periodical = {};
    var utterance = {};
    var website = {};
    var test = "test";
    if(sourceType=="Quotations"){
      if(headwordNameList.length!=0){
        headwordName = headwordNameList[0].attributes.headword;
      } else {
        headwordName = null;
      }
      if(itemToKeep.bookId!=null){
        sourceNameList = Object.values(this.state.books.attributes).filter(b=>b.id==itemToKeep.bookId);
        if(sourceNameList.length!=0){
          sourceName = sourceNameList[0].title;
        } else {
          sourceName= null;
        }
      } else if(itemToKeep.periodicalId!=null){
        sourceNameList = Object.values(this.state.periodicals.attributes).filter(b=>b.id==itemToKeep.periodicalId);
        if(sourceNameList.length!=0){
          sourceName = sourceNameList[0].title;
          } else {
            sourceName= null;
          }
      } else if(itemToKeep.utteranceId!=null){
        sourceNameList = Object.values(this.state.utterances.attributes).filter(b=>b.id==itemToKeep.utteranceId);
        if(sourceNameList.length!=0){
          sourceName = sourceNameList[0].siteName;
          } else {
            sourceName= null;
          }
      } else if(itemToKeep.websiteId!=null){
        sourceNameList = Object.values(this.state.websites.attributes).filter(b=>b.id==itemToKeep.websiteId);
        if(sourceNameList.length!=0){
          sourceName = sourceNameList[0].siteName;
          } else {
            sourceName= null;
          } 
      }
      return(
      <div> 
        <FormGroup column>
        {(headwordName!=null) && (headwordName!="") && (
          <FormControlLabel control={<Checkbox checked={headwordChosenCheck} onChange={(event) => this.updateChosenField("headwordChosenCheck")} color="primary"/> } label={"Headword: " + headwordName }/>
        )}
        {(sourceName!=null) && (sourceName!="") && (
          <FormControlLabel control={<Checkbox checked={sourceChosenCheck} onChange={(event) => this.updateChosenField("sourceChosenCheck")} color="primary"/> } label={"Source: " + sourceName}/>
        )}
        {(itemToKeep.author!=null) && (itemToKeep.author!="") && (
          <FormControlLabel control={<Checkbox checked={authorChosenCheck} onChange={(event) => this.updateChosenField("authorChosenCheck")} color="primary"/> } label={"Author: " + itemToKeep.author}/>
        )}
          {(itemToKeep.subPartTitle!=null) && (itemToKeep.subPartTitle!="") && (
          <FormControlLabel control={<Checkbox checked={sptChosenCheck} onChange={(event) => this.updateChosenField("sptChosenCheck")} color="primary"/> } label={"Sub-Part Title: " + itemToKeep.subPartTitle}/>
          )}
        {(itemToKeep.pageNo!=null) && (itemToKeep.pageNo!="") && (
          <FormControlLabel control={<Checkbox checked={pageNoChosenCheck} onChange={(event) => this.updateChosenField("pageNoChosenCheck")} color="primary"/> } label={"Volume/Chapter/Page Number: " + itemToKeep.pageNo}/>
          )}
        {(itemToKeep.articleTitle!=null) && (itemToKeep.articleTitle!="") && (
          <FormControlLabel control={<Checkbox checked={atChosenCheck} onChange={(event) => this.updateChosenField("atChosenCheck")}  name="authorCheck" color="primary"/> } label={"Article Title: " + itemToKeep.articleTitle} />
        )}
        {(itemToKeep.publishedDate!=null) && (itemToKeep.publishedDate!="") && (
          <FormControlLabel control={<Checkbox checked={datePublishedChosenCheck} onChange={(event) => this.updateChosenField("datePublishedChosenCheck")} color="primary"/> } label={"Date: " + itemToKeep.publishedDate}/>
        )}
        {(itemToKeep.url!=null) && (itemToKeep.url!="") && (
          <FormControlLabel control={<Checkbox checked={urlChosenCheck} onChange={(event) => this.updateChosenField("urlChosenCheck")} color="primary"/> } label={"URL: " + itemToKeep.url}/>
        )}
        {(itemToKeep.accessedDate!=null) && (itemToKeep.accessedDate!="") && (
          <FormControlLabel control={<Checkbox checked={adChosenCheck} onChange={(event) => this.updateChosenField("adChosenCheck")}  color="primary"/> } label={"Accessed Date: " + itemToKeep.accessedDate}/>
        )}
        {(itemToKeep.websiteName!=null) && (itemToKeep.websiteName!="") && (
          <FormControlLabel control={<Checkbox checked={websiteChosenCheck} onChange={(event) => this.updateChosenField("websiteChosenCheck")} color="primary"/> } label={"Website Name: " + itemToKeep.websiteName}/>
        )}
        {(itemToKeep.quotation!=null) && (itemToKeep.quotation!="") && (
          <FormControlLabel control={<Checkbox checked={quoteChosenCheck} onChange={(event) => this.updateChosenField("quoteChosenCheck")} color="primary"/> } label={"Quotation: " + itemToKeep.quotation}/>
        )}
        {(itemToKeep.region!=null) && (itemToKeep.region!="") && (
          <FormControlLabel control={<Checkbox checked={regionChosenCheck} onChange={(event) => this.updateChosenField("regionChosenCheck")} color="primary"/> } label={"Region: " + itemToKeep.region}/>
        )}
        {(itemToKeep.notes!=null) && (itemToKeep.notes!="") && (
          <FormControlLabel control={<Checkbox checked={notesChosenCheck} onChange={(event) => this.updateChosenField("notesChosenCheck")} color="primary"/> } label={"Notes: "+ itemToKeep.notes}/>
        )}
        {(itemToKeep.grammarDegree!=null) && (itemToKeep.grammarDegree!="") && (
          <FormControlLabel control={<Checkbox checked={gDegChosenCheck} onChange={(event) => this.updateChosenField("gDegChosenCheck")} color="primary"/> } label={"Grammar Degree: " + itemToKeep.grammarDegree}/>
        )}
        {(itemToKeep.grammarFunction!=null) && (itemToKeep.grammarFunction!="") && (
          <FormControlLabel control={<Checkbox checked={gfChosenCheck} onChange={(event) => this.updateChosenField("gfChosenCheck")} color="primary"/> } label={"Grammar Function: " + itemToKeep.grammarFunction}/>
        )} 
        {(itemToKeep.grammarParadigm) && (itemToKeep.grammarParadigm!="") && (
          <FormControlLabel control={<Checkbox checked={gParChosenCheck} onChange={(event) => this.updateChosenField("gParChosenCheck")} color="primary"/> } label={"Grammar Paradigm: " + itemToKeep.grammarParadigm}/>
        )}
        {(itemToKeep.grammarDeterminer) && (itemToKeep.grammarDeterminer!="")(
          <FormControlLabel control={<Checkbox checked={gDetChosenCheck} onChange={(event) => this.updateChosenField("gDetChosenCheck")} color="primary"/> } label={"Grammar Determiner: " + itemToKeep.grammarDeterminer}/>
        )}
        {(itemToKeep.grammarType) && (itemToKeep.grammarType!="") && (
          <FormControlLabel control={<Checkbox checked={gtChosenCheck} onChange={(event) => this.updateChosenField("gtChosenCheck")} color="primary"/> } label={"Grammar Type: " + itemToKeep.grammarType}/>
        )}
        {(itemToKeep.grammarMood) && (itemToKeep.grammarMood!="") && (
          <FormControlLabel control={<Checkbox checked={gmChosenCheck} onChange={(event) => this.updateChosenField("gmChosenCheck")} color="primary"/> } label={"Grammar Mood: " + itemToKeep.grammarMood}/>
        )}
        {(itemToKeep.grammarPlural) && (itemToKeep.grammarPlural!="") && (
        <FormControlLabel control={<Checkbox checked={gPluChosenCheck} onChange={(event) => this.updateChosenField("gPluChosenCheck")} color="primary"/> } label={"Grammar Plural: " + itemToKeep.grammarPlural}/>
        )}
        {(itemToKeep.grammarVerbal) && (itemToKeep.grammarVerbal!="") && (
          <FormControlLabel control={<Checkbox checked={gvChosenCheck} onChange={(event) => this.updateChosenField("gvChosenCheck")} color="primary"/> } label={"Grammar Verbal: " + itemToKeep.grammarVerbal}/>
        )}
        {(itemToKeep.grammarPhrase) && (itemToKeep.grammarPhrase!="") && (
          <FormControlLabel control={<Checkbox checked={gPhrChosenCheck} onChange={(event) => this.updateChosenField("gPhrChosenCheck")} color="primary"/> } label={"Grammar Phrase: " + itemToKeep.grammarPhrase}/>
        )}
        {(itemToKeep.recordingCreator) && (itemToKeep.recordingCreator!="") && (
          <FormControlLabel control={<Checkbox checked={rcChosenCheck} onChange={(event) => this.updateChosenField("rcChosenCheck")} color="primary"/> } label={"Recording Creator: " + itemToKeep.recordingCreator}/>
        )}
        {(itemToKeep.recordingDate) && (itemToKeep.recordingDate!="") && (
          <FormControlLabel control={<Checkbox checked={rdChosenCheck} onChange={(event) => this.updateChosenField("rdChosenCheck")} color="primary"/> } label={"Recording Date: " + itemToKeep.recordingDate}/>
        )}
        {(itemToKeep.speaker) && (itemToKeep.speaker!="") && (
          <FormControlLabel control={<Checkbox checked={speakerChosenCheck} onChange={(event) => this.updateChosenField("speakerChosenCheck")}  name="authorCheck" color="primary"/> } label={"Speaker: " + itemToKeep.speaker}/>
        )}
        {(itemToKeep.suggestedCitation) && (itemToKeep.suggestedCitation!="") && (
          <FormControlLabel control={<Checkbox checked={scChosenCheck} onChange={(event) => this.updateChosenField("scChosenCheck")}  name="authorCheck" color="primary"/> } label={"Suggested Citation: " + itemToKeep.suggestedCitation}/>
        )}
        {(itemToKeep.legacyNotes!=null) && (itemToKeep.legacyNotes!="") && (
        <FormControlLabel control={<Checkbox checked={legacyNotesChosenCheck} onChange={(event) => this.updateChosenField("legacyNotesChosenCheck")} color="primary"/> } label={"legacy Notes: " + itemToKeep.legacyNotes}/>
      )}
      </FormGroup>
      </div>
      );
    } else if(sourceType=="Texts"){
      return(
        <div> 
          <FormGroup column>
          {(itemToKeep.title!=null) && (itemToKeep.title!="") && (
            <FormControlLabel control={<Checkbox checked={titleChosenCheck} onChange={(event) => this.updateChosenField("titleChosenCheck")} color="primary"/> } label={"Title: " + itemToKeep.title}/>
            )}
          {(itemToKeep.type!=null) && (itemToKeep.type!="") && (
            <FormControlLabel control={<Checkbox checked={typeChosenCheck} onChange={(event) => this.updateChosenField("typeChosenCheck")} color="primary"/> } label={"Type: " + itemToKeep.type }/>
          )}
          {(itemToKeep.isbn!=null) && (itemToKeep.isbn!="") && (
            <FormControlLabel control={<Checkbox checked={isbnChosenCheck} onChange={(event) => this.updateChosenField("isbnChosenCheck")} color="primary"/> } label={"ISBN: " + itemToKeep.isbn}/>
          )}
          {(itemToKeep.author!=null) && (itemToKeep.author!="") && (
            <FormControlLabel control={<Checkbox checked={authorChosenCheck} onChange={(event) => this.updateChosenField("authorChosenCheck")} color="primary"/> } label={"Author: " + itemToKeep.author}/>
          )}
            {(itemToKeep.editor!=null) && (itemToKeep.editor!="") && (
            <FormControlLabel control={<Checkbox checked={editorChosenCheck} onChange={(event) => this.updateChosenField("editorChosenCheck")} color="primary"/> } label={"Editor: " + itemToKeep.editor}/>
            )}
          {(itemToKeep.edition!=null) && (itemToKeep.edition!="") && (
            <FormControlLabel control={<Checkbox checked={editionChosenCheck} onChange={(event) => this.updateChosenField("editionChosenCheck")} color="primary"/> } label={"Edition: " + itemToKeep.edition} />
          )}
          {(itemToKeep.publisher!=null) && (itemToKeep.publisher!="") && (
            <FormControlLabel control={<Checkbox checked={publisherChosenCheck} onChange={(event) => this.updateChosenField("publisherChosenCheck")} color="primary"/> } label={"Publisher: " + itemToKeep.publisher}/>
          )}
          {(itemToKeep.yearPublished!=null) && (itemToKeep.yearPublished!="") && (
            <FormControlLabel control={<Checkbox checked={yearPublishedChosenCheck} onChange={(event) => this.updateChosenField("yearPublishedChosenCheck")} color="primary"/> } label={"Year Published: " + itemToKeep.yearPublished}/>
          )}
          {(itemToKeep.placePublished!=null) && (itemToKeep.placePublished!="") && (
            <FormControlLabel control={<Checkbox checked={placePublishedChosenCheck} onChange={(event) => this.updateChosenField("placePublishedChosenCheck")}  color="primary"/> } label={"Place Published: " + itemToKeep.placePublished}/>
          )}
          {(itemToKeep.notes!=null) && (itemToKeep.notes!="") && (
            <FormControlLabel control={<Checkbox checked={notesChosenCheck} onChange={(event) => this.updateChosenField("notesChosenCheck")} color="primary"/> } label={"Notes: " + itemToKeep.notes}/>
          )}
          {(itemToKeep.dslBibliographyRef!=null) && (itemToKeep.dslBibliographyRef!="") && (
            <FormControlLabel control={<Checkbox checked={dslBibRefChosenCheck} onChange={(event) => this.updateChosenField("dslBibRefChosenCheck")} color="primary"/> } label={"DSL Bibliography Reference: " + itemToKeep.dslBibliographyRef}/>
          )}
          {(itemToKeep.legacyNotes!=null) && (itemToKeep.legacyNotes!="") && (
        <FormControlLabel control={<Checkbox checked={legacyNotesChosenCheck} onChange={(event) => this.updateChosenField("legacyNotesChosenCheck")} color="primary"/> } label={"legacy Notes: " + itemToKeep.legacyNotes}/>
           )}
        </FormGroup>
        </div>
        );
    } else if(sourceType=="Keywords") {
      return(
      <div> 
      <FormGroup column>

      {(itemToKeep.attributes!=null && itemToKeep.attributes.headword!=null) && (itemToKeep.attributes!="" && itemToKeep.attributes.headword!="") && (
        <FormControlLabel control={<Checkbox checked={headwordChosenCheck} onChange={(event) => this.updateChosenField("headwordChosenCheck")} color="primary"/> } label={"Headword: " + itemToKeep.attributes.headword}/>
        )}
      {(itemToKeep.attributes!=null && itemToKeep.attributes.partOfSpeech!=null) && (itemToKeep.attributes!="" && itemToKeep.attributes.partOfSpeech!="") && (
        <FormControlLabel control={<Checkbox checked={posChosenCheck} onChange={(event) => this.updateChosenField("posChosenCheck")} color="primary"/> } label={"Part of Speech: " + itemToKeep.attributes.partOfSpeech }/>
      )}
      {(itemToKeep.attributes!=null && itemToKeep.legacyNotes!=null) && (itemToKeep.attributes!="" && itemToKeep.legacyNotes!="") && (
        <FormControlLabel control={<Checkbox checked={legacyNotesChosenCheck} onChange={(event) => this.updateChosenField("legacyNotesChosenCheck")} color="primary"/> } label={"legacy Notes: " + itemToKeep.legacyNotes}/>
      )}
    </FormGroup>
    </div>
      );
    } else if(sourceType=="Periodicals"){
      return(
      <div> 
      <FormGroup column>
      {(itemToKeep.title!=null) && (itemToKeep.title!="") && (
        <FormControlLabel control={<Checkbox checked={titleChosenCheck} onChange={(event) => this.updateChosenField("titleChosenCheck")} color="primary"/> } label={"Title: " + itemToKeep.title}/>
        )}
      {(itemToKeep.type!=null) && (itemToKeep.type!="") && (
        <FormControlLabel control={<Checkbox checked={typeChosenCheck} onChange={(event) => this.updateChosenField("typeChosenCheck")} color="primary"/> } label={"Type: " + itemToKeep.type }/>
      )}
      {(itemToKeep.notes!=null) && (itemToKeep.notes!="") && (
        <FormControlLabel control={<Checkbox checked={notesChosenCheck} onChange={(event) => this.updateChosenField("notesChosenCheck")} color="primary"/> } label={"Notes: " + itemToKeep.notes}/>
      )}
      {(itemToKeep.dslBibliographyRef!=null) && (itemToKeep.dslBibliographyRef!="") && (
        <FormControlLabel control={<Checkbox checked={dslBibRefChosenCheck} onChange={(event) => this.updateChosenField("dslBibRefChosenCheck")} color="primary"/> } label={"DSL Bibliography Reference: " + itemToKeep.dslBibliographyRef}/>
      )}
      {(itemToKeep.legacyNotes!=null) && (itemToKeep.legacyNotes!="") && (
        <FormControlLabel control={<Checkbox checked={legacyNotesChosenCheck} onChange={(event) => this.updateChosenField("legacyNotesChosenCheck")} color="primary"/> } label={"legacy Notes: " + itemToKeep.legacyNotes}/>
      )}
    </FormGroup>
    </div>
      );
    } else if(sourceType=="Utterances"){
      return(
      <div> 
      <FormGroup column>
      {(itemToKeep.siteName!=null) && (itemToKeep.siteName!="") && (
        <FormControlLabel control={<Checkbox checked={siteNameChosenCheck} onChange={(event) => this.updateChosenField("siteNameChosenCheck")} color="primary"/> } label={"Site Name: " + itemToKeep.siteName}/>
        )}
      {(itemToKeep.type!=null) && (itemToKeep.type!="") && (
        <FormControlLabel control={<Checkbox checked={typeChosenCheck} onChange={(event) => this.updateChosenField("typeChosenCheck")} color="primary"/> } label={"Type: " + itemToKeep.type }/>
      )}
      {(itemToKeep.dslBibliographyRef!=null) && (itemToKeep.dslBibliographyRef!="") && (
        <FormControlLabel control={<Checkbox checked={dslBibRefChosenCheck} onChange={(event) => this.updateChosenField("dslBibRefChosenCheck")} color="primary"/> } label={"DSL Bibliography Reference: " + itemToKeep.dslBibliographyRef}/>
      )}
    </FormGroup>
    </div>
      );
    } else if(sourceType=="Websites"){
      return(
      <div> 
      <FormGroup column>
      {(itemToKeep.siteName!=null) && (itemToKeep.siteName!="") &&(
        <FormControlLabel control={<Checkbox checked={siteNameChosenCheck} onChange={(event) => this.updateChosenField("siteNameChosenCheck")} color="primary"/> } label={"Site Name: " + itemToKeep.siteName}/>
        )}
      {(itemToKeep.type!=null) && (itemToKeep.type!="") && (
        <FormControlLabel control={<Checkbox checked={typeChosenCheck} onChange={(event) => this.updateChosenField("typeChosenCheck")} color="primary"/> } label={"Type: " + itemToKeep.type }/>
      )}
      {(itemToKeep.dslBibliographyRef!=null) && (itemToKeep.dslBibliographyRef!="") && (
        <FormControlLabel control={<Checkbox checked={dslBibRefChosenCheck} onChange={(event) => this.updateChosenField("dslBibRefChosenCheck")} color="primary"/> } label={"DSL Bibliography Reference: " + itemToKeep.dslBibliographyRef}/>
      )}
      {(itemToKeep.legacyNotes!=null) && (itemToKeep.legacyNotes!="") && (
        <FormControlLabel control={<Checkbox checked={legacyNotesChosenCheck} onChange={(event) => this.updateChosenField("legacyNotesChosenCheck")} color="primary"/> } label={"legacy Notes: " + itemToKeep.legacyNotes}/>
      )}
    </FormGroup>
    </div>
      );
    }
  }

  renderSourcePreviewDelete(){
    const {classes, updating} = this.props;
    const {sourceType, itemToDelete} = this.state;
    if(sourceType=="Texts"){
     this.state.message="Entry Editor - Select the fields from the text (ID: " + itemToDelete.id + ") that you want to keep.";
    } else if(sourceType=="Periodicals"){
      this.state.message="Entry Editor - Select the fields from the periodical (ID: " + itemToDelete.id + ") that you want to keep.";
    } else if(sourceType=="Utterances"){
      this.state.message="Entry Editor - Select the fields from the utterance (ID: " + itemToDelete.id + ") that you want to keep.";
    } else if(sourceType=="Websites"){
      this.state.message="Entry Editor - Select the fields from the website (ID: " + itemToDelete.id + ") that you want to keep.";
    } else if(sourceType=="Quotations"){
      this.state.message="Entry Editor - Select the fields from the quotation (ID: " + itemToDelete.id + ") that you want to keep.";
    } else if(sourceType=="Keywords"){
      this.state.message="Entry Editor - Select the fields from the keyword (ID: " + itemToDelete.id + ") that you want to keep.";
    }
    return(
    <Portlet>
    <PortletHeader>
      <PortletLabel title={this.state.message}/>
    </PortletHeader>
    <PortletContent>
      {this.renderIndividualSourceDelete()}
    </PortletContent>
    </Portlet>);
  }

  renderIndividualSourceDelete(){
    const {classes} = this.props;
    const {books, periodicals, utterances, websites, sourceType, itemToDelete,
      headwordDeletionCheck, sourceDeletionCheck, authorDeletionCheck, sptDeletionCheck, pageNoDeletionCheck,
      atDeletionCheck, datePublishedDeletionCheck, urlDeletionCheck, adDeletionCheck, websiteDeletionCheck, quoteDeletionCheck,
      regionDeletionCheck, notesDeletionCheck, gDegDeletionCheck, gfDeletionCheck, gParDeletionCheck, gDetDeletionCheck, gtDeletionCheck, 
      gmDeletionCheck, gPluDeletionCheck, gvDeletionCheck, gPhrDeletionCheck, rcDeletionCheck, rdDeletionCheck, speakerDeletionCheck, scDeletionCheck, 
      posDeletionCheck, typeDeletionCheck, isbnDeletionCheck, editorDeletionCheck, titleDeletionCheck, editionDeletionCheck, publisherDeletionCheck,
      yearPublishedDeletionCheck, placePublishedDeletionCheck, dslBibRefDeletionCheck, siteNameDeletionCheck, legacyNotesDeletionCheck} = this.state;
    var headwordNameList = (this.state.headwords.filter( h => h.id==itemToDelete.headwordId));
    var headwordName = "";
    if(headwordNameList.length!=0){
      headwordName = headwordNameList[0].attributes.headword;
    } else {
      headwordName = null;
    }

    var sourceNameList = [];
    var sourceName = "";
    if(itemToDelete.bookId!=null){
      /*sourceNameList = await sourceService.getBook(itemToDelete.bookId);
      if(sourceNameList!=null){
        sourceName = sourceNameList.attributes.title;
      } else {
        sourceName= null;
      }*/
      sourceNameList = Object.values(books.attributes).filter(b=>b.id==itemToDelete.bookId);
      if(sourceNameList.length!=0){
        sourceName = sourceNameList[0].title;
      } else {
        sourceName= null;
      }
    } else if(itemToDelete.periodicalId!=null){
      /*sourceNameList = await sourceService.getPeriodical(itemToDelete.periodicalId);
      if(sourceNameList!=null){
        sourceName = sourceNameList.attributes.title;
      } else {
        sourceName= null;
      }*/
      sourceNameList = Object.values(periodicals.attributes).filter(b=>b.id==itemToDelete.periodicalId);
      if(sourceNameList.length!=0){
        sourceName = sourceNameList[0].title;
        } else {
          sourceName= null;
        }
    } else if(itemToDelete.utteranceId!=null){
      /*sourceNameList = await sourceService.getUtterance(itemToDelete.utteranceId);
      if(sourceNameList!=null){
        sourceName = sourceNameList.attributes.siteName;
      } else {
        sourceName= null;
      }*/
      sourceNameList = Object.values(utterances.attributes).filter(b=>b.id==itemToDelete.utteranceId);
      if(sourceNameList.length!=0){
        sourceName = sourceNameList[0].siteName;
        } else {
          sourceName= null;
        }
    } else if(itemToDelete.websiteId!=null){
      /*sourceNameList = await sourceService.getWebsite(itemToDelete.websiteId);
      if(sourceNameList!=null){
        sourceName = sourceNameList.attributes.siteName;
      } else {
        sourceName= null;
      }*/
      sourceNameList = Object.values(websites.attributes).filter(b=>b.id==itemToDelete.websiteId);
      if(sourceNameList.length!=0){
        sourceName = sourceNameList[0].siteName;
        } else {
          sourceName= null;
        }
    }
    var book = {}; 
    var periodical = {};
    var utterance = {};
    var website = {};
    var test = "test";
    if(sourceType=="Quotations"){
      return(
      <div> 
        <FormGroup column>
        {(headwordName!=null) && (headwordName!="") && (
          <FormControlLabel control={<Checkbox checked={headwordDeletionCheck} onChange={(event) => this.updateChosenField("headwordDeletionCheck")} color="primary"/> } label={"Headword: " + headwordName }/>
        )}
        {(sourceName!=null) && (sourceName!="") && (
          <FormControlLabel control={<Checkbox checked={sourceDeletionCheck} onChange={(event) => this.updateChosenField("sourceDeletionCheck")} color="primary"/> } label={"Source: " + sourceName}/>
        )}
        {(itemToDelete.author!=null) && (itemToDelete.author!="") && (
          <FormControlLabel control={<Checkbox checked={authorDeletionCheck} onChange={(event) => this.updateChosenField("authorDeletionCheck")} color="primary"/> } label={"Author: " + itemToDelete.author}/>
        )}
          {(itemToDelete.subPartTitle!=null) && (itemToDelete.subPartTitle!="") && (
          <FormControlLabel control={<Checkbox checked={sptDeletionCheck} onChange={(event) => this.updateChosenField("sptDeletionCheck")} color="primary"/> } label={"Sub-Part Title: " + itemToDelete.subPartTitle}/>
          )}
        {(itemToDelete.pageNo!=null) && (itemToDelete.pageNo!="") && (
          <FormControlLabel control={<Checkbox checked={pageNoDeletionCheck} onChange={(event) => this.updateChosenField("pageNoDeletionCheck")} color="primary"/> } label={"Volume/Chapter/Page Number: " + itemToDelete.pageNo}/>
          )}
        {(itemToDelete.articleTitle!=null) && (itemToDelete.articleTitle!="") && (
          <FormControlLabel control={<Checkbox checked={atDeletionCheck} onChange={(event) => this.updateChosenField("atDeletionCheck")}  name="authorCheck" color="primary"/> } label={"Article Title: " + itemToDelete.articleTitle} />
        )}
        {(itemToDelete.publishedDate!=null) && (itemToDelete.publishedDate!="") && (
          <FormControlLabel control={<Checkbox checked={datePublishedDeletionCheck} onChange={(event) => this.updateChosenField("datePublishedDeletionCheck")} color="primary"/> } label={"Date: " + itemToDelete.publishedDate}/>
        )}
        {(itemToDelete.url!=null) && (itemToDelete.url!="") && (
          <FormControlLabel control={<Checkbox checked={urlDeletionCheck} onChange={(event) => this.updateChosenField("urlDeletionCheck")} color="primary"/> } label={"URL: " + itemToDelete.url}/>
        )}
        {(itemToDelete.accessedDate!=null) && (itemToDelete.accessedDate!="") && (
          <FormControlLabel control={<Checkbox checked={adDeletionCheck} onChange={(event) => this.updateChosenField("adDeletionCheck")}  color="primary"/> } label={"Accessed Date: " + itemToDelete.accessedDate}/>
        )}
        {(itemToDelete.websiteName!=null) && (itemToDelete.websiteName!="") && (
          <FormControlLabel control={<Checkbox checked={websiteDeletionCheck} onChange={(event) => this.updateChosenField("websiteDeletionCheck")} color="primary"/> } label={"Website Name: " + itemToDelete.websiteName}/>
        )}
        {(itemToDelete.quotation!=null) && (itemToDelete.quotation!="") && (
          <FormControlLabel control={<Checkbox checked={quoteDeletionCheck} onChange={(event) => this.updateChosenField("quoteDeletionCheck")} color="primary"/> } label={"Quotation: " + itemToDelete.quotation}/>
        )}
        {(itemToDelete.region!=null) && (itemToDelete.region!="") && (
          <FormControlLabel control={<Checkbox checked={regionDeletionCheck} onChange={(event) => this.updateChosenField("regionDeletionCheck")} color="primary"/> } label={"Region: " + itemToDelete.region}/>
        )}
        {(itemToDelete.notes!=null) && (itemToDelete.notes!="") && (
          <FormControlLabel control={<Checkbox checked={notesDeletionCheck} onChange={(event) => this.updateChosenField("notesDeletionCheck")} color="primary"/> } label={"Notes: "+ itemToDelete.notes}/>
        )}
        {(itemToDelete.grammarDegree!=null) && (itemToDelete.grammarDegree!="") && (
          <FormControlLabel control={<Checkbox checked={gDegDeletionCheck} onChange={(event) => this.updateChosenField("gDegDeletionCheck")} color="primary"/> } label={"Grammar Degree: " + itemToDelete.grammarDegree}/>
        )}
        {(itemToDelete.grammarFunction!=null) && (itemToDelete.grammarFunction!="") && (
          <FormControlLabel control={<Checkbox checked={gfDeletionCheck} onChange={(event) => this.updateChosenField("gfDeletionCheck")} color="primary"/> } label={"Grammar Function: " + itemToDelete.grammarFunction}/>
        )} 
        {(itemToDelete.grammarParadigm) && (itemToDelete.grammarParadigm!="") && (
          <FormControlLabel control={<Checkbox checked={gParDeletionCheck} onChange={(event) => this.updateChosenField("gParDeletionCheck")} color="primary"/> } label={"Grammar Paradigm: " + itemToDelete.grammarParadigm}/>
        )}
        {(itemToDelete.grammarDeterminer) && (itemToDelete.grammarDeterminer!="") && (
          <FormControlLabel control={<Checkbox checked={gDetDeletionCheck} onChange={(event) => this.updateChosenField("gDetDeletionCheck")} color="primary"/> } label={"Grammar Determiner: " + itemToDelete.grammarDeterminer}/>
        )}
        {(itemToDelete.grammarType) && (itemToDelete.grammarType!="") && (
          <FormControlLabel control={<Checkbox checked={gtDeletionCheck} onChange={(event) => this.updateChosenField("gtDeletionCheck")} color="primary"/> } label={"Grammar Type: " + itemToDelete.grammarType}/>
        )}
        {(itemToDelete.grammarMood) && (itemToDelete.grammarMood!="") && (
          <FormControlLabel control={<Checkbox checked={gmDeletionCheck} onChange={(event) => this.updateChosenField("gmDeletionCheck")} color="primary"/> } label={"Grammar Mood: " + itemToDelete.grammarMood}/>
        )}
        {(itemToDelete.grammarPlural) && (itemToDelete.grammarPlural!="") && (
        <FormControlLabel control={<Checkbox checked={gPluDeletionCheck} onChange={(event) => this.updateChosenField("gPluDeletionCheck")} color="primary"/> } label={"Grammar Plural: " + itemToDelete.grammarPlural}/>
        )}
        {(itemToDelete.grammarVerbal) && (itemToDelete.grammarVerbal!="") && (
          <FormControlLabel control={<Checkbox checked={gvDeletionCheck} onChange={(event) => this.updateChosenField("gvDeletionCheck")} color="primary"/> } label={"Grammar Verbal: " + itemToDelete.grammarVerbal}/>
        )}
        {(itemToDelete.grammarPhrase) && (itemToDelete.grammarPhrase!="") && (
          <FormControlLabel control={<Checkbox checked={gPhrDeletionCheck} onChange={(event) => this.updateChosenField("gPhrDeletionCheck")} color="primary"/> } label={"Grammar Phrase: " + itemToDelete.grammarPhrase}/>
        )}
        {(itemToDelete.recordingCreator) && (itemToDelete.recordingCreator!="") && (
          <FormControlLabel control={<Checkbox checked={rcDeletionCheck} onChange={(event) => this.updateChosenField("rcDeletionCheck")} color="primary"/> } label={"Recording Creator: " + itemToDelete.recordingCreator}/>
        )}
        {(itemToDelete.recordingDate) && (itemToDelete.recordingDate!="") && (
          <FormControlLabel control={<Checkbox checked={rdDeletionCheck} onChange={(event) => this.updateChosenField("rdDeletionCheck")} color="primary"/> } label={"Recording Date: " + itemToDelete.recordingDate}/>
        )}
        {(itemToDelete.speaker) && (itemToDelete.speaker!="") && (
          <FormControlLabel control={<Checkbox checked={speakerDeletionCheck} onChange={(event) => this.updateChosenField("speakerDeletionCheck")}  name="authorCheck" color="primary"/> } label={"Speaker: " + itemToDelete.speaker}/>
        )}
        {(itemToDelete.suggestedCitation) && (itemToDelete.suggestedCitation!="") && (
          <FormControlLabel control={<Checkbox checked={scDeletionCheck} onChange={(event) => this.updateChosenField("scDeletionCheck")}  name="authorCheck" color="primary"/> } label={"Suggested Citation: " + itemToDelete.suggestedCitation}/>
        )}
        {(itemToDelete.legacyNotes!=null) && (itemToDelete.legacyNotes!="") && (
            <FormControlLabel control={<Checkbox checked={legacyNotesDeletionCheck} onChange={(event) => this.updateChosenField("legacyNotesDeletionCheck")} color="primary"/> } label={"Legacy Notes: " + itemToDelete.legacyNotes}/>
          )}
      </FormGroup>
      </div>
      );
    } else if(sourceType=="Texts"){
      return(
        <div> 
          <FormGroup column>
          {(itemToDelete.title!=null) && (itemToDelete.title!="") && (
            <FormControlLabel control={<Checkbox checked={titleDeletionCheck} onChange={(event) => this.updateChosenField("titleDeletionCheck")} color="primary"/> } label={"Title: " + itemToDelete.title}/>
            )}
          {(itemToDelete.type!=null) && (itemToDelete.type!="") && (
            <FormControlLabel control={<Checkbox checked={typeDeletionCheck} onChange={(event) => this.updateChosenField("typeDeletionCheck")} color="primary"/> } label={"Type: " + itemToDelete.type }/>
          )}
          {(itemToDelete.isbn!=null) && (itemToDelete.isbn!="") && (
            <FormControlLabel control={<Checkbox checked={isbnDeletionCheck} onChange={(event) => this.updateChosenField("isbnDeletionCheck")} color="primary"/> } label={"ISBN: " + itemToDelete.isbn}/>
          )}
          {(itemToDelete.author!=null) && (itemToDelete.author!="") && (
            <FormControlLabel control={<Checkbox checked={authorDeletionCheck} onChange={(event) => this.updateChosenField("authorDeletionCheck")} color="primary"/> } label={"Author: " + itemToDelete.author}/>
          )}
            {(itemToDelete.editor!=null) && (itemToDelete.editor!="") && (
            <FormControlLabel control={<Checkbox checked={editorDeletionCheck} onChange={(event) => this.updateChosenField("editorDeletionCheck")} color="primary"/> } label={"Editor: " + itemToDelete.editor}/>
            )}
          {(itemToDelete.edition!=null) && (itemToDelete.edition!="") && (
            <FormControlLabel control={<Checkbox checked={editionDeletionCheck} onChange={(event) => this.updateChosenField("editionDeletionCheck")} color="primary"/> } label={"Edition: " + itemToDelete.edition} />
          )}
          {(itemToDelete.publisher!=null) && (itemToDelete.publisher!="") && (
            <FormControlLabel control={<Checkbox checked={publisherDeletionCheck} onChange={(event) => this.updateChosenField("publisherDeletionCheck")} color="primary"/> } label={"Date: " + itemToDelete.publisher}/>
          )}
          {(itemToDelete.yearPublished!=null) && (itemToDelete.yearPublished!="") && (
            <FormControlLabel control={<Checkbox checked={yearPublishedDeletionCheck} onChange={(event) => this.updateChosenField("yearPublishedDeletionCheck")} color="primary"/> } label={"Year Published: " + itemToDelete.yearPublished}/>
          )}
          {(itemToDelete.placePublished!=null) && (itemToDelete.placePublished!="") && (
            <FormControlLabel control={<Checkbox checked={placePublishedDeletionCheck} onChange={(event) => this.updateChosenField("placePublishedDeletionCheck")}  color="primary"/> } label={"Place Published: " + itemToDelete.placePublished}/>
          )}
          {(itemToDelete.notes!=null) && (itemToDelete.notes!="") && (
            <FormControlLabel control={<Checkbox checked={notesDeletionCheck} onChange={(event) => this.updateChosenField("notesDeletionCheck")} color="primary"/> } label={"Notes: " + itemToDelete.notes}/>
          )}
          {(itemToDelete.dslBibliographyRef!=null) && (itemToDelete.dslBibliographyRef!="") && (
            <FormControlLabel control={<Checkbox checked={dslBibRefDeletionCheck} onChange={(event) => this.updateChosenField("dslBibRefDeletionCheck")} color="primary"/> } label={"DSL Bibliography Reference: " + itemToDelete.dslBibliographyRef}/>
          )}
          {(itemToDelete.legacyNotes!=null) && (itemToDelete.legacyNotes!="") && (
            <FormControlLabel control={<Checkbox checked={legacyNotesDeletionCheck} onChange={(event) => this.updateChosenField("legacyNotesDeletionCheck")} color="primary"/> } label={"Legacy Notes: " + itemToDelete.legacyNotes}/>
          )}
        </FormGroup>
        </div>
        );
    } else if(sourceType=="Keywords") {
      return(
      <div> 
      <FormGroup column>
      {(itemToDelete.attributes!=null && itemToDelete.attributes.headword!=null) && (itemToDelete.attributes!="" && itemToDelete.attributes.headword!="") && (
        <FormControlLabel control={<Checkbox checked={headwordDeletionCheck} onChange={(event) => this.updateChosenField("headwordDeletionCheck")} color="primary"/> } label={"Headword: " + itemToDelete.attributes.headword}/>
        )}
      {(itemToDelete.attributes!=null && itemToDelete.attributes.partOfSpeech!=null) && (itemToDelete.attributes!="" && itemToDelete.attributes.partOfSpeech!="") && (
        <FormControlLabel control={<Checkbox checked={posDeletionCheck} onChange={(event) => this.updateChosenField("posDeletionCheck")} color="primary"/> } label={"Part of Speech: " + itemToDelete.attributes.partOfSpeech }/>
      )}
      {(itemToDelete.attributes!=null && itemToDelete.legacyNotes!=null) && (itemToDelete.attributes!="" && itemToDelete.legacyNotes!="") &&  (
            <FormControlLabel control={<Checkbox checked={legacyNotesDeletionCheck} onChange={(event) => this.updateChosenField("legacyNotesDeletionCheck")} color="primary"/> } label={"Legacy Notes: " + itemToDelete.legacyNotes}/>
          )}
    </FormGroup>
    </div>
      );
    } else if(sourceType=="Periodicals"){
      return(
      <div> 
      <FormGroup column>
      {(itemToDelete.title!=null) && (itemToDelete.title!="") && (
        <FormControlLabel control={<Checkbox checked={titleDeletionCheck} onChange={(event) => this.updateChosenField("titleDeletionCheck")} color="primary"/> } label={"Title: " + itemToDelete.title}/>
        )}
      {(itemToDelete.type!=null) && (itemToDelete.type!="") && (
        <FormControlLabel control={<Checkbox checked={typeDeletionCheck} onChange={(event) => this.updateChosenField("typeDeletionCheck")} color="primary"/> } label={"Type: " + itemToDelete.type }/>
      )}
      {(itemToDelete.notes!=null) && (itemToDelete.notes!="") && (
        <FormControlLabel control={<Checkbox checked={notesDeletionCheck} onChange={(event) => this.updateChosenField("notesDeletionCheck")} color="primary"/> } label={"Notes: " + itemToDelete.notes}/>
      )}
      {(itemToDelete.dslBibliographyRef!=null) && (itemToDelete.dslBibliographyRef!="") && (
        <FormControlLabel control={<Checkbox checked={dslBibRefDeletionCheck} onChange={(event) => this.updateChosenField("dslBibRefDeletionCheck")} color="primary"/> } label={"DSL Bibliography Reference: " + itemToDelete.dslBibliographyRef}/>
      )}
      {(itemToDelete.legacyNotes!=null) && (itemToDelete.legacyNotes!="") && (
            <FormControlLabel control={<Checkbox checked={legacyNotesDeletionCheck} onChange={(event) => this.updateChosenField("legacyNotesDeletionCheck")} color="primary"/> } label={"Legacy Notes: " + itemToDelete.legacyNotes}/>
          )}
    </FormGroup>
    </div>
      );
    } else if(sourceType=="Utterances"){
      return(
      <div> 
      <FormGroup column>
      {(itemToDelete.siteName!=null) && (itemToDelete.siteName!="") && (
        <FormControlLabel control={<Checkbox checked={siteNameDeletionCheck} onChange={(event) => this.updateChosenField("siteNameDeletionCheck")} color="primary"/> } label={"Site Name: " + itemToDelete.siteName}/>
        )}
      {(itemToDelete.type!=null) && (itemToDelete.type!="") && (
        <FormControlLabel control={<Checkbox checked={typeDeletionCheck} onChange={(event) => this.updateChosenField("typeDeletionCheck")} color="primary"/> } label={"Type: " + itemToDelete.type }/>
      )}
      {(itemToDelete.dslBibliographyRef!=null) && (itemToDelete.dslBibliographyRef!="") && (
        <FormControlLabel control={<Checkbox checked={dslBibRefDeletionCheck} onChange={(event) => this.updateChosenField("dslBibRefDeletionCheck")} color="primary"/> } label={"DSL Bibliography Reference: " + itemToDelete.dslBibliographyRef}/>
      )}
    </FormGroup>
    </div>
      );
    } else if(sourceType=="Websites"){
      return(
      <div> 
      <FormGroup column>
      {(itemToDelete.siteName!=null) && (itemToDelete.siteName!="") && (
        <FormControlLabel control={<Checkbox checked={siteNameDeletionCheck} onChange={(event) => this.updateChosenField("siteNameDeletionCheck")} color="primary"/> } label={"Site Name: " + itemToDelete.siteName}/>
        )}
      {(itemToDelete.type!=null) && (itemToDelete.type!="") && (
        <FormControlLabel control={<Checkbox checked={typeDeletionCheck} onChange={(event) => this.updateChosenField("typeDeletionCheck")} color="primary"/> } label={"Type: " + itemToDelete.type }/>
      )}
      {(itemToDelete.dslBibliographyRef!=null) && (itemToDelete.dslBibliographyRef!="") && (
        <FormControlLabel control={<Checkbox checked={dslBibRefDeletionCheck} onChange={(event) => this.updateChosenField("dslBibRefDeletionCheck")} color="primary"/> } label={"DSL Bibliography Reference: " + itemToDelete.dslBibliographyRef}/>
      )}
      {(itemToDelete.legacyNotes!=null) && (itemToDelete.legacyNotes!="") && (
            <FormControlLabel control={<Checkbox checked={legacyNotesDeletionCheck} onChange={(event) => this.updateChosenField("legacyNotesDeletionCheck")} color="primary"/> } label={"Legacy Notes: " + itemToDelete.legacyNotes}/>
          )}
    </FormGroup>
    </div>
      );
    }
  }

  renderAllSources(){
    const { classes } = this.props;
    const { minChars } = this.state;
    if(this.state.sourceType=='Texts' && this.state.searchStringK.length>=minChars){
      return(
        <div>
            {this.renderBooks()}
        </div>
      );
    } else if(this.state.sourceType=='Periodicals' && this.state.searchStringK.length>=minChars){
      return(
        <div>
      {this.renderPeriodicals()}
      </div>
      );
    } else if(this.state.sourceType=='Utterances' && this.state.searchStringK.length>=minChars){
      return(
        <div>
        {this.renderUtterances()}
      </div>);
    } else if(this.state.sourceType=='Websites' && this.state.searchStringK.length>=minChars){
      return(
          <div>
        {this.renderWebsites()}
        </div>
      );
    }
    else if(this.state.sourceType=='Quotations' && this.state.searchStringK.length>=minChars){
      return(
          <div>
        {this.renderQuotations()}
        </div>
      );
    } else if(this.state.sourceType=='Keywords' && this.state.searchStringK.length>=minChars){
      return(
          <div>
        {this.renderKeywords()}
        </div>
      );
    } else if (this.state.searchStringK.length>=minChars){
      return(
        <div>
              {this.renderBooks()}
              {this.renderPeriodicals()}
              {this.renderUtterances()}
              {this.renderWebsites()}
              {this.renderQuotations()}
              {this.renderKeywords()}
        </div>
      );
      }
  }

  renderAllDeletedSources(){
    const { minChars } = this.state;
    const { classes } = this.props;
    if(this.state.sourceType=='Texts' && this.state.searchStringD.length>=minChars){
      return(
        <div>
            {this.renderDeletedBooks()}
        </div>
      );
    } else if(this.state.sourceType=='Periodicals' && this.state.searchStringD.length>=minChars){
      return(
        <div>
      {this.renderDeletedPeriodicals()}
      </div>
      );
    } else if(this.state.sourceType=='Utterances' && this.state.searchStringD.length>=minChars){
      return(
        <div>
        {this.renderDeletedUtterances()}
      </div>);
    } else if(this.state.sourceType=='Websites' && this.state.searchStringD.length>=minChars){
      return(
          <div>
        {this.renderDeletedWebsites()}
        </div>
      );
    }
    else if(this.state.sourceType=='Quotations' && this.state.searchStringD.length>=minChars){
      return(
          <div>
        {this.renderDeletedQuotations()}
        </div>
      );
    } else if(this.state.sourceType=='Keywords' && this.state.searchStringD.length>=minChars){
      return(
          <div>
        {this.renderDeletedKeywords()}
        </div>
      );
    } else if (this.state.searchStringD.length>=minChars){
      return(
        <div>
              {this.renderDeletedBooks()}
              {this.renderDeletedPeriodicals()}
              {this.renderDeletedUtterances()}
              {this.renderDeletedWebsites()}
              {this.renderDeletedQuotations()}
              {this.renderDeletedKeywords()}
        </div>
      );
      }
  }

  reset(){
    localStorage.removeItem('MergeSearchStringK');
    localStorage.removeItem('MergeSearchStringD');
    localStorage.removeItem('MergeST');
    localStorage.removeItem('MergeMinChars');
    localStorage.removeItem('MovedQuotations');
    localStorage.removeItem('DeletedHeadword');
    window.location.reload()
  }

  async undo (){
    if(window.confirm('Do you wish to undo your last merge?')) {
      try {
        const movedQuotes = JSON.parse(localStorage.getItem('MovedQuotations'));
        const deletedHeadword = JSON.parse(localStorage.getItem('DeletedHeadword'));
        const headword = await headwords.createHeadword(deletedHeadword);
        let updatedQuotes = [];
        for(var i = 0; i<movedQuotes.length; i++){
          let quoteJ = JSON.parse(movedQuotes[i]);
          quoteJ.attributes.headwordId = headword.id
          updatedQuotes.push(quoteJ);
        };
        await Promise.all(updatedQuotes.map(async (quote) => {
          return await citationService.updateCitation(quote);
        }));
        localStorage.removeItem('MovedQuotations');
        localStorage.removeItem('DeletedHeadword');
        window.alert('Undo successful');
        window.location.reload();
      } catch (e) {
        window.alert('Undo failed, please try again');
      }
    }
  }

  toggleExactSearchK(){
    const updatedExactSearch = !this.state.exactSearchK;
    this.setState({ exactSearchK: updatedExactSearch });
  }

  toggleExactSearchD(){
    const updatedExactSearch = !this.state.exactSearchD;
    this.setState({ exactSearchD: updatedExactSearch });
  }

  render() {
    const { classes } = this.props;
    const { selectedLetter, progressMessage, progressLabel, isLoading, sourceType, exactSearchK, exactSearchD } = this.state;
    if(isLoading){
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress size={110} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
          </Box>
            <Typography variant="h5" component="div" color="textSecondary">{
              progressLabel
            }</Typography>
        </div>
      );
    }

    return (
      <DashboardLayout title="Merge">
        <div className={classes.root}>
        <Grid item lg={3} md={5} xl={2} sm={5} xs={12}>
          <Button onClick={event => this.reset()} fullWidth='true' color="primary" size="small" variant="outlined">Reset</Button>
          <br></br>
          <br></br>
        </Grid>
        {(this.state.keptChosen && this.state.deleteChosen) && (    
        <Grid item lg={3} md={5} xl={2} sm={5} xs={12}>
          <Button onClick={event => this.merge()} fullWidth='true' color="primary" size="small" variant="outlined">Merge</Button>
          <br></br>
          <Typography variant="caption">{progressMessage}</Typography>
          <br></br>
        </Grid>
        )}
        {(localStorage.getItem('DeletedHeadword') && (sourceType == "Keywords")) && (    
        <Grid item lg={3} md={5} xl={2} sm={5} xs={12}>
          <Button onClick={event => this.undo()} fullWidth='true' color="primary" size="small" variant="outlined">Undo Last Merge</Button>
          <br></br>
          <br></br>
        </Grid>
        )}
        <div className={classes.row}>
        <Grid item lg={2} md={3} xl={1} sm={3} xs={8}>
                  <TextField
                    className={classes.textField}
                    label="Source Type"
                    margin="dense"
                    select
                    fullWidth='true'
                    SelectProps={{ native: true }}
                    value={this.state.sourceType}
                    onChange={(event) => this.onChange(event.target.value)}
                    variant="outlined">
                    {this.state.sourceOptions.map(option => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}  
                  </TextField>
        </Grid>
        <Grid item lg={1} md={2} xl={1} sm={2} xs={4}>
                  <TextField
                    className={classes.textField}
                    label="Min Char Search"
                    margin="dense"
                    select
                    fullWidth='true'
                    SelectProps={{ native: true }}
                    value={this.state.minChars}
                    onChange={(event) => this.onCharChange(event.target.value)}
                    variant="outlined">
                    {this.state.minCharOptions.map(option => (
                      <option
                        key={option.value}
                        value={option.value}
                      >
                        {option.label}
                      </option>
                    ))}  
                  </TextField>
        </Grid>
        </div>
        <br></br>
        <Grid id="top-row" container spacing={24}>
          <Grid item lg={6} md={12} xl={6} sm={12} xs={12}>
            <Typography variant="h4">
            Entry To Keep:
            </Typography>
            <div  className={classes.row}>
              <div style={{width: '80%'}} >  
                <SearchInput className={classes.searchInput} value={this.state.searchStringK} placeholder="Search" onChange={event => this.onSearchChangeK(event.target.value)}/>
              </div>
              <div style={{width: '14%', marginLeft:"3%"}} >
                <Button onClick={event => this.onSearchChange((this.state.searchStringK + 'ȝ'))} fullWidth='true' color="primary" size="small" variant="outlined">Add ȝ </Button>
              </div>
              {((sourceType == "Keywords")) && (
              <div style={{marginLeft:"20px", minWidth: "150px"}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={exactSearchK}
                      onChange={(event) => this.toggleExactSearchK()}
                      name="exactSearchK"
                      color="primary"
                    />
                  }
                  label="Exact Search"
                />     
            </div> 
            )}
            </div>
            <div style={{width: '97%'}}>
              {this.renderAllSources()}
              {(this.state.keptChosen) && (  
                <Grid item lg={12} md={12} xl={12} sm={12} xs={12}>
                    {this.renderSourcePreview()}
                </Grid>
              )}
            </div>
          </Grid>
          <Grid item lg={6} md={12} xl={6} sm={12} xs={12}>
            <Typography variant="h4">
            Entry To Delete:
            </Typography>
            <div className={classes.row}>
            <div style={{width: '80%'}} >  
            <SearchInput className={classes.searchInput} value={this.state.searchStringD} placeholder="Search" onChange={event => this.onSearchChangeD(event.target.value)}/>
            </div>
            <div style={{width: '14%', marginLeft:"3%"}} >
            <Button onClick={event => this.onSearchChange((this.state.searchStringD + 'ȝ'))} fullWidth='true' color="primary" size="small" variant="outlined">Add ȝ </Button>
            </div>
            <div style={{marginLeft:"20px", minWidth: "150px"}}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={exactSearchD}
                      onChange={(event) => this.toggleExactSearchD()}
                      name="exactSearchD"
                      color="primary"
                    />
                  }
                  label="Exact Search"
                />     
            </div> 
            </div>
            <div style={{width: '97%'}}>
            {this.renderAllDeletedSources()}
            {(this.state.deleteChosen) && (  
            <Grid item lg={12} md={12} xl={12} sm={12} xs={12}>
                {this.renderSourcePreviewDelete()}
            </Grid>
            )}
            </div>
          </Grid>
          </Grid>
        </div>
      </DashboardLayout>
    );
  }
}

Merge.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles)
)(Merge);

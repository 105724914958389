import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Paper } from 'components';

import styles from './styles';
import citations from 'services/citations';
import { BorderAll } from '@material-ui/icons';

class SearchCard extends Component {
  render() {
    const { classes, className, book, headwords} = this.props; //citation, citations, books, periodicals, utterances, websites

    const rootClassName = classNames(classes.root, className);
    let url = '/citations/'+book.id;
    return (
      <Link to={url}>
        <Paper className={rootClassName}>
          <div className={classes.details}>
          <Typography className={classes.title} variant="h6">
            {this.props.headwords}
            </Typography>
            <Typography className={classes.description}>
              {this.newText()}
            </Typography>
          </div>
        </Paper>
      </Link>
    );
  }

  title(){
    let headword = this.props.headwords.find(h => h.id==this.props.book.headwordId);
    return(headword.attributes.headword);
  }
  newText(){
    let title = this.props.book.quotation.replace(/(<([^>]+)>)/ig,"");
    title = title.replace(/\&nbsp;/g, ' ');
    return (
        title
    )
  }
}

SearchCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchCard);

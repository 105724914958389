import _ from 'lodash';
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Grid, TextField, Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

import styles from './styles';

class EditHeadword extends Component {


  state = {
    headword: this.props.headword,
    url: '',
    error: null,
    partOfSpeechOptions: [
      { value: '', label: ''},
      { value: 'Noun', label: 'Noun' },
      { value: 'Adjective', label: 'Adjective' },
      { value: 'Verb', label: 'Verb' },
      { value: 'Adverb', label: 'Adverb' },
      { value: 'Pronoun', label: 'Pronoun' },
      { value: 'Preposition', label: 'Preposition' },
      { value: 'Conjunction', label: 'Conjunction' },
      { value: 'Interjection', label: 'Interjection' },
      { value: 'Prefix', label: 'Prefix' },
      { value: 'Suffix', label: 'Suffix' },
      { value: 'Combining Form', label: 'Combining Form' },
      { value: 'Legacy', label: 'Legacy'},
      { value: 'Volunteer', label: 'Volunteer'}
    ],
    boolOptions: [
      { value: '', label: ''},
      { value: 'False', label: 'False' },
      { value: 'True', label: 'True' },
    ], 
    otherOptions: [
      { value: '', label: ''},
      { value: 'No', label: 'No'},
      { value: 'Yes', label: 'Yes' },
    ]

  };

  componentWillMount() {
    if(this.props.headword.attributes.headword!=undefined){
      this.state.headword.attributes.headword = this.props.headword.attributes.headword
    } else {
      this.state.headword.attributes.headword = '';
    }
    if(!this.props.create){
      this.state.url='/headwords/' + this.props.headword.id;
    } else {
      this.state.url='/headwords/';
    }
    console.log(this.state.headword)
  }

  componentWillUnmount() {
  }

  onChange(path, value) {
    let headword = this.state.headword;
    _.set(headword, path, value);
    this.setState({ headword });
  }

  renderHeadwordForm() {
    const { classes } = this.props;
    const { headword } = this.state;

    return (
      <div>
      <Link to={this.state.url}> 
      <Button
              color="primary"
              variant="contained"
              className={classes.saveButton}
      >Back</Button>
      </Link> 
      <div><br></br></div>
      <Portlet>
        <PortletHeader>
          <PortletLabel title="Details" />
        </PortletHeader>
        <PortletContent noPadding>
          <form autoComplete="off" noValidate>
            <div className={classes.field}>
              <div className={classes.row} style={{display:"flex"}} >
              <TextField
                className={classes.textField}
                label="Keyword"
                margin="dense"
                required
                value={headword.attributes.headword || ''}
                variant="outlined"
                onChange={(event) => this.onChange('attributes.headword', event.target.value || null)}
              />
              <div style={{width: '10%', marginRight:"5px", marginTop:"12px"}} >
              <Button onClick={event => this.onChange('attributes.headword', (headword.attributes.headword + 'ȝ'))} fullWidth='true' color="primary" size="small" variant="outlined">Add ȝ </Button>
             </div>
             </div>
              <TextField
                className={classes.textField}
                label="Part of Speech"
                margin="dense"
                required
                select
                SelectProps={{ native: true }}
                value={headword.attributes.partOfSpeech || ''}
                onChange={(event) => this.onChange('attributes.partOfSpeech', event.target.value)}
                variant="outlined">
                {this.state.partOfSpeechOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
              <TextField
                className={classes.textField}
                label="Keyword In DSL"
                margin="dense"
                select
                SelectProps={{ native: true }}
                value={headword.attributes.kwInDsl || ''}
                onChange={(event) => this.onChange('attributes.kwInDsl', event.target.value)}
                variant="outlined">
                {this.state.otherOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
              <TextField
                className={classes.textField}
                label="Legacy Notes"
                margin="dense"
                value={headword.attributes.legacyNotes || ''}
                multiline="true"
                variant="outlined"
                onChange={(event) => this.onChange('attributes.legacyNotes', event.target.value || null)}
              />
              {(/*<TextField
                className={classes.textField}
                label="DSL Bibliographical Reference"
                margin="dense"
                required
                select
                SelectProps={{ native: true }}
                value={headword.attributes.dslBibRef || ''}
                onChange={(event) => this.onChange('attributes.dslBibRef', event.target.value)}
                variant="outlined">
                {this.state.boolOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>*/<div></div>)}
            </div>
          </form>
        </PortletContent>
      </Portlet>
      </div>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid container spacing={4}>
          <Grid item lg={7} md={7} xl={7} xs={12}>
            {this.renderHeadwordForm()}
          </Grid>
        </Grid>
        <div className={classes.row}>
          <Button
            color="primary"
            variant="contained"
            className={classes.saveButton}
            onClick={this.props.onSave}
          >Save</Button>
        </div>
      </div>
    );
  }
}

EditHeadword.propTypes = {
  headword: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(EditHeadword);

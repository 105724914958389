import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, Grid, TextField, Button, InputLabel, Paper, Tab, Tabs, Box } from '@material-ui/core';
import headwordService from 'services/headwords';
import ReactQuill from 'react-quill';
import SourcesCard from './SourcesCard';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  SearchInput,
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import service from 'services/citations';
import { Book, Periodical, Website, Utterance } from './components';
import styles from './styles';
import citations from 'services/citations';

const partOfSpeech = 'Volunteer'
const volunteer = 'Yes'

const otherOptions = [
  { value: '', label: ''},
  { value: 'No', label: 'No' },
  { value: 'Yes', label: 'Yes' },
];

const decisionOptions = [
  { value: '', label: ''},
  { value: 'Rejected', label: 'Rejected' },
  { value: 'Sent On', label: 'Sent On' },
];

class EditCitation extends Component {
  signal = true;

  state = {
    isLoading: false,
    citation: this.props.citation,
    hw: {},
    updating: this.props.updating,
    error: null,
    books: [],
    periodicals: [],
    utterances: [],
    websites: [],
    searchString: '',
    searchStringP: '',
    searchStringU: '',
    searchStringW: '',
    functionOptions: [],
    typeOptions: [],
    degreeOptions: [],
    determinerOptions: [],
    paradigmOptions: [],
    moodOptions: [],
    sourceTypeOptions: [],
    posSelection: '',
    hw: [],
    typeSelection: '',
    progressLabel: "getting citation and headwords",
    noHeadword: false,
    newHeadwordID: null,
    minCharSearch: 3,
    minCharOptions: [
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
    ],
    prevHeadword: null,
    prevHeadwordId: null,
    url: '/vheadwords/' + this.props.citation.attributes.headwordId,
    buttonClick: false,
    previewClick: this.props.updating,
    sources: [{
      attributes: {}
    },{
      attributes: {}
    },{
      attributes: {}
    },{
      attributes: {}
    }]
  };

  async getHeadwords() {
    try {
      this.setState({isLoading: true, progressLabel:"getting sources"})
      this.getCitations();
      this.setState({ isLoading: false });
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  
  async getCitations() {
    try {
      //this.setState({ isLoading: true });

      let fullsources = await service.getVCitations();
      
      var bookList = [];
      var periodicalList = [];
      var utteranceList = [];
      var websiteList = [];

      var i = 0;
      var item;
      for(item in fullsources[0].attributes){
        var list = []
        list.push(fullsources[0].attributes[i].id);
        list.push(fullsources[0].attributes[i].title);
        bookList.push(list);
        i++;    
      }

      i = 0;
      for(item in fullsources[1].attributes){
        var list = []
        list.push(fullsources[1].attributes[i].id);
        list.push(fullsources[1].attributes[i].title);
        periodicalList.push(list);
        i++;    
      }

      i = 0;
      for(item in fullsources[2].attributes){
        var list = []
        list.push(fullsources[2].attributes[i].id);
        list.push(fullsources[2].attributes[i].siteName);
        utteranceList.push(list);
        i++;    
      }

      i = 0;
      for(item in fullsources[3].attributes){
        var list = []
        list.push(fullsources[3].attributes[i].id);
        list.push(fullsources[3].attributes[i].siteName);
        websiteList.push(list);
        i++;    
      }

      if (this.signal) {
        this.setState({
          //isLoading: false,
          books: bookList,
          periodicals: periodicalList,
          utterances: utteranceList,
          websites: websiteList,
          sources: fullsources
        });
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          //isLoading: false,
          error
        });
      }
    }
  }

  componentWillMount() {
    this.signal = true;
    this.getHeadwords();
    if(this.props.citation.attributes.quotation!=undefined){
    this.state.citation.attributes.quotation = this.props.citation.attributes.quotation;
    } else if(this.props.citation.attributes.quotation==undefined){
      this.state.citation.attributes.quotation = '';
    }
    
  }

  componentWillUnmount() {
    this.signal = false;
  }

  onChange(path, value) {
    let citation = this.state.citation;
    _.set(citation, path, value);
    this.setState({ citation });

  }

  onChangeYogh(path, value){
    value=this.state.citation.attributes.quotation.substring(0,this.state.citation.attributes.quotation.length-4)+value
        +this.state.citation.attributes.quotation.substring(this.state.citation.attributes.quotation.length-4,this.state.citation.attributes.quotation.length);
    let citation = this.state.citation;
    _.set(citation, path, value);
    this.setState({ citation });

  }

  updateType(value) {
    let citation = this.state.citation;
    this.setState({typeSelection: value});
    if(citation.relationships.book!=undefined){
      let path = 'relationships.book.attributes.type';
        _.set(citation, path, value);
      this.setState({ citation });
    } else if (citation.relationships.periodical!=undefined) {
      let path = 'relationships.periodical.attributes.type';
        _.set(citation, path, value);
      this.setState({ citation });  
    } else if (citation.relationships.utterance!=undefined) {
      let path = 'relationships.utterance.attributes.type';
      _.set(citation, path, value);
      this.setState({ citation });
    } else if (citation.relationships.website!=undefined) {
      let path = 'relationships.website.attributes.type';
      _.set(citation, path, value);
      this.setState({ citation });
    }

  }

  updateTypeSelection(value){
    this.setState({typeSelection: value});
  }

  sourceDetailedType(){
    let citation = this.state.citation;
    if(citation.relationships.book!=undefined){
      return(citation.relationships.book.attributes.type);
    } else if (citation.relationships.periodical!=undefined) {
      return(citation.relationships.periodical.attributes.type);
    } else if (citation.relationships.utterance!=undefined) {
      return(citation.relationships.utterance.attributes.type);
    } else if (citation.relationships.website!=undefined) {
      return(citation.relationships.website.attributes.type);
    }
  }

  setTypeOptions(value) {
      if(value == "books") {
        this.setState({sourceTypeOptions: [{value: '', label: ''},{value: 'Text (other)', label: 'Text (other)'},
                        {value: 'Text (anthology/collection)', label: 'Text (anthology/collection)'}]});
      } else if(value == "periodicals"){
        this.setState({sourceTypeOptions: [{value: '', label: ''},{value: 'Press article (print)', label: 'Press article (print)'},
                        {value: 'Press article (online)', label: 'Press article (online)'}, {value: 'Journal article (print/online)', label: 'Journal article (print/online)'}]});
      } else if(value == "websites"){
        this.setState({sourceTypeOptions: [{value: '', label: ''},{value: 'Online (website)', label: 'Online (website)'},
                        {value: 'Online (social media)', label: 'Online (social media)'}]});
      } else if(value == "utterances"){
        this.setState({sourceTypeOptions: [{value: '', label: ''},{value: 'Oral (recorded)', label: 'Oral (recorded)'}]});
      }
}

async updateTypeOptions(path, value) {
  let citation = this.state.citation;
  _.set(citation, path, value);
  this.setState({ citation });
  if(value == "books") {
    this.setState({sourceTypeOptions: [{value: '', label: ''},{value: 'Text (other)', label: 'Text (other)'},
                    {value: 'Text (anthology/collection)', label: 'Text (anthology/collection)'}]});
  } else if(value == "periodicals"){
    this.setState({sourceTypeOptions: [{value: '', label: ''},{value: 'Press article (print)', label: 'Press article (print)'},
                    {value: 'Press article (online)', label: 'Press article (online)'}, {value: 'Journal article (print/online)', label: 'Journal article (print/online)'}]});
  } else if(value == "websites"){
    this.setState({sourceTypeOptions: [{value: '', label: ''},{value: 'Online (website)', label: 'Online (website)'},
                    {value: 'Online (social media)', label: 'Online (social media)'}]});
  } else if(value == "utterances"){
    this.setState({sourceTypeOptions: [{value: '', label: ''},{value: 'Oral (recorded)', label: 'Oral (recorded)'}]});
  }
}

  async save() {
    if (!this.state.citation.attributes.headwordId) {
      if (!window.confirm(`Create new keyword "${this.headword}"?`)) {
        alert('Quotation not saved');
        return;
      }
      let headword = await headwordService.createVHeadword({
        type: 'headwords',
        attributes: {
          headword: this.headword
        },
      });
      this.state.citation.attributes.headwordId = headword.id;
    }
    this.props.onSave();
  }

  renderCitationDetails() {
    const { classes } = this.props;
    const { citation, headwordOptions, selectedHeadwordOption } = this.state;

    return (
      <Portlet>
        <PortletHeader>
          <PortletLabel title="Quotation Details" />
        </PortletHeader>
        <PortletContent noPadding>
          <form
            autoComplete="off"
            noValidate
          >
            <div className={classes.field}>
            <InputLabel htmlFor="citation-input">Keyword</InputLabel>
            </div>
              <div className={classes.field}>
                <TextField
                  className={classes.textField}
                  label="Keyword"
                  margin="dense"
                  required
                  value={citation.relationships.headword.attributes.headword || ''}
                  variant="outlined">
                </TextField>
              </div>
              <div className={classes.field}>
                <TextField
                  className={classes.textField}
                  label="Part of Speech"
                  margin="dense"
                  value={citation.attributes.partOfSpeech || partOfSpeech}
                  variant="outlined">
                </TextField>
              </div>
            {(this.state.posSelection != "" && this.state.posSelection != "Interjection" && this.state.posSelection != "Prefix" 
             && this.state.posSelection != "Suffix" && this.state.posSelection != "Combining Form") && (
            <div className={classes.field}>
            <InputLabel htmlFor="citation-input">Quotation</InputLabel>
            </div>
            )}
            <div className={classes.field}>
              <ReactQuill className={classes.editor}
                          value={citation.attributes.quotation || ''}
                          onChange={(value) => this.onChange(`attributes.quotation`, value || null)}
                          ref={ref => this.citationEditor = ref}
                          id="citation-input"/>
            </div>
            <div className={classes.field} style={{width: '10%', marginRight:"5px", marginTop:"12px"}} >
              <Button onClick={event => this.onChangeYogh('attributes.quotation', ('ȝ'))} fullWidth='true' color="primary" size="small" variant="outlined">Add ȝ </Button>
            </div>
            {(this.state.typeSelection=='Text (other)' || this.state.typeSelection=='Text (anthology/collection)' 
            || this.state.typeSelection=='Press article (print)' || this.state.typeSelection=='Journal article (print/online)') && (  
            <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Volume/Chapter/Page Number"
                margin="dense"
                value={citation.attributes.pageNo || ''}
                onChange={(event) => this.onChange('attributes.pageNo', event.target.value)}
                variant="outlined"
              />
            </div>
            )} 
            <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Notes"
                margin="dense"
                multiline="true"
                value={citation.attributes.notes || ''}
                onChange={(event) => this.onChange('attributes.notes', event.target.value)}
                variant="outlined"
              />
            </div>
          </form> 
        </PortletContent>
      </Portlet>
    );
  }

  renderSourceForm() {
    const { citation, typeSelection } = this.state;

    switch(citation.attributes.sourceType) {
      case 'books': return <Book book={citation.relationships.book} citation={citation} type={typeSelection} updateTypeSelection={this.updateTypeSelection.bind(this)}/>;
      case 'periodicals': return <Periodical periodical={citation.relationships.periodical}  citation={citation} type={typeSelection} updateTypeSelection={this.updateTypeSelection.bind(this)}/>;
      case 'websites': return <Website website={citation.relationships.website}  citation={citation} type={typeSelection} updateTypeSelection={this.updateTypeSelection.bind(this)}/>;
      case 'utterances': return <Utterance utterance={citation.relationships.utterance}  citation={citation} type={typeSelection} updateTypeSelection={this.updateTypeSelection.bind(this)}/>;
    }
    
  }

  renderSourceDetails() {
    const { classes } = this.props;
    const { citation } = this.state;

    return (
      <Portlet>
        <PortletHeader>
          <PortletLabel title="Add New Source" />
        </PortletHeader>
        <PortletContent noPadding>
          <Paper square>
            <Tabs
              value={citation.attributes.sourceType}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, value) => this.updateTypeOptions('attributes.sourceType', value)}
            >
              <Tab className={classes.sourceTab} value={null} label="None"/>
              <Tab className={classes.sourceTab} value="books" label="Text" />
              <Tab className={classes.sourceTab} value="periodicals" label="Periodical" />
              <Tab className={classes.sourceTab} value="utterances" label="Spoken" />
              <Tab className={classes.sourceTab} value="websites" label="Website/Other" />
            </Tabs>
          </Paper>
          {this.renderSourceForm()}
        </PortletContent>
      </Portlet>
    );
  }

  onSearchChange(searchString) {
    this.setState({ searchString });
   // console.log(searchString);
  }

  onSearchChangeP(searchStringP) {
    this.setState({ searchStringP });
   // console.log(searchString);
  }

  onSearchChangeU(searchStringU) {
    this.setState({ searchStringU });
   // console.log(searchString);
  }

  onSearchChangeW(searchStringW) {
    this.setState({ searchStringW });
   // console.log(searchString);
  }

  setMinChars(value){
    this.setState({minCharSearch: value})
  }
renderSearchDetails(){
  const { classes, updating } = this.props;
  const { sources, searchString, searchStringP } = this.state;

  return(
    <div>
    {(!this.state.previewClick) && (  
    <div>
    <Portlet>
    <PortletHeader>
      <PortletLabel title="Find Text" />
    </PortletHeader>
    <PortletContent>
      <div className={classes.row} style={{display:"flex"}} >
      <SearchInput className={classes.searchInput} value={this.state.searchString} placeholder="Search" onChange={event => this.onSearchChange(event.target.value)}/>
      <div style={{width:"15%", paddingLeft:"5px"}}>
              <TextField
                className={classes.textField}
                label="Min Char Search"
                margin="dense"
                select
                SelectProps={{ native: true }}
                value={this.state.minCharSearch || ''}
                onChange={(event) => this.setMinChars(event.target.value)}
                variant="outlined">
                {this.state.minCharOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
      <div style={{height:"20%", marginTop:"7px", paddingLeft:"5px"}} >
      <Button onClick={event => this.onSearchChange((this.state.searchString + 'ȝ'))} fullWidth='true' color="primary" size="small" variant="outlined">Add ȝ </Button>
      </div>
      </div>
      <div className={classes.content}>
      <div><br></br></div>  
      {this.renderBook()}
      </div>
    </PortletContent>
    </Portlet>
    <div><br></br></div>
    <Portlet>
    <PortletHeader>
      <PortletLabel title="Find Periodical" />
    </PortletHeader>
    <PortletContent>
      <div className={classes.row} style={{display:"flex"}} >
      <SearchInput className={classes.searchInput} value={this.state.searchStringP} placeholder="Search" onChange={event => this.onSearchChangeP(event.target.value)}/>
      <div style={{width:"15%", paddingLeft:"5px"}}>
              <TextField
                className={classes.textField}
                label="Min Char Search"
                margin="dense"
                select
                SelectProps={{ native: true }}
                value={this.state.minCharSearch || ''}
                onChange={(event) => this.setMinChars(event.target.value)}
                variant="outlined">
                {this.state.minCharOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
      </div>
      <div className={classes.content}>
      <div><br></br></div>  
      {this.renderPeriodicals()}
      </div>
    </PortletContent>
    </Portlet>    
    <div><br></br></div>
    <Portlet>
    <PortletHeader>
      <PortletLabel title="Find Utterance" />
    </PortletHeader>
    <PortletContent>
    <div className={classes.row} style={{display:"flex"}} >
      <SearchInput className={classes.searchInput} value={this.state.searchStringU} placeholder="Search" onChange={event => this.onSearchChangeU(event.target.value)}/>
      <div style={{width:"15%", paddingLeft:"5px"}}>
              <TextField
                className={classes.textField}
                label="Min Char Search"
                margin="dense"
                select
                SelectProps={{ native: true }}
                value={this.state.minCharSearch || ''}
                onChange={(event) => this.setMinChars(event.target.value)}
                variant="outlined">
                {this.state.minCharOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
      </div>
      <div className={classes.content}>
      <div><br></br></div>  
      {this.renderUtterances()}
      </div>
    </PortletContent>
    </Portlet>
    <div><br></br></div>
    <Portlet>
    <PortletHeader>
      <PortletLabel title="Find Website" />
    </PortletHeader>
    <PortletContent>
    <div className={classes.row} style={{display:"flex"}} >
      <SearchInput className={classes.searchInput} value={this.state.searchStringW} placeholder="Search" onChange={event => this.onSearchChangeW(event.target.value)}/>
      <div style={{width:"15%", paddingLeft:"5px"}}>
              <TextField
                className={classes.textField}
                label="Min Char Search"
                margin="dense"
                select
                SelectProps={{ native: true }}
                value={this.state.minCharSearch || ''}
                onChange={(event) => this.setMinChars(event.target.value)}
                variant="outlined">
                {this.state.minCharOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
      </div>
      <div className={classes.content}>
      <div><br></br></div>  
      {this.renderWebsites()}
      </div>
    </PortletContent>
    </Portlet>
    </div>
    )}
    </div>
  );
  }

  renderBook(){
    const { classes }  = this.props;
    const { isLoading, citation, books, periodicals, searchString, minCharSearch } = this.state;
  
    if (isLoading) {
      return (
        <div className={classes.progressWIrapper}>
          <CircularProgress />
        </div>
      );
    }
  
    //console.log(citations);
    let visibleBooks = books.filter(book => book[1]!=null && book[1].toLowerCase().includes(searchString.toLowerCase()));
    let type = "book";
    if (visibleBooks.length === 0 || searchString.length<1 || searchString.length < minCharSearch ) {
      return (
        <Typography variant="h6"> No sources to display</Typography>
      );
    }
    let displayedBooks = visibleBooks.splice(0,100)
    return (
      displayedBooks.map(book => (
        <SourcesCard key={book[0]} citation={citation} type={type} id={book[0]} source={book[1]}
        setSourceType={this.setTypeOptions.bind(this)}  onSearchChange={this.onSearchChange.bind(this)} 
        renderSourcePreview={this.renderSourcePreview.bind(this)} previewClicked={this.previewClicked.bind(this)}/>
      ))
      );
    
        
  }  

  renderPeriodicals(){
    const { classes }  = this.props;
    const { isLoading, citation, periodicals, searchStringP, minCharSearch } = this.state;
  
    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    let visiblePeriodicals = periodicals.filter(periodical => periodical[1]!=null && periodical[1].toLowerCase().includes(searchStringP.toLowerCase()));
    let type = "periodical";
    if (visiblePeriodicals==0 || searchStringP.length<1 || searchStringP.length < minCharSearch) {
      return (
        <Typography variant="h6"> No sources to display</Typography>
      );
    }
    let displayedPeriodicals = visiblePeriodicals.splice(0,100)
    return (
      displayedPeriodicals.map(periodical => (
        <SourcesCard key={periodical[0]} citation={citation} type={type} id={periodical[0]} source={periodical[1]} 
        setSourceType={this.setTypeOptions.bind(this)}  onSearchChange={this.onSearchChangeP.bind(this)} 
        renderSourcePreview={this.renderSourcePreview.bind(this)} previewClicked={this.previewClicked.bind(this)}/>
      ))
      );
    
  
  }  

  renderUtterances(){
    const { classes }  = this.props;
    const { isLoading, citation, utterances, searchStringU, minCharSearch } = this.state;
  
    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    let visibleUtterances = utterances.filter(utterance => utterance[1]!=null && utterance[1].toLowerCase().includes(searchStringU.toLowerCase()));
    let type = "utterance";
    if (visibleUtterances==0 || searchStringU.length<1 || searchStringU.length < minCharSearch) {
      return (
        <Typography variant="h6"> No sources to display</Typography>
      );
    }
    let displayedUtterances = visibleUtterances.splice(0,100)
    return (
      displayedUtterances.map(utterance => (
        <SourcesCard key={utterance[0]}  citation={citation} type={type} id={utterance[0]} source={utterance[1]}
        setSourceType={this.setTypeOptions.bind(this)}  onSearchChange={this.onSearchChangeU.bind(this)}
        renderSourcePreview={this.renderSourcePreview.bind(this)} previewClicked={this.previewClicked.bind(this)}/>
      ))
      );
    
  
  }  

  renderWebsites(){
    const { classes }  = this.props;
    const { isLoading, citation, websites, searchStringW, sourceTypeOptions, minCharSearch } = this.state;
  
    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    let visibleWebsites = websites.filter(website => website[1]!=null && website[1].toLowerCase().includes(searchStringW.toLowerCase()));
    let type = "website";
    if (visibleWebsites==0 || searchStringW.length<1 || searchStringW.length < minCharSearch) {
      return (
        <Typography variant="h6"> No sources to display</Typography>
      );
    }
    let displayedWebsites = visibleWebsites.splice(0,100)
    return (
      displayedWebsites.map(website => (
        <SourcesCard  key={website[0]}  citation={citation} type={type} id={website[0]} source={website[1]}
        setSourceType={this.setTypeOptions.bind(this)}  onSearchChange={this.onSearchChangeW.bind(this)}
        renderSourcePreview={this.renderSourcePreview.bind(this)} previewClicked={this.previewClicked.bind(this)}/>
      ))
      );
    
  
  } 

  renderSourceInfo(){
      if(!this.state.buttonClick){
        return(
          <div>
           {this.renderSearchDetails()}
          </div>
        );
      } else {
        return(
          <div>
           {this.renderSourceDetails()}
          </div>
        );
      }
  }

  buttonClicked(){
  this.state.buttonClick = !this.state.buttonClick;
  this.state.citation.attributes.sourceType = null;
  this.state.citation.attributes.bookId = undefined;
  this.state.citation.attributes.periodicalId = undefined;
  this.state.citation.attributes.utteranceId = undefined;
  this.state.citation.attributes.websiteId = undefined;
  this.forceUpdate();
  }

  previewClicked(){
    this.state.previewClick = !this.state.previewClick;
    this.forceUpdate();
    }

  previewClickedButton(){
      this.state.previewClick = !this.state.previewClick;
      if(!this.state.updating){
        this.state.citation.attributes.sourceType = null;
        this.state.citation.attributes.bookId = undefined;
        this.state.citation.attributes.periodicalId = undefined;
        this.state.citation.attributes.utteranceId = undefined;
        this.state.citation.attributes.websiteId = undefined;
      }
      this.forceUpdate();
      }
  

  text(){
    if(!this.state.buttonClick){
      return(
        <div>
         Add New Source
        </div>
      );
    } else {
      return(
        <div>
         Select Existing Source
        </div>
      );
    }
  }

  renderSourcePreview(){
    const {classes, updating} = this.props;
    return(
    <div>
    {(this.state.previewClick) && (  
    <Portlet>
    <PortletHeader>
      <PortletLabel title="Source Preview" />
    </PortletHeader>
    <PortletContent>
      <div className={classes.content}> 
      {this.renderIndividualSource()}
      </div>
    </PortletContent>
    </Portlet>
    )}
    </div>);
  }

  renderIndividualSource(){
    const {classes} = this.props;
    const {sources} = this.state;
    var book = {}; 
    var periodical = {};
    var utterance = {};
    var website = {};
    if(this.state.citation.attributes.sourceType=="books"){
      if(this.state.citation.relationships.book!=null){
        book = this.state.citation.relationships.book.attributes;
      }
      /*var i;
      if(sources != undefined){
        for(i=0;i<Object.values(sources[0].attributes).length;i++){
            let books = Object.values(sources[0].attributes);
            if(books[i].id==this.state.citation.attributes.bookId){
              book = books[i];
            }
        }
      } */
      this.state.typeSelection = book.type;
      if(book.type=='Text (other)'){
        return(
          <Typography className={classes.description} variant="body1">
          Type: {book.type} <br></br>  
          Title:  {book.title}<br></br>
          DSL Ref ID:  {book.dslBibliographyRef}<br></br>
          Author: {book.author}<br></br>
          ISBN: {book.isbn} <br></br>
          Edition: {book.edition} <br></br>
          Publisher: {book.publisher}<br></br>
          Published: {book.yearPublished} <br></br>
          Place: {book.placePublished} <br></br>
          Notes: {book.notes}
        </Typography>
        );
      } else {
        return(
          <Typography className={classes.description} variant="body1">
          Type: {book.type} <br></br>  
          Title:  {book.title}<br></br>
          DSL Ref ID:  {book.dslBibliographyRef}<br></br>
          Author: {book.author}<br></br>
          Editor: {book.editor}<br></br>
          ISBN: {book.isbn} <br></br>
          Edition: {book.edition} <br></br>
          Publisher: {book.publisher}<br></br>
          Published: {book.yearPublished} <br></br>
          Place: {book.placePublished} <br></br>
          Notes: {book.notes}
        </Typography>
        );
      }
    } else if(this.state.citation.attributes.sourceType=="periodicals"){
      if(this.state.citation.relationships.periodical!=null){
        periodical = this.state.citation.relationships.periodical.attributes;
      }
        /*var i;
        if(sources != undefined){
          for(i=0;i<Object.values(sources[1].attributes).length;i++){
              let periodicals = Object.values(sources[1].attributes);
              if(periodicals[i].id==this.state.citation.attributes.periodicalId){
                periodical = periodicals[i];
              }
          }
        } */
        this.state.typeSelection = periodical.type;
      return(
        <Typography className={classes.description} variant="body1">
        Type: {periodical.type} <br></br>  
        Title: {periodical.title}<br></br>
        DSL Ref ID:  {periodical.dslBibliographyRef}<br></br>
        Notes:  {periodical.notes}
      </Typography>
      );
    } else if(this.state.citation.attributes.sourceType=="utterances"){
      if(this.state.citation.relationships.utterance!=null){
        utterance = this.state.citation.relationships.utterance.attributes;
      }     
      /*var i;
        if(sources != undefined){
          for(i=0;i<Object.values(sources[2].attributes).length;i++){
              let utterances = Object.values(sources[2].attributes);
              if(utterances[i].id==this.state.citation.attributes.utteranceId){
                utterance = utterances[i];
              }
          }
        } */
        this.state.typeSelection = utterance.type;
      return(
        <Typography className={classes.description} variant="body1">
        Type: {utterance.type} <br></br>    
        Site Name: {utterance.siteName}<br></br>
        DSL Ref ID:  {utterance.dslBibliographyRef}<br></br>
      </Typography>
      );
    } else if(this.state.citation.attributes.sourceType=="websites"){
      if(this.state.citation.relationships.website!=null){
        website = this.state.citation.relationships.website.attributes;
      } 
      /*var i;
      if(sources != undefined){
        for(i=0;i<Object.values(sources[3].attributes).length;i++){
            let websites = Object.values(sources[3].attributes);
            if(websites[i].id==this.state.citation.attributes.websiteId){
              website = websites[i];
            }
        }
      } */
      this.state.typeSelection = website.type;
      return(
        <Typography className={classes.description} variant="body1">
        Type: {website.type} <br></br>   
        Site Name: {website.siteName}<br></br>
        DSL Ref ID:  {website.dslBibliographyRef}<br></br>
      </Typography>
      );
    }
  }

url(){
  if(this.state.citation.attributes.sourceType == 'books') {
    return(`/book/` + this.state.citation.attributes.bookId);
  } else if(this.state.citation.attributes.sourceType == 'periodicals') {
    return(`/periodical/` + this.state.citation.attributes.periodicalId);
  } else if(this.state.citation.attributes.sourceType == 'utterances') {
    return(`/utterance/` + this.state.citation.attributes.utteranceId);
  } else if(this.state.citation.attributes.sourceType == 'websites') {
    return(`/website/` + this.state.citation.attributes.websiteId);
  }
}

warnUser(e){
  if(this.props.citation.relationships.headword==null){
      if(!window.confirm("Are you sure you want to leave? Any unsaved data will be lost.")){
        e.preventDefault();
      }
  }
}

render() {
    const { classes } = this.props;
    const { isLoading, progressLabel } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress size={110} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
          </Box>
            <Typography variant="h5" component="div" color="textSecondary">{
              progressLabel
            }</Typography>
        </div>
      );
    }

    return (
      <div className={classes.root}>
      <Link  onClick={event => this.warnUser(event)} to={this.state.url}> 
      <Button
              color="primary"
              variant="contained"
              className={classes.saveButton}
      >Back</Button>
      </Link> 
      <div><br></br></div>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            {this.renderSourceInfo()}
            {this.renderSourcePreview()}
            <div><br></br></div> 
            {(this.state.previewClick) && (  
            <Button
                style={{marginRight: '5px'}}
                color="primary"
                variant="contained"
                className={classes.saveButton}
                onClick={event => this.previewClickedButton()}
            >Search Again</Button> )}
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            {this.renderCitationDetails()}
            <br></br>
            {(!this.state.noHeadword) && ( 
            <Button
            color="primary"
            variant="contained"
            className={classes.saveButton}
            onMouseDown={this.save.bind(this)}
          >Save Quotation</Button>
          )}
          {(this.state.noHeadword) && ( 
            <Button
            color="primary"
            variant="contained"
            className={classes.saveButton}
          >Please Select A Headword</Button>
          )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

EditCitation.propTypes = {
  citation: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EditCitation);

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Paper } from 'components';

import styles from './styles';
import citations from 'services/citations';

class CitationCard extends Component {
  render() {
    const { classes, className, citation } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Link to={`/citations/${citation.id}`}>
        <Paper className={rootClassName}>
          <div className={classes.details}>
            <Typography className={classes.title} variant="h4">
              {citation.attributes.shortMeaning}
            </Typography>
            <Typography className={classes.description} variant="body1">
              {this.text(citation)}
            </Typography>
          </div>
        </Paper>
      </Link>
    );
  }

  text(citation){
    if(citation.attributes.clippedText!=null && citation.attributes.clippedText!=""){
      //console.log("Clipped Text Before", citation.attributes.clippedText)
      citation.attributes.clippedText = citation.attributes.clippedText.replace(/(<([^>]+)>)/ig,"");
      citation.attributes.clippedText = citation.attributes.clippedText.replace(/\&nbsp;/g, ' ');
      //console.log("Clipped Text After", citation.attributes.clippedText)
      return(
        citation.attributes.clippedText    
      );
    } else {
      //console.log("Quotation Before", citation.attributes.quotation)
      citation.attributes.quotation = citation.attributes.quotation.replace(/(<([^>]+)>)/ig,"");
      citation.attributes.quotation = citation.attributes.quotation.replace(/\&nbsp;/g, ' ');
      //console.log("Quotation After", citation.attributes.quotation)
      return(
       citation.attributes.quotation
      );
    }
  }
}

CitationCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  citation: PropTypes.object.isRequired
};

export default withStyles(styles)(CitationCard);

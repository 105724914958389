import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import deletion from 'services/deletion';
// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles, Button } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

class WebsiteCard extends Component {
  render() {
    const { classes, className, website, citations } = this.props;

    const rootClassName = classNames(classes.root, className);

   // let citationsCount = website.relationships.citations.count;
   let numCitations1 = citations.find(c => c.websiteId === website.id);
   let numCitations = numCitations1 ? numCitations1.count : 0
    return (
     
        <Paper className={rootClassName}>
           <Link to={`/website/${website.id}`}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="body1"
            >
              {this.title()}
            </Typography>
            <Typography
              className={classes.description}
              variant="body1"
            >
            {numCitations + " Quotations"}
            </Typography>
          </div>
          </Link>
          {(!this.props.merge) && (
          <Button style={{marginTop:'13px'}} onClick={event => this.delete()} color="primary" size="small" variant="outlined">Delete</Button>
          )}
        </Paper>

    );
  }

  delete(){
    const { classes, className, website, citations } = this.props;

    if(window.confirm("Are you sure you want to permanantly delete this entry?")){
        let test = deletion.deleteWebsite(website);
        test.then(( value ) => 
        { 
          window.alert("Entry Deleted");
          window.location.reload();
        }
        ).catch ((error) => 
        {
          window.alert("Please delete all quotations for this website first");
        });
    }
  }
  quotes(){
    let visibleHeadwords = Object.values(this.props.citations.attributes).filter(citation => citation.websiteId==this.props.website.id);
    return (visibleHeadwords.length + " Quotations");
  }
  filter(text){
    const {searchString} = this.props;
    var title="";
    let match = true;
    if(searchString.length==1){
      title = "";
      for (var i = 0; i<text.length; i++){
        if(searchString.toLowerCase().includes(text.charAt(i).toLowerCase())){
          title = title + "<mark>"+text.charAt(i)+"</mark>";
        } else {
          title = title + text.charAt(i);
        }
      }
    } else if(searchString.length>1){
      title = "";
      for (var i = 0; i<text.length; i++){
        if(searchString.charAt(0).toLowerCase()==(text.charAt(i).toLowerCase())){
          for(var j = 1; j<searchString.length; j++){
            if(i+j<text.length && searchString.charAt(j).toLowerCase()==(text.charAt(i+j).toLowerCase())){
              match = true;
            } else {
              match = false;
              title = title + text.substring(i, i+j+1);
              i = i+j;
              break;
            }
          }
          if(match){
            title = title + "<mark>"+text.substring(i, i+j)+"</mark>" + text.charAt(i+j);
            i = i+j;
          }
        } else {
          title = title + text.charAt(i);
        }
      }
    } else {
      title = text;
    }
      return(title);
  }

  title(){
    const {legacyNotesCheck} = this.props;
    if(!legacyNotesCheck){
      if(this.props.website.title!=undefined){
        let title = this.filter(this.props.website.title);
          return(
            <div>
            <b><div dangerouslySetInnerHTML={{__html:title}}></div></b>
            </div>
          );
      } else if(this.props.website.siteName!=undefined){
        let title = this.filter(this.props.website.siteName);
        return(
          <div>
          <b><div dangerouslySetInnerHTML={{__html:title}}></div></b>
          </div>
        );
      }
    } else {
      if(this.props.website.legacyNotes!=null && this.props.website.legacyNotes!=""){
        console.log(this.props.website.legacyNotes)
        let title = "Legacy Notes: " + "" + this.filter(this.props.website.legacyNotes) + "";
        return(
          <div>
          <div dangerouslySetInnerHTML={{__html:this.props.website.siteName}}></div>
          <b><div dangerouslySetInnerHTML={{__html:title}}></div></b>
        </div>
        );
      }
    }
  }
}

WebsiteCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  website: PropTypes.object.isRequired
};

export default withStyles(styles)(WebsiteCard);

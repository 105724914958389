import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import citations from 'services/citations';

class BookCard extends Component {
  render() {
    const { classes, className, book, citations } = this.props;

    const rootClassName = classNames(classes.root, className);

   // let citationsCount = headword.relationships.citations.count;
   let numCitations1 = citations.find(c => c.bookId === book.id);
   let numCitations = numCitations1 ? numCitations1.count : 0
    return (
      <Link to={`/book/${book.id}`}>
        <Paper className={rootClassName}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="h4"
            >
              {this.title()}
            </Typography>
            <Typography
              className={classes.description}
              variant="body1"
            >
              {numCitations + " Quotations"}
            </Typography>
          </div>
        </Paper>
      </Link>
    );
  }
  
  //quotes(){
  //  let visibleHeadwords = Object.values(this.props.citations.attributes).filter(citation => citation.bookId==this.props.book.id);
  //  return (visibleHeadwords.length + " Quotations");
 // }

  title(){
      return(
        this.props.book.title
      );
  }
}

BookCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  book: PropTypes.object.isRequired
};

export default withStyles(styles)(BookCard);

import api from 'services/api';

const getKeywordResearch = async (keywordResearchId) => {
  return await api.get(`research/${keywordResearchId}`);
};

const createKeywordResearch = async (keywordResearch) => {
  return await api.post(`research`, keywordResearch);
};


export default { getKeywordResearch, createKeywordResearch };

import api from 'services/api';

const getHeadwords = async (limit) => {
  return await api.get(`headwords`);
};

const getVHeadwords = async (limit) => {
  return await api.get(`headwords/v`);
};

/*const getHeadwordInRange = async (min, max) => {
  return await api.get(`headwords/${min}/${max}`);
};*/

const getHeadword = async (headwordId) => {
  return await api.get(`headwords/${headwordId}`);
};

const getVHeadword = async (headwordId) => {
  return await api.get(`headwords/v/${headwordId}`);
};

const createHeadword = async (headword) => {
  return await api.post(`headwords`, headword);
};

const createVHeadword = async (headword) => {
  return await api.post(`headwords/v/`, headword);
};

const updateHeadword = async (headword) => {
  return await api.put(`headwords/${headword.id}`, headword);
};

const updateVHeadword = async (headword) => {
  return await api.put(`headwords/v/${headword.id}`, headword);
};

export default { getHeadwords, getVHeadwords, getHeadword, createHeadword, updateHeadword, getVHeadword, createVHeadword, updateVHeadword};

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

class UtteranceCard extends Component {
  render() {
    const { classes, className, utterance, citations } = this.props;

    const rootClassName = classNames(classes.root, className);

   // let citationsCount = utterance.relationships.citations.count;
   let numCitations1 = citations.find(c => c.utteranceId === utterance.id);
   let numCitations = numCitations1 ? numCitations1.count : 0
    return (
      <Link to={`/utterance/${utterance.id}`}>
        <Paper className={rootClassName}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="h4"
            >
              {this.title()}
            </Typography>
            <Typography
              className={classes.description}
              variant="body1"
            >
              {numCitations + " Quotations"}
            </Typography>
          </div>
        </Paper>
      </Link>
    );
  }
 /* quotes(){
    let visibleHeadwords = Object.values(this.props.citations.attributes).filter(citation => citation.utteranceId==this.props.utterance.id);
    return (visibleHeadwords.length + " Quotations");
  } */
  title(){
    if(this.props.utterance.title!=undefined){
      return(
        this.props.utterance.title
      );
    } else if(this.props.utterance.siteName!=undefined){
      return(
        this.props.utterance.siteName
      );
    }
  }
}

UtteranceCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  utterance: PropTypes.object.isRequired
};

export default withStyles(styles)(UtteranceCard);

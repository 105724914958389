import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { TextField, Button } from '@material-ui/core';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

let styles = theme => ({
  field: {
    margin: theme.spacing(3)
  },
  textField: {
    width: '420px',
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
});

class Periodical extends Component {

  constructor(props) {
    super(props);
    this.state = {
      periodical: props.periodical,
      error: null,
      typeOptions: [
        { value: '', label: ''},
        { value: 'Press article (print)', label: 'Press article (print)' },
        { value: 'Press article (online)', label: 'Press article (online)' },
        { value: 'Journal article (print/online)', label: 'Journal article (print/online)' },
        ]
    };
  }

  onChange(path, value) {
    let periodical = this.state.periodical;
    _.set(periodical, path, this.fromViewValue(value));
    this.setState({ periodical });
  }

  fromViewValue(value) {
    return value || null;
  }

  componentWillMount(){
    this.state.periodical.attributes.title = this.props.periodical.attributes.title;
  }
  render() {
    const { classes, periodical } = this.props;
    return (
      <form autoComplete="off" noValidate>
        <div className={classes.field}>
        <TextField
            className={classes.textField}
            label="Type"
            margin="dense"
            required
            select
            SelectProps={{ native: true }}
            value={periodical.attributes.type || ''}
            onChange={(event) => this.onChange('attributes.type', event.target.value)}
            variant="outlined">
            {this.state.typeOptions.map(option => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
          <TextField
            className={classes.textField}
            label="Title"
            margin="dense"
            required
            value={periodical.attributes.title || ''}
            variant="outlined"
            multiline="true"
            onChange={(event) => this.onChange('attributes.title', event.target.value)}
          />
          <TextField
            className={classes.textField}
            label="DSL Bibliographical Reference ID"
            margin="dense"
            required
            value={periodical.attributes.dslBibliographyRef || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.dslBibliographyRef', event.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Notes"
            margin="dense"
            required
            value={periodical.attributes.notes || ''}
            variant="outlined"
            multiline="true"
            onChange={(event) => this.onChange('attributes.notes', event.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Legacy Notes"
            margin="dense"
            value={periodical.attributes.legacyNotes || ''}
            variant="outlined"
            multiline="true"
            onChange={(event) => this.onChange('attributes.legacyNotes', event.target.value)}
          />
        </div>
      </form>
    );
  }
}

Periodical.propTypes = {
  classes: PropTypes.object.isRequired,
  periodical: PropTypes.object
};

Periodical.defaultProps = {
  periodical: {
    attributes: {},
    relationships: {}
  }
};

export default withStyles(styles)(Periodical);

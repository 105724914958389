import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Grid, CircularProgress } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { HeadwordProfile, HeadwordDetails } from './components';
import { state as appState } from 'App';
import service from 'services/headwords';
import citationService from 'services/citations';
import ac from 'services/allCitations';
import sources from 'services/sources';
import _ from 'lodash';
import { Button, InputLabel, Paper, Tab, Tabs, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import SearchCard from './components/SearchCard';
import { Link, useHistory } from 'react-router-dom';
import {
  SearchInput,
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import { Book } from './components/Sources';
import keywordResearch from 'services/keyword-research';
import KeywordResearch from '..';
import { isDate } from 'validate.js';
import headwords from 'services/headwords';

const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  progressWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center'
  },
});

class Headword extends Component {
  state = {
    tabIndex: 0,
    volunteerCheck: false,
    isLoading: false,
    user: appState.get('user'),
    sources: [],
    allSources: [],
    source: this.props.match.params.bookId,
    books: [],
    periodicals: [],
    utterances: [],
    websites: [],
    searchString:'',
    headwords:[],
    krs: '',
    kr: {
      attributes: {},
    },
    sourceType: "book",
    book: {
      attributes: {},
    },
    citations: {},
    citationsArray: [],
    progressLabel: "getting quotations",
    small: true
  };

  async getData() {
    try {
      this.setState({ isLoading: true});
      this.state.citations = await ac.getBooksCitations(this.props.match.params.bookId);
      this.setState({progressLabel: "getting text" }); 
      this.state.book = await sources.getBook(this.props.match.params.bookId);
      this.setState({progressLabel: "getting headwords" }); 

      this.state.citationsArray = Object.values(this.state.citations.attributes);
      let ids = []
      if(this.state.citationsArray.length>9){
        this.state.headwords = await service.getHeadwords();
        
        this.state.small = false;
      } else {
        this.state.small = true;
        for(var i=0; i<this.state.citationsArray.length; i++){
          ids.push(this.state.citations.attributes[i].headwordId);
        }
        for(var i=0; i<ids.length; i++){
            //console.log("IDs, ", ids[i])
            let headword = await ac.getCitationsHeadwords(ids[i]);
            //console.log("Headwords, ", headword.attributes.headwordId)
            this.state.headwords.push(headword)
        } 
      }
     // this.state.headwords = await service.getHeadwords();

      if (this.signal) {
        this.setState({
          isLoading: false,
          sourceType: "book",
        });
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  componentWillMount() {
    this.signal = true;
    this.getData();
    this._unwatch = appState.watch('user', user => {
      this.setState({ user });
    });
  }

  componentWillUnmount() {
    this._unwatch();
    this.signal = false;
  }

  canEdit() {
    return this.state.user && this.state.user.attributes.roles.includes('editor');
  }

  isViewOnly() {
    return this.state.user && this.state.user.attributes.roles.includes('view-only');
  }

  renderSourceForm() {
    switch(this.state.sourceType) {
      case 'book': return <Book book={this.state.book}/>;
    }
  }

  onChange(value) {
    this.state.sourceType = value;
  }

  async save(){
    try {
      await sources.updateBook(this.state.book); 
      window.alert("Book Updated");
      window.location.reload();
    } catch(err) {
      alert(`Error: ${err} Please Enter a Title`);
    }
  }
  
  onSearchChange(searchString) {
    this.setState({ searchString });
  }

  renderQuotations() {
    const { classes, selectedUsers  } = this.props;
    const { isLoading, krs, searchString, progressLabel, volunteerCheck } = this.state;

    
    let visibleQuotations = Object.values(this.state.citations.attributes).filter(citation => citation.bookId!=null && citation.bookId==this.props.match.params.bookId);
    visibleQuotations = visibleQuotations.filter(citation => citation.quotation.toLowerCase().includes(searchString.toLowerCase()));
    if(volunteerCheck){
      visibleQuotations = visibleQuotations.filter(citation => citation.volunteer=="Yes");
    }
    let found = false;
    for(var i = 0; i<visibleQuotations.length; i++){
      //console.log("Source Headword", visibleQuotations[i].headwordId)
      found = false;
      if(this.state.small){
        for(var j=0;j<this.state.headwords.length;j++){
          //console.log("Searched Headword", this.state.headwords[j].attributes[0].id)
          if(this.state.headwords[j].attributes[0].id == visibleQuotations[i].headwordId){
            visibleQuotations[i] = [visibleQuotations[i], this.state.headwords[j].attributes[0].headword];
            found = true;
            break;
          }
        }
        if(!found){
          visibleQuotations[i] = [visibleQuotations[i], ""]
        }
      } else {
        let headword = this.state.headwords.find(h => h.id==visibleQuotations[i].headwordId);
        visibleQuotations[i] = [visibleQuotations[i], headword.attributes.headword]
      }
    }

    visibleQuotations.sort((a, b) =>  a[1].localeCompare(b[1])); 

    if (visibleQuotations.length === 0) {
      return (
        <Typography variant="h6">No keywords to display</Typography>
      );
    }

    return (
      visibleQuotations.map(book => (
        <SearchCard key={book.id} book={book[0]} headwords={book[1]} small={this.state.small}/>
      ))
    );  
  }

  isVolunteerQuote(){
    const {volunteerCheck} = this.state;
    this.setState({volunteerCheck: !volunteerCheck});
  }

  render() {
    const { classes } = this.props;
    const { headword, isLoading, book, books, periodicals, utterances, websites, allSources, progressLabel, volunteerCheck } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress size={110} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
          </Box>
            <Typography variant="h5" component="div" color="textSecondary">{
              progressLabel
            }</Typography>
        </div>
      );
    }

    return (
      <DashboardLayout title="Source Editor">
        <div className={classes.root}>
        <div className={classes.root}>
        <div className={classes.row}> 
      <Button
              color="primary"
              variant="contained"
              className={classes.saveButton}
              onClick={() => this.props.history.goBack()}
      >Back</Button>
    </div> 
      <div><br></br></div>
          <Grid container spacing={4}>
            
            <Grid item lg={5} md={5} xl={5} xs={12}>
              <HeadwordProfile source={book} isEditable={this.canEdit()}/>
              <div><br></br></div>
              <Portlet>
              <PortletHeader>
                <PortletLabel title="Edit Source" />
              </PortletHeader>
              <PortletContent noPadding>
                <Paper square>
                  <Tabs
                    value={this.state.sourceType}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(e, value) => this.onChange(value)}
                  >
                    <Tab className={classes.sourceTab} value="book" label="Book"/>
                </Tabs>
              </Paper>
              {this.renderSourceForm()}
              {console.log(this.state.kr)}
              </PortletContent>
              </Portlet>
              <div><br></br></div>
              {(!this.isViewOnly()) && (
              <Button
                color="primary"
                variant="contained"
                className={classes.saveButton}
                onClick={() => {this.save()}}
              >Save</Button>
              )}
            </Grid>
            <Grid item lg={7} md={5} xl={7} xs={12}>
              <Portlet>
                <PortletHeader>
                   <PortletLabel title="Quotations - Type To Search Quotation Text" />
                   {(!this.isViewOnly()) && (
                   <FormControlLabel
                      control={
                      <Checkbox
                          checked={volunteerCheck}
                          onChange={(event) => this.isVolunteerQuote()}
                          name="volunteerCheck"
                          color="primary"
                      />
                      }
              label="Volunteer Quotes"
          /> 
                   )}       
                </PortletHeader>
                <PortletContent>
                  <SearchInput className={classes.searchInput} placeholder="Search" onChange={event => this.onSearchChange(event.target.value)}/>
                  <div className={classes.content}>
                  <div><br></br></div>  
                  {this.renderQuotations()}
                  </div>
              </PortletContent>
             </Portlet>
            </Grid>
          </Grid>
        </div>
        </div>
      </DashboardLayout>
    );
  }
}

Headword.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Headword);

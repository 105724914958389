import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { CircularProgress, Typography } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import service from 'services/citations';
import EditCitation from './EditCitation';
import _ from 'lodash';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

const styles = theme => ({
  title: {
    display: 'inline-block',
    paddingRight: '10px'
  },
  subtitle: {
    display: 'inline-block',
    fontWeight: 400,
    color: theme.palette.text.secondary
  },
  progressWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center'
  },
});

class CreateCitation extends Component {
  signal = true;

  state = {
    isLoading: false,
    citation: null,
    error: null
  };

  async getCitation() {
    try {
      this.setState({ isLoading: true });

      let citation = {
        type: 'citations',
        attributes: {
          headwordId: this.props.match.params.headwordId || null,
          sourceType: null
        },
        relationships: {
          
        }
      };

      if (this.signal) {
        this.setState({
          isLoading: false,
          citation
        });
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  componentWillMount() {
    this.signal = true;
    this.getCitation();
  }

  componentWillUnmount() {
    this.signal = false;
  }

  async save() {
    if(this.state.citation.attributes.quotation==null || this.state.citation.attributes.quotation==undefined || this.state.citation.attributes.quotation==''){
      window.alert("Please enter quotation");
    } else if((this.state.citation.attributes.bookId == undefined && this.state.citation.attributes.utteranceId == undefined && 
      this.state.citation.attributes.periodicalId == undefined && this.state.citation.attributes.websiteId == undefined &&
      _.isEmpty(this.state.citation.relationships)) || this.state.citation.attributes.sourceType==undefined) { 
        window.alert("Please enter source");
    } else if(this.state.citation.attributes.bookId == undefined && this.state.citation.attributes.utteranceId == undefined && 
      this.state.citation.attributes.periodicalId == undefined && this.state.citation.attributes.websiteId == undefined && ( 
      (_.isEmpty(this.state.citation.relationships.book) && this.state.citation.attributes.sourceType == 'books') || 
      (_.isEmpty(this.state.citation.relationships.periodical) && this.state.citation.attributes.sourceType == 'periodicals') ||
      (_.isEmpty(this.state.citation.relationships.utterance) && this.state.citation.attributes.sourceType == 'utterances') ||
      (_.isEmpty(this.state.citation.relationships.website) && this.state.citation.attributes.sourceType == 'websites') ) ){
        window.alert("Please enter current source details");
    } else if((this.state.citation.attributes.sourceType == 'books' && 
    (this.state.citation.relationships.book!=null && _.isEmpty(this.state.citation.relationships.book.attributes.title))) ||
      (this.state.citation.attributes.sourceType == 'periodicals' &&
      (this.state.citation.relationships.periodical!=null && _.isEmpty(this.state.citation.relationships.periodical.attributes.title))) ||
      (this.state.citation.attributes.sourceType == 'utterances' && 
      (this.state.citation.relationships.utterance!=null && _.isEmpty(this.state.citation.relationships.utterance.attributes.siteName))) ||
      (this.state.citation.attributes.sourceType == 'websites' && 
      (this.state.citation.relationships.website!=null && _.isEmpty(this.state.citation.relationships.website.attributes.siteName)))){
      window.alert('Please give a source name')
      //console.log(this.state.citation.relationships.book)

    } else {
      let user = JSON.parse(localStorage.getItem('user'));
      if(user!=null){
        this.state.citation.attributes.createdBy = user.attributes.firstName + " " + user.attributes.lastName;
      }
      let citation = await service.createCitation(this.state.citation);
      this.props.history.push(`/citations/${citation.id}`);
      window.alert("Quotation Saved Successfully");
    }
  }

  renderTitle() {
    const {classes} = this.props;

    return (
      <div>
        <Typography className={classes.title} variant="h4">Quotation</Typography>
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    const { isLoading, citation } = this.state;
    let updating = false;
    if (isLoading) {
      return (
        <DashboardLayout>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </DashboardLayout>
      );
    }

    return (
      <DashboardLayout title={this.renderTitle()}>
        <EditCitation updating={updating} citation={citation} onSave={this.save.bind(this)}></EditCitation>
      </DashboardLayout>
    );
  }
}

CreateCitation.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(CreateCitation);

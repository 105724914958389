import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { TextField } from '@material-ui/core';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

let styles = theme => ({
  field: {
    margin: theme.spacing(3)
  },
  textField: {
    width: '420px',
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
});

class KR extends Component {

  constructor(props) {
    super(props);
    this.state = {
      kr: props.kr,
      error: null
    };
  }

  onChange(path, value) {
    let kr = this.state.kr;
    kr.attributes.headwordId = this.props.headwordId;
    _.set(kr, path, this.fromViewValue(value));
    this.setState({ kr });
  }

  fromViewValue(value) {
    return value || null;
  }

  render() {
    const { classes } = this.props;
    let user = JSON.parse(localStorage.getItem('user'));
    let editor = ""
    if(user!=null){
      editor = user.attributes.firstName + " " + user.attributes.lastName;
      this.state.kr.attributes.editor = editor;
    }
    return (
      <form autoComplete="off" noValidate>
        <div className={classes.field}>
          <TextField
            className={classes.textField}
            label="Editor"
            margin="dense"
            value={this.state.kr.attributes.editor || ''}
            variant="outlined"
          />
          <TextField
            className={classes.textField}
            label="URL"
            margin="dense"
            value={this.state.kr.attributes.url || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.url', event.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Title"
            margin="dense"
            value={this.state.kr.attributes.title || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.title', event.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Date (DD/MM/YYYY)"
            margin="dense"
            value={this.state.kr.attributes.createdDate || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.createdDate', event.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Search Terms"
            margin="dense"
            value={this.state.kr.attributes.searchTerms || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.searchTerms', event.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Findings"
            margin="dense"
            value={this.state.kr.attributes.findings || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.findings', event.target.value)}
          />
        </div>
      </form>
    );
  }
}

KR.propTypes = {
  headwordId: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  kr: PropTypes.object
};

KR.defaultProps = {
  kr: {
    attributes: {},
  }
};

export default withStyles(styles)(KR);

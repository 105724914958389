import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import deletion from 'services/deletion';
// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles, Button } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

class QuotationCard extends Component {
  render() {
    const { classes, className, keyword, citations } = this.props;

    const rootClassName = classNames(classes.root, className);

   // let citationsCount = citation.relationships.citations.count;
   let numCitations1 = citations.find(c => c.headwordId === keyword.id);
   let numCitations = numCitations1 ? numCitations1.count : 0
    return (
        <Paper className={rootClassName}>
          <Link to={`/headwords/${keyword.id}`}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="h1"
            >
              {this.title()}
            </Typography>
            <Typography
              className={classes.description}
              variant="body1"
            >
            {numCitations + " Quotations"}
            </Typography>
          </div>
          </Link>
          {(!this.props.merge) && (
          <Button style={{marginTop:'13px'}} onClick={event => this.delete()} color="primary" size="small" variant="outlined">Delete</Button>
          )}
        </Paper>

    );
  }
  delete(){
    const { classes, className, keyword, citations } = this.props;

    if(window.confirm("Are you sure you want to permanantly delete this entry?")){
        let test = deletion.deleteKeyword(keyword);
        test.then(( value ) => 
        { 
          window.alert("Entry Deleted");
          window.location.reload();
        }
        ).catch ((error) => 
        {
          window.alert("Please delete all quotations for this keyword first");
        });
    }
  }
  filter(text){
    const {searchString} = this.props;
    var title="";
    let match = true;
    if(searchString.length==1){
      title = "";
      for (var i = 0; i<text.length; i++){
        if(searchString.toLowerCase().includes(text.charAt(i).toLowerCase())){
          title = title + "<mark>"+text.charAt(i)+"</mark>";
        } else {
          title = title + text.charAt(i);
        }
      }
    } else if(searchString.length>1){
      title = "";
      for (var i = 0; i<text.length; i++){
        if(searchString.charAt(0).toLowerCase()==(text.charAt(i).toLowerCase())){
          for(var j = 1; j<searchString.length; j++){
            if(i+j<text.length && searchString.charAt(j).toLowerCase()==(text.charAt(i+j).toLowerCase())){
              match = true;
            } else {
              match = false;
              title = title + text.substring(i, i+j+1);
              i = i+j;
              break;
            }
          }
          if(match){
            title = title + "<mark>"+text.substring(i, i+j)+"</mark>" + text.charAt(i+j);
            i = i+j;
          }
        } else {
          title = title + text.charAt(i);
        }
      }
    } else {
      title = text;
    }
      return(title);
  }
  title(){
    const { keyword, POSCheck, legacyNotesCheck, kwNotInDslCheck, kwInDslCheck, createdByCheck, lastModifiedByCheck } = this.props;
    if(POSCheck){
      if(keyword.attributes.headword!=undefined && keyword.attributes.partOfSpeech!=undefined){
        let title = keyword.attributes.headword + " - " + this.filter(keyword.attributes.partOfSpeech);
          return(
            <div>
            <div dangerouslySetInnerHTML={{__html:title}}></div>
            </div>
          );
      }
    } else if(legacyNotesCheck) {
      if(keyword.attributes.headword!=undefined && keyword.attributes.legacyNotes!=undefined && keyword.attributes.legacyNotes!=""){
        let title = keyword.attributes.headword + " - Legacy Notes: " + this.filter(keyword.attributes.legacyNotes);
          return(
            <div>
            <div dangerouslySetInnerHTML={{__html:title}}></div>
            </div>
          );
      }
    } else if(kwInDslCheck) {
      if(keyword.attributes.headword!=undefined && keyword.attributes.partOfSpeech!=undefined){
        let title = this.filter(keyword.attributes.headword) + " - " + keyword.attributes.partOfSpeech;
          return(
            <div>
            <div dangerouslySetInnerHTML={{__html:title}}></div>
            </div>
          );
      } else if(keyword.attributes.headword!=undefined){
        let title = this.filter(keyword.attributes.headword);
        return(
          <div>
          <div dangerouslySetInnerHTML={{__html:title}}></div>
          </div>
        );
      }
    } else if(kwNotInDslCheck) {
      if(keyword.attributes.headword!=undefined && keyword.attributes.partOfSpeech!=undefined){
        let title = this.filter(keyword.attributes.headword) + " - " + keyword.attributes.partOfSpeech;
          return(
            <div>
            <div dangerouslySetInnerHTML={{__html:title}}></div>
            </div>
          );
      } else if(keyword.attributes.headword!=undefined){
        let title = this.filter(keyword.attributes.headword);
        return(
          <div>
          <div dangerouslySetInnerHTML={{__html:title}}></div>
          </div>
        );
      }
    } else if(createdByCheck) {
      if(keyword.attributes.headword!=undefined && keyword.attributes.createdBy!=undefined && keyword.attributes.createdBy!=""){
        let title = keyword.attributes.headword + " - Keyword Created By: " + this.filter(keyword.attributes.createdBy);
          return(
            <div>
            <div dangerouslySetInnerHTML={{__html:title}}></div>
            </div>
          );
      }
    } else if(lastModifiedByCheck) {
      if(keyword.attributes.headword!=undefined && keyword.attributes.lastModifiedBy!=undefined && keyword.attributes.lastModifiedBy!=""){
        let title = keyword.attributes.headword + " - Keyword Last Modified By: " + this.filter(keyword.attributes.lastModifiedBy);
          return(
            <div>
            <div dangerouslySetInnerHTML={{__html:title}}></div>
            </div>
          );
      }
    } else {
      if(keyword.attributes.headword!=undefined && keyword.attributes.partOfSpeech!=undefined){
        let title = this.filter(keyword.attributes.headword) + " - " + keyword.attributes.partOfSpeech;
          return(
            <div>
            <div dangerouslySetInnerHTML={{__html:title}}></div>
            </div>
          );
      } else if(keyword.attributes.headword!=undefined){
        let title = this.filter(keyword.attributes.headword);
        return(
          <div>
          <div dangerouslySetInnerHTML={{__html:title}}></div>
          </div>
        );
      }
    }
  }
  quotes(){
    let visibleHeadwords = Object.values(this.props.citations.attributes).filter(citation => citation.headwordId==this.props.keyword.id);
    return (visibleHeadwords.length + " Quotations");
  }
}

QuotationCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  citation: PropTypes.object.isRequired
};

export default withStyles(styles)(QuotationCard);

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';
import { head } from 'lodash';

class HeadwordCard extends Component {
  render() {
    const { classes, className, headword, krs, hide } = this.props;

    const rootClassName = classNames(classes.root, className);
    
    let krCountText = "Number of KR Entries - " + krs.length
      return (
        <Link to={`/keyword-research/${headword.id}`}>
          <Paper className={rootClassName}>
            <div className={classes.details}>
              <Typography
                className={classes.title}
                variant="h4"
              >
                {this.text(headword)}
              </Typography>
              <Typography>
                {krCountText}
              </Typography>
            </div>
          </Paper>
        </Link>
      );
  }

  text(headword){
    if(headword.attributes.partOfSpeech!=null){
      return(headword.attributes.headword + " - " + headword.attributes.partOfSpeech);
    } else {
      return(headword.attributes.headword);
    }
  }
}

HeadwordCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  headword: PropTypes.object.isRequired
};

export default withStyles(styles)(HeadwordCard);

import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Button, TextField } from '@material-ui/core';
import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

import CitationCard from '../CitationCard';
import styles from './styles';

class Headword extends Component {
  state = {

  };

  handleChange = e => {
    this.setState({
      state: e.target.value
    });
  };

  render() {
    const { classes, className, allSources, headword, books, periodicals, utterances, websites, ...rest } = this.props;
    const { } = this.state;

    //console.log(headword.relationships.citations.length);
    var bookIds = [];
    var periodicalIds = [];
    var utteranceIds = [];
    var websiteIds = []
    var jsonBooks = allSources[0];
    var jsonPeriodicals = allSources[1];
    var jsonUtterances = allSources[2];
    var jsonWebsites = allSources[3];

    var i;
    for(i =0;i<headword.relationships.citations.length; i++){
      if(headword.relationships.citations[i].attributes.bookId!=null){
        bookIds.push(headword.relationships.citations[i].attributes.bookId);
      } else if(headword.relationships.citations[i].attributes.periodicalId!=null) {
        periodicalIds.push(headword.relationships.citations[i].attributes.periodicalId);
      } else if(headword.relationships.citations[i].attributes.utteranceId!=null) {
        utteranceIds.push(headword.relationships.citations[i].attributes.utteranceId);
      } else if(headword.relationships.citations[i].attributes.websiteId!=null) {
        websiteIds.push(headword.relationships.citations[i].attributes.websiteId);
      }
      }

    let uniqueBooksIds = [...new Set(bookIds)];
    let uniquePeriodicalsIds = [...new Set(periodicalIds)];
    let uniqueUtterancesIds = [...new Set(utteranceIds)];
    let uniqueWebsitesIds = [...new Set(websiteIds)];

    var displayedBooks = [];
    if(uniqueBooksIds.length>0){
      for(i=0;i<uniqueBooksIds.length;i++){
        if(jsonBooks!=undefined && uniqueBooksIds[i]!=null){
          var j =0;
          while(jsonBooks.attributes[j]!=undefined){
            if(jsonBooks.attributes[j].id == uniqueBooksIds[i]){
              displayedBooks.push(jsonBooks.attributes[j]);
            }
            j++;
          }
        }
      }
    } 

    var displayedPeriodicals = [];
    if(uniquePeriodicalsIds.length>0){
      for(i=0;i<uniquePeriodicalsIds.length;i++){
        if(jsonPeriodicals!=undefined && uniquePeriodicalsIds[i]!=null){
          var j =0;
          while(jsonPeriodicals.attributes[j]!=undefined){
            if(jsonPeriodicals.attributes[j].id == uniquePeriodicalsIds[i]){
              displayedPeriodicals.push(jsonPeriodicals.attributes[j]);
            }
            j++;
          }
        }
      }
    }

    var displayedUtterances = [];
    if(uniqueUtterancesIds.length>0){
      for(i=0;i<uniqueUtterancesIds.length;i++){
        if(jsonUtterances!=undefined && uniqueUtterancesIds[i]!=null){
          var j =0;
          while(jsonUtterances.attributes[j]!=undefined){
            if(jsonUtterances.attributes[j].id == uniqueUtterancesIds[i]){
              displayedUtterances.push(jsonUtterances.attributes[j]);
            }
            j++;
          }
        }
      }
    }
    
    var displayedWebsites = [];
    if(uniqueWebsitesIds.length>0){
      for(i=0;i<uniqueWebsitesIds.length;i++){
        if(jsonWebsites!=undefined && uniqueWebsitesIds[i]!=null){
          var j =0;
          while(jsonWebsites.attributes[j]!=undefined){
            if(jsonWebsites.attributes[j].id == uniqueWebsitesIds[i]){
              displayedWebsites.push(jsonWebsites.attributes[j]);
            }
            j++;
          }
        }
      }
    }


    return (
      <div>
        {
          //headword.relationships.citations.map(citation => (
          //  <CitationCard key={citation.id} citation={citation} citations={headword.relationships.citations} books={books} periodicals={periodicals} 
          //    utterances={utterances} websites={websites}/>
         // ))
          displayedBooks.map(book => (
            <CitationCard key={book.id} book={book}/>
          ))
        }
        {
          displayedPeriodicals.map(book => (
            <CitationCard  key={book.id} book={book}/>
          ))
        }
               {
          displayedUtterances.map(book => (
            <CitationCard  key={book.id}  book={book}/>
          ))
        }
               {
          displayedWebsites.map(book => (
            <CitationCard  key={book.id} book={book}/>
          ))
        }
      </div>
    );
  }
}

Headword.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  headword: PropTypes.object.isRequired
};

export default withStyles(styles)(Headword);

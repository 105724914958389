import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { TextField, Button } from '@material-ui/core';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

let styles = theme => ({
  field: {
    margin: theme.spacing(3)
  },
  textField: {
    width: '420px',
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
});

class Utterance extends Component {

  constructor(props) {
    super(props);
    this.state = {
      utterance: props.utterance,
      error: null,
      typeOptions: [
        { value: '', label: ''},
        { value: 'Oral (recorded)', label: 'Oral (recorded)' },
      ]
    };
  }

  onChange(path, value) {
    let utterance = this.state.utterance;
    _.set(utterance, path, this.fromViewValue(value));
    this.setState({ utterance });

  }

  fromViewValue(value) {
    return value === '' ? null : value;
  }
  
  componentWillMount(){
    this.state.utterance.attributes.siteName = this.props.utterance.attributes.siteName;
  }

  render() {
    const { classes, utterance } = this.props;
    return (
      <form autoComplete="off" noValidate>
        <div className={classes.field}>
        <TextField
                className={classes.textField}
                label="Type"
                margin="dense"
                required
                select
                SelectProps={{ native: true }}
                value={utterance.attributes.type || ''}
                onChange={(event) => this.onChange('attributes.type', event.target.value)}
                variant="outlined">
                {this.state.typeOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
        </TextField>
        <TextField
            className={classes.textField}
            label="Site Name"
            margin="dense"
            required
            value={utterance.attributes.siteName || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.siteName', event.target.value)}
          />
           <TextField
            className={classes.textField}
            label="DSL Bibliographical Reference ID"
            margin="dense"
            required
            value={utterance.attributes.dslBibliographyRef || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.dslBibliographyRef', event.target.value)}
          />
        </div>

      </form>
    );
  }
}

Utterance.propTypes = {
  classes: PropTypes.object.isRequired,
  utterance: PropTypes.object
};

Utterance.defaultProps = {
  utterance: {
    attributes: {},
    relationships: {}
  }
};

export default withStyles(styles)(Utterance);

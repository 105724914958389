import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Grid, CircularProgress } from '@material-ui/core';
import { Dashboard as DashboardLayout } from 'layouts';
import { HeadwordProfile, HeadwordDetails } from './components';
import { state as appState } from 'App';
import service from 'services/headwords';
import citationService from 'services/citations';
import _ from 'lodash';
import { Button, InputLabel, Paper, Tab, Tabs } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import SearchCard from './components/SearchCard';
import { Link } from 'react-router-dom';
import {
  SearchInput,
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import { KR, Book, Periodical, Website, Utterance } from './components/Sources';
import keywordResearch from 'services/keyword-research';
import KeywordResearch from '..';

const styles = theme => ({
  root: {
    padding: theme.spacing(4)
  },
  progressWrapper: {
    padding: theme.spacing(3),
    display: 'flex',
    justifyContent: 'center'
  },
});

class Headword extends Component {
  state = {
    tabIndex: 0,
    isLoading: false,
    user: appState.get('user'),
    sources: [],
    allSources: [],
    books: [],
    periodicals: [],
    utterances: [],
    websites: [],
    searchString:'',
    krs: '',
    kr: {
      attributes: {
        headwordId: this.props.match.params.headwordId,
        editor: '',
        url: '',
        title: '',
        createdDate: '',
        searchTerms: '',
        findings: ''
      },
    }
  };

  async getData() {
    try {
      this.setState({ isLoading: true });

      const headword = await service.getHeadword(this.props.match.params.headwordId);
      const krs = await keywordResearch.getKeywordResearch()

      this.getCitations();

      if (this.signal) {
        this.setState({
          isLoading: false,
          headword: headword,
          sourceType: "keywordResearch",
          krs: krs
        });
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  async getCitations() {
      let fullsources = await citationService.getCitations();

      var bookList = [];
      var periodicalList = [];
      var utteranceList = [];
      var websiteList = [];
      var sourceList = [];

      var i = 0;
      var item;
      for(item in fullsources[0].attributes){
        var list = []
        list.push(fullsources[0].attributes[i].id);
        list.push(fullsources[0].attributes[i].title);
        bookList.push(list);
        sourceList.push(list);
        i++;    
      }

      i = 0;
      for(item in fullsources[1].attributes){
        var list = []
        list.push(fullsources[1].attributes[i].id);
        list.push(fullsources[1].attributes[i].title);
        periodicalList.push(list);
        sourceList.push(list);
        i++;    
      }

      i = 0;
      for(item in fullsources[2].attributes){
        var list = []
        list.push(fullsources[2].attributes[i].id);
        list.push(fullsources[2].attributes[i].siteName);
        utteranceList.push(list);
        sourceList.push(list);
        i++;    
      }

      i = 0;
      for(item in fullsources[3].attributes){
        var list = []
        list.push(fullsources[3].attributes[i].id);
        list.push(fullsources[3].attributes[i].siteName);
        websiteList.push(list);
        sourceList.push(list);
        i++;    
      }

        this.setState({
          allSources: fullsources,
          sources: sourceList,
          books: bookList,
          periodicals: periodicalList,
          utterances: utteranceList,
          websites: websiteList
        });
  }

  componentWillMount() {
    this.signal = true;
    this.getData();
    this._unwatch = appState.watch('user', user => {
      this.setState({ user });
    });
  }

  componentWillUnmount() {
    this._unwatch();
    this.signal = false;
  }

  canEdit() {
    return this.state.user && this.state.user.attributes.roles.includes('editor');
  }

  renderSourceForm() {
    
    switch(this.state.sourceType) {
      case 'keywordResearch': return <KR kr={this.state.kr} headwordId={this.state.headword.id} />;
    }
    
  }
  onChange(value) {
    //let citation = this.state.citation;
    //_.set(citation, path, value);
    //this.setState({ citation });
    this.state.sourceType = value;

  }

  async save(){
    await keywordResearch.createKeywordResearch(this.state.kr);
    window.alert("Keyword Research Entry Saved");
    window.location.reload();
  }
  
  onSearchChange(searchString) {
    this.setState({ searchString });
   // console.log(searchString);
  }

  renderSources() {
    const { classes, selectedUsers  } = this.props;
    const { isLoading, krs, searchString } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    let visibleHeadwords = []
    visibleHeadwords = Object.values(krs.attributes).filter(kr => kr.editor.toLowerCase().includes(searchString.toLowerCase()));
    visibleHeadwords = visibleHeadwords.filter(kr => kr.headwordId==this.state.headword.id);

    if (visibleHeadwords.length === 0) {
      return (
        <Typography variant="h6">No entries to display</Typography>
      );
    }

    return (
      visibleHeadwords.map(book => (
        <SearchCard key={book.id} book={book}/>
      ))
    );  
  }

  render() {
    const { classes } = this.props;
    const { headword, isLoading, books, periodicals, utterances, websites, allSources } = this.state;

    if (isLoading) {
      return (
        <DashboardLayout>
          <div className={classes.progressWrapper}>
            <CircularProgress />
          </div>
        </DashboardLayout>
      );
    }

    return (
      <DashboardLayout title="Keyword Research Editor">
        
        <div className={classes.root}>
        <Link to={'/keyword-research'}> 
        <Button
                color="primary"
                variant="contained"
                className={classes.saveButton}
        >Back</Button>
        </Link> 
        <div className={classes.root}>
          <Grid container spacing={4}>
            <Grid item lg={5} md={5} xl={5} xs={12}>
              <Link to={"/headwords/" + headword.id}>
              <HeadwordProfile headword={headword} isEditable={this.canEdit()}/>
              </Link>
              <div><br></br></div>
              <Portlet>
              <PortletHeader>
                <PortletLabel title="Add New Entry" />
              </PortletHeader>
              <PortletContent noPadding>
                <Paper square>
                  <Tabs
                    value={this.state.sourceType}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={(e, value) => this.onChange(value)}
                  >
                    <Tab className={classes.sourceTab} value="keywordResearch" label="Keyword Research Entry"/>
                </Tabs>
              </Paper>
              {this.renderSourceForm()}
              </PortletContent>
              </Portlet>
              <div><br></br></div>
              <Button
                color="primary"
                variant="contained"
                className={classes.saveButton}
                onClick={() => {this.save()}}
              >Save</Button>
            </Grid>
            <Grid item lg={7} md={5} xl={7} xs={12}>
              <Portlet>
                <PortletHeader>
                   <PortletLabel title="Keyword Research Entries - Editor Search" />
                </PortletHeader>
                <PortletContent>
                  <SearchInput className={classes.searchInput} placeholder="Search Entries By Editor" onChange={event => this.onSearchChange(event.target.value)}/>
                  <div className={classes.content}>
                  <div><br></br></div>  
                  {this.renderSources()}
                  </div>
              </PortletContent>
             </Portlet>
            </Grid>
          </Grid>
        </div>
        </div>
      </DashboardLayout>
    );
  }
}

Headword.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Headword);

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Paper } from 'components';

import styles from './styles';
import citations from 'services/citations';
import { BorderAll } from '@material-ui/icons';

class SearchCard extends Component {
  render() {
    const { classes, className, book, headwords} = this.props; //citation, citations, books, periodicals, utterances, websites

    let url = '/citations/'+book.id;
    const rootClassName = classNames(classes.root, className);

    return (
        <Link to={url}>
        <Paper className={rootClassName}>
          <div className={classes.details}>
          <Typography className={classes.title} variant="h6">
            {this.props.headwords}
            </Typography>
            <Typography className={classes.description}>
              {this.newText()}
            </Typography>
          </div>
        </Paper>
        </Link>
    );
  }

  title(){
    //let headword = null
    if(this.props.small){
      for(var i=0;i<this.props.headwords.length;i++){
        if(this.props.headwords[i].attributes[0].id == this.props.book.headwordId){
          return(this.props.headwords[i].attributes[0].headword)
        }
      }
    } else {
      let headword = this.props.headwords.find(h => h.id==this.props.book.headwordId);
      return(headword.attributes.headword);
    }
  }
  newText(){
    let title = this.props.book.quotation.replace(/(<([^>]+)>)/ig,"");
    title = title.replace(/\&nbsp;/g, ' ');
    return (
        title
    )
  }

  text(citation){
    if(citation.attributes.bookId!=null){
      var i;
      for(i = 0; i<this.props.books.length; i++){
          if(citation.attributes.bookId==this.props.books[i][0]){
            return(
              this.props.books[i][1]
            );
          }
      }
    } else if(citation.attributes.periodicalId!=null){
      var i;
      for(i = 0; i<this.props.periodicals.length; i++){
          if(citation.attributes.periodicalId==this.props.periodicals[i][0]){
            return(
              this.props.periodicals[i][1]
            );
          }
      }
    } else if(citation.attributes.utteranceId!=null) {
      var i;
      for(i = 0; i<this.props.utterance.length; i++){
          if(citation.attributes.utteranceId==this.props.utterance[i][0]){
            return(
              this.props.utterances[i][1]
            );
          }
      }
    } else if(citation.attributes.websiteId!=null){
      var i;
      for(i = 0; i<this.props.websites.length; i++){
          if(citation.attributes.websiteId==this.props.websites[i][0]){
            return(
              this.props.websites[i][1]
            );
          }
      }
    }
  }
}

SearchCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SearchCard);

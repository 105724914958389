import api from 'services/api';

const deleteCitation = async (citation) => {
    return await api.put(`delete/quotation`, citation);
  };

  const deleteKeyword = async (headword) => {
    return await api.put(`delete/keyword`, headword);
  };
  const deleteBook = async (book) => {
    return await api.put(`delete/book`, book);
  };
  const deletePeriodical = async (periodical) => {
    return await api.put(`delete/periodical`, periodical);
  };
  const deleteUtterance = async (utterance) => {
    return await api.put(`delete/utterance`, utterance);
  };
  const deleteWebsite = async (website) => {
    return await api.put(`delete/website`, website);
  };

  export default { deleteCitation, deleteKeyword, deleteBook, deletePeriodical, deleteUtterance, deleteWebsite };
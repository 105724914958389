import api from 'services/api';

const getCitations = async (limit) => {
  return await api.get(`citations`);
};

const getVCitations = async (limit) => {
  return await api.get(`citations/v/`);
};

const getAllSources = async (limit) => {
  return await api.get(`citations/AllSources`);
};

const getBooks = async (limit) => {
  return await api.get(`citations/Books`);
};

const getPeriodicals = async (limit) => {
  return await api.get(`citations/Periodicals`);
};

const getUtterances = async (limit) => {
  return await api.get(`citations/Utterances`);
};

const getWebsites = async (limit) => {
  return await api.get(`citations/Websites`);
};

const getCitation = async (citationId) => {
  return await api.get(`citations/${citationId}`);
};

const updateCitation = async (citation) => {
  return await api.put(`citations/${citation.id}`, citation);
};

const createCitation = async (citation) => {
  return await api.post(`citations`, citation);
};

const getVCitation = async (citationId) => {
  return await api.get(`citations/v/${citationId}`);
};

const updateVCitation = async (citation) => {
  return await api.put(`citations/v/${citation.id}`, citation);
};

const createVCitation = async (citation) => {
  return await api.post(`citations/v/`, citation);
};

/*const getBooks = async (limit) => {
  return await api.get(`books`);
};

const getPeriodicals = async (limit) => {
  return await api.get(`periodicals`);
};

const getUtterances = async (limit) => {
  return await api.get(`utterances`);
};

const getWebsites = async (limit) => {
  return await api.get(`websites`);
}; */


export default { getCitations, getVCitations, getAllSources, getCitation, updateCitation, 
  createCitation, getVCitation, updateVCitation, createVCitation, getBooks, getPeriodicals, getUtterances, getWebsites };

import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { CircularProgress, Grid, TextField, Button, 
  InputLabel, Paper, Tab, Tabs, Box, FormControlLabel, Checkbox } from '@material-ui/core';
import headwordService from 'services/headwords';
import ReactQuill from 'react-quill';
import SourcesCard from './SourcesCard';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  SearchInput,
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';
import service from 'services/citations';
import deletion from 'services/deletion';
import allCitations from 'services/allCitations';
import { Book, Periodical, Website, Utterance } from './components';
import styles from './styles';
import citations from 'services/citations';

const partOfSpeechOptions = [
  { value: '', label: ''},
  { value: 'Noun', label: 'Noun' },
  { value: 'Adjective', label: 'Adjective' },
  { value: 'Verb', label: 'Verb' },
  { value: 'Adverb', label: 'Adverb' },
  { value: 'Pronoun', label: 'Pronoun' },
  { value: 'Preposition', label: 'Preposition' },
  { value: 'Conjunction', label: 'Conjunction' },
  { value: 'Interjection', label: 'Interjection' },
  { value: 'Prefix', label: 'Prefix' },
  { value: 'Suffix', label: 'Suffix' },
  { value: 'Combining Form', label: 'Combining Form' },
];

const otherOptions = [
  { value: '', label: ''},
  { value: 'No', label: 'No' },
  { value: 'Yes', label: 'Yes' },
];

const volunteerOptions = [
  { value: '', label: ''},
  { value: 'Yes', label: 'Yes' },
];

const decisionOptions = [
  { value: '', label: ''},
  { value: 'Rejected', label: 'Rejected' },
  { value: 'Sent On', label: 'Sent On' },
];

class EditCitation extends Component {
  signal = true;

  state = {
    isLoading: false,
    citation: this.props.citation,
    someChange: false,
    hw: {},
    updating: this.props.updating,
    error: null,
    books: [],
    periodicals: [],
    utterances: [],
    websites: [],
    searchString: '',
    searchStringP: '',
    searchStringU: '',
    searchStringW: '',
    functionOptions: [],
    typeOptions: [],
    degreeOptions: [],
    determinerOptions: [],
    paradigmOptions: [],
    moodOptions: [],
    sourceTypeOptions: [],
    posSelection: '',
    hw: [],
    typeSelection: '',
    progressLabel: "getting citation and headwords",
    noHeadword: false,
    newHeadwordID: null,
    minCharSearch: 3,
    includeLegacyCheck: false,
    minCharOptions: [
      { value: '1', label: '1' },
      { value: '2', label: '2' },
      { value: '3', label: '3' },
      { value: '4', label: '4' },
      { value: '5', label: '5' },
    ],
    prevHeadword: null,
    prevHeadwordId: null,
    url: '/headwords/' + this.props.citation.attributes.headwordId,
    buttonClick: false,
    previewClick: this.props.updating,
    sources: [{
      attributes: {}
    },{
      attributes: {}
    },{
      attributes: {}
    },{
      attributes: {}
    }]
  };

  async getHeadwords() {
    try {
      this.setState({ isLoading: true });
      let headwords = await headwordService.getHeadwords();
      let headwordOptions = headwords.map(hw => ({ label: hw.attributes.headword, headword: hw }));
      let citation = this.props.citation;
      console.log(citation)
      //console.log(citation.relationships.headword.attributes.headword)
      let hw = headwordOptions.find(opt => opt.headword.id === citation.attributes.headwordId);
      //this.state.hw = hw;
      citation.relationships.headword = hw.headword;
      //console.log(hw);

      this.setState({hw: hw, citation:citation})
      if (this.signal) {
        this.setState({
          headwordOptions,
          selectedHeadwordOption: headwordOptions.find(opt => opt.headword.id === citation.attributes.headwordId)
        });
      }
      this.setState({progressLabel:"getting sources"})
      this.getCitations();
      if(this.state.hw.headword.attributes.partOfSpeech==null){
         this.setGrammar(this.state.citation.attributes.partOfSpeech);
         this.setTypeOptions(this.state.citation.attributes.sourceType);
      } else {
        this.onChange('attributes.partOfSpeech', this.state.hw.headword.attributes.partOfSpeech)
        this.setGrammar(this.state.hw.headword.attributes.partOfSpeech);
        this.setTypeOptions(this.state.citation.attributes.sourceType);
        if(this.props.citation.relationships.book!=undefined){
            console.log(this.props.citation.relationships.book)
            this.state.typeSelection = this.props.citation.relationships.book.attributes.type;
            this.forceUpdate();
        } else if(this.props.citation.relationships.periodical!=undefined) {
            this.state.typeSelection = this.props.citation.relationships.periodical.attributes.type;
            this.forceUpdate();
        } else if(this.props.citation.relationships.utterance!=undefined){
            this.state.typeSelection = this.props.citation.relationships.utterance.attributes.type;
            this.forceUpdate();
        } else if(this.props.citation.relationships.website!=undefined) {
            this.state.typeSelection = this.props.citation.relationships.website.attributes.type;
            this.forceUpdate();
        }
      }
      this.setState({ isLoading: false });
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  
  async getCitations() {
    try {
      //this.setState({ isLoading: true });

      let fullsources = await service.getCitations();
      
      var bookList = [];
      var periodicalList = [];
      var utteranceList = [];
      var websiteList = [];

      var i = 0;
      var item;
      for(item in fullsources[0].attributes){
        var list = []
        list.push(fullsources[0].attributes[i].id);
        list.push(fullsources[0].attributes[i].title);
        list.push(fullsources[0].attributes[i].legacy);
        bookList.push(list);
        i++;    
      }

      i = 0;
      for(item in fullsources[1].attributes){
        var list = []
        list.push(fullsources[1].attributes[i].id);
        list.push(fullsources[1].attributes[i].title);
        list.push(fullsources[1].attributes[i].legacy);
        periodicalList.push(list);
        i++;    
      }

      i = 0;
      for(item in fullsources[2].attributes){
        var list = []
        list.push(fullsources[2].attributes[i].id);
        list.push(fullsources[2].attributes[i].siteName);
        list.push(fullsources[2].attributes[i].legacy);
        utteranceList.push(list);
        i++;    
      }

      i = 0;
      for(item in fullsources[3].attributes){
        var list = []
        list.push(fullsources[3].attributes[i].id);
        list.push(fullsources[3].attributes[i].siteName);
        list.push(fullsources[3].attributes[i].legacy);
        websiteList.push(list);
        i++;    
      }

      if (this.signal) {
        this.setState({
          //isLoading: false,
          books: bookList,
          periodicals: periodicalList,
          utterances: utteranceList,
          websites: websiteList,
          sources: fullsources
        });
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          //isLoading: false,
          error
        });
      }
    }
  }

  componentWillMount() {
    this.signal = true;
    this.getHeadwords();
    this.state.originalCitation=this.props.citation;
    if(this.props.citation.attributes.quotation!=undefined){
    this.state.citation.attributes.quotation = this.props.citation.attributes.quotation;
    } else if(this.props.citation.attributes.quotation==undefined){
      this.state.citation.attributes.quotation = '';
    }
    
  }

  componentWillUnmount() {
    this.signal = false;
  }

  
  isViewOnly() {
    let user = JSON.parse(localStorage.getItem('user'));
    return user && user.attributes.roles.includes('view-only');
  }

  onChange(path, value) {
    let citation = this.state.citation;
    _.set(citation, path, value);
    this.setState({ citation });
    if(!this.state.isLoading){
      this.setState({someChange:true});
    }

  }

  onChangeYogh(path, value){
    value=this.state.citation.attributes.quotation.substring(0,this.state.citation.attributes.quotation.length-4)+value
        +this.state.citation.attributes.quotation.substring(this.state.citation.attributes.quotation.length-4,this.state.citation.attributes.quotation.length);
    let citation = this.state.citation;
    _.set(citation, path, value);
    this.setState({ citation });
    if(!this.state.isLoading){
      this.setState({someChange:true});
    }
  }

  updateType(value) {
    let citation = this.state.citation;
    this.setState({typeSelection: value});
    if(citation.relationships.book!=undefined){
      let path = 'relationships.book.attributes.type';
        _.set(citation, path, value);
      this.setState({ citation });
    } else if (citation.relationships.periodical!=undefined) {
      let path = 'relationships.periodical.attributes.type';
        _.set(citation, path, value);
      this.setState({ citation });  
    } else if (citation.relationships.utterance!=undefined) {
      let path = 'relationships.utterance.attributes.type';
      _.set(citation, path, value);
      this.setState({ citation });
    } else if (citation.relationships.website!=undefined) {
      let path = 'relationships.website.attributes.type';
      _.set(citation, path, value);
      this.setState({ citation });
    }

  }

  updateTypeSelection(value){
    this.setState({typeSelection: value});
  }

  sourceDetailedType(){
    let citation = this.state.citation;
    if(citation.relationships.book!=undefined){
      return(citation.relationships.book.attributes.type);
    } else if (citation.relationships.periodical!=undefined) {
      return(citation.relationships.periodical.attributes.type);
    } else if (citation.relationships.utterance!=undefined) {
      return(citation.relationships.utterance.attributes.type);
    } else if (citation.relationships.website!=undefined) {
      return(citation.relationships.website.attributes.type);
    }
  }

  setGrammar(value) {
      this.setState({posSelection: value});
        if(value == "Noun") {
          this.setState({functionOptions: [{value: '', label: ''},{value: 'Attributive', label: 'Attributive'},{value: 'Possessive', label: 'Possessive'}], 
                typeOptions: [{value: '', label: ''},{value: 'Collective', label: 'Collective'},{value: 'Proper', label: 'Proper'}]});
        } else if(value == "Adjective"){
          this.setState({degreeOptions: [{value: '', label: ''},{value: 'Comparative', label: 'Comparative'},{value: 'Superlative', label: 'Superlative'}], 
                functionOptions: [{value: '', label: ''},{value: 'Demonstrative', label: 'Demonstrative'},{value: 'Interrogative', label: 'Interrogative'},
                    {value: 'Possessive', label: 'Possessive'},{value: 'Relative', label: 'Relative'}], 
                determinerOptions: [{value: '', label: ''},{value: 'Definite', label: 'Definite'},{value: 'Indefinite', label: 'Indefinite'}]});
        } else if(value == "Verb"){
          this.setState({paradigmOptions: [{value: '', label: ''},{value: 'Past participle', label: 'Past participle'},
                            {value: 'Present participle', label: 'Present participle'},{value: 'Past tense', label: 'Past tense'}], 
                typeOptions: [{value: '', label: ''},{value: 'Transitive', label: 'Transitive'},{value: 'Instransitive', label: 'Instransitive'},
                    {value: 'Reflexive', label: 'Reflexive'},{value: 'Impersonal', label: 'Impersonal'}], 
                moodOptions: [{value: '', label: ''},{value: 'Imperative', label: 'Imperative'},{value: 'Subjunctive', label: 'Subjunctive'}]});
        } else if(value == "Adverb"){
          this.setState({degreeOptions: [{value: '', label: ''},{value: 'Comparative', label: 'Comparative'},{value: 'Superlative', label: 'Superlative'}], 
                functionOptions: [{value: '', label: ''},{value: 'Interrogative', label: 'Interrogative'},{value: 'Relative', label: 'Relative'}]});
        } else if(value == "Pronoun"){
          this.setState({functionOptions: [{value: '', label: ''},{value: 'Demonstrative', label: 'Demonstrative'},{value: 'Interrogative', label: 'Interrogative'},
          {value: 'Personal', label: 'Personal'},{value: 'Possessive', label: 'Possessive'},{value: 'Relative', label: 'Relative'}]});
        } else if(value == "Conjunction"){
          this.setState({functionOptions: [{value: '', label: ''},{value: 'Interrogative', label: 'Interrogative'},{value: 'Relative', label: 'Relative'}]});
        }
  }

  async updateGrammar(path, value) {
    let citation = this.state.citation;
    _.set(citation, path, value);
    this.setState({ citation });
    this.setState({posSelection: value});
      if(value == "Noun") {
        this.setState({functionOptions: [{value: '', label: ''},{value: 'Attributive', label: 'Attributive'},{value: 'Possessive', label: 'Possessive'}], 
              typeOptions: [{value: '', label: ''},{value: 'Collective', label: 'Collective'},{value: 'Proper', label: 'Proper'}]});
      } else if(value == "Adjective"){
        this.setState({degreeOptions: [{value: '', label: ''},{value: 'Comparative', label: 'Comparative'},{value: 'Superlative', label: 'Superlative'}], 
              functionOptions: [{value: '', label: ''},{value: 'Demonstrative', label: 'Demonstrative'},{value: 'Interrogative', label: 'Interrogative'},
                  {value: 'Possessive', label: 'Possessive'},{value: 'Relative', label: 'Relative'}], 
              determinerOptions: [{value: '', label: ''},{value: 'Definite', label: 'Definite'},{value: 'Indefinite', label: 'Indefinite'}]});
      } else if(value == "Verb"){
        this.setState({paradigmOptions: [{value: '', label: ''},{value: 'Past participle', label: 'Past participle'},
                          {value: 'Present participle', label: 'Present participle'},{value: 'Past tense', label: 'Past tense'}], 
              typeOptions: [{value: '', label: ''},{value: 'Transitive', label: 'Transitive'},{value: 'Instransitive', label: 'Instransitive'},
                  {value: 'Reflexive', label: 'Reflexive'},{value: 'Impersonal', label: 'Impersonal'}], 
              moodOptions: [{value: '', label: ''},{value: 'Imperative', label: 'Imperative'},{value: 'Subjunctive', label: 'Subjunctive'}]});
      } else if(value == "Adverb"){
        this.setState({degreeOptions: [{value: '', label: ''},{value: 'Comparative', label: 'Comparative'},{value: 'Superlative', label: 'Superlative'}], 
              functionOptions: [{value: '', label: ''},{value: 'Interrogative', label: 'Interrogative'},{value: 'Relative', label: 'Relative'}]});
      } else if(value == "Pronoun"){
        this.setState({functionOptions: [{value: '', label: ''},{value: 'Demonstrative', label: 'Demonstrative'},{value: 'Interrogative', label: 'Interrogative'},
        {value: 'Personal', label: 'Personal'},{value: 'Possessive', label: 'Possessive'},{value: 'Relative', label: 'Relative'}]});
      } else if(value == "Conjunction"){
        this.setState({functionOptions: [{value: '', label: ''},{value: 'Interrogative', label: 'Interrogative'},{value: 'Relative', label: 'Relative'}]});
      }
  }


  setTypeOptions(value) {
      if(value == "books") {
        this.setState({sourceTypeOptions: [{value: '', label: ''},{value: 'Text (other)', label: 'Text (other)'},
                        {value: 'Text (anthology/collection)', label: 'Text (anthology/collection)'}]});
      } else if(value == "periodicals"){
        this.setState({sourceTypeOptions: [{value: '', label: ''},{value: 'Press article (print)', label: 'Press article (print)'},
                        {value: 'Press article (online)', label: 'Press article (online)'}, {value: 'Journal article (print/online)', label: 'Journal article (print/online)'}]});
      } else if(value == "websites"){
        this.setState({sourceTypeOptions: [{value: '', label: ''},{value: 'Online (website)', label: 'Online (website)'},
                        {value: 'Online (social media)', label: 'Online (social media)'}]});
      } else if(value == "utterances"){
        this.setState({sourceTypeOptions: [{value: '', label: ''},{value: 'Oral (recorded)', label: 'Oral (recorded)'}]});
      }
}

async updateTypeOptions(path, value) {
  let citation = this.state.citation;
  _.set(citation, path, value);
  this.setState({ citation });
  if(value == "books") {
    this.setState({sourceTypeOptions: [{value: '', label: ''},{value: 'Text (other)', label: 'Text (other)'},
                    {value: 'Text (anthology/collection)', label: 'Text (anthology/collection)'}]});
  } else if(value == "periodicals"){
    this.setState({sourceTypeOptions: [{value: '', label: ''},{value: 'Press article (print)', label: 'Press article (print)'},
                    {value: 'Press article (online)', label: 'Press article (online)'}, {value: 'Journal article (print/online)', label: 'Journal article (print/online)'}]});
  } else if(value == "websites"){
    this.setState({sourceTypeOptions: [{value: '', label: ''},{value: 'Online (website)', label: 'Online (website)'},
                    {value: 'Online (social media)', label: 'Online (social media)'}]});
  } else if(value == "utterances"){
    this.setState({sourceTypeOptions: [{value: '', label: ''},{value: 'Oral (recorded)', label: 'Oral (recorded)'}]});
  }
  if(!this.state.isLoading){
    this.setState({someChange:true});
  }
}

  clipCitation() {
    let range = this.citationEditor.editor.getSelection(true);
    let contents = this.citationEditor.editor.getContents(range.index, range.length);
    let citation = this.state.citation;
    if(range.index==range.index + range.length){
      _.set(citation, 'attributes.clipStart', range.index);
      _.set(citation, 'attributes.clipEnd', range.index + range.length);
      _.set(citation, 'attributes.clippedText', null);
      this.setState({ citation }); 
    } else {
      _.set(citation, 'attributes.clipStart', range.index);
      _.set(citation, 'attributes.clipEnd', range.index + range.length);
      _.set(citation, 'attributes.clippedText', contents);
      this.isClipping = true;
      this.setState({ citation }); 
    }
  }

  onClippingChange(value) {
    if (this.isClipping) {
      this.isClipping = false;
      let citation = this.state.citation;
      _.set(citation, 'attributes.clippedText', value);
      this.setState({ citation });
    }
  }

  selectHeadword() {
    if(this.state.newHeadwordID != undefined || this.state.newHeadwordID != ""){
        let newHeadword = this.state.headwordOptions.find(opt => opt.headword.id == this.state.newHeadwordID);
        if(newHeadword==null){
          window.alert("Keyword ID does not exist")
        } else {
          this.setState({prevHeadword: this.state.citation.relationships.headword})
          this.setState({prevHeadwordId: this.state.citation.attributes.headwordId})
          this.setState({ selectedHeadwordOption: newHeadword.headword });
          this.onChange('attributes.headwordId', this.state.newHeadwordID);
          this.onChange('relationships.headword', newHeadword.headword);
          this.headword = newHeadword.headword;
        }
        this.state.newHeadwordID = ""
    } else {
      window.alert("Please Enter a Keyword ID")
    }
  }

  undoSelectHeadword() {
    if(this.state.prevHeadword != undefined && this.state.prevHeadwordId != null){
      this.setState({ selectedHeadwordOption: this.state.prevHeadword });
      this.onChange('attributes.headwordId', this.state.prevHeadwordId);
      this.onChange('relationships.headword', this.state.prevHeadword);
      this.headword = this.state.prevHeadword;
    } else {
      window.alert("nothing to undo.")
    }
  }

  updateKeyword(id){
    this.setState({newHeadwordID: id})
  }

  /*consolidateHeadword() {
    if ((this.headword || null) !== (this.state.selectedHeadwordOption && this.state.selectedHeadwordOption.label)) {
      let option = this.state.headwordOptions.find(opt => opt.label === this.headword);
      if (option) {
        this.selectHeadword(option);
      } else {
        this.onChange('attributes.headwordId', null);
      }
    }
  } */

  async save() {
    if (!this.state.citation.attributes.headwordId) {
      if (!window.confirm(`Create new keyword "${this.headword}"?`)) {
        alert('Quotation not saved');
        return;
      }
      let headword = await headwordService.createHeadword({
        type: 'headwords',
        attributes: {
          headword: this.headword
        },
      });
      this.state.citation.attributes.headwordId = headword.id;
    }
    this.props.onSave();
  }

  goToLink(){
    if(this.state.citation.attributes.url!="" && this.state.citation.attributes.url!=null){
      if(this.state.citation.attributes.url.substring(0,1)=="#" && 
      this.state.citation.attributes.url.substring((this.state.citation.attributes.url.length)-1,(this.state.citation.attributes.url.length))=="#"){
        this.state.citation.attributes.url=this.state.citation.attributes.url.substring(1,(this.state.citation.attributes.url.length)-1);
      }
      if(this.state.citation.attributes.url.substring(0,7)!="http://" && this.state.citation.attributes.url.substring(0,8)!="https://"){
        try{
          window.open("http://" + this.state.citation.attributes.url, '_blank').focus();
        } catch(e){
          window.alert("Invalid URL entered");
        }
      } else{
        try{
          window.open(this.state.citation.attributes.url, '_blank').focus();
        } catch(e){
          window.alert("Invalid URL entered");
        }
      }
    } else {
      window.alert("There's no URL to go to");
    }
  }

  async deleteVolunteerQuote(){
    const {citation, sourceType} = this.state;
    if(window.confirm("Are you sure you want to delete this quotation?")){
        try{
          let url = ""
          if(citation.attributes.sourceType=="books") {
            url = '/book/' + citation.attributes.bookId;
          } else if(citation.attributes.sourceType == "periodicals"){
            url = '/periodical/' + citation.attributes.periodicalId;
          } else if(citation.attributes.sourceType == "websites"){
            url = '/website/' + citation.attributes.websiteId;
          } else if(citation.attributes.sourceType == "utterances"){
            url = '/utterance/' + citation.attributes.utteranceId;
          }
          await deletion.deleteCitation(citation);
          let keywordCitations = await allCitations.getHeadwordsCitations(citation.attributes.headwordId);
          if(keywordCitations.attributes[0]==null){
            await deletion.deleteKeyword(citation.relationships.headword);
          }
          window.location.href = url;
        } catch(err){
          window.alert("Couldn't Delete Quote: Error: " + err);
        }
    }
  }

  renderCitationDetails() {
    const { classes } = this.props;
    const { citation, headwordOptions, selectedHeadwordOption } = this.state;

    return (
      <Portlet>
        <PortletHeader>
          <PortletLabel title="Quotation Details" />
        </PortletHeader>
        <PortletContent noPadding>
          <form
            autoComplete="off"
            noValidate
          >
            <div className={classes.field}>
            <InputLabel htmlFor="citation-input">Keyword</InputLabel>
            </div>
              {/*<div className={classes.field}>
                <Autocomplete
                  //options={headwordOptions}
                  className={classes.textField}
                  freeSolo={true}
                  //getOptionLabel={option => option.label}
                  disableClearable
                  value={citation.relationships.headword.attributes.headword}
                  //onChange={(event, value) => this.selectHeadword(value)}
                  //onInputChange={(event, value) => this.headword = value}
                  //onBlur={() => this.consolidateHeadword(this.headword)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Keyword"
                      margin="dense"
                      required
                      variant="outlined"
                      fullWidth />
                  )}
                  /> 
              </div> */}
              <div className={classes.field}>
                <TextField
                  className={classes.textField}
                  label="Keyword"
                  margin="dense"
                  required
                  value={citation.relationships.headword.attributes.headword || ''}
                  variant="outlined">
                </TextField>
              </div>
              {(!this.isViewOnly()) && (
              <div className={classes.field}>
                <div className={classes.row}>
                  <TextField
                    className={classes.textField}
                    label="Change Keyword - Enter ID"
                    margin="dense"
                    onChange={(event) => this.updateKeyword(event.target.value)}
                    value={this.state.newHeadwordID || ''}
                    variant="outlined">
                  </TextField>
                  <Button
                    style={{marginTop:"8px"}}
                    color="primary"
                    variant="contained"
                    onMouseDown={this.selectHeadword.bind(this)}
                  >Update
                  </Button>

                  <Button
                    style={{marginTop:"8px", marginLeft:"5px"}}
                    color="primary"
                    variant="contained"
                    onMouseDown={this.undoSelectHeadword.bind(this)}
                  >Undo
                  </Button>
                </div>
              </div>
              )}
              <div className={classes.field}>
                <TextField
                  className={classes.textField}
                  label="Part of Speech"
                  margin="dense"
                  value={citation.attributes.partOfSpeech || ''}
                  variant="outlined">
                </TextField>
              </div>
            {(this.state.posSelection != "" && this.state.posSelection != "Interjection" && this.state.posSelection != "Prefix" 
             && this.state.posSelection != "Suffix" && this.state.posSelection != "Combining Form") && (
            <div className={classes.field}>
            <InputLabel htmlFor="citation-input">Quotation</InputLabel>
            </div>
            )}
            <div className={classes.field}>
              <ReactQuill className={classes.editor}
                          value={citation.attributes.quotation || ''}
                          onChange={(value) => this.onChange(`attributes.quotation`, value || null)}
                          ref={ref => this.citationEditor = ref}
                          id="citation-input"/>
            </div>
            <div className={classes.field} style={{width: '10%', marginRight:"5px", marginTop:"12px"}} >
              <Button onClick={event => this.onChangeYogh('attributes.quotation', ('ȝ'))} fullWidth='true' color="primary" size="small" variant="outlined">Add ȝ </Button>
            </div>
            <div className={classes.field}>
              <Button
                variant="outlined"
                size="small"
                className={classes.clipButton}
                onClick={this.clipCitation.bind(this)}
              >Clip</Button>
            </div>
            <div className={classes.field}>
              <InputLabel>Clipped Quotation{citation.attributes.clipEnd > citation.attributes.clipStart ? ` (${citation.attributes.clipStart} - ${citation.attributes.clipEnd})` : ''}</InputLabel>
              <ReactQuill value={ citation.attributes.clippedText || ''}
                          readOnly={true}
                          onChange={this.onClippingChange.bind(this)}
                          theme="bubble"/>
            </div>
            <div className={classes.field}>
            <InputLabel htmlFor="citation-input">Grammar</InputLabel>
            </div>
            <div style={{paddingLeft: '5%'}}>
            {(this.state.posSelection == "Adjective" || this.state.posSelection == "Adverb") && (
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Degree"
                margin="dense"
                required
                select
                SelectProps={{ native: true }}
                value={citation.attributes.grammarDegree || ''}
                onChange={(event) => this.onChange('attributes.grammarDegree', event.target.value)}
                variant="outlined">
                {this.state.degreeOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
            )}
             {(this.state.posSelection == "Adjective" || this.state.posSelection == "Adverb" || this.state.posSelection == "Noun" || this.state.posSelection == "Pronoun" || this.state.posSelection == "Conjunction") && (
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Function"
                margin="dense"
                required
                select
                SelectProps={{ native: true }}
                value={citation.attributes.grammarFunction || ''}
                onChange={(event) => this.onChange('attributes.grammarFunction', event.target.value)}
                variant="outlined">
                {this.state.functionOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
             )}
            {(this.state.posSelection == "Verb") && (
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Paradigm"
                margin="dense"
                required
                select
                SelectProps={{ native: true }}
                value={citation.attributes.grammarParadigm || ''}
                onChange={(event) => this.onChange('attributes.grammarParadigm', event.target.value)}
                variant="outlined">
                {this.state.paradigmOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
            )}
             {(this.state.posSelection == "Adjective") && (
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Determiner"
                margin="dense"
                required
                select
                SelectProps={{ native: true }}
                value={citation.attributes.grammarDeterminer || ''}
                onChange={(event) => this.onChange('attributes.grammarDeterminer', event.target.value)}
                variant="outlined">
                {this.state.determinerOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
             )}
            {(this.state.posSelection == "Noun" || this.state.posSelection == "Adverb") && (
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Type"
                margin="dense"
                required
                select
                SelectProps={{ native: true }}
                value={citation.attributes.grammarType || ''}
                onChange={(event) => this.onChange('attributes.grammarType', event.target.value)}
                variant="outlined">
                {this.state.typeOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
            )}
             {(this.state.posSelection == "Verb") && (
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Mood"
                margin="dense"
                required
                select
                SelectProps={{ native: true }}
                value={citation.attributes.grammarMood || ''}
                onChange={(event) => this.onChange('attributes.grammarMood', event.target.value)}
                variant="outlined">
                {this.state.moodOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
             )}
            {(this.state.posSelection == "Noun" || this.state.posSelection == "Adjective") && (
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Plural"
                margin="dense"
                required
                select
                SelectProps={{ native: true }}
                value={citation.attributes.grammarPlural || ''}
                onChange={(event) => this.onChange('attributes.grammarPlural', event.target.value)}
                variant="outlined">
                {otherOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
            )}
            {(this.state.posSelection == "Noun") && (
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Verbal"
                margin="dense"
                required
                select
                SelectProps={{ native: true }}
                value={citation.attributes.grammarVerbal || ''}
                onChange={(event) => this.onChange('attributes.grammarVerbal', event.target.value)}
                variant="outlined">
                {otherOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
            )}
            {(this.state.posSelection == "Noun" || this.state.posSelection == "Adjective" || this.state.posSelection == "Verb" 
              || this.state.posSelection == "Adverb" || this.state.posSelection == "Preposition" || this.state.posSelection == "Conjunction"  )&& (
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Phrase"
                margin="dense"
                required
                select
                SelectProps={{ native: true }}
                value={citation.attributes.grammarPhrase || ''}
                onChange={(event) => this.onChange('attributes.grammarPhrase', event.target.value)}
                variant="outlined">
                {otherOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
              )}
              </div>
            <div className={classes.field}>
            </div>
            <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Region"
                margin="dense"
                value={citation.attributes.region || ''}
                onChange={(event) => this.onChange('attributes.region', event.target.value)}
                variant="outlined"
            />
            </div>
            <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Notes"
                margin="dense"
                multiline="true"
                value={citation.attributes.notes || ''}
                onChange={(event) => this.onChange('attributes.notes', event.target.value)}
                variant="outlined"
              />
            </div>
            <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Legacy Notes"
                margin="dense"
                value={citation.attributes.legacyNotes || ''}
                multiline="true"
                onChange={(event) => this.onChange('attributes.legacyNotes', event.target.value)}
                variant="outlined"
              />
            </div>
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Decision"
                margin="dense"
                select
                SelectProps={{ native: true }}
                value={citation.attributes.decision || ''}
                onChange={(event) => this.onChange('attributes.decision', event.target.value)}
                variant="outlined">
                {decisionOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
            {(!this.isViewOnly()) && (
            <div className={classes.field}>
              <TextField
                className={classes.textField}
                label="Quote Entered By Volunteer"
                margin="dense"
                select
                SelectProps={{ native: true }}
                value={citation.attributes.volunteer || ''}
                onChange={(event) => this.onChange('attributes.volunteer', event.target.value)}
                variant="outlined">
                {volunteerOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
            )}
            {(citation.attributes.volunteer=="Yes") && (!this.isViewOnly()) && (
            <div className={classes.field}>
                  <Button
                    color="primary"
                    variant="contained"
                    onMouseDown={this.deleteVolunteerQuote.bind(this)}
                  >Delete
                  </Button>
              </div>
              )}
            <div className={classes.field}>
            
            <InputLabel htmlFor="citation-input"> Quotation Source Details</InputLabel>
            </div>
            <div>
        
          {/*  <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Type"
                margin="dense"
                required
                select
                SelectProps={{ native: true }}
                value={this.state.typeSelection || ''}
                onChange={(event) => this.updateType(event.target.value)}
                variant="outlined">
                {this.state.sourceTypeOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
               
                </div> */}
            
            {(this.state.typeSelection!='') && (
            <div>
            {(this.state.typeSelection=='Text (anthology/collection)' || this.state.typeSelection=='Press article (print)' 
            || this.state.typeSelection == 'Press article (online)' || this.state.typeSelection=='Journal article (print/online)'
            || this.state.typeSelection == 'Online (website)' || this.state.typeSelection == 'Online (social media)') && (
            <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Author(s)"
                margin="dense"
                value={citation.attributes.author || ''}
                multiline="true"
                onChange={(event) => this.onChange('attributes.author', event.target.value)}
                variant="outlined"
              />
            </div>
          )}
            {(this.state.typeSelection=='Oral (recorded)') && (
            <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Creator Of Recording"
                margin="dense"
                value={citation.attributes.recordingCreator || ''}
                onChange={(event) => this.onChange('attributes.recordingCreator', event.target.value)}
                variant="outlined"
              />
            </div>
          )}
         {(this.state.typeSelection=='Oral (recorded)') && (
            <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Date Of Recording (YYYY)"
                margin="dense"
                value={citation.attributes.recordingDate || ''}
                onChange={(event) => this.onChange('attributes.recordingDate', event.target.value)}
                variant="outlined"
              />
            </div>
          )}
           {(this.state.typeSelection=='Oral (recorded)') && (
            <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Speaker"
                margin="dense"
                value={citation.attributes.speaker || ''}
                onChange={(event) => this.onChange('attributes.speaker', event.target.value)}
                variant="outlined"
              />
            </div>
          )}
          {(this.state.typeSelection=='Oral (recorded)') && (
            <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Suggested Citation"
                margin="dense"
                value={citation.attributes.suggestedCitation || ''}
                multiline="true"
                onChange={(event) => this.onChange('attributes.suggestedCitation', event.target.value)}
                variant="outlined"
              />
            </div>
          )}
            {(this.state.typeSelection=='Press article (print)' 
            || this.state.typeSelection == 'Press article (online)' || this.state.typeSelection=='Journal article (print/online)'
            || this.state.typeSelection == 'Online (website)' || this.state.typeSelection == 'Online (social media)') && (
            <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Article Title"
                margin="dense"
                value={citation.attributes.articleTitle || ''}
                onChange={(event) => this.onChange('attributes.articleTitle', event.target.value)}
                variant="outlined"
              />
            </div>
            )}
             {(this.state.typeSelection=='Oral (recorded)' 
            || this.state.typeSelection == 'Press article (online)' || this.state.typeSelection=='Journal article (print/online)'
            || this.state.typeSelection == 'Online (website)' || this.state.typeSelection == 'Online (social media)') && (  
            <div className={classes.field}>
              <div className={classes.row}>
              <TextField
                  className={classes.textField}
                  label="URL"
                  margin="dense"
                  value={citation.attributes.url || ''}
                  onChange={(event) => this.onChange('attributes.url', event.target.value)}
                  variant="outlined"
                />
                  <Button 
                    style={{marginTop:"8.5px"}}
                    color="primary"
                    variant="contained"
                    onMouseDown={this.goToLink.bind(this)}
                  >Go To URL
                  </Button>
              </div>
            </div>
            )}
           {(this.state.typeSelection=='Text (anthology/collection)') && (  
            <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Title of Sub-part"
                margin="dense"
                value={citation.attributes.subPartTitle || ''}
                multiline="true"
                onChange={(event) => this.onChange('attributes.subPartTitle', event.target.value)}
                variant="outlined"
              />
            </div>
            )}
            {(this.state.typeSelection=='Text (anthology/collection)' || this.state.typeSelection=='Press article (print)' 
            || this.state.typeSelection == 'Press article (online)' || this.state.typeSelection=='Journal article (print/online)'
            || this.state.typeSelection == 'Online (website)' || this.state.typeSelection == 'Online (social media)') && (
            <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Date (DD/MM/YYYY)"
                margin="dense"
                value={citation.attributes.publishedDate || ''}
                onChange={(event) => this.onChange('attributes.publishedDate', event.target.value)}
                variant="outlined"
              />
            </div>
            )}
              {( this.state.typeSelection=='Oral (recorded)' 
            || this.state.typeSelection == 'Press article (online)' || this.state.typeSelection=='Journal article (print/online)'
            || this.state.typeSelection == 'Online (website)' || this.state.typeSelection == 'Online (social media)') && (
            <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Date Accessed (DD/MM/YYYY)"
                margin="dense"
                value={citation.attributes.accessedDate || ''}
                onChange={(event) => this.onChange('attributes.accessedDate', event.target.value)}
                variant="outlined"
              />
            </div>
            )}
             {/*(this.state.typeSelection=='Journal article (print/online)') && (  
            <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Volume/Issue Number"
                margin="dense"
                value={citation.attributes.volumeNo || ''}
                onChange={(event) => this.onChange('attributes.volumeNo', event.target.value)}
                variant="outlined"
              />
            </div>
             )*/<div></div>} 
            {(this.state.typeSelection=='Text (other)' || this.state.typeSelection=='Text (anthology/collection)' 
            || this.state.typeSelection=='Press article (print)' || this.state.typeSelection=='Journal article (print/online)') && (  
            <div className={classes.field}>
            <TextField
                className={classes.textField}
                label="Volume/Chapter/Page Number"
                margin="dense"
                value={citation.attributes.pageNo || ''}
                onChange={(event) => this.onChange('attributes.pageNo', event.target.value)}
                variant="outlined"
              />
            </div>
            )}
            </div>
            )}  
            </div>
          </form>
        </PortletContent>
      </Portlet>
    );
  }

  renderSourceForm() {
    const { citation, typeSelection } = this.state;
    
    /*switch(citation.attributes.sourceType) {
      case 'books': 
        this.state.citation.relationships.book = undefined;
        this.state.citation.relationships.periodical = undefined;
        this.state.citation.relationships.website = undefined;
        this.state.citation.relationships.utterance = undefined;
      case 'periodicals':
         this.state.citation.relationships.periodical = { attributes: {} };
         this.state.citation.relationships.book = null;
         this.state.citation.relationships.website = null;
         this.state.citation.relationships.utterance = null;
      case 'websites':
         this.state.citation.relationships.website = { attributes: {} };
         this.state.citation.relationships.periodical = null;
         this.state.citation.relationships.book = null;
         this.state.citation.relationships.utterance = null;
      case 'utterances': 
        this.state.citation.relationships.utterance = { attributes: {} };
        this.state.citation.relationships.periodical = null;
        this.state.citation.relationships.website = null;
        this.state.citation.relationships.book = null;
    } */

    switch(citation.attributes.sourceType) {
      case 'books': return <Book book={citation.relationships.book} citation={citation} type={typeSelection} updateTypeSelection={this.updateTypeSelection.bind(this)}/>;
      case 'periodicals': return <Periodical periodical={citation.relationships.periodical}  citation={citation} type={typeSelection} updateTypeSelection={this.updateTypeSelection.bind(this)}/>;
      case 'websites': return <Website website={citation.relationships.website}  citation={citation} type={typeSelection} updateTypeSelection={this.updateTypeSelection.bind(this)}/>;
      case 'utterances': return <Utterance utterance={citation.relationships.utterance}  citation={citation} type={typeSelection} updateTypeSelection={this.updateTypeSelection.bind(this)}/>;
    }
    
  }

  renderSourceDetails() {
    const { classes } = this.props;
    const { citation } = this.state;

    return (
      <Portlet>
        <PortletHeader>
          <PortletLabel title="Add New Source" />
        </PortletHeader>
        <PortletContent noPadding>
          <Paper square>
            <Tabs
              value={citation.attributes.sourceType}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, value) => this.updateTypeOptions('attributes.sourceType', value)}
            >
              <Tab className={classes.sourceTab} value={null} label="None"/>
              <Tab className={classes.sourceTab} value="books" label="Text" />
              <Tab className={classes.sourceTab} value="periodicals" label="Periodical" />
              <Tab className={classes.sourceTab} value="utterances" label="Spoken" />
              <Tab className={classes.sourceTab} value="websites" label="Website/Other" />
            </Tabs>
          </Paper>
          {this.renderSourceForm()}
        </PortletContent>
        {/*<PortletFooter className={classes.portletFooter}>
          <Button
            color="primary"
            variant="contained"
            className={classes.saveButton}
          >
            Save
          </Button>
        </PortletFooter>*/}
      </Portlet>
    );
  }

  onSearchChange(searchString) {
    this.setState({ searchString });
   // console.log(searchString);
  }

  onSearchChangeP(searchStringP) {
    this.setState({ searchStringP });
   // console.log(searchString);
  }

  onSearchChangeU(searchStringU) {
    this.setState({ searchStringU });
   // console.log(searchString);
  }

  onSearchChangeW(searchStringW) {
    this.setState({ searchStringW });
   // console.log(searchString);
  }

  setMinChars(value){
    this.setState({minCharSearch: value})
  }

  toggleLegacy(){
    const {includeLegacyCheck} = this.state;
    this.setState({includeLegacyCheck:!includeLegacyCheck});
  }

renderSearchDetails(){
  const { classes, updating } = this.props;
  const { sources, searchString, searchStringP, includeLegacyCheck } = this.state;

  return(
    <div>
    {(!this.state.previewClick) && (  
    <div>
    <Portlet>
    <PortletHeader>
      <PortletLabel title="Find Text" />
    </PortletHeader>
    <PortletContent>
      <div className={classes.row} style={{display:"flex"}} >
      <div style={{width:"80%", paddingTop:"6px"}}>
      <SearchInput className={classes.searchInput} value={this.state.searchString} placeholder="Search" onChange={event => this.onSearchChange(event.target.value)}/>
      </div>  
      <div style={{width:"70px", paddingLeft:"5px"}}>
              <TextField
                className={classes.textField}
                label="Min Char Search"
                margin="dense"
                select
                SelectProps={{ native: true }}
                value={this.state.minCharSearch || ''}
                onChange={(event) => this.setMinChars(event.target.value)}
                variant="outlined">
                {this.state.minCharOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
      <div style={{height:"20%", marginTop:"14px", paddingLeft:"5px"}} >
      <Button onClick={event => this.onSearchChange((this.state.searchString + 'ȝ'))} fullWidth='true' color="primary" size="small" variant="outlined">Add ȝ </Button>
      </div>
      <div style={{width:"30%", marginTop:"7px", paddingLeft:"5px"}} >
      <FormControlLabel
            control={
              <Checkbox
                checked={includeLegacyCheck}
                onChange={(event) => this.toggleLegacy()}
                name="includeLegacyCheck"
                color="primary"
              />
            }
            label="Include Legacy"
          />
      </div>
      </div>
      <div className={classes.content}>
      <div><br></br></div>  
      {this.renderBook()}
      </div>
    </PortletContent>
    </Portlet>
    <div><br></br></div>
    <Portlet>
    <PortletHeader>
      <PortletLabel title="Find Periodical" />
    </PortletHeader>
    <PortletContent>
      <div className={classes.row} style={{display:"flex"}} >
        <SearchInput className={classes.searchInput} value={this.state.searchStringP} placeholder="Search" onChange={event => this.onSearchChangeP(event.target.value)}/>
        <div style={{width:"70px", paddingLeft:"5px"}}>
                <TextField
                  className={classes.textField}
                  label="Min Char Search"
                  margin="dense"
                  select
                  SelectProps={{ native: true }}
                  value={this.state.minCharSearch || ''}
                  onChange={(event) => this.setMinChars(event.target.value)}
                  variant="outlined">
                  {this.state.minCharOptions.map(option => (
                    <option
                      key={option.value}
                      value={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </TextField>
              </div>
        <div style={{width:"30%", marginTop:"7px", paddingLeft:"5px"}} >
        <FormControlLabel
              control={
                <Checkbox
                  checked={includeLegacyCheck}
                  onChange={(event) => this.toggleLegacy()}
                  name="includeLegacyCheck"
                  color="primary"
                />
              }
              label="Include Legacy"
            />
        </div>
      </div>
      <div className={classes.content}>
      <div><br></br></div>  
      {this.renderPeriodicals()}
      </div>
    </PortletContent>
    </Portlet>    
    <div><br></br></div>
    <Portlet>
    <PortletHeader>
      <PortletLabel title="Find Utterance" />
    </PortletHeader>
    <PortletContent>
    <div className={classes.row} style={{display:"flex"}} >
      <SearchInput className={classes.searchInput} value={this.state.searchStringU} placeholder="Search" onChange={event => this.onSearchChangeU(event.target.value)}/>
      <div style={{width:"70px", paddingLeft:"5px"}}>
              <TextField
                className={classes.textField}
                label="Min Char Search"
                margin="dense"
                select
                SelectProps={{ native: true }}
                value={this.state.minCharSearch || ''}
                onChange={(event) => this.setMinChars(event.target.value)}
                variant="outlined">
                {this.state.minCharOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
      <div style={{width:"30%", marginTop:"7px", paddingLeft:"5px"}} >
      <FormControlLabel
            control={
              <Checkbox
                checked={includeLegacyCheck}
                onChange={(event) => this.toggleLegacy()}
                name="includeLegacyCheck"
                color="primary"
              />
            }
            label="Include Legacy"
          />
      </div>
      </div>
      <div className={classes.content}>
      <div><br></br></div>  
      {this.renderUtterances()}
      </div>
    </PortletContent>
    </Portlet>
    <div><br></br></div>
    <Portlet>
    <PortletHeader>
      <PortletLabel title="Find Website" />
    </PortletHeader>
    <PortletContent>
    <div className={classes.row} style={{display:"flex"}} >
      <SearchInput className={classes.searchInput} value={this.state.searchStringW} placeholder="Search" onChange={event => this.onSearchChangeW(event.target.value)}/>
      <div style={{width:"70px", paddingLeft:"5px"}}>
              <TextField
                className={classes.textField}
                label="Min Char Search"
                margin="dense"
                select
                SelectProps={{ native: true }}
                value={this.state.minCharSearch || ''}
                onChange={(event) => this.setMinChars(event.target.value)}
                variant="outlined">
                {this.state.minCharOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </TextField>
            </div>
      <div style={{width:"30%", marginTop:"7px", paddingLeft:"5px"}} >
      <FormControlLabel
            control={
              <Checkbox
                checked={includeLegacyCheck}
                onChange={(event) => this.toggleLegacy()}
                name="includeLegacyCheck"
                color="primary"
              />
            }
            label="Include Legacy"
          />
      </div>
      </div>
      <div className={classes.content}>
      <div><br></br></div>  
      {this.renderWebsites()}
      </div>
    </PortletContent>
    </Portlet>
    </div>
    )}
    </div>
  );
  }

  renderBook(){
    const { classes }  = this.props;
    const { isLoading, citation, books, periodicals, searchString, minCharSearch, includeLegacyCheck } = this.state;
  
    if (isLoading) {
      return (
        <div className={classes.progressWIrapper}>
          <CircularProgress />
        </div>
      );
    }
  
    //console.log(citations);
    let visibleBooks = []
    if(includeLegacyCheck){
      visibleBooks = books.filter(book => book[1]!=null && book[1].toLowerCase().includes(searchString.toLowerCase()));
    } else {
      visibleBooks = books.filter(book => book[1]!=null && book[2]!=true && book[1].toLowerCase().includes(searchString.toLowerCase()));
    }

    let type = "book";
    if (visibleBooks.length === 0 || searchString.length<1 || searchString.length < minCharSearch ) {
      return (
        <Typography variant="h6"> No sources to display</Typography>
      );
    }
    let displayedBooks = visibleBooks.splice(0,100)
    return (
      displayedBooks.map(book => (
        <SourcesCard key={book[0]} citation={citation} type={type} id={book[0]} source={book[1]}
        setSourceType={this.setTypeOptions.bind(this)}  onSearchChange={this.onSearchChange.bind(this)} 
        renderSourcePreview={this.renderSourcePreview.bind(this)} previewClicked={this.previewClicked.bind(this)}/>
      ))
      );
    
        
  }  

  renderPeriodicals(){
    const { classes }  = this.props;
    const { isLoading, citation, periodicals, searchStringP, minCharSearch, includeLegacyCheck } = this.state;
  
    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    let visiblePeriodicals = []
    if(includeLegacyCheck){
      visiblePeriodicals = periodicals.filter(periodical => periodical[1]!=null && periodical[1].toLowerCase().includes(searchStringP.toLowerCase()));
    } else {
      visiblePeriodicals = periodicals.filter(periodical => periodical[1]!=null && periodical[2]!=true && periodical[1].toLowerCase().includes(searchStringP.toLowerCase()));
    }

    let type = "periodical";
    if (visiblePeriodicals==0 || searchStringP.length<1 || searchStringP.length < minCharSearch) {
      return (
        <Typography variant="h6"> No sources to display</Typography>
      );
    }
    let displayedPeriodicals = visiblePeriodicals.splice(0,100)
    return (
      displayedPeriodicals.map(periodical => (
        <SourcesCard key={periodical[0]} citation={citation} type={type} id={periodical[0]} source={periodical[1]} 
        setSourceType={this.setTypeOptions.bind(this)}  onSearchChange={this.onSearchChangeP.bind(this)} 
        renderSourcePreview={this.renderSourcePreview.bind(this)} previewClicked={this.previewClicked.bind(this)}/>
      ))
      );
    
  
  }  

  renderUtterances(){
    const { classes }  = this.props;
    const { isLoading, citation, utterances, searchStringU, minCharSearch, includeLegacyCheck } = this.state;
  
    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    let visibleUtterances = []
    if(includeLegacyCheck){
      visibleUtterances = utterances.filter(utterance => utterance[1]!=null && utterance[1].toLowerCase().includes(searchStringU.toLowerCase()));
    } else {
      visibleUtterances = utterances.filter(utterance => utterance[1]!=null && utterance[2]!=true && utterance[1].toLowerCase().includes(searchStringU.toLowerCase()));
    }

    let type = "utterance";
    if (visibleUtterances==0 || searchStringU.length<1 || searchStringU.length < minCharSearch) {
      return (
        <Typography variant="h6"> No sources to display</Typography>
      );
    }
    let displayedUtterances = visibleUtterances.splice(0,100)
    return (
      displayedUtterances.map(utterance => (
        <SourcesCard key={utterance[0]}  citation={citation} type={type} id={utterance[0]} source={utterance[1]}
        setSourceType={this.setTypeOptions.bind(this)}  onSearchChange={this.onSearchChangeU.bind(this)}
        renderSourcePreview={this.renderSourcePreview.bind(this)} previewClicked={this.previewClicked.bind(this)}/>
      ))
      );
    
  
  }  

  renderWebsites(){
    const { classes }  = this.props;
    const { isLoading, citation, websites, searchStringW, sourceTypeOptions, minCharSearch, includeLegacyCheck} = this.state;
  
    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }
    let visibleWebsites = []
    if(includeLegacyCheck){
      visibleWebsites = websites.filter(website => website[1]!=null && website[1].toLowerCase().includes(searchStringW.toLowerCase()));
    } else {
      visibleWebsites = websites.filter(website => website[1]!=null && website[2]!=true && website[1].toLowerCase().includes(searchStringW.toLowerCase()));
    }

    let type = "website";
    if (visibleWebsites==0 || searchStringW.length<1 || searchStringW.length < minCharSearch) {
      return (
        <Typography variant="h6"> No sources to display</Typography>
      );
    }
    let displayedWebsites = visibleWebsites.splice(0,100)
    return (
      displayedWebsites.map(website => (
        <SourcesCard  key={website[0]}  citation={citation} type={type} id={website[0]} source={website[1]}
        setSourceType={this.setTypeOptions.bind(this)}  onSearchChange={this.onSearchChangeW.bind(this)}
        renderSourcePreview={this.renderSourcePreview.bind(this)} previewClicked={this.previewClicked.bind(this)}/>
      ))
      );
    
  
  } 

  renderSourceInfo(){
      if(!this.state.buttonClick){
        return(
          <div>
           {this.renderSearchDetails()}
          </div>
        );
      } else {
        return(
          <div>
           {this.renderSourceDetails()}
          </div>
        );
      }
  }

  buttonClicked(){
  this.state.buttonClick = !this.state.buttonClick;
  this.state.citation.attributes.sourceType = null;
  this.state.citation.attributes.bookId = undefined;
  this.state.citation.attributes.periodicalId = undefined;
  this.state.citation.attributes.utteranceId = undefined;
  this.state.citation.attributes.websiteId = undefined;
  if(this.state.citation.relationships){
    this.state.citation.relationships.book = undefined;
    this.state.citation.relationships.periodical = undefined;
    this.state.citation.relationships.utterance = undefined;
    this.state.citation.relationships.website = undefined;
  }

  this.forceUpdate();
  }

  previewClicked(){
    this.state.previewClick = !this.state.previewClick;
    this.forceUpdate();
    if(!this.state.isLoading){
      this.setState({someChange:true});
    }
  }

  previewClickedButton(){
      this.state.previewClick = !this.state.previewClick;
      if(!this.state.updating){
        this.state.citation.attributes.sourceType = null;
        this.state.citation.attributes.bookId = undefined;
        this.state.citation.attributes.periodicalId = undefined;
        this.state.citation.attributes.utteranceId = undefined;
        this.state.citation.attributes.websiteId = undefined;
      }
      this.forceUpdate();
      }
  

  text(){
    if(!this.state.buttonClick){
      return(
        <div>
         Add New Source
        </div>
      );
    } else {
      return(
        <div>
         Select Existing Source
        </div>
      );
    }
  }

  renderSourcePreview(){
    const {classes, updating} = this.props;
    return(
    <div>
    {(this.state.previewClick) && (  
    <Portlet>
    <PortletHeader>
      <PortletLabel title="Source Preview" />
    </PortletHeader>
    <PortletContent>
      <div className={classes.content}> 
      {this.renderIndividualSource()}
      </div>
    </PortletContent>
    </Portlet>
    )}
    </div>);
  }

  renderIndividualSource(){
    const {classes} = this.props;
    const {sources} = this.state;
    var book = {}; 
    var periodical = {};
    var utterance = {};
    var website = {};
    if(this.state.citation.attributes.sourceType=="books"){
      var i;
      if(this.state.citation.relationships.book!=null){
        book = this.state.citation.relationships.book.attributes;
      }
      /*if(sources != undefined){
        for(i=0;i<Object.values(sources[0].attributes).length;i++){
            let books = Object.values(sources[0].attributes);
            if(books[i].id==this.state.citation.attributes.bookId){
              book = books[i];
            }
        }
      } */
      this.state.typeSelection = book.type;
      if(book.type=='Text (other)'){
        return(
          <Typography className={classes.description} variant="body1">
          Type: {book.type} <br></br>  
          Title:  {book.title}<br></br>
          DSL Ref ID:  {book.dslBibliographyRef}<br></br>
          Author: {book.author}<br></br>
          ISBN: {book.isbn} <br></br>
          Edition: {book.edition} <br></br>
          Publisher: {book.publisher}<br></br>
          Published: {book.yearPublished} <br></br>
          Place: {book.placePublished} <br></br>
          Notes: {book.notes} <br></br>
          Legacy Notes: {book.legacyNotes}
        </Typography>
        );
      } else {
        return(
          <Typography className={classes.description} variant="body1">
          Type: {book.type} <br></br>  
          Title:  {book.title}<br></br>
          DSL Ref ID:  {book.dslBibliographyRef}<br></br>
          Author: {book.author}<br></br>
          Editor: {book.editor}<br></br>
          ISBN: {book.isbn} <br></br>
          Edition: {book.edition} <br></br>
          Publisher: {book.publisher}<br></br>
          Published: {book.yearPublished} <br></br>
          Place: {book.placePublished} <br></br>
          Notes: {book.notes} <br></br>
          Legacy Notes: {book.legacyNotes}
        </Typography>
        );
      }
    } else if(this.state.citation.attributes.sourceType=="periodicals"){
      if(this.state.citation.relationships.periodical!=null){
        periodical = this.state.citation.relationships.periodical.attributes;
      }
       /* var i;
        if(sources != undefined){
          for(i=0;i<Object.values(sources[1].attributes).length;i++){
              let periodicals = Object.values(sources[1].attributes);
              if(periodicals[i].id==this.state.citation.attributes.periodicalId){
                periodical = periodicals[i];
              }
          }
        } */
        this.state.typeSelection = periodical.type;
      return(
        <Typography className={classes.description} variant="body1">
        Type: {periodical.type} <br></br>  
        Title: {periodical.title}<br></br>
        DSL Ref ID:  {periodical.dslBibliographyRef}<br></br>
        Notes:  {periodical.notes} <br></br>
        Legacy Notes: {periodical.legacyNotes}
      </Typography>
      );
    } else if(this.state.citation.attributes.sourceType=="utterances"){
      if(this.state.citation.relationships.utterance!=null){
        utterance = this.state.citation.relationships.utterance.attributes;
      }     
      /* 
      var i;
        if(sources != undefined){
          for(i=0;i<Object.values(sources[2].attributes).length;i++){
              let utterances = Object.values(sources[2].attributes);
              if(utterances[i].id==this.state.citation.attributes.utteranceId){
                utterance = utterances[i];
              }
          }
        } */
        this.state.typeSelection = utterance.type;
      return(
        <Typography className={classes.description} variant="body1">
        Type: {utterance.type} <br></br>    
        Site Name: {utterance.siteName}<br></br>
        DSL Ref ID:  {utterance.dslBibliographyRef}<br></br>
      </Typography>
      );
    } else if(this.state.citation.attributes.sourceType=="websites"){
      if(this.state.citation.relationships.website!=null){
        website = this.state.citation.relationships.website.attributes;
      }     
      /*var i;
      if(sources != undefined){
        for(i=0;i<Object.values(sources[3].attributes).length;i++){
            let websites = Object.values(sources[3].attributes);
            if(websites[i].id==this.state.citation.attributes.websiteId){
              website = websites[i];
            }
        }
      } */
      this.state.typeSelection = website.type;
      return(
        <Typography className={classes.description} variant="body1">
        Type: {website.type} <br></br>   
        Site Name: {website.siteName}<br></br>
        DSL Ref ID:  {website.dslBibliographyRef}<br></br>
        Legacy Notes: {website.legacyNotes}
      </Typography>
      );
    }
  }

url(){
  if(this.state.citation.attributes.sourceType == 'books') {
    return(`/book/` + this.state.citation.attributes.bookId);
  } else if(this.state.citation.attributes.sourceType == 'periodicals') {
    return(`/periodical/` + this.state.citation.attributes.periodicalId);
  } else if(this.state.citation.attributes.sourceType == 'utterances') {
    return(`/utterance/` + this.state.citation.attributes.utteranceId);
  } else if(this.state.citation.attributes.sourceType == 'websites') {
    return(`/website/` + this.state.citation.attributes.websiteId);
  }
}

warnUser(e){
  if(this.state.someChange && !(this.isViewOnly())){
      if(!window.confirm("Are you sure you want to leave? Any unsaved data will be lost.")){
        e.preventDefault();
      }
  }
}

render() {
    const { classes } = this.props;
    const { isLoading, progressLabel } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress size={110} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
          </Box>
            <Typography variant="h5" component="div" color="textSecondary">{
              progressLabel
            }</Typography>
        </div>
      );
    }

    /*if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }*/

    return (
      <div className={classes.root}>
      <Link  onClick={event => this.warnUser(event)} to={this.state.url}> 
      <Button
              color="primary"
              variant="contained"
              className={classes.saveButton}
      >Back</Button>
      </Link> 
      <div><br></br></div>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            {this.renderSourceInfo()}
            {this.renderSourcePreview()}
            <div><br></br></div> 
            {(!this.props.updating && !this.state.previewClick) && (  
             <Button
                style={{marginRight: '5px'}}
                color="primary"
                variant="contained"
                className={classes.saveButton}
                onClick={event => this.buttonClicked()}
            >{this.text()}</Button> )}
            {(!this.state.previewClick && this.state.updating) && (  
             <Button
                style={{marginRight: '5px'}}
                color="primary"
                variant="contained"
                className={classes.saveButton}
                onClick={event => this.previewClicked()}
            >View Source</Button> )}
            {(this.state.previewClick) && (!this.isViewOnly()) && (  
            <Button
                style={{marginRight: '5px'}}
                color="primary"
                variant="contained"
                className={classes.saveButton}
                onClick={event => this.previewClickedButton()}
            >Search Again</Button> )}
            {(this.props.updating) && (!this.isViewOnly()) && (  
            <Link to={this.url()}>
             <Button
                style={{marginRight: '5px'}}
                color="primary"
                variant="contained"
                className={classes.saveButton}
            >Edit Source</Button> </Link>)}
            {(this.props.updating) && (this.isViewOnly()) && (  
            <Link to={this.url()}>
             <Button
                style={{marginRight: '5px'}}
                color="primary"
                variant="contained"
                className={classes.saveButton}
            >View Source Details</Button> </Link>)}
          </Grid>
          <Grid item lg={6} md={6} xl={6} xs={12}>
            {this.renderCitationDetails()}
            <br></br>
            {(!this.state.noHeadword) && (!this.isViewOnly()) && ( 
            <Button
            color="primary"
            variant="contained"
            className={classes.saveButton}
            onMouseDown={this.save.bind(this)}
          >Save Quotation</Button>
          )}
          {(this.state.noHeadword) && ( 
            <Button
            color="primary"
            variant="contained"
            className={classes.saveButton}
          >Please Select A Headword</Button>
          )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

EditCitation.propTypes = {
  citation: PropTypes.object.isRequired,
  onSave: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(EditCitation);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { Typography, Button, LinearProgress } from '@material-ui/core';
import { Portlet, PortletContent, PortletFooter } from 'components';
import styles from './styles';

class HeadwordProfile extends Component {
  //hasEntry = this.props.headword.relationships.entry;

  goToEntry() {
    //let entryId = this.props.headword.relationships.entry.id;
    //this.props.history.push(`/entries/${entryId}`);
  }

  goToEdit() {
    //this.props.history.push(`${this.props.headword.id}/edit`);
  }

  addCitation() {
   // this.props.history.push(`/citations/new/${this.props.headword.id}`);
  }

  render() {
    const { classes, className, source, isEditable, staticContext /*to avoid react error*/, ...rest } = this.props;

    const rootClassName = classNames(classes.root, className);

    return (
      <Portlet {...rest} className={rootClassName}>
        <PortletContent>
        <div><br></br></div>
          <div className={classes.details}>
            <div className={classes.info}>
              <Typography variant="h2"> {this.title(source)}</Typography>
              <Typography className={classes.locationText} variant="body1">
              </Typography>
            </div>
            {/*<Avatar className={classes.avatar} src="/images/avatars/avatar_1.png"/>*/}
          </div>
          <div><br></br></div>
        </PortletContent>

      </Portlet>
    );
  }

  title(source){
    if(source.attributes.title!=undefined){
      return( 
        source.attributes.title
      );
    } else if(source.attributes.siteName!=undefined){
      return(
        source.attributes.siteName
      )
    }
  }
}

HeadwordProfile.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  isEditable: PropTypes.bool,
  history: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles)
)(HeadwordProfile);

import api from 'services/api';

const getBook = async (bookId) => {
  return await api.get(`books/${bookId}`);
};

const updateBook = async (book) => {
  return await api.put(`books/${book.id}`, book);
};

const getPeriodical = async (periodicalId) => {
  return await api.get(`periodicals/${periodicalId}`);
};

const updatePeriodical = async (periodical) => {
  return await api.put(`periodicals/${periodical.id}`, periodical);
};

const getUtterance = async (utteranceId) => {
  return await api.get(`utterances/${utteranceId}`);
};

const updateUtterance = async (utterance) => {
  return await api.put(`utterances/${utterance.id}`, utterance);
};

const getWebsite = async (websiteId) => {
  return await api.get(`websites/${websiteId}`);
};

const updateWebsite = async (website) => {
  return await api.put(`websites/${website.id}`, website);
};





export default { getBook, getPeriodical, getUtterance, getWebsite, updateBook, updatePeriodical, updateUtterance, updateWebsite };

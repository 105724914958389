import _ from 'lodash';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { TextField, Button } from '@material-ui/core';

import {
  Portlet,
  PortletHeader,
  PortletLabel,
  PortletContent,
  PortletFooter
} from 'components';

let styles = theme => ({
  field: {
    margin: theme.spacing(3)
  },
  textField: {
    width: '420px',
    maxWidth: '100%',
    marginRight: theme.spacing(3)
  },
});

class Book extends Component {

  constructor(props) {
    super(props);
    this.state = {
      book: props.book,
      citation: props.citation,
      error: null,
      typeOptions: [
      { value: '', label: ''},
      { value: 'Text (other)', label: 'Text (other)' },
      { value: 'Text (anthology/collection)', label: 'Text (anthology/collection)' },
      ]
    };
  }


  onChange(path, value) {
    const { citation } = this.state;
    let book = this.state.book;
    _.set(book, path, this.fromViewValue(value));
    this.setState({ book });
    this.state.citation.relationships.book = book;
  }

  onChangeType(path, value) {
    const { citation } = this.state;
    let book = this.state.book;
    _.set(book, path, this.fromViewValue(value));
    this.setState({ book });
    this.state.citation.relationships.book = book;
    this.props.updateTypeSelection(value);
  }



  fromViewValue(value) {
    return value || null;
  }

  componentWillMount(){
    //if(this.props.book.attributes.title!=undefined){
  //      this.state.book.attributes.title = '';
   // } else {
    this.state.book.attributes = {};
   // }
  }

  render() {
    const { classes, book } = this.props;
    return (
      <form autoComplete="off" noValidate>
        <div className={classes.field}>
        <TextField
            className={classes.textField}
            label="Type"
            margin="dense"
            required
            select
            SelectProps={{ native: true }}
            value={book.attributes.type || ''}
            onChange={(event) => this.onChangeType('attributes.type', event.target.value)}
            variant="outlined">
            {this.state.typeOptions.map(option => (
              <option
                key={option.value}
                value={option.value}
              >
                {option.label}
              </option>
            ))}
          </TextField>
        <div className={classes.row} style={{display:"flex"}} >
          <TextField
            className={classes.textField}
            label="Title"
            margin="dense"
            value={book.attributes.title || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.title', event.target.value)}
          />
          <div style={{width: '10%', marginRight:"5px", marginTop:"12px"}} >
            <Button onClick={event => this.onChange('attributes.title', (book.attributes.title + 'ȝ'))} fullWidth='true' color="primary" size="small" variant="outlined">Add ȝ </Button>
          </div>
          </div>
          <TextField
            className={classes.textField}
            label="DSL Bibliographical Reference ID"
            margin="dense"
            value={book.attributes.dslBibliographyRef || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.dslBibliographyRef', event.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Author(s) - *format Author 1, Author 2,...Author N*"
            margin="dense"
            value={book.attributes.author || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.author', event.target.value)}
          />
           {(this.props.type == 'Text (anthology/collection)') && (
           <TextField
            className={classes.textField}
            label="Editor(s) - *format Editor 1, Editor 2,...Editor N*"
            margin="dense"
            value={book.attributes.editor || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.editor', event.target.value)}
          />
           )}
          <TextField
            className={classes.textField}
            label="ISBN"
            margin="dense"
            value={book.attributes.isbn || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.isbn', event.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Edition"
            margin="dense"
            value={book.attributes.edition || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.edition', event.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Publisher"
            margin="dense"
            value={book.attributes.publisher || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.publisher', event.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Published (YYYY)"
            margin="dense"
            value={book.attributes.yearPublished || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.yearPublished', event.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Place"
            margin="dense"
            value={book.attributes.placePublished || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.placePublished', event.target.value)}
          />
          <TextField
            className={classes.textField}
            label="Notes"
            margin="dense"
            value={book.attributes.notes || ''}
            variant="outlined"
            onChange={(event) => this.onChange('attributes.notes', event.target.value)}
          />
        </div>
      </form>
    );
  }
}

Book.propTypes = {
  citation: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  book: PropTypes.object
};

Book.defaultProps = {
  book: {
    attributes: {},
    relationships: {}
  }
};

export default withStyles(styles)(Book);

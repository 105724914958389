import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { Paper } from 'components';

import styles from './styles';
import citations from 'services/citations';
import { BorderAll } from '@material-ui/icons';

class CitationCard extends Component {
  render() {
    const { classes, className, book} = this.props; //citation, citations, books, periodicals, utterances, websites

    const rootClassName = classNames(classes.root, className);

    return (
        <Paper className={rootClassName}>
          <div className={classes.details}>
            <Typography className={classes.title} variant="h4">
              Keyword Research Decision - {book.krDecision}
            </Typography>
            <Typography className={classes.description} variant="body1">
              {book.title}
            </Typography>
          </div>
        </Paper>
    );
  }

  text(citation){
    if(citation.attributes.bookId!=null){
      var i;
      for(i = 0; i<this.props.books.length; i++){
          if(citation.attributes.bookId==this.props.books[i][0]){
            return(
              this.props.books[i][1]
            );
          }
      }
    } else if(citation.attributes.periodicalId!=null){
      var i;
      for(i = 0; i<this.props.periodicals.length; i++){
          if(citation.attributes.periodicalId==this.props.periodicals[i][0]){
            return(
              this.props.periodicals[i][1]
            );
          }
      }
    } else if(citation.attributes.utteranceId!=null) {
      var i;
      for(i = 0; i<this.props.utterance.length; i++){
          if(citation.attributes.utteranceId==this.props.utterance[i][0]){
            return(
              this.props.utterances[i][1]
            );
          }
      }
    } else if(citation.attributes.websiteId!=null){
      var i;
      for(i = 0; i<this.props.websites.length; i++){
          if(citation.attributes.websiteId==this.props.websites[i][0]){
            return(
              this.props.websites[i][1]
            );
          }
      }
    }
  }
}

CitationCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired
  //citation: PropTypes.object.isRequired
};

export default withStyles(styles)(CitationCard);

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { DisplayMode, SearchInput } from 'components';
import {
  Button,
  IconButton,
  CircularProgress,
  Typography, 
  Box
} from '@material-ui/core';
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon
} from '@material-ui/icons';
import { Dashboard as DashboardLayout } from 'layouts';
import service from 'services/headwords';
import citation from 'services/citations';
import BookCard from './components/BookCard';
import PeriodicalCard from './components/PeriodicalCard';
import UtteranceCard from './components/UtteranceCard';
import WebsiteCard from './components/WebsiteCard';
import { state as appState } from 'App';
import { TextField } from '@material-ui/core';
import styles from './styles';
import headwords from 'services/headwords';
import ac from 'services/allCitations';
import periodical from './UpdatePeriodical/components/Sources/periodical';

class Sources extends Component {
  signal = true;

  state = {
    isLoading: false,
    limit: 6,
    sources: [],
    searchString: '',
    selectedLetter: null,
    error: null,
    citationCount: {},
    user: appState.get('user'),
    sourceOptions: [
      //{ value: 'All', label: 'All'},
      { value: 'Texts', label: 'Texts' },
      { value: 'Periodicals', label: 'Periodicals' },
      { value: 'Utterances', label: 'Utterances' },
      { value: 'Websites', label: 'Websites' }
    ],
    sourceType: 'Texts',
    progress: 0,
    progressLabel: "",
    visibleBooks:[],
    visiblePeriodicals:[],
    visibleUtterances: [],
    visibleWebsites: [],
    currPosition: 0,
    currDisplayed: []
  };

  async getCitations(limit) {
    try {
      this.setState({ isLoading: true });
      let prevSearch = localStorage.getItem('SourceSearchString'); 
      let prevType = localStorage.getItem('SourceST');
      if(prevSearch!=null){
        this.setState({searchString:prevSearch});
      }

      if(prevType!=null){
        this.setState({sourceType:prevType});
        if(prevType == 'Texts'){
          try {
            this.setState({progress: 0, progressLabel: "getting texts"})
            const sources = await citation.getBooks();
            this.setState({progress: 70, progressLabel: "getting texts' citations"})
            this.state.citationCount = await ac.getBookCitationsCount();
      
            if (this.signal) {
              this.setState({
                isLoading: false,
                sources: sources,
              });
            }
          } catch (error) {
            if (this.signal) {
              this.setState({
                isLoading: false,
                error
              });
            }
          }
        } else if(prevType == 'Periodicals'){
          try {
            this.setState({progress: 0, progressLabel: "getting periodicals"})
            const sources = await citation.getPeriodicals();
            this.setState({progress: 70, progressLabel: "getting periodicals' citations"})
            this.state.citationCount = await ac.getPeriodicalCitationsCount();
      
            if (this.signal) {
              this.setState({
                isLoading: false,
                sources: sources,
              });
            }
          } catch (error) {
            if (this.signal) {
              this.setState({
                isLoading: false,
                error
              });
            }
          }
        } else if(prevType == 'Utterances'){
          try {
            this.setState({progress: 0, progressLabel: "getting utterances"})
            const sources = await citation.getUtterances();
            this.setState({progress: 70, progressLabel: "getting utterances' citations"})
            this.state.citationCount = await ac.getUtteranceCitationsCount();
      
            if (this.signal) {
              this.setState({
                isLoading: false,
                sources: sources,
              });
            }
          } catch (error) {
            if (this.signal) {
              this.setState({
                isLoading: false,
                error
              });
            }
          }

        } else if(prevType == 'Websites'){
          try {
            this.setState({progress: 0, progressLabel: "getting websites"})
            const sources = await citation.getWebsites();
            this.setState({progress: 70, progressLabel: "getting websites' citations"})
            this.state.citationCount = await ac.getWebsiteCitationsCount();
      
            if (this.signal) {
              this.setState({
                isLoading: false,
                sources: sources,
              });
            }
          } catch (error) {
            if (this.signal) {
              this.setState({
                isLoading: false,
                error
              });
            }
          }
        }
      } else {
        this.setState({progress: 0, progressLabel: "getting texts"})
        const sources = await citation.getBooks();
        this.setState({progress: 70, progressLabel: "getting texts' citations"})
        this.state.citationCount = await ac.getBookCitationsCount();

        if (this.signal) {
          this.setState({
            isLoading: false,
            sources: sources,
            limit
          });
        }
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  selectLetter(selectedLetter) {
    this.setState({ selectedLetter });
  }

  
  onSearchChange(searchString) {
    this.setState({ searchString });
    localStorage.setItem('SourceSearchString', searchString);
   // console.log(searchString);
  }

  componentWillMount() {
    this.signal = true;

    const { limit } = this.state;

    this.getCitations(limit);

    this._unwatch = appState.watch('user', user => {
      this.setState({ user });
    });
  }

  componentWillUnmount() {
    this._unwatch();
    this.signal = false;
  }

  canEdit() {
    return this.state.user && this.state.user.attributes.roles.includes('editor');
  }

  renderBooks() {
    const { classes, selectedUsers  } = this.props;
    const { isLoading, sources, selectedLetter, searchString, currPosition } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    this.state.visibleBooks = Object.values(sources.attributes).filter(book => book.title!=null && book.title.toLowerCase().includes(searchString.toLowerCase()));
    this.state.visibleBooks.sort((a, b) =>  a.title.localeCompare(b.title));  
    

    if (this.state.visibleBooks.length === 0) {
      return (
        <div>
        <Typography variant="h6">No books to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleBooks.length > 249) {
      if(this.state.visibleBooks.length > currPosition+250){
        this.state.currDisplayed = this.state.visibleBooks.slice(currPosition, currPosition + 250);
      } else {
        this.state.currDisplayed = this.state.visibleBooks.slice(currPosition);
      }
      return (
        this.state.currDisplayed.map(book => (
          <BookCard key={book.id} book={book} citations={this.state.citationCount}/>
        ))
        );  
    } else {
      return (
        this.state.visibleBooks.map(book => (
          <BookCard key={book.id} book={book} citations={this.state.citationCount}/>
        ))
      );  
    }
  }

  renderPeriodicals(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, sources, selectedLetter, searchString, currPosition } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    this.state.visiblePeriodicals = Object.values(sources.attributes).filter(periodical => periodical.title!=null && periodical.title.toLowerCase().includes(searchString.toLowerCase()));
    this.state.visiblePeriodicals.sort((a, b) =>  a.title.localeCompare(b.title));   

    if (this.state.visiblePeriodicals.length === 0) {
      return (
        <div>
        <Typography variant="h6">No periodicals to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visiblePeriodicals.length > 249) {
      if(this.state.visiblePeriodicals.length > currPosition+250){
        this.state.currDisplayed = this.state.visiblePeriodicals.slice(currPosition, currPosition + 250);
      } else {
        this.state.currDisplayed = this.state.visiblePeriodicals.slice(currPosition);
      }
      return (
        this.state.currDisplayed.map(periodical => (
          <PeriodicalCard key={periodical.id} periodical={periodical} citations={this.state.citationCount} />
        ))
        );  
    } else {
      return (
        this.state.visiblePeriodicals.map(periodical => (
          <PeriodicalCard key={periodical.id} periodical={periodical} citations={this.state.citationCount} />
        ))
      );  
    }
  }

  renderUtterances(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, sources, selectedLetter, searchString, currPosition } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    this.state.visibleUtterances = Object.values(sources.attributes).filter(utterance => utterance.siteName!=null && utterance.siteName.toLowerCase().includes(searchString.toLowerCase()));
    this.state.visibleUtterances.sort((a, b) =>  a.siteName.localeCompare(b.siteName));    

    if (this.state.visibleUtterances.length === 0) {
      return (
        <div>
        <Typography variant="h6">No utterances to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleUtterances.length > 249) {
      if(this.state.visibleUtterances.length > currPosition+250){
        this.state.currDisplayed = this.state.visibleUtterances.slice(currPosition, currPosition + 250);
      } else {
        this.state.currDisplayed = this.state.visibleUtterances.slice(currPosition);
      }
      return (
        this.state.currDisplayed.map(utterance => (
          <UtteranceCard key={utterance.id} utterance={utterance} citations={this.state.citationCount} />
        ))
        );  
    } else {
      return (
        this.state.visibleUtterances.map(utterance => (
          <UtteranceCard key={utterance.id} utterance={utterance} citations={this.state.citationCount} />
        ))
      ); 
    }
  }

  renderWebsites(){
    const { classes, selectedUsers  } = this.props;
    const { isLoading, sources, selectedLetter, searchString, currPosition } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress />
        </div>
      );
    }

    this.state.visibleWebsites = Object.values(sources.attributes).filter(website => website.siteName!=null && website.siteName.toLowerCase().includes(searchString.toLowerCase()));
    this.state.visibleWebsites.sort((a, b) =>  a.siteName.localeCompare(b.siteName));   

    if (this.state.visibleWebsites.length === 0) {
      return (
        <div>
        <Typography variant="h6">No websites to display</Typography>
        <br></br>
        </div>
      );
    }
    if(this.state.visibleWebsites.length > 249) {
      if(this.state.visibleWebsites.length > currPosition+250){
        this.state.currDisplayed = this.state.visibleWebsites.slice(currPosition, currPosition + 250);
      } else {
        this.state.currDisplayed = this.state.visibleWebsites.slice(currPosition);
      }
      return (
        this.state.currDisplayed.map(website => (
          <WebsiteCard key={website.id} website={website} citations={this.state.citationCount} />
        ))
        );  
    } else {
      return (
        this.state.visibleWebsites.map(website => (
          <WebsiteCard key={website.id} website={website} citations={this.state.citationCount} />
        ))
      );
    }  
  }

  async onChange(value){
    localStorage.setItem('SourceST', value);
    this.setState({isLoading: true});
    if(value == 'Texts'){
      try {
        this.setState({progress: 0, progressLabel: "getting texts"})
        const sources = await citation.getBooks();
        this.setState({progress: 70, progressLabel: "getting texts' citations"})
        this.state.citationCount = await ac.getBookCitationsCount();
  
        if (this.signal) {
          this.setState({
            isLoading: false,
            sources: sources,
          });
        }
      } catch (error) {
        if (this.signal) {
          this.setState({
            isLoading: false,
            error
          });
        }
      }
    } else if(value == 'Periodicals'){
      try {
        this.setState({progress: 0, progressLabel: "getting periodicals"})
        const sources = await citation.getPeriodicals();
        this.setState({progress: 70, progressLabel: "getting periodicals' citations"})
        this.state.citationCount = await ac.getPeriodicalCitationsCount();
  
        if (this.signal) {
          this.setState({
            isLoading: false,
            sources: sources,
          });
        }
      } catch (error) {
        if (this.signal) {
          this.setState({
            isLoading: false,
            error
          });
        }
      }
    } else if(value == 'Utterances'){
      try {
        this.setState({progress: 0, progressLabel: "getting utterances"})
        const sources = await citation.getUtterances();
        this.setState({progress: 70, progressLabel: "getting utterances' citations"})
        this.state.citationCount = await ac.getUtteranceCitationsCount();
  
        if (this.signal) {
          this.setState({
            isLoading: false,
            sources: sources,
          });
        }
      } catch (error) {
        if (this.signal) {
          this.setState({
            isLoading: false,
            error
          });
        }
      }

    } else if(value == 'Websites'){
      try {
        this.setState({progress: 0, progressLabel: "getting websites"})
        const sources = await citation.getWebsites();
        this.setState({progress: 70, progressLabel: "getting websites' citations"})
        this.state.citationCount = await ac.getWebsiteCitationsCount();
  
        if (this.signal) {
          this.setState({
            isLoading: false,
            sources: sources,
          });
        }
      } catch (error) {
        if (this.signal) {
          this.setState({
            isLoading: false,
            error
          });
        }
      }
    }
    this.state.sourceType = value;
    this.forceUpdate();
  }

  renderAllSources(){
    const { classes } = this.props;
    const { progress, progressLabel, isLoading } = this.state;
    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress size={110} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
          </Box>
            <Typography variant="h5" component="div" color="textSecondary">{
              progressLabel
            }</Typography>
        </div>
      );
    }
    if(this.state.sourceType=='Texts'){
      return(
        <div>
        <Typography
             className={classes.title}
             variant="h4"
           >
             Texts
           </Typography>
           <br></br>
           {this.renderBooks()}
        </div>
      );
    } else if(this.state.sourceType=='Periodicals'){
      return(
        <div>
        <Typography
        className={classes.title}
        variant="h4"
      >
        Periodicals
      </Typography>
      <br></br>
      {this.renderPeriodicals()}
      </div>
      );
    } else if(this.state.sourceType=='Utterances'){
      return(
        <div>
         <Typography
          className={classes.title}
          variant="h4"
        >
          Utterances
        </Typography>
        <br></br>
        {this.renderUtterances()}
      </div>);
    } else if(this.state.sourceType=='Websites'){
      return(
          <div>
          <Typography
          className={classes.title}
          variant="h4"
        >
          Websites
        </Typography>
        <br></br>
        {this.renderWebsites()}
        </div>
      );
    } else if(this.state.sourceType=='All'){
    return(
      <div>
         <Typography
              className={classes.title}
              variant="h4"
            >
              Texts
            </Typography>
            <br></br>
            {this.renderBooks()}
            <Typography
              className={classes.title}
              variant="h4"
            >
              Periodicals
            </Typography>
            <br></br>
            {this.renderPeriodicals()}
            <Typography
              className={classes.title}
              variant="h4"
            >
              Utterances
            </Typography>
            <br></br>
            {this.renderUtterances()}
            <Typography
              className={classes.title}
              variant="h4"
            >
              Websites
            </Typography>
            <br></br>
            {this.renderWebsites()}
      </div>
    );
    } else {
      return(
        <div>
           <Typography
                className={classes.title}
                variant="h4"
              >
                Texts
              </Typography>
              <br></br>
              {this.renderBooks()}
              <Typography
                className={classes.title}
                variant="h4"
              >
                Periodicals
              </Typography>
              <br></br>
              {this.renderPeriodicals()}
              <Typography
                className={classes.title}
                variant="h4"
              >
                Utterances
              </Typography>
              <br></br>
              {this.renderUtterances()}
              <Typography
                className={classes.title}
                variant="h4"
              >
                Websites
              </Typography>
              <br></br>
              {this.renderWebsites()}
        </div>
      );
      }
  }

  showMore(){
    const { visibleBooks, visiblePeriodicals, visibleUtterances, visibleWebsites,
        currPosition, sourceType } = this.state;
    if(sourceType == "All"){
      this.setState({currPosition: currPosition + 250});
      window.scrollTo(0,0);
    } else if(sourceType == "Texts"){
      if(visibleBooks.length >= currPosition + 250){
        this.setState({currPosition: currPosition + 250});
        window.scrollTo(0,0);
      }
    } else if(sourceType == "Periodicals"){
      if(visiblePeriodicals.length >= currPosition + 250){
        this.setState({currPosition: currPosition + 250});
        window.scrollTo(0,0);
      }
    } else if(sourceType == "Utterances"){
      if(visibleUtterances.length >= currPosition + 250){
        this.setState({currPosition: currPosition + 250});
        window.scrollTo(0,0);
      }
    } else if(sourceType == "Websites"){
      if(visibleWebsites.length >= currPosition +250){
        this.setState({currPosition: currPosition + 250});
        window.scrollTo(0,0);
      }
    }
  }

  showLess(){
    const { currPosition } = this.state;
    if(currPosition - 250>=0){
      this.setState({currPosition: currPosition - 250});
      window.scrollTo(0,0)
    }
  }
  render() {
    const { classes } = this.props;
    const { selectedLetter, isLoading } = this.state;

    return (
      <DashboardLayout title="Sources Editor">
        <div className={classes.root}>
        <div><br></br></div>
        <div style={{width: '50%'}} className={classes.row}>
        <SearchInput className={classes.searchInput} value={this.state.searchString} placeholder="Search" onChange={event => this.onSearchChange(event.target.value)}/>
        <div style={{width: '5px'}}></div>
              <TextField
                className={classes.textField}
                label="Source Type"
                margin="dense"
                select
                SelectProps={{ native: true }}
                value={this.state.sourceType}
                onChange={(event) => this.onChange(event.target.value)}
                variant="outlined">
                {this.state.sourceOptions.map(option => (
                  <option
                    key={option.value}
                    value={option.value}
                  >
                    {option.label}
                  </option>
                ))}
                
              </TextField>
              <div style={{width: '10%', marginLeft:"5px"}} >
        <Button onClick={event => this.onSearchChange((this.state.searchString + 'ȝ'))} fullWidth='true' color="primary" size="small" variant="outlined">Add ȝ </Button>
        </div>
        </div>
          <div className={classes.content}>
          {this.renderAllSources()}
          {((!isLoading) && 
          <div style={{width: '30%'}} className={classes.row}>
          <Button onClick={event => this.showLess()} color="primary" size="small" variant="outlined">Display Previous 250 </Button>
          <div style={{width: '5%'}}></div>
          <Button onClick={event => this.showMore()} color="primary" size="small" variant="outlined">Display Next 250 </Button>
          </div>
          )}
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

Sources.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles)
)(Sources);

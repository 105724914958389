import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import deletion from 'services/deletion';
// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography, Button } from '@material-ui/core';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

class QuotationCard extends Component {
  render() {
    const { classes, className, citation, citations, keep } = this.props;

    const rootClassName = classNames(classes.root, className);

   // let citationsCount = citation.relationships.citations.count;

    return (

      <Paper className={rootClassName} onClick={event => this.chooseEntry(keep)}>
          <div className={classes.details}>
            <Typography
              className={classes.description}
              variant="body1"
            >
              
             {this.search()}
             
            </Typography>
          </div>
      </Paper>
    );
  }

  chooseEntry(keep){
    this.props.chooseQuotation(keep, this.props.citation);
  }
  
  filter(text){
    const {searchString} = this.props;
    var title="";
    let match = true;
    if(searchString.length==1){
      title = "";
      for (var i = 0; i<text.length; i++){
        if(searchString.toLowerCase().includes(text.charAt(i).toLowerCase())){
          title = title + "<mark>"+text.charAt(i)+"</mark>";
        } else {
          title = title + text.charAt(i);
        }
      }
    } else if(searchString.length>1){
      title = "";
      for (var i = 0; i<text.length; i++){
        if(searchString.charAt(0).toLowerCase()==(text.charAt(i).toLowerCase())){
          for(var j = 1; j<searchString.length; j++){
            if(i+j<text.length && searchString.charAt(j).toLowerCase()==(text.charAt(i+j).toLowerCase())){
              match = true;
            } else {
              match = false;
              title = title + text.substring(i, i+j+1);
              i = i+j;
              break;
            }
          }
          if(match){
            title = title + "<mark>"+text.substring(i, i+j)+"</mark>" + text.charAt(i+j);
            i = i+j;
          }
        } else {
          title = title + text.charAt(i);
        }
      }
    } else {
      title = text;
    }
      return(title);
  }

  search(){
    const {searchString} = this.props;
      if(this.props.citation.quotation!=undefined){
        let title = this.filter(this.props.citation.quotation);
        return(
          <div>
            <div dangerouslySetInnerHTML={{__html:title}}></div>
          </div>
        );
      } 
    }
}

QuotationCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  citation: PropTypes.object.isRequired
};

export default withStyles(styles)(QuotationCard);

import React, { Component } from 'react';
import PropTypes, { number } from 'prop-types';
import { withStyles } from '@material-ui/core';
import { withRouter, Link } from 'react-router-dom';
import compose from 'recompose/compose';
import { DisplayMode, SearchInput } from 'components';
import {
  Button,
  IconButton,
  CircularProgress,
  Typography,
  Box,

} from '@material-ui/core';
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon
} from '@material-ui/icons';
import { Dashboard as DashboardLayout } from 'layouts';
import service from 'services/headwords';
import ac from 'services/allCitations';
import countService from 'services/allCitations';
import AlphabetToolbar from '../../../components/AlphabetToolbar';
import HeadwordCard from './components/HeadwordCard';
import { state as appState } from 'App';

import styles from './styles';
import headwords from 'services/headwords';

class VolunteerHeadwords extends Component {
  signal = true;

  state = {
    isLoading: false,
    limit: 6,
    headwords: [],
    citationsCount: [],
    searchString: '',
    selectedLetter: null,
    error: null,
    user: appState.get('user'),
    currPosition: 0,
    currDisplayed: [],
    visibleHeadwords: [],
    progressLabel: "",
  };

  async getHeadwords(limit) {
    try {
      let prevSearch = localStorage.getItem('VHWListSearchString'); 
      if(prevSearch!=null){
        this.setState({searchString:prevSearch});
      }

      this.setState({ isLoading: true, progressLabel: "getting keywords" });
      const headwords = await service.getVHeadwords(limit);
      /*this.setState({ isLoading: true, progressLabel: "getting quotations counts" });
      const citationsCount = await ac.getVCitationsCount(limit);*/
      

      if (this.signal) {
        this.setState({
          isLoading: false,
          headwords: headwords,
          //citationsCount: citationsCount,
          limit
        });
        this.state.headwords.sort((a, b) =>  a.attributes.headword.localeCompare(b.attributes.headword));
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  selectLetter(selectedLetter) {
    this.setState({ selectedLetter });
  }

  
  onSearchChange(searchString) {
    this.setState({ searchString });
    localStorage.setItem('VHWListSearchString', searchString);
   // console.log(searchString);
  }

  componentWillMount() {
    this.signal = true;

    const { limit } = this.state;

    this.getHeadwords(limit);

    this._unwatch = appState.watch('user', user => {
      this.setState({ user });
    });
  }

  componentWillUnmount() {
    this._unwatch();
    this.signal = false;
  }

  canEdit() {
    return this.state.user && this.state.user.attributes.roles.includes('reviewer');
  }

  newHeadword() {
    this.props.history.push('vheadwords/new');
  }

  renderHeadwords() {
    const { classes, selectedUsers  } = this.props;
    const { isLoading, headwords, selectedLetter, searchString, currPosition, progressLabel, citationsCount} = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress size={110} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
          </Box>
            <Typography variant="h5" component="div" color="textSecondary">{
              progressLabel
            }</Typography>
        </div>
      );
    }
    
    this.state.headwords.sort((a, b) =>  a.attributes.headword.localeCompare(b.attributes.headword));
    this.state.visibleHeadwords = headwords.filter(headword => headword.attributes.headword!=null && headword.attributes.headword.toLowerCase().includes(searchString.toLowerCase()));
      
    
    if (this.state.visibleHeadwords.length === 0) {
      return (
        <Typography variant="h6">No keywords to display</Typography>
      );
    }
    if(this.state.visibleHeadwords.length > 249) {
      this.state.currDisplayed = this.state.visibleHeadwords.slice(currPosition, currPosition + 250);
      return (
        this.state.currDisplayed.map(headword => (
          <HeadwordCard key={headword.id} headword={headword} citationsCount={citationsCount}/>
        ))
      );  
    } else {
      return (
        this.state.visibleHeadwords.map(headword => (
          <HeadwordCard key={headword.id} headword={headword} citationsCount={citationsCount}/>
        ))
      );  
    }    
  }

  
  showMore(){
    const { visibleHeadwords, currPosition } = this.state;
    if(visibleHeadwords.length >= currPosition + 250){
      this.setState({currPosition: currPosition + 250});
      window.scrollTo(0,0);
    }
  }

  showLess(){
    const { visibleHeadwords, currPosition } = this.state;
    if(currPosition - 250>=0){
      this.setState({currPosition: currPosition - 250});
      window.scrollTo(0,0)
    }
  }

  render() {
    const { classes } = this.props;
    const { selectedLetter } = this.state;

    return (
      <DashboardLayout title="Keywords">
        <div className={classes.root}>
        <Link to={`/vheadwords/new`}>
        <div style={{width: '10%'}} className={classes.row}>
          <span className={classes.spacer} />
            <Button fullWidth='true' color="primary" size="small" variant="outlined">New Keyword</Button>
        </div>
        </Link>
        <div><br></br></div>
        <div style={{width: '30%'}} className={classes.row}>
        <SearchInput className={classes.searchInput} value={this.state.searchString} placeholder="Search" onChange={event => this.onSearchChange(event.target.value)}/>
        <div style={{width: '20%', marginLeft:"5px"}} >
        <Button onClick={event => this.onSearchChange((this.state.searchString + 'ȝ'))} fullWidth='true' color="primary" size="small" variant="outlined">Add ȝ </Button>
        </div>
        </div>
          <div className={classes.content}>
            {this.renderHeadwords()}
          </div>
          <div style={{width: '30%'}} className={classes.row}>
          <Button onClick={event => this.showLess()} color="primary" size="small" variant="outlined">Display Previous 250 </Button>
          <div style={{width: '5%'}}></div>
          <Button onClick={event => this.showMore()} color="primary" size="small" variant="outlined">Display Next 250 </Button>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

VolunteerHeadwords.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles)
)(VolunteerHeadwords);

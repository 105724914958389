import React, {Component} from 'react';
import {Switch, Route, Redirect} from 'react-router-dom';

// Views
import Home from './views/Home';
import Entries from './views/Entries';
import Entry from './views/Entry';
import KeywordResearch from './views/KeywordResearch';
import ShowKR from './views/KeywordResearch/UpdateKR';
import Sources from './views/Sources';
import ShowBook from './views/Sources/UpdateBook';
import ShowPeriodical from './views/Sources/UpdatePeriodical';
import ShowUtterance from './views/Sources/UpdateUtterance';
import ShowWebsite from './views/Sources/UpdateWebsite';
import Search from './views/Search';
import Merge from './views/Search/Merge';
import ListVolunteerHeadwords from './views/VolunteerHeadwords/ListVolunteerHeadwords';
import ShowVolunteerHeadword from './views/VolunteerHeadwords/ShowVolunteerHeadword';
import CreateVolunteerHeadword from './views/VolunteerHeadwords/CreateVolunteerHeadword';
import UpdateVolunteerHeadword from './views/VolunteerHeadwords/UpdateVolunteerHeadword';
import CreateVolunteerCitation from './views/VolunteerCitations/CreateVolunteerCitation';
import UpdateVolunteerCitation from './views/VolunteerCitations/UpdateVolunteerCitation';
/*import ListKR from './views/KeywordResearch/ListHeadwords';
import ShowKR from './views/KeywordResearch/ShowHeadword';
import CreateKR from './views/KeywordResearch/CreateHeadword';
import UpdateKR from './views/KeywordResearch/UpdateHeadword';
import ListSearch from './views/Search/ListHeadwords';
import ShowSearch from './views/Search/ShowHeadword';
import CreateSearch from './views/Search/CreateHeadword';
import UpdateSearch from './views/Search/UpdateHeadword';
import ListSource from './views/Sources/ListHeadwords';
import ShowSource from './views/Sources/ShowHeadword';
import CreateSource from './views/Sources/CreateHeadword';
import UpdateSource from './views/Sources/UpdateHeadword';*/
import ListHeadwords from './views/Headwords/ListHeadwords';
import ShowHeadword from './views/Headwords/ShowHeadword';
import CreateHeadword from './views/Headwords/CreateHeadword';
import UpdateHeadword from './views/Headwords/UpdateHeadword';
import CreateCitation from './views/Citations/CreateCitation';
import UpdateCitation from './views/Citations/UpdateCitation';
import Dashboard from './views/Dashboard';
import ProductList from './views/ProductList';
import UserList from './views/UserList';
import EditUser from './views/EditUser/editUser';
import AddUser from './views/EditUser/addUser';
import Typography from './views/Typography';
import Icons from './views/Icons';
import Account from './views/Account';
import Settings from './views/Settings';
import SignUp from './views/SignUp';
import SignIn from './views/SignIn';
import UnderDevelopment from './views/UnderDevelopment';
import NotFound from './views/NotFound';

export default class Routes extends Component {
  render() {
    return (
      <Switch>
        <Redirect exact from="/" to="/sign-in"/>
        {/*<Route component={ListKR} exact path="/keyword-research"/>
        <Route component={ShowKR} exact path="/keyword-research/:headwordId"/>
        <Route component={CreateKR} exact path="/keyword-research/new"/>
        <Route component={UpdateKR} exact path="/keyword-research/:headwordId/edit"/>
        <Route component={ListSearch} exact path="/search"/>
        <Route component={ShowSearch} exact path="/search/:headwordId"/>
        <Route component={CreateSearch} exact path="/search/new"/>
        <Route component={UpdateSearch} exact path="/search/:headwordId/edit"/>
        <Route component={ListSource} exact path="/source"/>
        <Route component={ShowSource} exact path="/source/:headwordId"/>
        <Route component={CreateSource} exact path="/source/new"/>
    <Route component={UpdateSource} exact path="/source/:headwordId/edit"/> */}
        <Route component={Home} exact path="/home"/>
        <Route component={Entries} exact path="/entries"/>
        <Route component={Entry} exact path="/entries/:entryId"/>
        <Route component={ListHeadwords} exact path="/headwords"/>
        <Route component={KeywordResearch} exact path="/keyword-research"/>
        <Route component={ShowKR} exact path="/keyword-research/:headwordId"/>
        <Route component={Sources} exact path="/sources"/>
        <Route component={ShowBook} exact path="/book/:bookId"/>
        <Route component={ShowPeriodical} exact path="/periodical/:periodicalId"/>
        <Route component={ShowUtterance} exact path="/utterance/:utteranceId"/>
        <Route component={ShowWebsite} exact path="/website/:websiteId"/>
        <Route component={Search} exact path="/search"/>
        <Route component={Merge} exact path="/merge-tool"/>
        <Route component={CreateHeadword} exact path="/headwords/new"/>
        <Route component={ShowHeadword} exact path="/headwords/:headwordId"/>
        <Route component={UpdateHeadword} exact path="/headwords/:headwordId/edit"/>
        <Route component={CreateCitation} exact path="/citations/new"/>
        <Route component={CreateCitation} exact path="/citations/new/:headwordId"/>
        <Route component={UpdateCitation} exact path="/citations/:citationId"/>
        <Route component={ListVolunteerHeadwords} exact path="/vheadwords"/>
        <Route component={CreateVolunteerHeadword} exact path="/vheadwords/new"/>
        <Route component={ShowVolunteerHeadword} exact path="/vheadwords/:headwordId"/>
        <Route component={UpdateVolunteerHeadword} exact path="/vheadwords/:headwordId/edit"/>
        <Route component={CreateVolunteerCitation} exact path="/vcitations/new"/>
        <Route component={CreateVolunteerCitation} exact path="/vcitations/new/:headwordId"/>
        <Route component={UpdateVolunteerCitation} exact path="/vcitations/:citationId"/>
        <Route component={Dashboard} exact path="/dashboard"/>
        <Route component={UserList} exact path="/users"/>
        <Route component={AddUser} exact path="/users/new"/>
        <Route component={EditUser} exact path="/users/:userId/edit"/>
        <Route component={ProductList} exact path="/products"/>
        <Route component={Typography} exact path="/typography"/>
        <Route component={Icons} exact path="/icons"/>
        <Route component={Account} exact path="/account"/>
        <Route component={Settings} exact path="/settings"/>
        <Route component={SignUp} exact path="/sign-up"/>
        <Route component={SignIn} exact path="/sign-in"/>
        <Route component={UnderDevelopment} exact path="/under-development"/>
        <Route component={NotFound} exact path="/not-found"/>
        <Redirect to="/not-found"/>
      </Switch>
    );
  }
}

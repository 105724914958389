import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import compose from 'recompose/compose';
import { DisplayMode, SearchInput } from 'components';
import {
  Button,
  IconButton,
  CircularProgress,
  Typography,
  Box,
  FormControlLabel,
  Checkbox
} from '@material-ui/core';
import {
  ChevronRight as ChevronRightIcon,
  ChevronLeft as ChevronLeftIcon
} from '@material-ui/icons';
import { Dashboard as DashboardLayout } from 'layouts';
import service from 'services/headwords';
import HeadwordCard from './components/HeadwordCard';
import { state as appState } from 'App';

import styles from './styles';
import headwords from 'services/headwords';
import keywordResearch from 'services/keyword-research';

class KeywordResearch extends Component {
  signal = true;

  state = {
    isLoading: false,
    limit: 6,
    headwords: [],
    searchString: '',
    selectedLetter: null,
    error: null,
    user: appState.get('user'),
    visibleHeadwords: [],
    visibleHeadwordsKRS: [],
    currDisplayed: [],
    currPosition: 0,
    progressLabel: "",
    hide:false
  };

  async getHeadwords(limit) {
    try {
      let prevSearch = localStorage.getItem('KRSearchString'); 
      let prevHide = localStorage.getItem('KRhide');
      let prevHideBool = (prevHide == 'true');
      if(prevSearch!=null){
        this.setState({searchString:prevSearch});
      }

      if(prevHideBool!=null){
        this.setState({hide:prevHideBool});
      }

      this.setState({ isLoading: true, progressLabel: "getting keywords" });
      const headwords = await service.getHeadwords(limit);
      this.setState({ progressLabel: "getting keyword research entries" });
      const krs = await keywordResearch.getKeywordResearch()
      if (this.signal) {
        this.setState({
          isLoading: false,
          headwords: headwords,
          krs: krs,
          limit
        });
        this.state.headwords.sort((a, b) =>  a.attributes.headword.localeCompare(b.attributes.headword));
      }
    } catch (error) {
      if (this.signal) {
        this.setState({
          isLoading: false,
          error
        });
      }
    }
  }

  selectLetter(selectedLetter) {
    this.setState({ selectedLetter });
  }

  
  onSearchChange(searchString) {
    this.setState({ searchString });
    localStorage.setItem('KRSearchString', searchString);
   // console.log(searchString);
  }

  componentWillMount() {
    this.signal = true;

    const { limit } = this.state;

    this.getHeadwords(limit);

    this._unwatch = appState.watch('user', user => {
      this.setState({ user });
    });
  }

  componentWillUnmount() {
    this._unwatch();
    this.signal = false;
  }

  canEdit() {
    return this.state.user && this.state.user.attributes.roles.includes('editor');
  }

  newHeadword() {
    this.props.history.push('headwords/new');
  }

  renderHeadwords() {
    const { classes, selectedUsers  } = this.props;
    const { isLoading, headwords, selectedLetter, searchString, krs, progressLabel, hide } = this.state;

    if (isLoading) {
      return (
        <div className={classes.progressWrapper}>
          <CircularProgress size={110} />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
          </Box>
            <Typography variant="h5" component="div" color="textSecondary">{
              progressLabel
            }</Typography>
        </div>
      );
    }
    
    this.state.headwords.sort((a, b) =>  a.attributes.headword.localeCompare(b.attributes.headword));
    this.state.visibleHeadwords = headwords.filter(headword => headword.attributes.headword!=null && searchString!=null && headword.attributes.headword.toLowerCase().includes(searchString.toLowerCase())); 
    if(hide){
      this.state.visibleHeadwordsKRS = [];
      for(var i=0; i<this.state.visibleHeadwords.length;i++){
        let headwordKRS = Object.values(krs.attributes).filter(kr => kr.headwordId==this.state.visibleHeadwords[i].id)
        if(headwordKRS.length!=0) {
            this.state.visibleHeadwordsKRS.push(this.state.visibleHeadwords[i])
        }
      }
      this.state.visibleHeadwords = this.state.visibleHeadwordsKRS;
    }

    if (this.state.visibleHeadwords.length === 0) {
      return (
        <Typography variant="h6">No keywords to display</Typography>
      );
    }

    if(this.state.visibleHeadwords.length > 249) {
      this.state.currDisplayed = this.state.visibleHeadwords.slice(this.state.currPosition, this.state.currPosition + 250);
      return (
        this.state.currDisplayed.map(headword => (
          <HeadwordCard key={headword.id} headword={headword} krs = {Object.values(krs.attributes).filter(kr => kr.headwordId==headword.id)} />
        ))
      );  
    } else {
      return (
        this.state.visibleHeadwords.map(headword => (
          <HeadwordCard key={headword.id} headword={headword} krs = {Object.values(krs.attributes).filter(kr => kr.headwordId==headword.id)}/>
        ))
      );  
    }
  }

  showMore(){
    const { visibleHeadwords, currPosition } = this.state;
    if(visibleHeadwords.length >= currPosition + 250){
      this.setState({currPosition: currPosition + 250});
      window.scrollTo(0,0);
    }
  }

  showLess(){
    const { visibleHeadwords, currPosition } = this.state;
    if(currPosition - 250>=0){
      this.setState({currPosition: currPosition - 250});
      window.scrollTo(0,0)
    }
  }

  toggleHide(){
    const {hide} = this.state;
    localStorage.setItem('KRhide', (!hide).toString());
    this.setState({hide:!hide})
  }

  render() {
    const { classes } = this.props;
    const { selectedLetter, hide } = this.state;

    return (
      <DashboardLayout title="Keyword Research">
        <div className={classes.root}>
        <div style={{width: "auto"}} className={classes.row}>
        <SearchInput className={classes.searchInput} value={this.state.searchString} placeholder="Search" onChange={event => this.onSearchChange(event.target.value)}/>
        <div style={{width: '50px', marginLeft:"5px"}} >
        <Button onClick={event => this.onSearchChange((this.state.searchString + 'ȝ'))} fullWidth='true' color="primary" size="small" variant="outlined">Add ȝ </Button>
        </div>
        <div style={{marginLeft:"25px"}}>
        <FormControlLabel
                      control={
                      <Checkbox
                          checked={hide}
                          onChange={(event) => this.toggleHide()}
                          name="hide"
                          color="primary"
                      />
                      }
              label="Hide Unused"
          />     
          </div>   
        </div>
          <div className={classes.content}>
            {this.renderHeadwords()}
          <div style={{width: '30%'}} className={classes.row}>
          <Button onClick={event => this.showLess()} color="primary" size="small" variant="outlined">Display Previous 250 </Button>
          <div style={{width: '5%'}}></div>
          <Button onClick={event => this.showMore()} color="primary" size="small" variant="outlined">Display Next 250 </Button>
          </div>
          </div>
        </div>
      </DashboardLayout>
    );
  }
}

KeywordResearch.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

export default compose(
  withRouter,
  withStyles(styles)
)(KeywordResearch);

import api from 'services/api';

const getAllCitations = async (limit) => {
  return await api.get(`allcitations`);
};

const getCitationsInRange = async (min, max) => {
  return await api.get(`allcitations/min/${min}/max/${max}`);
};

const getMaxCitationId = async () => {
  return await api.get(`allcitations/maxid`);
};

const getHeadwordsCitations = async (headwordId) => {
  return await api.get(`allcitations/headwordid/${headwordId}`);
};

const getBooksCitations = async (bookId) => {
  return await api.get(`allcitations/bookid/${bookId}`);
};

const getPeriodicalsCitations = async (periodicalId) => {
  return await api.get(`allcitations/periodicalid/${periodicalId}`);
};

const getUtterancesCitations = async (utteranceId) => {
  return await api.get(`allcitations/utteranceid/${utteranceId}`);
};

const getWebsitesCitations = async (websiteId) => {
  return await api.get(`allcitations/websiteid/${websiteId}`);
};

const getCitationsHeadwords = async (headwordId) => {
  return await api.get(`allcitations/hid/${headwordId}`);
};

const getCitationsCount = async (limit) => {
  return await api.get(`allcitations/countGroup`);
};

const getBookCitationsCount = async (limit) => {
  return await api.get(`allcitations/countBookCitations`);
};

const getPeriodicalCitationsCount = async (limit) => {
  return await api.get(`allcitations/countPeriodicalCitations`);
};

const getUtteranceCitationsCount = async (limit) => {
  return await api.get(`allcitations/countUtteranceCitations`);
};

const getWebsiteCitationsCount = async (limit) => {
  return await api.get(`allcitations/countWebsiteCitations`);
};

const countCitations = async (limit) => {
  return await api.get(`allcitations/count`);
};

const countKeywords = async (limit) => {
  return await api.get(`allcitations/countKey`);
};

const countBooks = async (limit) => {
  return await api.get(`allcitations/countBook`);
};

const countPeriodicals = async (limit) => {
  return await api.get(`allcitations/countPer`);
};

const countUtterances = async (limit) => {
  return await api.get(`allcitations/countUtt`);
};

const countWebsites = async (limit) => {
  return await api.get(`allcitations/countWeb`);
};

const getVCitationsCount = async (limit) => {
  return await api.get(`allcitations/vcountGroup`);
};

const countVCitations = async (limit) => {
  return await api.get(`allcitations/vcount`);
};

const countVKeywords = async (limit) => {
  return await api.get(`allcitations/vcountKey`);
};

const countVBooks = async (limit) => {
  return await api.get(`allcitations/vcountBook`);
};

const countVPeriodicals = async (limit) => {
  return await api.get(`allcitations/vcountPer`);
};

const countVUtterances = async (limit) => {
  return await api.get(`allcitations/vcountUtt`);
};

const countVWebsites = async (limit) => {
  return await api.get(`allcitations/vcountWeb`);
};

const countACitations = async (limit) => {
  return await api.get(`allcitations/acount`);
};

const countAKeywords = async (limit) => {
  return await api.get(`allcitations/acountKey`);
};

const countABooks = async (limit) => {
  return await api.get(`allcitations/acountBook`);
};

const countAPeriodicals = async (limit) => {
  return await api.get(`allcitations/acountPer`);
};

const countAUtterances = async (limit) => {
  return await api.get(`allcitations/acountUtt`);
};

const countAWebsites = async (limit) => {
  return await api.get(`allcitations/acountWeb`);
};

export default { getAllCitations, getCitationsInRange, getMaxCitationId, getHeadwordsCitations, getBooksCitations, getPeriodicalsCitations, getUtterancesCitations, getWebsitesCitations,
   getCitationsHeadwords, getCitationsCount, getBookCitationsCount, getPeriodicalCitationsCount, getUtteranceCitationsCount, 
   getWebsiteCitationsCount, countCitations, countKeywords, countBooks, countPeriodicals, countUtterances, countWebsites, 
   getVCitationsCount, countVCitations, countVKeywords, countVBooks, countVPeriodicals, countVUtterances, countVWebsites,
   countACitations, countAKeywords, countABooks, countAPeriodicals, countAUtterances, countAWebsites  };

import React, { Component } from 'react';
import { Link } from 'react-router-dom';

// Externals
import PropTypes from 'prop-types';
import classNames from 'classnames';

// Material helpers
import { withStyles } from '@material-ui/core';

// Material components
import { Typography } from '@material-ui/core';

// Shared components
import { Paper } from 'components';

// Component styles
import styles from './styles';

class HeadwordCard extends Component {
  render() {
    const { classes, className, headword, citationsCount } = this.props;

    const rootClassName = classNames(classes.root, className);
    let numCitations1 = citationsCount.find(c => c.headwordId === headword.id);
    let numCitations = numCitations1 ? numCitations1.count : 0

    return (
      <Link to={`/headwords/${headword.id}`}>
        <Paper className={rootClassName}>
          <div className={classes.details}>
            <Typography
              className={classes.title}
              variant="h4"
            >
              {this.title()}
            </Typography>
            <Typography
              className={classes.description}
              variant="body1"
            >
              {numCitations} Quotations
            </Typography>
          </div>
        </Paper>
      </Link>
    );
  }
  title(){
    if(this.props.headword.attributes.partOfSpeech!=null){
    return(
      this.props.headword.attributes.headword + " - " + this.props.headword.attributes.partOfSpeech
    );
    } else {
      return(
        this.props.headword.attributes.headword
      );
    }
  }
}

HeadwordCard.propTypes = {
  className: PropTypes.string,
  classes: PropTypes.object.isRequired,
  headword: PropTypes.object.isRequired
};

export default withStyles(styles)(HeadwordCard);
